import React from "react";
import {
  Avatar,
  Badge,
  Button,
  Flex,
  Link,
  Td,
  Text,
  Tr,
  useColorModeValue,
  useDisclosure,
  ButtonGroup,
  IconButton,
  Image

} from "@chakra-ui/react";
import { BsBoxArrowUpRight, BsFillTrashFill } from "react-icons/bs";
import { ViewIcon } from "@chakra-ui/icons";
import { AiFillEdit } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import dataConstant from "constants/dataConstant";


const RgpManagementTableRow = (props) => {
  const {
    passType,
    mode,
    status,
    txn,
    currentStatus,
    gatepassNumber,
    gatepassStatus,
    guardStatus,
    totalTime,
    serialnumber,
    logs,
    department,
    subdepartment,
    id,
    key,
    isLast,
    date,
    raisedMaterial,
    returnDate,
    returnQuantity,
    pendingQuantity,
    isNewEntry,
    invoices,
    supplier,
    material,
    employee,
    materialMode,
    vehicleNumber
  } = props;

  const textColor = useColorModeValue("gray.500", "white");
  const titleColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const raisedGatePassDetail = useSelector((state) => state.raisedGatePass.raisedGatePassDetail); // Make sure you have the correct state slice for device detail

  if(isNewEntry)
  {
    let rowSpanValue = 0;
    invoices.forEach((element) => {
        rowSpanValue += element.materials.length;
    });
    return (
        <>
        {invoices.map((item, invoiceIndex) => (
        <React.Fragment key={invoiceIndex}>
          {item.materials.map((data, itemIndex) => (
            <Tr key={itemIndex}>
              {/* Only render these cells in the first row */}
              {invoiceIndex === 0 && itemIndex === 0 && (
                   
                <>
                  <Td rowSpan={rowSpanValue}>
                  <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                    <Flex direction="column">
                      <Text
                        fontSize="md"
                        color={titleColor}
                        fontWeight="bold"
                        minWidth="100%"
                      >
                        {serialnumber}
                      </Text>
                    </Flex>
                  </Flex>
                </Td>
              <Td
              rowSpan={rowSpanValue}
                pl="0px"
                borderColor={borderColor}
                borderBottom={isLast ? "none" : "1px solid"}
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                  <Flex direction="column">
                    <Text
                      fontSize="md"
                      color={titleColor}
                      fontWeight="bold"
                      minWidth="100%"
                    >
                      {date}
                    </Text>
                  </Flex>
                </Flex>
              </Td>
              <Td 
              rowSpan={rowSpanValue}
              pl="0px"
                borderColor={borderColor}
                borderBottom={isLast ? "none" : "1px solid"}
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis">
                <Flex direction="column">
                  <Text fontSize="md" color={textColor} fontWeight="bold">
                    {gatepassNumber != ""?gatepassNumber:txn}
                  </Text>
                </Flex>
              </Td>
              <Td
                rowSpan={rowSpanValue}
                pl="0px"
                borderColor={borderColor}
                borderBottom={isLast ? "none" : "1px solid"}
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                  <Flex direction="column">
                    <Text
                      fontSize="md"
                      color={titleColor}
                      fontWeight="bold"
                      minWidth="100%"
                    >
                      {/* {passType.replace(/_/g, " ")} */}
                      {materialMode.replace(/_/g, " ")} <br/> {vehicleNumber}
                    </Text>
                  </Flex>
                </Flex>
              </Td>
              <Td 
              rowSpan={rowSpanValue}
              pl="0px"
                borderColor={borderColor}
                borderBottom={isLast ? "none" : "1px solid"}
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis">
                <Flex direction="column">
                  <Text fontSize="md" color={textColor} fontWeight="bold">
                    {supplier}
                  </Text>
                </Flex>
              </Td>
              
              
                </>
              )}
  
              {/* Invoice related data, span across items */}
              {itemIndex === 0 && (
                <Td rowSpan={item.materials.length}>
                  <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                    <Flex direction="column">
                      <Text fontSize="md" color={titleColor} fontWeight="bold" minWidth="100%">
                        {item.invoiceNumber}
                      </Text>
                    </Flex>
                  </Flex>
                </Td>
              )}
  
              {/* Per-item fields */}
              <Td>
                <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                  <Flex direction="column">
                    <Text fontSize="md" color={titleColor} fontWeight="bold" minWidth="100%">
                    {data.materialDescription} - {data.quantity} {data.uom}
                    </Text>
                  </Flex>
                </Flex>
              </Td>

              
            <Td>
            <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                <Flex direction="column">
                <Text fontSize="md" color={titleColor} fontWeight="bold" minWidth="100%">
                {data.returnDate}
                </Text>
                </Flex>
            </Flex>
            </Td>
             
              
              {itemIndex === 0 && (
                status == 'RGP' ? 
                <Td rowSpan={item.materials.length}>
                  <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                    <Flex direction="column">
                      <Text fontSize="md" color={titleColor} fontWeight="bold" minWidth="100%">
                        {item.approxValue}
                      </Text>
                    </Flex>
                  </Flex>
                </Td> : <></>
              )}
              
  
              {/* Action button */}
              {invoiceIndex === 0 && itemIndex === 0 && (
                 <>  
                  <Td pl="0px"
                    rowSpan={rowSpanValue} 
                    borderColor={borderColor}
                    borderBottom={isLast ? "none" : "1px solid"}
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis">
                    <Text fontSize="md" color={textColor} fontWeight="bold">
                    {returnQuantity}
                    </Text>
                    </Td>
                    <Td pl="0px"
                    rowSpan={rowSpanValue} 
                    borderColor={borderColor}
                    borderBottom={isLast ? "none" : "1px solid"}
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis">
                    <Text fontSize="md" color={textColor} fontWeight="bold">
                    {pendingQuantity}
                    </Text>
                    </Td>
                    
                    <Td pl="0px"
                    rowSpan={rowSpanValue} 
                    borderColor={borderColor}
                    borderBottom={isLast ? "none" : "1px solid"}
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis">
                    <Badge colorScheme={gatepassStatus == "APPROVED" ? "green" : "red"}>{gatepassStatus}</Badge>
                    </Td>
                    
                    <Td pl="0px"
                    rowSpan={rowSpanValue} 
                    borderColor={borderColor}
                    borderBottom={isLast ? "none" : "1px solid"}
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis">
                    <Flex direction="column">
                        <Text fontSize="md" color={textColor} fontWeight="bold">
                        {department}
                        </Text>
                    </Flex>
                    </Td>
                    <Td pl="0px"
                    rowSpan={rowSpanValue} 
                    borderColor={borderColor}
                    borderBottom={isLast ? "none" : "1px solid"}
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis">
                    <Flex direction="column">
                        <Text fontSize="md" color={textColor} fontWeight="bold">
                        {subdepartment}
                        </Text>
                    </Flex>
                    </Td>
                    <Td pl="0px"
                    rowSpan={rowSpanValue} 
                    borderColor={borderColor}
                    borderBottom={isLast ? "none" : "1px solid"}
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis">
                    <Flex direction="column">
                        <Text fontSize="md" color={textColor} fontWeight="bold">
                        {employee?.employeeName}
                        </Text>
                    </Flex>
                    </Td>
                    {/* <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
                    <Badge colorScheme={guardStatus == "IN" ? "green" : "red"}>{guardStatus}</Badge>
                    </Td> */}
                    <Td
                    rowSpan={rowSpanValue} 
                    borderColor={borderColor}
                    borderBottom={isLast ? "none" : null}
                    overflowX="auto" // Add overflowX property for horizontal scrollbar
                    >
                    <Flex direction="column">
                        {/* {logs.map((logItem, index) => ( */}
                        <Text fontSize="md" color={textColor} fontWeight="bold" whiteSpace="nowrap">
                            {logs[logs.length -1].description} 
                            {/* {logItem.formattedDateTime} */}
                        </Text>
                         {/* ))} */}
                    </Flex>
                    </Td>
                
            
                    <Td pl="0px"
                    rowSpan={rowSpanValue} 
                    borderColor={borderColor}
                    borderBottom={isLast ? "none" : "1px solid"}
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis">
                    <ButtonGroup>
                        <Link href={`#/admin/raisedGatePass-view-form/${id}`}>
                        <IconButton
                            colorScheme="blue"
                            icon={<ViewIcon />}
                            aria-label="Up"
                        />
                        </Link>
                    </ButtonGroup>
                    </Td>
                 </>
                
      
              )}
            </Tr>
          ))}
        </React.Fragment>
      ))}
        </>
      );
    
  }
  else if(passType == "MATERIAL_GATE_PASS")
  {
    let rowSpanValue = 0;
    material.forEach((element) => {
        rowSpanValue ++;
    });
    let materialItems = [{
        "id" : 1
    }];
    return (
        <>
        {material.map((item, invoiceIndex) => (
        <React.Fragment key={invoiceIndex}>
          {materialItems.map((data, itemIndex) => (
            <Tr key={itemIndex}>
              {/* Only render these cells in the first row */}
              {invoiceIndex === 0 && itemIndex === 0 && (
                   
                <>
                  <Td rowSpan={rowSpanValue}>
                  <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                    <Flex direction="column">
                      <Text
                        fontSize="md"
                        color={titleColor}
                        fontWeight="bold"
                        minWidth="100%"
                      >
                        {serialnumber}
                      </Text>
                    </Flex>
                  </Flex>
                </Td>
              <Td
              rowSpan={rowSpanValue}
                pl="0px"
                borderColor={borderColor}
                borderBottom={isLast ? "none" : "1px solid"}
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                  <Flex direction="column">
                    <Text
                      fontSize="md"
                      color={titleColor}
                      fontWeight="bold"
                      minWidth="100%"
                    >
                      {date}
                    </Text>
                  </Flex>
                </Flex>
              </Td>
              <Td 
              rowSpan={rowSpanValue}
              pl="0px"
                borderColor={borderColor}
                borderBottom={isLast ? "none" : "1px solid"}
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis">
                <Flex direction="column">
                  <Text fontSize="md" color={textColor} fontWeight="bold">
                    {gatepassNumber != ""?gatepassNumber:txn}
                  </Text>
                </Flex>
              </Td>
              <Td
                rowSpan={rowSpanValue}
                pl="0px"
                borderColor={borderColor}
                borderBottom={isLast ? "none" : "1px solid"}
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                  <Flex direction="column">
                    <Text
                      fontSize="md"
                      color={titleColor}
                      fontWeight="bold"
                      minWidth="100%"
                    >
                      
                    --
                    </Text>
                  </Flex>
                </Flex>
              </Td>
              
                </>
              )}
  
              {/* Invoice related data, span across items */}
              {itemIndex === 0 && (
                <>
                <Td
                rowSpan={materialItems.length}
                pl="0px"
                    borderColor={borderColor}
                    borderBottom={isLast ? "none" : "1px solid"}
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis">
                    <Flex direction="column">
                    <Text fontSize="md" color={textColor} fontWeight="bold">
                        {item.vendor == "" ? "--" : item.vendor}
                    </Text>
                    </Flex>
                </Td>
                
                <Td rowSpan={materialItems.length}>
                  <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                    <Flex direction="column">
                      <Text fontSize="md" color={titleColor} fontWeight="bold" minWidth="100%">
                        {item.invoiceNumber == "" ? "--" : item.invoiceNumber}
                      </Text>
                    </Flex>
                  </Flex>
                </Td>
                </>
              )}
  
              {/* Per-item fields */}
              
              
              {itemIndex === 0 && (
                <>
                <Td>
                <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                  <Flex direction="column">
                    <Text fontSize="md" color={titleColor} fontWeight="bold" minWidth="100%">
                    {item.materialDescription} - {item.quantity} {item.oum}
                    </Text>
                  </Flex>
                </Flex>
              </Td>
              <Td>
                    <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                    <Flex direction="column">
                        <Text fontSize="md" color={titleColor} fontWeight="bold" minWidth="100%">
                        {item.returnDate??'--'}
                        </Text>
                    </Flex>
                    </Flex>
                </Td>
                <Td rowSpan={materialItems.length}>
                  <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                    <Flex direction="column">
                      <Text fontSize="md" color={titleColor} fontWeight="bold" minWidth="100%">
                        {item.approxValue == "" ? "--" : item.approxValue}
                      </Text>
                    </Flex>
                  </Flex>
                </Td>
  
             
                </>
              )}
              
  
              {/* Action button */}
              {invoiceIndex === 0 && itemIndex === 0 && (
                 <>
                 <Td pl="0px"
                 rowSpan={rowSpanValue} 
                    borderColor={borderColor}
                    borderBottom={isLast ? "none" : "1px solid"}
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis">
                    <Text fontSize="md" color={textColor} fontWeight="bold">
                    {returnQuantity}
                    </Text>
                    </Td>
                    <Td pl="0px"
                    rowSpan={rowSpanValue} 
                    borderColor={borderColor}
                    borderBottom={isLast ? "none" : "1px solid"}
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis">
                    <Text fontSize="md" color={textColor} fontWeight="bold">
                    {pendingQuantity}
                    </Text>
                    </Td>
                 <Td pl="0px"
                 rowSpan={rowSpanValue} 
                  borderColor={borderColor}
                  borderBottom={isLast ? "none" : "1px solid"}
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis">
                  <Badge colorScheme={gatepassStatus == "APPROVED" ? "green" : "red"}>{gatepassStatus}</Badge>
                  </Td>
                  
                  <Td pl="0px"
                  rowSpan={rowSpanValue} 
                  borderColor={borderColor}
                  borderBottom={isLast ? "none" : "1px solid"}
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis">
                  <Flex direction="column">
                      <Text fontSize="md" color={textColor} fontWeight="bold">
                      {department}
                      </Text>
                  </Flex>
                  </Td>
                  <Td pl="0px"
                  rowSpan={rowSpanValue} 
                  borderColor={borderColor}
                  borderBottom={isLast ? "none" : "1px solid"}
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis">
                  <Flex direction="column">
                      <Text fontSize="md" color={textColor} fontWeight="bold">
                      {subdepartment}
                      </Text>
                  </Flex>
                  </Td>
                  <Td pl="0px"
                  rowSpan={rowSpanValue} 
                  borderColor={borderColor}
                  borderBottom={isLast ? "none" : "1px solid"}
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis">
                  <Flex direction="column">
                      <Text fontSize="md" color={textColor} fontWeight="bold">
                      {employee?.employeeName}
                      </Text>
                  </Flex>
                  </Td>
                  <Td
                  rowSpan={rowSpanValue} 
                  borderColor={borderColor}
                  borderBottom={isLast ? "none" : null}
                  overflowX="auto" // Add overflowX property for horizontal scrollbar
                  >
                  <Flex direction="column">
                    {/* {logs.map((logItem, index) => ( */}
                    <Text 
                    //   key={index}
                    fontSize="md" color={textColor} fontWeight="bold" whiteSpace="nowrap">
                        {logs[logs.length-1].description} 
                    </Text>
                    {/* ))} */}
                </Flex>
                  </Td>
              
          
                  <Td pl="0px"
                  rowSpan={rowSpanValue} 
                  borderColor={borderColor}
                  borderBottom={isLast ? "none" : "1px solid"}
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis">
                  <ButtonGroup>
                  <Link href={`#/admin/raisedGatePass-view-form/${id}`}>
                      <IconButton
                          colorScheme="blue"
                          icon={<ViewIcon />}
                          aria-label="Up"
                      />
                      </Link>
                  </ButtonGroup>
                  </Td>
                 </>
                
      
              )}
            </Tr>
          ))}
        </React.Fragment>
      ))}
        </>
      );
  }
  else
  {
    return (
        <>
          <Tr>
          <Td
              >
                <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                  <Flex direction="column">
                    <Text
                      fontSize="md"
                      color={titleColor}
                      fontWeight="bold"
                      minWidth="100%"
                    >
                      {serialnumber}
                    </Text>
                  </Flex>
                </Flex>
              </Td>
              <Td
              pl="0px"
              borderColor={borderColor}
              borderBottom={isLast ? "none" : "1px solid"}
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis">
              <Flex direction="column">
                <Text fontSize="md" color={textColor} fontWeight="bold">
                  {date}
                </Text>
              </Flex>
            </Td>
              <Td pl="0px"
              borderColor={borderColor}
              borderBottom={isLast ? "none" : "1px solid"}
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis">
              <Flex direction="column">
                <Text fontSize="md" color={textColor} fontWeight="bold">
                  {gatepassNumber != ""? gatepassNumber: txn}
                </Text>
              </Flex>
            </Td>
            <Td
              pl="0px"
              borderColor={borderColor}
              borderBottom={isLast ? "none" : "1px solid"}
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                <Flex direction="column">
                  <Text
                    fontSize="md"
                    color={titleColor}
                    fontWeight="bold"
                    minWidth="100%"
                  >
                    BY VEHICLE
                    <br/>
                    {raisedMaterial?.vehicleNumber}
                    {/* {passType} */}
                  </Text>
                </Flex>
              </Flex>
            </Td>
    
            <Td pl="0px"
              borderColor={borderColor}
              borderBottom={isLast ? "none" : "1px solid"}
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis">
              <Flex direction="column">
                <Text fontSize="md" color={textColor} fontWeight="bold">
                  {raisedMaterial?.vendor}
                </Text>
              </Flex>
            </Td>
            <Td pl="0px"
              borderColor={borderColor}
              borderBottom={isLast ? "none" : "1px solid"}
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis">
              <Flex direction="column">
                <Text fontSize="md" color={textColor} fontWeight="bold">
                  --
                </Text>
              </Flex>
            </Td>
            <Td pl="0px"
              borderColor={borderColor}
              borderBottom={isLast ? "none" : "1px solid"}
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis">
            <Text fontSize="md" color={textColor} fontWeight="bold">
            {raisedMaterial?.materialDescription} - {raisedMaterial?.quantity} {raisedMaterial?.oum}
            </Text>
            </Td>
            <Td
            pl="0px"
            borderColor={borderColor}
            borderBottom={isLast ? "none" : "1px solid"}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis">
            <Text fontSize="md" color={textColor} fontWeight="bold">
                  {returnDate}
                </Text>
            </Td>
            <Td
            pl="0px"
            borderColor={borderColor}
            borderBottom={isLast ? "none" : "1px solid"}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis">
            <Text fontSize="md" color={textColor} fontWeight="bold">
                  --
                </Text>
            </Td>
            <Td pl="0px"
              borderColor={borderColor}
              borderBottom={isLast ? "none" : "1px solid"}
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis">
            <Text fontSize="md" color={textColor} fontWeight="bold">
            {returnQuantity}
            </Text>
            </Td>
            <Td pl="0px"
              borderColor={borderColor}
              borderBottom={isLast ? "none" : "1px solid"}
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis">
            <Text fontSize="md" color={textColor} fontWeight="bold">
            {pendingQuantity}
            </Text>
            </Td>
            
            <Td pl="0px"
              borderColor={borderColor}
              borderBottom={isLast ? "none" : "1px solid"}
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis">
              <Badge colorScheme={gatepassStatus == "APPROVED" ? "green" : "red"}>{gatepassStatus}</Badge>
            </Td>
            
            <Td pl="0px"
              borderColor={borderColor}
              borderBottom={isLast ? "none" : "1px solid"}
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis">
              <Flex direction="column">
                <Text fontSize="md" color={textColor} fontWeight="bold">
                  {department}
                </Text>
              </Flex>
            </Td>
            <Td pl="0px"
              borderColor={borderColor}
              borderBottom={isLast ? "none" : "1px solid"}
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis">
              <Flex direction="column">
                <Text fontSize="md" color={textColor} fontWeight="bold">
                  {subdepartment}
                </Text>
              </Flex>
            </Td>
            <Td pl="0px"
              borderColor={borderColor}
              borderBottom={isLast ? "none" : "1px solid"}
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis">
              <Flex direction="column">
                <Text fontSize="md" color={textColor} fontWeight="bold">
                  {employee?.employeeName}
                </Text>
              </Flex>
            </Td>
            {/* <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
              <Badge colorScheme={guardStatus == "IN" ? "green" : "red"}>{guardStatus}</Badge>
            </Td> */}
            <Td
              borderColor={borderColor}
              borderBottom={isLast ? "none" : null}
              overflowX="auto" // Add overflowX property for horizontal scrollbar
            >
              <Flex direction="column">
                {/* {logs.map((logItem, index) => ( */}
                  <Text fontSize="md" color={textColor} fontWeight="bold" whiteSpace="nowrap">
                    {logs[logs.length -1].description} 
                    {/* {logItem.formattedDateTime} */}
                  </Text>
                {/* ))} */}
              </Flex>
            </Td>
           
    
            <Td pl="0px"
              borderColor={borderColor}
              borderBottom={isLast ? "none" : "1px solid"}
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis">
              <ButtonGroup>
                <Link href={`#/admin/raisedGatePass-view-form/${id}`}>
                  <IconButton
                    colorScheme="blue"
                    icon={<ViewIcon />}
                    aria-label="Up"
                  />
                </Link>
              </ButtonGroup>
            </Td>
          </Tr>
        </>
      );
  }
};

export default RgpManagementTableRow;
