import React, {useState} from "react";
import {
  chakra,
  Td,
  Tr,
  Text,
  useColorModeValue,
  Flex,
  ButtonGroup,
  IconButton,
  Link,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Box,
  Button,
} from "@chakra-ui/react";
import { ViewIcon } from "@chakra-ui/icons";

const CanteenInOutTableRow = (props) => {
  const {
    typeOfItems,
    deliveryAgentName,
    driverPhoto,
    checkpostInName,
    checkpostInTime,
    checkpostInGuardName,
    checkpostOutName,
    checkpostOutTime,
    checkpostOutGuardName,
    isLast,
    serialnumber,
    id,
    date,
    materialBy,
    canteenItems,
    supplierName
  } = props;

  const textColor = useColorModeValue("gray.500", "white");
  const titleColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [modalImage , setModalImage]= useState("");
  // Calculate total rowspan based on canteenItems
  let rowSpanValue = 0;
  canteenItems.forEach((element) => {
    rowSpanValue += element.items.length;
  });
  const { isOpen, onOpen, onClose } = useDisclosure()
  // Main condition to check if materialBy is valid
  if (materialBy && materialBy !== "") {
  

    return (
      <>
        {canteenItems.map((item, invoiceIndex) => (
          <React.Fragment key={invoiceIndex}>
            {item.items.map((data, itemIndex) => (
              <Tr key={itemIndex}>
                {/* Only render these cells in the first row */}
                {invoiceIndex === 0 && itemIndex === 0 && (
                  <>
                    <Td rowSpan={rowSpanValue}>
                      <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                        <Flex direction="column">
                          <Text fontSize="md" color={titleColor} fontWeight="bold" minWidth="100%">
                            {serialnumber}
                          </Text>
                        </Flex>
                      </Flex>
                    </Td>
                    <Td rowSpan={rowSpanValue}>
                      <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                        <Flex direction="column">
                          <Text fontSize="md" color={titleColor} fontWeight="bold" minWidth="100%">
                            {date}
                          </Text>
                        </Flex>
                      </Flex>
                    </Td>
                    <Td rowSpan={rowSpanValue}>
                      <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                        <Flex direction="column">
                          <Text fontSize="md" color={titleColor} fontWeight="bold" minWidth="100%">
                            {supplierName}
                          </Text>
                        </Flex>
                      </Flex>
                    </Td>
                  </>
                )}

                {/* Invoice related data, span across items */}
                {itemIndex === 0 && (
                  <Td rowSpan={item.items.length}>
                    <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                      <Flex direction="column">
                        <Text fontSize="md" color={titleColor} fontWeight="bold" minWidth="100%">
                          {item.invoiceNo}
                        </Text>
                      </Flex>
                    </Flex>
                  </Td>
                )}

                {/* Per-item fields */}
                <Td>
                  <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                    <Flex direction="column">
                      <Text fontSize="md" color={titleColor} fontWeight="bold" minWidth="100%">
                      {data.canteenCategoryName}
                      </Text>
                    </Flex>
                  </Flex>
                </Td>
                <Td>
                  <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                    <Flex direction="column">
                      <Text fontSize="md" color={titleColor} fontWeight="bold" minWidth="100%">
                      {data.quantity} {data.uom}
                      </Text>
                    </Flex>
                  </Flex>
                </Td>

                {/* Delivery Agent details */}
                {itemIndex === 0 && (
                  <Td rowSpan={item.items.length}>
                    <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                      <Flex direction="column">
                        <Text fontSize="md" color={textColor} fontWeight="bold">
                        <Image
                            boxSize='35px'
                            objectFit='cover'
                            src={item.invoiceImage}
                            alt='Dan Abramov'
                            cursor="pointer"
                            onClick={()=>{
                              onClose();
                              setModalImage(item.invoiceImage);
                              onOpen();
                            }}
                          />
                        </Text>
                      </Flex>
                    </Flex>
                  </Td>
                )}

                {/* Action button */}
                {invoiceIndex === 0 && itemIndex === 0 && (
                  <Td rowSpan={rowSpanValue}>
                    <ButtonGroup>
                      <Link href={`#/admin/canteen-view/${id}`}>
                        <IconButton colorScheme="blue" icon={<ViewIcon />} aria-label="View" />
                      </Link>
                    </ButtonGroup>
                  </Td>
                )}
              </Tr>
            ))}
          </React.Fragment>
        ))}
        <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Invoice</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <Box>
          <Image src={modalImage} alt='Invoice' />
        </Box>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      </>
    );
  } else {
    return (
      <>
        <Tr>
          <Td>
            <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
              <Flex direction="column">
                <Text fontSize="md" color={titleColor} fontWeight="bold" minWidth="100%">
                  {serialnumber}
                </Text>
              </Flex>
            </Flex>
          </Td>
          <Td>
            <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
              <Flex direction="column">
                <Text fontSize="md" color={titleColor} fontWeight="bold" minWidth="100%">
                  {date}
                </Text>
              </Flex>
            </Flex>
          </Td>
          <Td>
            <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
              <Flex direction="column">
                <Text fontSize="md" color={titleColor} fontWeight="bold" minWidth="100%">
                  {deliveryAgentName}
                </Text>
              </Flex>
            </Flex>
          </Td>
          <Td>
            <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
              <Flex direction="column">
                <Text fontSize="md" color={titleColor} fontWeight="bold" minWidth="100%">
                  NA
                </Text>
              </Flex>
            </Flex>
          </Td>
          <Td>
            <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
              <Flex direction="column">
                <Text fontSize="md" color={titleColor} fontWeight="bold" minWidth="100%">
                  {typeOfItems}
                </Text>
              </Flex>
            </Flex>
          </Td>
          <Td>
            <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
              <Flex direction="column">
                <Text fontSize="md" color={titleColor} fontWeight="bold" minWidth="100%">
                --
                </Text>
              </Flex>
            </Flex>
          </Td>
          <Td>
            <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
              <Flex direction="column">
                <Text fontSize="md" color={textColor} fontWeight="bold">
                  --
                </Text>
              </Flex>
            </Flex>
          </Td>
          <Td>
            <ButtonGroup>
              <Link href={`#/admin/canteen-view/${id}`}>
                <IconButton colorScheme="blue" icon={<ViewIcon />} aria-label="View" />
              </Link>
            </ButtonGroup>
          </Td>
        </Tr>
        <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Invoice</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <Box>
          <Image src={modalImage} alt='Invoice' />
        </Box>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      </>
    );
  }
};

export default CanteenInOutTableRow;
