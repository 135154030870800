import React, { useEffect, useState ,useRef} from 'react';
import { Flex,Button, InputGroup, Spacer, Table, Tbody, Text, Td, Th, Thead, Tr, useColorModeValue, InputLeftElement, IconButton, Input, Icon, Link } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { useToast } from '@chakra-ui/react';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import HighAlertPagination from './HighAlertPagination'; // Import your HighAlertPagination component
import { AddIcon } from '@chakra-ui/icons';
import { HIGH_ALERT_CLEAR_SNACKBAR } from 'constants/types'; // Import HIGH_ALERT_CLEAR_SNACKBAR
import { highAlertGetAll } from '../../actions/highAlertAction'; // Import your highAlertGetAll action
import HighAlertTableRow from './HighAlertTableRow'; // Import your HighAlertTableRow component
import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import HighAlertAdvanceSearch from './HighAlertAdvanceSearch';
import { Skeleton, SkeletonCircle, SkeletonText,Stack } from '@chakra-ui/react'
import {
  HIGH_ALERT_SEARCH
} from '../../constants/types';
const HighAlertTable = () => {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const responseStatus = useSelector(state => state.highAlert.status);
  const responseMessage = useSelector(state => state.highAlert.text);
  const loading = useSelector(state => state.highAlert.loading);
  const highAlertList = useSelector(state => state.highAlert.highAlertList);
  const response = useSelector(state => state.highAlert.response);
  const showSnackbar = useSelector(state => state.highAlert.showSnackbar);
  const currentPage = useSelector(state => state.highAlert.page);
  const limit = useSelector(state => state.highAlert.limit);
  const dispatch = useDispatch();
  const toast = useToast();
  const searchIconColor = useColorModeValue("gray.700", "gray.200");
  const inputBg = useColorModeValue("#edf3f8", "navy.800");
  const [search, setSearch] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const tableRef = useRef(null);
  const history = useHistory();
  console.log("highAlertList22222")
console.log(highAlertList); 
 useEffect(() => {
  dispatch({
    type: HIGH_ALERT_SEARCH,
    payload: {
      searching: ""
    }
  });
    dispatch(highAlertGetAll());
  }, [dispatch]);

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: HIGH_ALERT_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("desc");
    }
    const sorting = "&sortOrder=" + sortOrder + "&column=" + column;
    dispatch({
      type: HIGH_ALERT_SEARCH,
      payload: {
        searching: ""
      }
    });
    dispatch(highAlertGetAll(sorting));
  };

  useEffect(() => {
    const searching = "&search=" + search;
    dispatch({
      type: HIGH_ALERT_SEARCH,
      payload: {
        searching: ""
      }
    });
    dispatch(highAlertGetAll(searching));
  }, [search]);
  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])
  return (
    <>
    <HighAlertAdvanceSearch></HighAlertAdvanceSearch>
    <Flex direction="column" pt={{ base: "120px", md: "50px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
      <Flex>
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
            High Alerts
            </Text>
          </CardHeader>
          <Spacer />
          {/* <Flex justify="flex-end" style={{ padding: '10px' ,marginTop : '-5px' ,marginRight :'12px' }}> 
            <Link href={`#/admin/highalert-form`}>
            <IconButton
              colorScheme="blue"
              aria-label="Search database"
              icon={<AddIcon />}
            />
          </Link>
          </Flex> */}
          <Flex justify="flex-end" style={{ padding: '10px', marginTop: '-5px', marginRight: '12px' }}>
            <DownloadTableExcel
              filename="High Alert Table"
              sheet="users"
              currentTableRef={tableRef.current}
            >
              <Button colorScheme="blue" _hover={{ backgroundColor: 'black', color: 'white' }}>
                Export Excel
              </Button>
            </DownloadTableExcel>
          </Flex>
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              <InputGroup borderRadius="8px" w="200px">
                <InputLeftElement
                  children={
                    <IconButton
                      bg="inherit"
                      borderRadius="inherit"
                      _hover="none"
                      _active={{
                        bg: "inherit",
                        transform: "none",
                        borderColor: "transparent",
                      }}
                      _focus={{
                        boxShadow: "none",
                      }}
                      icon={
                        <SearchIcon
                          color={searchIconColor}
                          w="15px"
                          h="15px"
                        />
                      }
                    ></IconButton>
                  }
                />
                <Input
                  type="pass"
                  variant="search"
                  fontSize="xs"
                  bg={inputBg}
                  onKeyUp={(e) => {
                    setSearch(e.target.value);
                    if (e.target.value.length < 3) {
                      setSearch("");
                    }
                  }}
                  placeholder="Type here..."
                />
              </InputGroup>
            </Text>
          </CardHeader>
        </Flex>
        <CardBody pb={4}>
        <div style={{ overflowX: "auto",maxHeight:"500px" }}>
       
          {loading ? <Stack>
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                  </Stack>:<Table variant="simple" color={textColor} ref={tableRef}>
            <Thead className='stick-header'>
            <Tr my=".8rem" pl="0px" color="gray.400">
            <Th
                    pl="15px"
                    borderColor={borderColor}
                    color="gray.400"
                    cursor="pointer"
                  >
                   SNO
                  </Th>
                <Th
                  pl="0px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("name")}
                  cursor="pointer"
                >
                 checkpost
                  {sortColumn === "name" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("photo")}
                  cursor="pointer"
                >
                  Guard
                  {sortColumn === "photo" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("number")}
                  cursor="pointer"
                >
                  Pin
                  {sortColumn === "number" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
               
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("guardId")}
                  cursor="pointer"
                >
                  Status
                  {sortColumn === "guardId" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {highAlertList ? (
                highAlertList.map((row, index, arr) => (
                  <HighAlertTableRow
                   serialnumber={((currentPage - 1)*limit) + (index+1)}
                   checkpost = {row.checkpost.name}
                   guard = {row.guard.name}
                   pin = {row.pin}
                   status = {row.status}
                   id = {row._id}
                   isLast={index === arr.length - 1 ? true : false}
                  />
                ))
              ) : (
                <h1></h1>
              )}
            </Tbody>
          </Table>}
          </div>
          {highAlertList.length === 0 ? (
            <></>
          ) : (
            <HighAlertPagination
             totalPages={response.totalPages}
              currentPage={response.currentPage}
              perPage={response.perPage}
              sortBy={sortColumn}
              sortOrder={sortOrder}
              handlePageClick={(data) => {
                const selectedPage = data.selected + 1;
                dispatch(highAlertGetAll(`&page=${selectedPage}`));
              }}
            />
          )}
        </CardBody>
      </Card>
    </Flex>
    </>
  );
};

export default HighAlertTable;
