// Chakra imports
import {
    Flex,
    InputGroup,
    Spacer,
    Table,
    Tbody,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    InputLeftElement,
    IconButton,
    Input,
    Icon,
    Link,
  } from "@chakra-ui/react";
  import { SearchIcon } from "@chakra-ui/icons";
  // Custom components
  import Card from "components/Card/Card.js";
  import CardBody from "components/Card/CardBody.js";
  import CardHeader from "components/Card/CardHeader.js";
  import Pagination from "components/Tables/Pagination";
  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import { useToast } from "@chakra-ui/react";
  import {
    factoryGetAll,
    factoryDelete,
    factoryAddOrUpdate,
    factoryClearShowSnackbar,
  } from "../../actions/FactoryActions";
  import { FACTORY_CLEAR_SNACKBAR } from "constants/types";
  import { FaChevronDown, FaChevronUp } from "react-icons/fa";
  import FactoryTableRow from "./FactoryTableRow";
  
  
  
  import { AddIcon } from '@chakra-ui/icons';
  
  
  function FactoryTable() {
    const textColor = useColorModeValue("gray.700", "white");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    const responseStatus = useSelector((state) => state.factory.status);
    const responseMessage = useSelector((state) => state.factory.text);
    const loading = useSelector((state) => state.factory.loading);
    const factoryList = useSelector((state) => state.factory.factoryList);
    const response = useSelector((state) => state.factory.response);
    const showSnackbar = useSelector((state) => state.factory.showSnackbar);
    const dispatch = useDispatch();
    const toast = useToast();
    const searchIconColor = useColorModeValue("gray.700", "gray.200");
    const inputBg = useColorModeValue("#edf3f8", "navy.800");
    const [search, setSearch] = useState("");
    const [sortColumn, setSortColumn] = useState("");
    const [sortOrder, setSortOrder] = useState("asc");
  
    useEffect(() => {
      dispatch(factoryGetAll());
    }, [dispatch]);
  
    useEffect(() => {
      if (showSnackbar) {
        if (responseStatus === "OK" && loading === false) {
          if (responseMessage !== "") {
            toastFunction(responseMessage, "success");
          }
        } else if (responseStatus === "NOK" && loading === false) {
          if (responseMessage !== "") {
            toastFunction(responseMessage, "error");
          }
        }
      }
      dispatch({
        type: FACTORY_CLEAR_SNACKBAR,
        payload: {
          showSnackbar: false,
        },
      });
    }, [loading]);
  
    const toastFunction = (title, status) => {
      toast({
        title: title,
        status: status,
        duration: 3000,
        isClosable: true,
      });
    };
  
    const handleSort = (column) => {
      if (column === sortColumn) {
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
      } else {
        setSortColumn(column);
        setSortOrder("desc");
      }
      const sorting = "&sortOrder=" + sortOrder + "&column=" + column;
      dispatch(factoryGetAll(sorting));
    };
  
    useEffect(() => {
      const searching = "&search=" + search;
      console.log(searching);
      dispatch(factoryGetAll(searching));
    }, [search]);
  
    return (
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
          <Flex>
            <CardHeader p="6px 0px 22px 0px">
              <Text fontSize="xl" color={textColor} fontWeight="bold">
                Factory Table
              </Text>
            </CardHeader>
            <Spacer />
  
            
            <Flex justify="flex-end" style={{ padding: '10px' ,marginTop : '-5px' ,marginRight :'12px' }}> 
                    <Link href={`#/admin/factory-form`}>
                    <IconButton
                      colorScheme="blue"
                      aria-label="Search database"
                      icon={<AddIcon />}
                    />
                    </Link>
            </Flex>
  
  
            <CardHeader p="6px 0px 22px 0px">
              <Text fontSize="xl" color={textColor} fontWeight="bold">
                <InputGroup borderRadius="8px" w="200px">
                  <InputLeftElement
                    children={
                      <IconButton
                        bg="inherit"
                        borderRadius="inherit"
                        _hover="none"
                        _active={{
                          bg: "inherit",
                          transform: "none",
                          borderColor: "transparent",
                        }}
                        _focus={{
                          boxShadow: "none",
                        }}
                        icon={
                          <SearchIcon
                            color={searchIconColor}
                            w="15px"
                            h="15px"
                          />
                        }
                      ></IconButton>
                    }
                  />
  
                  
                  <Input
                  type="pass"
                  variant="search"
                  fontSize="xs"
                  bg={inputBg}
                  onKeyUp={(e) => {
                      setSearch(e.target.value);
                      if (e.target.value.length < 3) {
                        setSearch("");
                      }
                    }}
                    placeholder="Type here..."
                  />
                </InputGroup>
              </Text>
            </CardHeader>
          </Flex>
          <CardBody pb={4}>
            <Table variant="simple" color={textColor}>
              <Thead>
                <Tr my=".8rem" pl="0px" color="gray.400">
                  <Th
                    pl="0px"
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("name")}
                    cursor="pointer"
                  >
                    Factory Name
                    {sortColumn === "name" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("image")}
                    cursor="pointer"
                  >
                    Factory Address
                    {sortColumn === "image" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("status")}
                    cursor="pointer"
                  >
                    City
                    {sortColumn === "status" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("position")}
                    cursor="pointer"
                  >
                    State/Province
                    {sortColumn === "position" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("position")}
                    cursor="pointer"
                  >
                    Country
                    {sortColumn === "position" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("position")}
                    cursor="pointer"
                  >
                    Contact Person
                    {sortColumn === "position" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("position")}
                    cursor="pointer"
                  >
                    Contact Email
                    {sortColumn === "position" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("position")}
                    cursor="pointer"
                  >
                    Contact Phone Number
                    {sortColumn === "position" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("position")}
                    cursor="pointer"
                  >
                    Industry Type
                    {sortColumn === "position" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("position")}
                    cursor="pointer"
                  >
                    Year of Establishment
                    {sortColumn === "position" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th borderColor={borderColor}></Th>
                  {/* <Th borderColor={borderColor}></Th> */}
                </Tr>
              </Thead>
              <Tbody>
  
              { factoryList ? (factoryList.map((row, index, arr) => {
                return (
                  <FactoryTableRow
                  factoryName={row.factoryName}
                  address={row.address}
                  City= {row.city}
                  state= {row.state}
                  country= {row.country}
                  contactPerson= {row.contactPerson}
                  contactEmail= {row.contactEmail}
                  contactPhoneNumber= {row.contactPhoneNumber}
                  industryType= {row.industryType}
                  yearOfEstablishment= {row.yearOfEstablishment}
                    isLast={index === arr.length - 1 ? true : false}
                    id={row._id}
                    
                  />
                );
              })) : (<h1></h1>)
                }
  
              </Tbody>
            </Table>
            {factoryList ? (factoryList.length === 0 ? (
              <></>
            ) : (
              <Pagination
                totalPages={response.totalPages}
                currentPage={response.currentPage}
                perPage={response.perPage}
                sortBy={sortColumn}
                sortOrder={sortOrder}
                handlePageClick={(data) => {
                  const selectedPage = data.selected + 1;
                  dispatch(factoryGetAll(`&page=${selectedPage}`));
                }}
              />
            )) :(<></>)}
          </CardBody>
        </Card>
      </Flex>
    );
  }
  
  export default FactoryTable;