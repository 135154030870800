import React, { useEffect, useState,useRef } from 'react';
import { Flex, InputGroup, Spacer, Table, Tbody, Text, Td, Th, Thead, Tr, useColorModeValue, InputLeftElement, IconButton, Input, Icon, Link ,Button, Tabs, TabList, TabPanels, TabPanel, Tab,Badge} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';

import { useToast } from '@chakra-ui/react';

import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';

import { AddIcon } from '@chakra-ui/icons';
import { Skeleton, SkeletonCircle, SkeletonText,Stack } from '@chakra-ui/react'
import { useParams ,useLocation,useHistory} from "react-router-dom/cjs/react-router-dom.min";
import axios from 'axios';
import { server_url } from "constants/types";
import { meetingroombookingsListGetAll } from '../../actions/meetingroombookingsActions';
import { MEETING_ROOM_BOOKINGS_CLEAR_SNACKBAR ,MEETING_ROOM_BOOKINGS_SEARCH,MEETING_ROOM_BOOKINGS_ERROR} from 'constants/types';
import MeetingRoomBookingsTable from './MeetingRoomBookingsTable';
import MeetingRoomBookingsAdvanceSearch from './MeetingRoomBookingsAdvanceSearch';
const MeetingRoomBookingsTabs = (props) => {
    const {
        module=1,
      } = props;
  const tableRef = useRef(null);
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const dispatch = useDispatch();
  const toast = useToast();
  const searchIconColor = useColorModeValue("gray.700", "gray.200");
  const inputBg = useColorModeValue("#edf3f8", "navy.800");
  const [search, setSearch] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [tab, setTab] = useState(0);
  const [tabValue, setTabValue] = useState(0);
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const totalItems = useSelector(state => state.inspectionSummary.totalItems);
  const [pastTotal, setPastTotal] = useState(0);
  const [currentTotal, setCurrentTotal] = useState(0);
  const [incomingMaterial, setIncomingMaterial] = useState(0);
  const [outgoingMaterialMaterial, setOutgoingMaterialMaterial] = useState(0);
  const [tabCountDetail, setTabCountDetail] = useState('');
  const searching = useSelector(state => state.inspectionSummary.searching);
  useEffect(() => {
    
    const query = new URLSearchParams(location.search);
    setTabValue(parseInt(tabValue, 10));
    setTabValue(query.get('tab'));
    setTabValue(query.get('tab'));
    setTabValue(query.get('tab'));
    // tabValue = query.get('tab');
    console.log(tabValue + "taaaaaaa")
    const tabValueInt = parseInt(query.get('tab'), 10)
    if (!isNaN(tabValueInt)) {
        setTab(tabValueInt);
      }
      else
      {
        setTab(0);
      }
    //   getVisitorTabCount();
      dispatch({
        type : MEETING_ROOM_BOOKINGS_ERROR,
        payload : {
          inspectionList: [],
            response: {},
            inspectionListDetail: {},
            limit : 0,
            page : 0,
            totalPages : 0,
            totalItems : 0,
            loading: false,
            status: false,
            text: "",
            showSnackbar: true
        }
      })
  }, []);



  const getVisitorTabCount = () => {
    axios
      .get(`${server_url}/material/get/tab-count`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((response) => {
        if(response.data.status == 'OK')
            {
                var data = response.data.data;
                setPastTotal(data['pastCount']);
                setCurrentTotal(data['currentCount']);
                setIncomingMaterial(data['incomingCount']);
                setOutgoingMaterialMaterial(data['outgoingCount']);
            }
        
      }).catch(() => console.log("error"))
  }

const onChange = (tab) =>
    {
        if(tab==0)
            {
                history.push(`?tab=${tab}`);
                // dispatch(meetingroombookingsListGetAll("&limit=50"));
            }
            else if(tab ==1)
                {
                    history.push(`?tab=${tab}`);
                    // dispatch(meetingroombookingsListGetAll("&inspectionSchedule=Daily&limit=50"));
                }
            else if(tab ==2)
                {
                    history.push(`?tab=${tab}`);
                    // dispatch(meetingroombookingsListGetAll("&inspectionSchedule=7 Days&limit=50"));
                }
            else if(tab ==3)
                {
                    history.push(`?tab=${tab}`);
                    // dispatch(meetingroombookingsListGetAll("mode=OUTGOING_MATERIAL"));
                }
            else if(tab ==4)
                {
                    history.push(`?tab=${tab}`);
                    // dispatch(meetingroombookingsListGetAll("mode=OUTGOING_MATERIAL"));
                }
            else if(tab ==5)
                {
                    history.push(`?tab=${tab}`);
                    // dispatch(meetingroombookingsListGetAll("mode=OUTGOING_MATERIAL"));
                }
            else if(tab ==6)
                {
                    history.push(`?tab=${tab}`);
                    // dispatch(meetingroombookingsListGetAll("mode=OUTGOING_MATERIAL"));
                }
            else if(tab ==7)
                {
                    history.push(`?tab=${tab}`);
                    // dispatch(meetingroombookingsListGetAll("mode=OUTGOING_MATERIAL"));
                }
        setTab(tab);
    }
    useEffect(() => {
        const timer = setTimeout(() => {
          setLoading(false); // Hide loader after 2 seconds
        }, 200);
    
        return () => clearTimeout(timer); // Clean up timer on unmount
      }, []);

  return (
  <>
  {tab ==0 ?<MeetingRoomBookingsAdvanceSearch schedule="Common"/> : (tab ==1 ?<MeetingRoomBookingsAdvanceSearch schedule="Internal"/>  :<></>)}
  
  <Flex direction="column" pt={{ base: "120px", md: "20px" }}>
       
        {!loading ?<Tabs defaultIndex={tab}>
        <Card><TabList>
            <Tab onChange={()=>{
                onChange(0);
                setTab(0);
            }} 
            onClick={()=>{
                onChange(0);
                setTab(0);
            }} 
            >Common
             {/* <Badge ml='1' fontSize='0.8em' colorScheme='red'>
            {tab==0 ?totalItems:pastTotal }
          </Badge> */}
          </Tab>
            <Tab onChange={()=>{
                onChange(1);
                setTab(1);
            }} onClick={()=>{
                onChange(1);
                setTab(1);
            }}>Internal 
            {/* <Badge ml='1' fontSize='0.8em' colorScheme='red'>
            {tab==1 ?totalItems:currentTotal}
          </Badge> */}
          </Tab>
        </TabList>
        </Card>
        <TabPanels >
            <TabPanel className="remove-padding-table">{tab ==0 ? <MeetingRoomBookingsTable schedule="COMMON"></MeetingRoomBookingsTable>:<></>}</TabPanel>
             <TabPanel className="remove-padding-table">{tab ==1 ? <MeetingRoomBookingsTable schedule="INTERNAL"></MeetingRoomBookingsTable>:<></>}</TabPanel>
             {/*<TabPanel className="remove-padding-table">{tab ==2 ? <AllTable schedule="7 Days"></AllTable>:<></>}</TabPanel>
            <TabPanel className="remove-padding-table">{tab ==3 ? <AllTable schedule="15 Days"></AllTable>:<></>}</TabPanel>
            <TabPanel className="remove-padding-table">{tab ==4 ? <AllTable schedule="30 Days"></AllTable>:<></>}</TabPanel>
            <TabPanel className="remove-padding-table">{tab ==5 ? <AllTable schedule="90 Days"></AllTable>:<></>}</TabPanel>
            <TabPanel className="remove-padding-table">{tab ==6 ? <AllTable schedule="180 Days"></AllTable>:<></>}</TabPanel>
            <TabPanel className="remove-padding-table">{tab ==7 ? <AllTable schedule="360 Days"></AllTable>:<></>}</TabPanel> */}
            
        </TabPanels>
        </Tabs>:<></>}
    </Flex>
  </>
  );
};

export default MeetingRoomBookingsTabs;
