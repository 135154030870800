// Chakra imports
import {
    Box,
    Button,
    Center,
    Flex,
    Image,
    Input,
    SimpleGrid,
    Spacer,
    Stack,
    Text,
    Wrap,
    WrapItem,
    useColorModeValue
  } from "@chakra-ui/react";
  // Custom components
  import Card from "components/Card/Card.js";
  import CardBody from "components/Card/CardBody.js";
  import CardHeader from "components/Card/CardHeader.js";
  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector } from 'react-redux';
  import { useToast } from '@chakra-ui/react'
  import { useParams } from "react-router-dom/cjs/react-router-dom.min";
  import { BANNER_POSITION_CLEAR_SNACKBAR } from "constants/types";
  import axios from 'axios';
  import { server_url } from '../../constants/types';
  import { useHistory } from 'react-router-dom';
  
  import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
  } from '@chakra-ui/react'
  import { Skeleton, SkeletonCircle, SkeletonText } from '@chakra-ui/react'
  import dataConstant from "constants/dataConstant";

  
  function MultiAuthMatrixView() {
    const textColor = useColorModeValue("gray.700", "white");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    const [id, setId] = useState("");
    const [plantAdmin, setPlantAdmin] = useState("");
    
    const [loading, setLoading] = useState(false);
    const [allDepartments, setAllDepartments] = useState("");
    const [department, setDepartment] = useState([]);
    const [allType, setAllType] = useState("");
    const [type, setType] = useState([]);
    const [firstAuth, setFirstAuth] = useState("");
    const [secondAuth, setSecondAuth] = useState("");
    const [thirdAuth, setThirdAuth] = useState("");
    const [fourthAuth, setFourthAuth] = useState("");
    const [fifthAuth, setFifthAuth] = useState("");
    const [status, setStatus] = useState('false');

    const dispatch = useDispatch();
    const toast = useToast();
  
    const statuses = ['success', 'error', 'warning', 'info'];
    
    

    const history = useHistory();
  
    const params = useParams();
  
    useEffect(() => {
      setLoading(true);
      console.log("params");
      const id = params.id;
      axios
        .get(`${server_url}/admin/multi-auth-matrix/${id}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((response) => {
          // if(responce.data.data)
          // {
            setId(response.data.data._id);
            setAllDepartments(response.data.data.allDepartments);
            setDepartment(response.data.data.department);
            setAllType(response.data.data.allType);
            setType(response.data.data.type);
            setFirstAuth(response.data.data.firstAuth);
            setSecondAuth(response.data.data.secondAuth);
            setThirdAuth(response.data.data.thirdAuth);
            setFourthAuth(response.data.data.fourthAuth);
            setFifthAuth(response.data.data.fifthAuth);
            
            
            
            setPlantAdmin(response.data.data.plant?._id);
            console.log("I am here");
          // }
        })
        .catch((e) => {
  
        });
        setLoading(false);
    }, []);
  
    const toastFunction = (title, status) => {
      toast({
        title: title,
        status: status,
        duration: 3000,
        isClosable: true,
      })
    };
  
    useEffect(() => {
      axios
        .get(`${server_url}/admin/detail`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((responce) => {
          console.log("uadlfdafljadhfoadilf");
        })
        .catch((e) => {
          history.push('/auth/signin');
  
        });
    }, [])
    return (
      <>
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="18px">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Multi Auth Matrix Detail
            </Text>
          </CardHeader>
          <CardBody>
            {loading ? <Stack>
              <Skeleton height='20px' />
              <Skeleton height='20px' />
              <Skeleton height='20px' />
            </Stack>
              :
              <TableContainer>
                <Table variant='simple'>
                  <Tbody>
                    <Tr>
                      <Td>Department</Td>
                      <Td>{allDepartments ? 'All Department' : department.map((row, index, arr) => (
                        <Text>{row.label}</Text>))}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Type</Td>
                      <Td>{allType ? 'All Department' : type.map((row, index, arr) => (
                        <Text>{row.label}</Text>))}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>1st Authorization</Td>
                      <Td>{firstAuth.assignedMember}</Td>
                    </Tr>
                    
                    <Tr>
                      <Td>2nd Authorization</Td>
                      <Td>{secondAuth.assignedMember}</Td>
                    </Tr>
                    <Tr>
                      <Td>3rd Authorization</Td>
                      <Td>{thirdAuth.assignedMember}</Td>
                    </Tr>
                    <Tr>
                      <Td>4th Authorization</Td>
                      <Td>{fourthAuth.assignedMember}</Td>
                    </Tr>
                    <Tr>
                      <Td>5th Authorization</Td>
                      <Td>{fifthAuth.assignedMember}</Td>
                    </Tr>
                    <Tr>
                      <Td>Status</Td>
                      <Td>{status?'Active':'Inactive'}</Td>
                    </Tr>
                    
                    {/* <Tr>
                        <Td>City</Td>
                        <Td>{city}</Td>
                      </Tr> */}
                  </Tbody>
                </Table>
              </TableContainer>}
          </CardBody>
        </Card>
      </Flex>
    </>
    );
  }
  
  export default MultiAuthMatrixView;