const {
    MULTI_AUTH_MATRIX_LOADING,
    MULTI_AUTH_MATRIX_GET_ALL_SUCCESS,
    MULTI_AUTH_MATRIX_ERROR,
    MULTI_AUTH_MATRIX_CLEAR_SNACKBAR,
    MULTI_AUTH_MATRIX_SEARCH,
    MULTI_AUTH_MATRIX_BOOKINGS_DATE_RANGE
  } = require('../constants/types');
  
  const initialState = {
    response: {},
    multiauthmatrixList: [],
    multiauthmatrixListDetail: {},
    loading: false,
    status: 'success',
    text: '',
    showSnackbar: false,
    limit : 0,
    page : 0,
    totalPages : 0,
    totalItems : 0,
    searching : "",
    startDate : "",
    endDate : "",
  };
  
  const multiauthmatrixReducer = (state = initialState, action) => {
    switch (action.type) {
      case MULTI_AUTH_MATRIX_SEARCH:
        return {
          ...state,
          searching: action.payload.searching
        };
      case MULTI_AUTH_MATRIX_CLEAR_SNACKBAR:
        return {
          ...state,
          showSnackbar: false
        };
      case MULTI_AUTH_MATRIX_LOADING:
        return {
          ...state,
          loading: true
        };
      case MULTI_AUTH_MATRIX_ERROR:
        return {
          ...state,
          multiauthmatrixList: [],
          response: {},
          multiauthmatrixListDetail: {},
          limit : 0,
          page : 0,
          totalPages : 0,
          totalItems : 0,
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true
        };
      case MULTI_AUTH_MATRIX_GET_ALL_SUCCESS:
        return {
          ...state,
          multiauthmatrixList: action.payload.multiauthmatrixList,
          response: action.payload.response,
          multiauthmatrixListDetail: action.payload.multiauthmatrixListDetail,
          limit: action.payload.limit,
          page: action.payload.page,
          totalPages: action.payload.totalPages,
          totalItems: action.payload.totalItems,
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true
        };
      case MULTI_AUTH_MATRIX_BOOKINGS_DATE_RANGE: // Add the new case
      return {
        ...state,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };
      default:
        return state || {};
    }
  };
  
  export default multiauthmatrixReducer;
