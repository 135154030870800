import React, { useEffect, useState,useRef } from 'react';
import { Flex, InputGroup,Button, Spacer, Table, Tbody, Text, Td, Th, Thead, Tr, useColorModeValue, InputLeftElement, IconButton, Input, Icon, Link , useDisclosure} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';

import { useToast } from '@chakra-ui/react';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
// import DevicePagination from './DevicePagination';
import { AddIcon } from '@chakra-ui/icons';
import { MATERIAL_CLEAR_SNACKBAR ,MATERIAL_GATE_PASS_SEARCH} from 'constants/types';
// import {  } from '../../actions/devicesAction'; // Assuming you have a DeviceAction file
// import DeviceTableRow from './DevicesTableRow';

// import DevicesPagination from './DevicesPagination';
// import materialGatePassPagination from './materialGatePassPagination'
import GatePassTableRow from './GatepassTableRow';
import { materialGatePassGetAll } from '../../actions/materialGatePassAction';

import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';
import { Skeleton, SkeletonCircle, SkeletonText,Stack } from '@chakra-ui/react'
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import GatePassPagination from './GatepassPagination';
const GatePassTable = (props) => {
  const { url = "", plant,gatepassType="" } = props;
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const responseStatus = useSelector(state => state.materialGatePass.status);
  const responseMessage = useSelector(state => state.materialGatePass.text);
  const loading = useSelector(state => state.materialGatePass.loading);
  const materialGatePassList = useSelector(state => state.materialGatePass.materialGatePassList || []);
  const response = useSelector(state => state.materialGatePass.response);
  const showSnackbar = useSelector(state => state.materialGatePass.showSnackbar);
  const currentPage = useSelector(state => state.materialGatePass.page);
  const totalItems = useSelector(state => state.materialGatePass.totalItems);
  const limit = useSelector(state => state.materialGatePass.limit);
  const dispatch = useDispatch();
  const toast = useToast();
  const searchIconColor = useColorModeValue("gray.700", "gray.200");
  const inputBg = useColorModeValue("#edf3f8", "navy.800");
  const [search, setSearch] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [submittingLoader, setSubmittingLoader] = useState(false);
  const history = useHistory();
  const tableRef = useRef(null);
  console.log("materialGatePassList");
  console.log(materialGatePassList)
  useEffect(() => {
    dispatch(materialGatePassGetAll(`&status=${gatepassType}&plant=${plant}`));
    dispatch({
      type : MATERIAL_GATE_PASS_SEARCH,
      payload:{
        searching : ''
      }
    })
  }, [dispatch]);

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: MATERIAL_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("desc");
    }
    const sorting = "&sortOrder=" + sortOrder + "&column=" + column;
    dispatch({
      type : MATERIAL_GATE_PASS_SEARCH,
      payload:{
        searching : ''
      }
    })
    dispatch(deviceGetAll(sorting));
  };

  useEffect(() => {
    const searching = `&status=${gatepassType}&search=${search}`;
    dispatch({
      type : MATERIAL_GATE_PASS_SEARCH,
      payload:{
        searching : ''
      }
    })
    dispatch(materialGatePassGetAll(searching));
  }, [search]);
  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])
  const fetchImageAsBase64 = async (imageUrl) => {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(',')[1]); // Get base64 data
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  return (
    <>
    {/* <GatePassAdvanceSearch></GatePassAdvanceSearch> */}
      <Flex direction="column" pt={{ base: "120px", md: "20px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <Flex>
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              {gatepassType}
            </Text>
          </CardHeader>
          <Spacer />
          {/* <Flex justify="flex-end" style={{ padding: '10px' ,marginTop : '-5px' ,marginRight :'12px' }}> 
            <Link href={`#/admin/patrolling-route-form`}>
            <IconButton
              colorScheme="blue"
              aria-label="Search database"
              icon={<AddIcon />}
            />
          </Link>
          </Flex> */}
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              <InputGroup borderRadius="8px" w="200px">
                <InputLeftElement
                  children={
                    <IconButton
                      bg="inherit"
                      borderRadius="inherit"
                      _hover="none"
                      _active={{
                        bg: "inherit",
                        transform: "none",
                        borderColor: "transparent",
                      }}
                      _focus={{
                        boxShadow: "none",
                      }}
                      icon={
                        <SearchIcon
                          color={searchIconColor}
                          w="15px"
                          h="15px"
                        />
                      }
                    ></IconButton>
                  }
                />
                <Input
                  type="pass"
                  variant="search"
                  fontSize="xs"
                  bg={inputBg}
                  onKeyUp={(e) => {
                    setSearch(e.target.value);
                    if (e.target.value.length < 3) {
                      setSearch("");
                    }
                  }}
                  placeholder="Type here..."
                />
              </InputGroup>
            </Text>
          </CardHeader>
        </Flex>
        <CardBody pb={4}>
          <div style={{ overflowX: "auto" }}>
          
            {loading ? <Stack>
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                  </Stack>:<Table className='vertical-scroll' variant="simple" color={textColor} ref={tableRef}>
              <Thead className='stick-header'>
                <Tr my=".8rem" pl="0px" color="gray.400">
                <Th
                    pl="15px"
                    borderColor={borderColor}
                    color="gray.400"
                    cursor="pointer"
                  >
                   SNO
                  </Th>
                  <Th
                    pl="0px"
                    borderColor={borderColor}
                    color="gray.400"
                    cursor="pointer"
                  >
                    Date
                    {sortColumn === "name" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("outtime")}
                    cursor="pointer"
                  >
                    Mode
                    {sortColumn === "outtime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>

                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("outtime")}
                    cursor="pointer"
                  >
                    Gatepass Number
                    {sortColumn === "outtime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("outtime")}
                    cursor="pointer"
                  >
                    Party Name
                    {sortColumn === "outtime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  {gatepassType == 'NRGP' ? 
                  <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("outtime")}
                  cursor="pointer"
                >
                  Material Type
                  {sortColumn === "outtime" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th> : 
                <></>
                  }
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("outtime")}
                    cursor="pointer"
                  >
                    PO / Invoice
                    {sortColumn === "outtime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("outtime")}
                    cursor="pointer"
                  >
                    Description
                    {sortColumn === "outtime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  {
                    gatepassType == 'REJECTED_GATE_PASS' ?
                    <Th
                        borderColor={borderColor}
                        color="gray.400"
                        onClick={() => handleSort("outtime")}
                        cursor="pointer"
                    >
                        Reason of Rejection
                        {sortColumn === "outtime" && (
                        <Icon
                            as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                            boxSize={4}
                            ml={1}
                        />
                        )}
                    </Th>:
                    <></>
                    }
                  {
                    gatepassType == 'RGP' ? <>
                    <Th
                        borderColor={borderColor}
                        color="gray.400"
                        onClick={() => handleSort("outtime")}
                        cursor="pointer"
                    >
                        Return Date
                        {sortColumn === "outtime" && (
                        <Icon
                            as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                            boxSize={4}
                            ml={1}
                        />
                        )}
                    </Th>
                    <Th
                        borderColor={borderColor}
                        color="gray.400"
                        onClick={() => handleSort("outtime")}
                        cursor="pointer"
                    >
                        Approx Value
                        {sortColumn === "outtime" && (
                        <Icon
                            as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                            boxSize={4}
                            ml={1}
                        />
                        )}
                    </Th>
                    </>
                    :
                    <></>
                  }
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("outtime")}
                    cursor="pointer"
                  >
                    Gatepass Status
                    {sortColumn === "outtime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>

                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("outtime")}
                    cursor="pointer"
                  >
                    Dept
                    {sortColumn === "outtime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("outtime")}
                    cursor="pointer"
                  >
                    Sub-Dept
                    {sortColumn === "outtime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("outtime")}
                    cursor="pointer"
                  >
                    Guard Status
                    {sortColumn === "outtime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("outtime")}
                    cursor="pointer"
                  >
                    Raised By
                    {sortColumn === "outtime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("outtime")}
                    cursor="pointer"
                  >
                    Logs
                    {sortColumn === "outtime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th></Th>

                </Tr>
              </Thead>
              <Tbody>
                {materialGatePassList ? (
                  materialGatePassList.map((row, index, arr) => {
              
                    return (
                      <GatePassTableRow
                       serialnumber={totalItems - (((currentPage - 1) * limit) + index)}
                        date={row.date}
                        passType={row.passType}
                        mode={row.mode}
                        status={row.status}
                        currentStatus={row.currentStatus}
                        gatepassNumber={row.gatepassNumber}
                        gatepassStatus={row.gatepassStatus}
                        guardStatus={row.guardStatus}
                        department={row.department ? row.department.name : <></>}
                        subdepartment={row.subdepartment ? row.subdepartment.name : <></>}
                        totalTime={row.totalTime}
                        txn={row.txnNumber}
                        logs={row.logs}
                        isLast={index === arr.length - 1 ? true : false}
                        id={row._id}
                        key={row._id}
                        gatepassPhoto= {row.gatepassPhoto}
                        isNewEntry= {row.isNewEntry}
                        invoices= {row.invoices}
                        supplier= {row.supplier}
                        material= {row.material}
                        raisedMaterial= {row.raisedMaterial}
                        purpose= {row.purpose}
                        reasonOfRejection= {row.reasonOfRejection}
                        materialMode= {row.materialMode}
                        vehicleNumber= {row.vehicleNumber}
                        employee= {row.employee}
                      />
                    );
                    
                  })
                ) : (
                  <h1></h1>
                )}

              </Tbody>
            </Table>}
          </div>
          {materialGatePassList.length === 0 ? (
            <></>
          ) : (
            <GatePassPagination
                gatepassType={gatepassType}
              totalPages={response.totalPages}
              currentPage={response.currentPage}
              perPage={response.perPage}
              sortBy={sortColumn}
              sortOrder={sortOrder}
              handlePageClick={(data) => {
                const selectedPage = data.selected + 1;
                dispatch(materialGatePassGetAll(`&status=${gatepassType}&page=${selectedPage}`));
              }}
            />
          )}
        </CardBody>
      </Card>
    </Flex>
    </>
  );
};

export default GatePassTable;
