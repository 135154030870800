import axios from "axios";
import {
  RAISED_GATE_PASS_LOADING,
  RAISED_GATE_PASS_GET_ALL_SUCCESS,
  RAISED_GATE_PASS_ERROR,
  RAISED_GATE_PASS_CLEAR_SNACKBAR,
} from "../constants/types";

import { server_url } from "../constants/types";

export const raisedGatePassGetAll = (pagination = "" ) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: RAISED_GATE_PASS_LOADING,
        payload: {
          loading: true,
        },
      });
      axios
        .get(`${server_url}/raisedGatepass?${pagination}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("authToken"),
          },
        })
        .then((response) => {
          dispatch({
            type: RAISED_GATE_PASS_GET_ALL_SUCCESS,
            payload: {
              raisedGatePassList: response.data.data,
              limit: response.data.limit,
              page: response.data.currentPage,
              totalPages: response.data.totalPages,
              totalItems: response.data.totalItems,
              response: response.data,
              logs: [],
              raised: [],
              raisedGatePass: [],
              loading: false,
              status: 'success',
              text: "Get all banner position successfully",
            },
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: RAISED_GATE_PASS_ERROR,
            payload: {
              status: "error",
              text: "Error occurred during getting raisedGatePass data.",
              loading: false,
            },
          });
          resolve();
        });
    });
  };
};

export const rgpManagementGetAll = (pagination = "" ) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: RAISED_GATE_PASS_LOADING,
        payload: {
          loading: true,
        },
      });
      axios
        .get(`${server_url}/admin/rgp-management?${pagination}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("authToken"),
          },
        })
        .then((response) => {
          dispatch({
            type: RAISED_GATE_PASS_GET_ALL_SUCCESS,
            payload: {
              raisedGatePassList: response.data.data,
              limit: response.data.limit,
              page: response.data.currentPage,
              totalPages: response.data.totalPages,
              totalItems: response.data.totalItems,
              response: response.data,
              logs: [],
              raised: [],
              raisedGatePass: [],
              loading: false,
              status: 'success',
              text: "Get all banner position successfully",
            },
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: RAISED_GATE_PASS_ERROR,
            payload: {
              status: "error",
              text: "Error occurred during getting raisedGatePass data.",
              loading: false,
            },
          });
          resolve();
        });
    });
  };
};


export const raisedGatePassAddOrUpdate = (raisedGatePass, state , page ='' , limit='') => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: RAISED_GATE_PASS_LOADING,
        payload: {
          loading: true,
        },
      });
      if (state === "view") {
        axios
          .get(`${server_url}/materialGatePass/${raisedGatePass._id}`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("authToken")
            },
          })
          .then((responseF) => {
            axios
              .get(`${server_url}/raisedGatepass?limit=${limit}&page=${page}`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("authToken")
                },
              })
              .then((response) => {
                dispatch({
                  type: RAISED_GATE_PASS_GET_ALL_SUCCESS,
                  payload: {
                    raisedGatePassList: response.data.data,
                    limit: response.data.limit,
                    page: response.data.currentPage,
                    totalPages: response.data.totalPages,
                    response: response.data,
                    raisedGatePassDetail: responseF.data["data"],
                    logs: responseF.data["data"]["logs"],
                    raised: responseF.data["data"]["material"],
                    materialGatePass: responseF.data["data"]["materialGatePass"],
                    loading: false,
                    status: responseF.data["status"],
                    text: responseF.data["message"],
                  },
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: RAISED_GATE_PASS_ERROR,
                  payload: {
                    text: "Error occurred during getting raisedGatePass data.",
                    status: "error",
                    loading: false,
                  },
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: RAISED_GATE_PASS_ERROR,
              payload: {
                text: "Error occurred during updating raisedGatePass data.",
                status: "error",
                loading: false,
              },
            });
            resolve();
          });
      }
    });
  };
};


export const raisedGatePassClearShowSnackbar = () => {
  return (dispatch, getState) => {
    dispatch({
      type: RAISED_GATE_PASS_CLEAR_SNACKBAR,
    });
  };
};

