import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  SimpleGrid,
  Text,
  FormControl,
  FormLabel,
  Select,
  Spinner,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import axios from "axios";
import { MULTI_AUTH_MATRIX_CLEAR_SNACKBAR, server_url } from "../../constants/types";
import dataConstant from "constants/dataConstant";
import { multiauthmatrixListAddOrUpdate } from "../../actions/multiauthmatrixAction";
import RSelect from "react-select";

function MultiAuthMatrixForm() {
  const params = useParams();
  const textColor = useColorModeValue("gray.700", "white");
  const toast = useToast();
  const dispatch = useDispatch();
  const history = useHistory();

  const [auths, setAuths] = useState([
    {
      department: [],
      allDepartments: 'false',
      type: [],
      allType: 'false',
      purpose: [],
      allPurpose: 'false',
      firstAuth: { assignedMember: 0 },
      secondAuth: { assignedMember: 0 },
      thirdAuth: { assignedMember: 0 },
      fourthAuth: { assignedMember: 0 },
      fifthAuth: { assignedMember: 0 },
      plantAdmin: params.id,
    },
  ]);

  const [departmentList, setDepartmentList] = useState([]);
  const [purposeList, setPurposeList] = useState([]);
  const loading = useSelector((state) => state.multiauthmatrix.loading);
  const showSnackbar = useSelector((state) => state.multiauthmatrix.showSnackbar);
  const responseStatus = useSelector((state) => state.multiauthmatrix.status);
  const responseMessage = useSelector((state) => state.multiauthmatrix.text);

  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
      })
      .catch(() => history.push("/auth/signin"));

    getDepartmentList();
    getPurposeList();
  }, []);

  const getDepartmentList = () => {
    axios
      .get(`${server_url}/department?plant=${params.id}&limit=1000`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
      })
      .then((response) => setDepartmentList(response.data.data))
      .catch(console.error);
  };

//   purpose list
const getPurposeList = () => {
    axios
      .get(`${server_url}/admin/purpose-list`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
      })
      .then((response) => setPurposeList(response.data.data))
      .catch(console.error);
  };

  const handleAuthChange = (index, field, value) => {
    const updatedAuths = [...auths];
     if (field.includes("assignedMember")) {
        // Handle fields like `firstAuth.assignedMember` correctly
        const [authLevel, subField] = field.split(".");
        updatedAuths[index][authLevel][subField] = value;
      } else {
        // For any other direct assignment
        updatedAuths[index][field] = value;
      }
    console.log(updatedAuths);
    setAuths(updatedAuths);
  };

  const addRoom = () => {
    setAuths([
      ...auths,
      {
        department: [],
        allDepartments: 'false',
        type: [],
        allType: 'false',
        purpose: [],
        allPurpose: 'false',
        firstAuth: { assignedMember: 0 },
        secondAuth: { assignedMember: 0 },
        thirdAuth: { assignedMember: 0 },
        fourthAuth: { assignedMember: 0 },
        fifthAuth: { assignedMember: 0 },
        plantAdmin: params.id,
      },
    ]);
  };

  const removeAuth = (index) => {
    setAuths(auths.filter((_, i) => i !== index));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const multiAuthMatrix = { multiAuthMatrix: auths };
    console.log(multiAuthMatrix);
    const state = 'add';
    dispatch(multiauthmatrixListAddOrUpdate(multiAuthMatrix,state));
  };

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  };

  useEffect(() => {
    if (showSnackbar) {
        if (responseStatus === 'OK' && loading === false) {
          if (responseMessage !== '') {
            toastFunction(responseMessage, 'success');
            history.goBack();
          }
        } else if (responseStatus === 'NOK' && loading === false) {
          if (responseMessage !== '') {
            toastFunction(responseMessage, 'error');
          }
        }
      }
      dispatch({
        type: MULTI_AUTH_MATRIX_CLEAR_SNACKBAR,
        payload: {
          showSnackbar: false,
        },
      });
    
  }, [loading]);

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Multi Auth Matrix Form
          </Text>
          {auths.map((room, index) => (
            <Box key={index} border="1px solid" borderColor="gray.300" p="4" mt="4" borderRadius="md">
              <SimpleGrid minChildWidth="120px" spacing="40px">
                <FormControl>
                  <FormLabel>Department</FormLabel>
                  <RSelect
                    isMulti
                    options={[
                    { value: "all", label: "Select All" },
                    ...departmentList.map((item) => ({
                        value: item._id,
                        label: item.name,
                    })),
                    ]}
                    value={room.department}
                    onChange={(selectedOptions) => {
                    if (selectedOptions.some(option => option.value === "all")) {
                        handleAuthChange(
                        index, 
                        "department", 
                        departmentList.map((item) => ({ value: item._id, label: item.name }))
                        );
                        handleAuthChange(index, "allDepartments", "true");
                    } else {
                        handleAuthChange(index, "department", selectedOptions);
                        handleAuthChange(index, "allDepartments", "false");
                    }
                    }}
                />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Type</FormLabel>
                  <RSelect
                    isMulti
                    options={[
                        { value: "all", label: "Select All" },
                        ...dataConstant.multiauthmatrixTypeConstant.map((item) => ({
                        value: item.value,
                        label: item.name,
                        })),
                    ]}
                    value={room.type}
                    onChange={(selectedOptions) => {
                        if (selectedOptions.some(option => option.value === "all")) {
                        handleAuthChange(
                            index, 
                            "type", 
                            dataConstant.multiauthmatrixTypeConstant.map((item) => ({
                            value: item.value, 
                            label: item.name,
                            }))
                        );
                        handleAuthChange(index, "allType", "true");
                        } else {
                        handleAuthChange(index, "type", selectedOptions);
                        handleAuthChange(index, "allType", "false");
                        }
                    }}
                    />
                </FormControl>
                {room.type.some(option => option.value === dataConstant.gatepassTypeNRGP)?<FormControl>
                  <FormLabel>Purpose (NRGP)</FormLabel>
                  <RSelect
                    isMulti
                    options={[
                    { value: "all", label: "Select All" },
                    ...purposeList.map((item) => ({
                        value: item.value,
                        label: item.name,
                    })),
                    ]}
                    value={room.purpose}
                    onChange={(selectedOptions) => {
                    if (selectedOptions.some(option => option.value === "all")) {
                        handleAuthChange(
                        index, 
                        "purpose", 
                        purposeList.map((item) => ({ value: item.value, label: item.name }))
                        );
                        handleAuthChange(index, "allPurpose", "true");
                    } else {
                        handleAuthChange(index, "purpose", selectedOptions);
                        handleAuthChange(index, "allPurpose", "false");
                    }
                    }}
                />
                </FormControl> : <></>}
                {[{key:1,value:"first"},{key:2,value:"second"},{key:3,value:"third"},{key:4,value:"fourth"},{key:5,value:"fifth"}].map((num) => (
                  <FormControl key={num} isRequired>
                    <FormLabel>{`${num.key}th`}</FormLabel>
                    <Input
                      type="number"
                      value={room[`${num.value}Auth.assignedMember`]}
                      onChange={(e) => handleAuthChange(index, `${num.value}Auth.assignedMember`, e.target.value)}
                    />
                  </FormControl>
                ))}
              </SimpleGrid>
              {index !== 0 && (
                <Button size="xs" mt="4" colorScheme="red" onClick={() => removeAuth(index)}>
                  Remove
                </Button>
              )}
            </Box>
          ))}
          <Button size="xs" mt="6" colorScheme="blue" onClick={addRoom}>
            Add More
          </Button>
          <Center>
            {loading ? (
              <Spinner mt="24px" size="lg" />
            ) : (
              <Button colorScheme="green" mt="24px" onClick={handleSubmit}>
                Submit
              </Button>
            )}
          </Center>
        </CardHeader>
        <CardBody />
      </Card>
    </Flex>
  );
}

export default MultiAuthMatrixForm;
