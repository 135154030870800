import React, { useEffect, useState,useRef } from 'react';
import { Flex, InputGroup, Spacer, Table, Tbody, Text, Td, Th, Thead, Tr, useColorModeValue, InputLeftElement, IconButton, Input, Icon, Link ,Button, Tabs, TabList, TabPanels, TabPanel, Tab,Badge} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';

import { useToast } from '@chakra-ui/react';

import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';

import { AddIcon } from '@chakra-ui/icons';
import { Skeleton, SkeletonCircle, SkeletonText,Stack } from '@chakra-ui/react'
import VisitorPreapprovalAdvanceSearch from "./Preapproval/PreapprovalAdvanceSearch";
import VisitorInOutAdvanceSearch from "./VisitorInOutAdvanceSearch";
import PreapprovalTable from "./Preapproval/PreapprovalTable";
import VisitorInOutTable from './VisitorInOutTable';
import CurrentTable from './Current/CurrentTable';
import { VisitorInOutGetAll } from '../../actions/VisitorInOutAction';
import CurrentAdvanceSearch from './Current/CurrentAdvanceSearch';
import { useParams ,useLocation,useHistory} from "react-router-dom/cjs/react-router-dom.min";
import WrongEntryTable from 'views/VisitorInOut/Wrongentry/WrongentryTable';
import WrongentryAdvanceSearch from './Wrongentry/WrongentryAdvanceSearch';
import DenyAdvanceSearch from './Deny/DenyAdvanceSearch';
import DenyTable from './Deny/DenyTable';
import PastAdvanceSearch from './Past/PastAdvanceSearch';
import PastTable from './Past/PastTable';
import axios from 'axios';
import { server_url } from "constants/types";
import Dashboard from "./Dashboard"
import dataConstant from "constants/dataConstant";
const VisitorTabs = (props) => {
    const {
        module=1,
      } = props;
  const tableRef = useRef(null);
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const dispatch = useDispatch();
  const toast = useToast();
  const searchIconColor = useColorModeValue("gray.700", "gray.200");
  const inputBg = useColorModeValue("#edf3f8", "navy.800");
  const [search, setSearch] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [tab, setTab] = useState(0);
  const [tabValue, setTabValue] = useState(0);
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const visitortotalItems = useSelector(state => state.visitorInOut.totalItems);
  const preapprovaltotalItems = useSelector(state => state.visitorPreapproval.totalItems);
  const [pastTotal, setPastTotal] = useState(0);
  const [currentTotal, setCurrentTotal] = useState(0);
  const [wrongentryTotal, setWrongentryTotal] = useState(0);
  const [denyTotal, setDenyTotal] = useState(0);
  const [tabCountDetail, setTabCountDetail] = useState('');
  const searching = useSelector(state => state.visitorInOut.searching);
  const authDetail = useSelector((state) => state.auth.authDetail);
  useEffect(() => {
    
    const query = new URLSearchParams(location.search);
    setTabValue(parseInt(tabValue, 10));
    setTabValue(query.get('tab'));
    setTabValue(query.get('tab'));
    setTabValue(query.get('tab'));
    // tabValue = query.get('tab');
    console.log(tabValue + "taaaaaaa")
    const tabValueInt = parseInt(query.get('tab'), 10)
    if (!isNaN(tabValueInt)) {
        setTab(tabValueInt);
      }
      else
      {
        setTab(0);
      }
      getVisitorTabCount();
  }, []);

//   useEffect(() => {
    
//     const query = new URLSearchParams(location.search);
//     // setTabValue(parseInt(tabValue, 10));
//     // setTabValue(query.get('tab'));
//     const tabValueInt = parseInt(query.get('tab'), 10);
//     console.log("tabValueInt" +tabValueInt);
//     switch (tabValueInt) {
//         case 0:
//             setPastTotal(visitortotalItems.totalItems);
//             console.log(visitortotalItems.totalItems + "items")
//             break;
//         case 2:
//             setCurrentTotal(visitortotalItems.totalItems);
//             break;
//         case 3:
//             setWrongentryTotal(visitortotalItems.totalItems);
//             break;
//         case 4:
//             setDenyTotal(visitortotalItems.totalItems);
//             break;
    
//         default:
//             break;
//     }
    
//   }, [searching]);

  const getVisitorTabCount = () => {
    axios
      .get(`${server_url}/visitor/tab-count`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((response) => {
        if(response.data.status == 'OK')
            {
                var data = response.data.data;
                setPastTotal(data['pastCount']);
                setCurrentTotal(data['currentCount']);
                setWrongentryTotal(data['wrongentryCount']);
                setDenyTotal(data['denyCount']);
            }
        
      }).catch(() => console.log("error"))
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    var localSearch = "&startDate=" + startDate+"&endDate=" + endDate+"&department=" + departmentId+"&subdepartment=" + subdepartment+"&visitorPurpose=" + visitorPurpose + "&visitorType=" + visitorType; 
    dispatch({
      type : VISITOR_SEARCH,
      payload : {
        searching : localSearch+searching,
      }
    })
    dispatch(VisitorInOutGetAll(localSearch+searching));
  };

const onChange = (tab) =>
    {
        if(tab==0)
            {
                history.push(`?tab=${tab}`);
                // dispatch(VisitorInOutGetAll("visitorStatus=EXIT"));
            }
            else if(tab ==2)
                {
                    history.push(`?tab=${tab}`);
                    // dispatch(VisitorInOutGetAll("visitorStatus=IN"));
                }
            else if(tab ==3)
                {
                    history.push(`?tab=${tab}`);
                    // dispatch(VisitorInOutGetAll("status=WRONGENTRY"));
                }
            else if(tab ==4)
                {
                    history.push(`?tab=${tab}`);
                    // dispatch(VisitorInOutGetAll("status=DENY"));
                }
        setTab(tab);
    }
    useEffect(() => {
        const timer = setTimeout(() => {
          setLoading(false); // Hide loader after 2 seconds
        }, 200);
    
        return () => clearTimeout(timer); // Clean up timer on unmount
      }, []);

  return (
  <>
  {authDetail?.role?.name == dataConstant.GROUPROLE ?<></>:<Dashboard></Dashboard>}
  {authDetail?.role?.name == dataConstant.GROUPROLE ?<></>:tab ==0 ?<PastAdvanceSearch/> : (tab ==1 ?<VisitorPreapprovalAdvanceSearch/> : tab ==2 ? <CurrentAdvanceSearch/>: tab == 3 ? <WrongentryAdvanceSearch/> : <DenyAdvanceSearch/>)}
  {authDetail?.role?.name == dataConstant.GROUPROLE ? (<>
    <Flex direction="column" pt={{ base: "120px", md: "75" }}>
    <PreapprovalTable></PreapprovalTable>
      </Flex>
  </>):
  (<Flex direction="column" pt={{ base: "120px", md: "20px" }}>
       
        {!loading ?<Tabs defaultIndex={tab}>
        <Card><TabList>
            <Tab onClick={()=>{
                onChange(0);
                setTab(0);
            }} onChange={()=>{
              onChange(0);
              setTab(0);
          }} >Past <Badge ml='1' fontSize='0.8em' colorScheme='red'>
            {tab==0 ?visitortotalItems:pastTotal }
          </Badge>
          </Tab>
            <Tab onClick={()=>{
                onChange(1);
                setTab(1);
            }}  onChange={()=>{
              onChange(1);
              setTab(1);
          }} >Upcoming <Badge ml='1' fontSize='0.8em' colorScheme='red'>
            {preapprovaltotalItems}
          </Badge></Tab>
            <Tab onClick={()=>{
                onChange(2);
                setTab(2);
            }} onChange={()=>{
              onChange(2);
              setTab(2);
          }}>Current <Badge ml='1' fontSize='0.8em' colorScheme='red'>
            {tab==2 ?visitortotalItems:currentTotal}
          </Badge></Tab>
            <Tab isSelected onClick={()=>{
                onChange(3);
                setTab(3);
            }} onChange={()=>{
              onChange(3);
              setTab(3);
          }}>Wrong Entry <Badge ml='1' fontSize='0.8em' colorScheme='red'>
            {tab==3 ?visitortotalItems:wrongentryTotal}
          </Badge> </Tab>
            <Tab isSelected onClick={()=>{
                onChange(4);
                setTab(4);
            }}>Denied <Badge ml='1' fontSize='0.8em' colorScheme='red'>
            {tab==4 ?visitortotalItems:denyTotal}
          </Badge></Tab>
        </TabList>
        </Card>
        <TabPanels >
            <TabPanel className="remove-padding-table">{tab ==0 ? <PastTable/>:<></>}</TabPanel>
            <TabPanel className="remove-padding-table"><PreapprovalTable></PreapprovalTable></TabPanel>
            <TabPanel className="remove-padding-table">{tab ==2 ? <CurrentTable></CurrentTable>:<></>}</TabPanel>
            <TabPanel className="remove-padding-table">{tab ==3 ? <WrongEntryTable></WrongEntryTable>:<></>}</TabPanel>
            <TabPanel className="remove-padding-table">{tab ==4 ? <DenyTable></DenyTable>:<></>}</TabPanel>
            
        </TabPanels>
        </Tabs>:<></>}
    </Flex>)}
  </>
  );
};

export default VisitorTabs;
