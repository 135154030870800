// Chakra imports
import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  SimpleGrid,
  FormHelperText,
  Spacer,
  Text,

  Icon,
  Wrap,
  WrapItem,
  useColorModeValue,
  InputGroup,
  InputRightElement,


} from "@chakra-ui/react";

// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import Select from 'react-select';
import { Spinner } from "@chakra-ui/react";
import dataConstant from "constants/dataConstant";
import { roleGetAll } from "actions/roleActions";
import { cityGetAll } from "actions/CityActions";
import { checkpostGetAll } from "actions/checkpostActions";
import { groupGetAll } from "actions/groupActions";
import { plantadminAddOrUpdate } from "actions/plantAction";
import { departmentGetAll } from "actions/departmentActions";
import { parkingdepartmentGetAll } from "actions/parkingDepartmentAction";
import { PLANTADMIN_CLEAR_SNACKBAR } from "constants/types";
import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';
import CustomInput from "views/CustomValidation";

function PlantAdminForm() {
  const textColor = useColorModeValue("gray.700", "white");
  const [plantname, setPlantname] = useState("");
  const [contactpersonName, setContactpersonName] = useState("");
  const [personalEmail, setPersonalEmail] = useState("");
  const [officialEmail, setOfficialEmail] = useState("");

  const [contactNo, setContactNo] = useState("");
  const [alternateNo, setAlternateNo] = useState("");
  const [role, setRole] = useState("");
  const [checkpost, setCheckpost] = useState("");
  const [group, setGroup] = useState("");

  const [Line1, setLine1] = useState("");
  const [Line2, setLine2] = useState("");
  const [Postalcode, setPostalcode] = useState("");
  const [Locality, setLocality] = useState("");
  const [State, setState] = useState("");
  const [department, setDepartment] = useState("");
  const [parkingdepartment, setParkingDepartment] = useState("");
  const [photo, setPhoto] = useState("");
  const [locationLink, setLocationLink] = useState("");

  const [status, setStatus] = useState();
  const [roleis, setRoleis] = useState();
  const history = useHistory();
  const [emergencyContact, setEmergencyContact] = useState("");
  const [isErrorName, setIsErrorName] = useState(false);
  const [isErrorContactpersonName, setIsErrorContactpersonName] = useState(false);
  const [isErrorOfficialEmail, setIsErrorOfficialEmail] = useState(false);
  const [isErrorPersonalEmail, setIsErrorPersonalEmail] = useState(false);
  const [isErrorContactNo, setIsErrorContactNo] = useState(false);
  const [isErrorAlternateNo, setIsErrorAlternateNo] = useState(false);
  const [isErrorRole, setIsErrorRole] = useState(false);
  const [isErrorCheckpost, setIsErrorCheckpost] = useState(false);

  const [isErrorStatus, setIsErrorStatus] = useState(false);

  const [isErrorState, setIsErrorState] = useState(false);
  const [isErrorDepartment, setIsErrorDepartment] = useState(false);
  const [isErrorParkingDepartment, setIsErrorParkingDepartment] = useState(false);

  const [isErrorPostalcode, setIsErrorPostalcode] = useState(false);
  const [isErrorLine1, setIsErrorLine1] = useState(false);
  const [isErrorLine2, setIsErrorLine2] = useState(false);
  const [isErrorLocality, setIsErrorLocality] = useState(false);
  const [isErrorGroup, setIsErrorGroup] = useState(false);
  const [isErrorPhoto, setIsErrorPhoto] = useState(false);
  const [isErrorLocationLink, setIsErrorLocationLink] = useState(false);
  const [isErrorEmergencyContact, setIsErrorEmergencyContact] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [materialGatePassApproveStage, setMaterialGatePassApproveStage] = useState(0);
  const [outPassApproveStage, setOutPassApproveStage] = useState(0);


  const [rolies, setRolies] = useState();

  const statuses = ['success', 'error', 'warning', 'info'];
  const responseStatus = useSelector(state => state.plantadmin.status);
  const responseMessage = useSelector(state => state.plantadmin.text);
  const showSnackbar = useSelector(state => state.plantadmin.showSnackbar);

  const groupList = useSelector(state => state.group.groupList);
  // const roleList = useSelector(state => state.role.roleList);
  const checkpostList = useSelector(state => state.checkpost.checkpostList);
  const departmentList = useSelector(state => state.department.departmentList);
  const parkingdepartmentList = useSelector(state => state.parkingdepartment.parkingdepartmentList);
  const loading = useSelector((state) => state.plantadmin.loading);

  const dispatch = useDispatch();

  const toast = useToast();


  console.log("l;baljdnfa");
  console.log(roleList);

  useEffect(() => {
    
    dispatch(departmentGetAll());
    dispatch(groupGetAll());
    // dispatch(roleGetAll());
    getRoleList();
    dispatch(checkpostGetAll());
    dispatch(parkingdepartmentGetAll());


    

  }, []);

  const getRoleList = () => {
    console.log("i am here also");
      axios
        .get(`${server_url}/role`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((responce) => {
          console.log('rolelist: '+ responce.data.data)
          setRoleList(responce.data.data)
          responce.data.data.map((element, index) => {
          if (element['name'] === "Plant Admin") {
            setRole({
              value: element['_id'],
              label: element['name']
            })
          }
        })
        })
    

  }




  const handleSubmit = (event) => {
    console.log(role.value);
    event.preventDefault();
    setIsErrorName(plantname === "");
    setIsErrorContactpersonName(contactpersonName === "")
    setIsErrorOfficialEmail(officialEmail === "");
    setIsErrorPersonalEmail(personalEmail === "");
    setIsErrorContactNo(contactNo === "");
    setIsErrorAlternateNo(alternateNo === "");
    // setIsErrorRole(role === "");
    setIsErrorCheckpost(checkpost === "");
    setIsErrorGroup(group === "");
    setIsErrorStatus(status === "");
    setIsErrorDepartment(department === "");
    setIsErrorParkingDepartment(parkingdepartment === "");
    setIsErrorPostalcode(Postalcode === "");
    setIsErrorLocality(Locality === "");
    setIsErrorLine1(Line1 === "");
    // setIsErrorLine2(Line2 === "");
    setIsErrorState(State === "");
    // setIsErrorPhoto(State === "");
    setIsErrorLocationLink(locationLink != "")
    setIsErrorEmergencyContact(emergencyContact == "");
    console.log(emergencyContact);
    console.log("emergencyContact");
    if (
      plantname != "" &&
      contactpersonName != "" &&
      officialEmail != "" &&
      personalEmail != "" &&
      contactNo != "" &&
      alternateNo != "" &&
      status != "" &&
      Line1 != "" &&
      // Line2 != "" &&
      Postalcode != "" &&
      Locality != "" &&
      State != "" &&
      group != "" &&
      // photo != "" && 
      !isErrorLocationLink
      //  && emergencyContact !== ''

    ) {
      console.log("Submit");
      // Get form data
      const admin = {
        name: contactpersonName,

        contactNo: contactNo,
        email: personalEmail,

        role: role.value,

        address: [{
          line1: Line1,
          line2: Line2,
          postalCode: Postalcode,
          locality: Locality,
          state: State,
          locationLink: locationLink
        }],

        // checkpost:checkpost.map((item)=>item.value),
        // department :department.map((item)=>item.value),
        // parkingdepartment:parkingdepartment.map((item)=>item.value),

        status: status.value,

        plant: [
          {
            plantName: plantname,
            contactPersonName: contactpersonName,
            alternateNo: alternateNo,
            officalEmail: officialEmail,
            emergencyContact: emergencyContact,
          }
        ],
        materialGatePassApprovalStage : materialGatePassApproveStage,
        outPassApprovalStage : outPassApproveStage,

      };
      if(photo)
        {
          admin.file = photo;
        }
      const state = "add";
      dispatch(plantadminAddOrUpdate(admin, state))

    }
  };


  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
        if (responseMessage !== "") {
          history.goBack();
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: PLANTADMIN_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])
  const textRegex = /^[A-Za-z,\s]+$/;

  const phoneRegex = /^\d{10}$/;
  const pinRegex = /^\d{6}$/;
  const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,10}$/;
  const numberRegex = /^\d{10}$/;
  const licencenoRegex = /^[A-Za-z0-9]{6,12}$/
  const urlRegex = /^(https?):\/\/(?:www\.)?([a-zA-Z0-9-]+)\.([a-zA-Z]{2,})(\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=%]*)?$/;
  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Plant Admin Form
          </Text>
          <SimpleGrid minChildWidth="120px" spacing="40px">
          <FormControl isInvalid={isErrorPhoto}>
              <FormLabel>Company Logo</FormLabel>
              <Input
                type='file'
                onChange={(e) => {
                  setIsErrorPhoto(e.target.value === '')
                  setPhoto(e.target.files[0])
                }}
              />
              {isErrorPhoto ?(
                <FormErrorMessage>Company Logo is required.</FormErrorMessage>
              ):null}
            </FormControl>

            <FormControl isInvalid={isErrorName}>
              <FormLabel>Plant Name</FormLabel>
              <Input
                type="text"
                value={plantname}
                onChange={(e) => {
                  setIsErrorName(e.target.value === "");
                  setPlantname(e.target.value);
                }}
              />
              {!isErrorName ? (
                <></>
              ) : (
                <FormErrorMessage>Name is required.</FormErrorMessage>
              )}
            </FormControl>


            <FormControl isInvalid={isErrorContactpersonName}>
              <FormLabel>Contact person Name</FormLabel>
              <Input
                type="text"
                value={contactpersonName}
                onChange={(e) => {
                  setIsErrorContactpersonName(e.target.value === "");
                  setContactpersonName(e.target.value);
                }}
              />
              {!isErrorContactpersonName ? (
                <></>
              ) : (
                <FormErrorMessage>Contact person Name is required.</FormErrorMessage>
              )}
            </FormControl>
          </SimpleGrid>
          <SimpleGrid minChildWidth="120px" spacing="40px" mt="24px">
            <FormControl isInvalid={isErrorOfficialEmail}>
              <FormLabel>Official-Email</FormLabel>
              <Input
                type="email"
                value={officialEmail}
                onChange={(e) => {
                  setIsErrorOfficialEmail(e.target.value === "" || !emailRegex.test(e.target.value));
                  setOfficialEmail(e.target.value);
                }}
              />
              {!isErrorOfficialEmail ? (
                <></>
              ) : (
                <FormErrorMessage>Official-Email is required.</FormErrorMessage>
              )}
            </FormControl>

            <FormControl isInvalid={isErrorPersonalEmail}>
              <FormLabel>Personal-Email</FormLabel>
              <Input
                type="email"
                value={personalEmail}
                onChange={(e) => {
                  setIsErrorPersonalEmail(e.target.value === "" || !emailRegex.test(e.target.value));
                  setPersonalEmail(e.target.value);
                }}
              />
              {!isErrorPersonalEmail ? (
                <></>
              ) : (
                <FormErrorMessage>Personal-Email is required.</FormErrorMessage>
              )}
            </FormControl>
          </SimpleGrid>
          <SimpleGrid minChildWidth="120px" spacing="40px" mt="24px">
            <FormControl isInvalid={isErrorContactNo}>
              <FormLabel>Contact Number</FormLabel>
              <Input
                type="number"
                value={contactNo}
                onChange={(e) => {
                  setIsErrorContactNo(e.target.value === "" || !phoneRegex.test(e.target.value));
                  setContactNo(e.target.value);
                }}
              />
              {!isErrorContactNo ? (
                <></>
              ) : (
                <FormErrorMessage>Contact No. is required.</FormErrorMessage>
              )}
            </FormControl>

            <FormControl isInvalid={isErrorAlternateNo}>
              <FormLabel>Alternate Number</FormLabel>
              <Input
                type="number"
                value={alternateNo}
                onChange={(e) => {
                  setIsErrorAlternateNo(e.target.value === "" || !phoneRegex.test(e.target.value));
                  setAlternateNo(e.target.value);
                }}
              />
              {!isErrorAlternateNo ? (
                <></>
              ) : (
                <FormErrorMessage>Alternate Number is required.</FormErrorMessage>
              )}
            </FormControl>


          </SimpleGrid>
          <SimpleGrid minChildWidth="120px" spacing="40px" mt="24px">

            <FormControl isInvalid={isErrorRole}>
              <FormLabel>Role</FormLabel>
              <Select
                isDisabled="true"
                placeholder="Plant Admin"
                value={rolies}
                options={roleList
                  .filter((role) => role['name'] === 'Plant Admin') // Filter to include the specific role based on name
                  .map((role) => ({
                    value: role['_id'],
                    label: role['name'],
                  }))
                }
                onChange={(role) => {
                  console.log(role);
                  setIsErrorRole(role === '');
                  setRole(role);
                }}
              />
              {!isErrorRole ? (
                <></>
              ) : (
                <FormErrorMessage>Role is required.</FormErrorMessage>
              )}
            </FormControl>



            <FormControl isInvalid={isErrorGroup}>
              <FormLabel>Group</FormLabel>
              <Select
                value={group}
                placeholder="---select Group---"
                onChange={(group) => {
                  setIsErrorGroup(group === '')
                  setGroup(group)
                }}
                options={groupList.map((group) => ({
                  value: group['_id'],
                  label: group['name']
                }))}
              ></Select>
              {!isErrorGroup ? (
                <></>
              ) : (
                <FormErrorMessage>Group is required.</FormErrorMessage>
              )}
            </FormControl>

          </SimpleGrid>

          <SimpleGrid minChildWidth="120px" spacing="40px" mt="24px">

            {/* <FormControl isInvalid={isErrorCheckpost}>
              <FormLabel>CheckPost</FormLabel>
              <Select
               isMulti
               name="colors"
               placeholder="--select Checkpost--"
               options={checkpostList.map((item) => ({
                  value: item['_id'],
                  label: item['name']
                  }))}
               value={checkpost}
               onChange={(checkpost) => {
                 setCheckpost(checkpost)
                 setIsErrorCheckpost(checkpost === '')}}
                className="basic-multi-select"
                classNamePrefix="select"
              />
              {!isErrorCheckpost ? (
                <></>
              ) : (
                <FormErrorMessage>Checkpost is required.</FormErrorMessage>
              )}
            </FormControl> */}

            {/* <FormControl isInvalid={isErrorDepartment}>
              <FormLabel>Department/subdepartment</FormLabel>
              <Select
               isMulti
               name="colors"
               placeholder="--select department--"
               options={departmentList.map((item) => ({
                  value: item['_id'],
                  label: item['name']
                  }))}
               value={department}
               onChange={(department) => {
                 setDepartment(department)
                 setIsErrorDepartment(department === '')}}
                className="basic-multi-select"
                classNamePrefix="select"
              />
              {!isErrorDepartment ? (
                <></>
              ) : (
                <FormErrorMessage>Department/subDepartment is required.</FormErrorMessage>
              )}
            </FormControl> */}

            {/* <FormControl isInvalid={isErrorDepartment}>
              <FormLabel>ParkingDepartment</FormLabel>
              <Select
               isMulti
               name="colors"
               placeholder="--select parkingdepartment--"
               options={parkingdepartmentList.map((item) => ({
                  value: item['_id'],
                  label: item['name']
                  }))}
               value={parkingdepartment}
               onChange={(parkingdepartment) => {
                 setParkingDepartment(parkingdepartment)
                 setIsErrorParkingDepartment(parkingdepartment === '')}}
                className="basic-multi-select"
                classNamePrefix="select"
              />
              {!isErrorParkingDepartment ? (
                <></>
              ) : (
                <FormErrorMessage>ParkingDepartment is required.</FormErrorMessage>
              )}
            </FormControl> */}

          </SimpleGrid>

          <SimpleGrid minChildWidth="120px" spacing="40px" mt="24px">

            <FormControl isInvalid={isErrorLine1}>
              <FormLabel>Address Line1</FormLabel>
              <Input
                type="text"
                value={Line1}
                onChange={(e) => {
                  setIsErrorLine1(e.target.value === "" );
                  setLine1(e.target.value);
                }}
              />
              {!isErrorLine1 ? (
                <></>
              ) : (
                <FormErrorMessage>Address Line1 is required.</FormErrorMessage>
              )}
            </FormControl>


            <FormControl isInvalid={isErrorLine2}>
              <FormLabel>Address Line2</FormLabel>
              <Input
                type="text"
                value={Line2}
                onChange={(e) => {
                  setIsErrorLine2(e.target.value === "" );
                  setLine2(e.target.value);
                }}
              />
              {!isErrorLine2 ? (
                <></>
              ) : (
                <FormErrorMessage>Address Line2 is required.</FormErrorMessage>
              )}
            </FormControl>


            <FormControl isInvalid={isErrorPostalcode}>
              <FormLabel>Postal Code</FormLabel>
              <Input
                type="number"
                value={Postalcode}
                onChange={(e) => {
                  setIsErrorPostalcode(e.target.value === "" || !pinRegex.test(e.target.value));
                  setPostalcode(e.target.value);
                }}
              />
              {!isErrorPostalcode ? (
                <></>
              ) : (
                <FormErrorMessage>Postal Code is required.</FormErrorMessage>
              )}
            </FormControl>
          </SimpleGrid>

          <SimpleGrid minChildWidth="120px" spacing="40px" mt="24px">

            <FormControl isInvalid={isErrorLocality}>
              <FormLabel>City</FormLabel>
              <Input
                type="text"
                value={Locality}
                onChange={(e) => {
                  setIsErrorLocality(e.target.value === "");
                  setLocality(e.target.value);
                }}
              />
              {!isErrorLocality ? (
                <></>
              ) : (
                <FormErrorMessage>City is required.</FormErrorMessage>
              )}
            </FormControl>


            <FormControl isInvalid={isErrorState}>
              <FormLabel>State</FormLabel>
              <Input
                type="text"
                value={State}
                onChange={(e) => {
                  setIsErrorState(e.target.value === "");
                  setState(e.target.value);
                }}
              />
              {!isErrorState ? (
                <></>
              ) : (
                <FormErrorMessage>State is required.</FormErrorMessage>
              )}
            </FormControl>




            <FormControl isInvalid={isErrorStatus}>
              <FormLabel>Status</FormLabel>
              <Select
                value={status}
                placeholder="Select Status"
                onChange={(status) => {
                  setIsErrorStatus(status === '')
                  setStatus(status)
                }}
                options={dataConstant.status.map((status) => ({
                  value: status['value'],
                  label: status['name']
                }))}

              />


              {!isErrorStatus ? (
                <></>
              ) : (
                <FormErrorMessage>Status is required.</FormErrorMessage>
              )}
            </FormControl>



          </SimpleGrid>

          <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
            <FormControl isInvalid={isErrorPostalcode}>
              <CustomInput
                type='text'
                label="Location URL"
                placeholder="Location URL"
                regex={urlRegex}
                value={locationLink}
                onChange={(value) => {
                  setIsErrorLocationLink(!urlRegex.test(value));
                  setLocationLink(value);
                }}
                isInvalid={isErrorLocationLink}
              />
            </FormControl>
            <FormControl isInvalid={isErrorEmergencyContact || (emergencyContact === "")}>
              <CustomInput
                type="number"
                label="Emergency Contact (For security guidelines)"
                value={emergencyContact}
                onChange={(value) => {
                  setIsErrorEmergencyContact(value === '' || !numberRegex.test(value));
                  setEmergencyContact(value);
                }}

                regex={numberRegex}
              />
            </FormControl>
          </SimpleGrid>
          <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
            <FormControl >
              <CustomInput
                type='number'
                label="Material/ Raised Gate Pass Approval Stage"
                placeholder="Material/ Raised Gate Pass Approval Stage"
                value={materialGatePassApproveStage}
                onChange={(value) => {
                  setMaterialGatePassApproveStage(value);
                }}
                
              />
            </FormControl>
            <FormControl >
              <CustomInput
                type="number"
                label="Out Pass Approval Stage"
                value={outPassApproveStage}
                onChange={(value) => {
                  setOutPassApproveStage(value);
                }}

              />
            </FormControl>
          </SimpleGrid>


          <Center>
            {loading ? (
              <Spinner mt="24px" size="lg" />
            ) : (
              <Button colorScheme="blue" mt="24px" onClick={handleSubmit}>
                Submit
              </Button>
            )}
          </Center>
        </CardHeader>
        <CardBody></CardBody>
      </Card>
    </Flex>
  )
}

export default PlantAdminForm;