import React, { useEffect, useState,useRef } from 'react';
import { Flex, InputGroup, Spacer, Table, Tbody, Text, Td, Th, Thead, Tr, useColorModeValue, InputLeftElement, IconButton, Input, Icon, Link ,Button, Tabs, TabList, TabPanels, TabPanel, Tab,Badge} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';

import { useToast } from '@chakra-ui/react';

import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';

import { AddIcon } from '@chakra-ui/icons';
import { Skeleton, SkeletonCircle, SkeletonText,Stack } from '@chakra-ui/react'
import { useParams ,useLocation,useHistory} from "react-router-dom/cjs/react-router-dom.min";
import axios from 'axios';
import { server_url } from "constants/types";
import { materialGatePassGetAll } from '../../actions/materialGatePassAction';
import dataConstant from '../../constants/dataConstant'
import RgpManagementAdvanceSearch from './RgpManagementAdvanceSearch';
import RgpManagementTable from './RgpManagementTable';


const RgpManagementTabs = (props) => {
    const {
        module=1,
      } = props;
  const tableRef = useRef(null);
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const dispatch = useDispatch();
  const toast = useToast();
  const searchIconColor = useColorModeValue("gray.700", "gray.200");
  const inputBg = useColorModeValue("#edf3f8", "navy.800");
  const [search, setSearch] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [tab, setTab] = useState(0);
  const [tabValue, setTabValue] = useState(0);
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const totalItems = useSelector(state => state.raisedGatePass.totalItems);
  const [approvedRgpManagement, setApprovedRgpManagement] = useState(0);
  const [deniedRgpManagement, setDeniedRgpManagement] = useState(0);
  const searching = useSelector(state => state.raisedGatePass.searching);
  useEffect(() => {
    
    const query = new URLSearchParams(location.search);
    setTabValue(parseInt(tabValue, 10));
    setTabValue(query.get('tab'));
    setTabValue(query.get('tab'));
    setTabValue(query.get('tab'));
    // tabValue = query.get('tab');
    console.log(tabValue + "taaaaaaa")
    const tabValueInt = parseInt(query.get('tab'), 10)
    if (!isNaN(tabValueInt)) {
        setTab(tabValueInt);
      }
      else
      {
        setTab(0);
      }
      getVisitorTabCount();
  }, []);



  const getVisitorTabCount = () => {
    axios
      .get(`${server_url}/admin/raisedGatepass/tab-count`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((response) => {
        if(response.data.status == 'OK')
            {
                var data = response.data.data;
                setApprovedRgpManagement(data['approvedRgpManagement']);
                setDeniedRgpManagement(data['deniedRgpManagement']);
            }
        
      }).catch(() => console.log("error"))
  }

const onChange = (tab) =>
    {
        if(tab==0)
            {
                history.push(`?tab=${tab}`);
                dispatch(materialGatePassGetAll(`&status=RGP`));
            }
            else if(tab ==1)
                {
                    history.push(`?tab=${tab}`);
                    dispatch(materialGatePassGetAll(`&status=NRGP`));
                }
            else if(tab ==2)
                {
                    history.push(`?tab=${tab}`);
                    dispatch(materialGatePassGetAll(`&status=REJECTED_GATE_PASS`));
                }
        setTab(tab);
    }
    useEffect(() => {
        const timer = setTimeout(() => {
          setLoading(false); // Hide loader after 2 seconds
        }, 200);
    
        return () => clearTimeout(timer); // Clean up timer on unmount
      }, []);

  return (
  <>
  {tab == 0 ?<RgpManagementAdvanceSearch gatepassStatus={dataConstant.gatepassStatusAPPROVED}></RgpManagementAdvanceSearch> : tab == 1 ?<RgpManagementAdvanceSearch gatepassStatus={dataConstant.gatepassStatusDENIED}/>:<></> }
  <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
       
        {!loading ?<Tabs defaultIndex={tab}>
        <Card><TabList>
            <Tab onChange={()=>{
                onChange(0);
                setTab(0);
            }} 
            onClick={()=>{
                onChange(0);
                setTab(0);
            }} 
            >Approved <Badge ml='1' fontSize='0.8em' colorScheme='red'>
            {tab==0 ?totalItems:approvedRgpManagement }
          </Badge>
          </Tab>
            <Tab onChange={()=>{
                onChange(1);
                setTab(1);
            }} onClick={()=>{
                onChange(1);
                setTab(1);
            }}>Denied <Badge ml='1' fontSize='0.8em' colorScheme='red'>
            {tab==1 ?totalItems:deniedRgpManagement}
          </Badge></Tab>
        </TabList>
        </Card>
        <TabPanels >
              <TabPanel className="remove-padding-table">{tab ==0 ? <RgpManagementTable gatepassStatus={dataConstant.gatepassStatusAPPROVED}></RgpManagementTable>:<></>}</TabPanel>
              <TabPanel className="remove-padding-table">{tab ==1 ? <RgpManagementTable gatepassStatus={dataConstant.gatepassStatusDENIED}></RgpManagementTable>:<></>}</TabPanel>
             {/* <TabPanel className="remove-padding-table">{tab ==2 ? <GatePassTable gatepassType="REJECTED_GATE_PASS"></GatePassTable>:<></>}</TabPanel> */}
            
        </TabPanels>
        </Tabs>:<></>}
    </Flex>
  </>
  );
};

export default RgpManagementTabs;
