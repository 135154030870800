import React from "react";
import {
  chakra,
  Badge,
  Td,
  Tr,
  Text,
  useColorModeValue,
  Flex,
  ButtonGroup,
  IconButton,
  Link,
  Image,
  useDisclosure
} from "@chakra-ui/react";
import { ViewIcon } from "@chakra-ui/icons";
import { id } from "date-fns/locale";
import { name } from "xlsx-populate/lib/RichTextFragment";
import { BrowserRouter, Route, useHistory } from 'react-router-dom';
import PasswordViewVerification from "views/PasswordView/PasswordViewVerification";

const WorkforceHeadcountTableRow = (props) => {
  const {
    serialnumber,
    employee,
    department,
    subdepartment,
    inDate,
    inTime,
    contactNo,
    type,
    id,
    isLast
  } = props;
  const textColor = useColorModeValue("gray.500", "white");
  const titleColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useHistory();
  const handelView = (id,url) => {
    history.push(`/admin${url}`);
  }

  return (
    <>
      <Tr>

        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {employee} <br></br>
              {contactNo} <ViewIcon ml={"4px"} onClick={onOpen} boxSize={4} cursor={"pointer"}></ViewIcon>
              
            </Text>
          </Flex>
        </Td>

        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {department} <br/>
              {subdepartment}
            </Text>
          </Flex>
        </Td>
        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {inDate} <br/> {inTime}
              </Text>
            </Flex>
          </Flex>
        </Td>

        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {type}
              
            </Text>
          </Flex>
        </Td>

        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              1
            </Text>
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <ButtonGroup>
            <Link>
              <IconButton
              onClick={onOpen}
              colorScheme="blue"
                icon={<ViewIcon />}
                aria-label="Up"
              />
            </Link>
            {/* View Data Modal */}
          <PasswordViewVerification
            reportName = {`Work force View` }
            type="View"
            id={id}
            isOpen={isOpen}
            onClose={onClose}
            onExport={() =>
            {
              if(type == 'Employee')
              {
                handelView(id,`/employee-view/${id}`);
              }
              else if(type == 'Guard')
              {
                handelView(id,`/guard-view/${id}`);
              }
              else
              {
                handelView(id,`/driver-view/${id}`);
              }
              
            }
            }
            />

            
          </ButtonGroup>
        </Td>
      </Tr>
    </>
  );
};

export default WorkforceHeadcountTableRow;
