// Chakra imports
import {
    Flex,
    IconButton,
    Input,
    InputGroup,
    InputLeftElement,
    Spacer,
    Table,
    Tbody,
    Text,
    Th,
    Thead,
    Tr,
    Td,
    useColorModeValue,
    Link,
    Icon
  } from "@chakra-ui/react";
  // Custom components
  import Card from "components/Card/Card.js";
  import CardBody from "components/Card/CardBody.js";
  import CardHeader from "components/Card/CardHeader.js";
  import CorporateTableRow from "./CorporateTableRow.js"
  import React, { useEffect } from "react";
  import { useDispatch, useSelector } from 'react-redux';
  import { corporateGetAll, adminDelete, adminAddOrUpdate, adminClearShowSnackbar } from '../../actions/adminActions';
  import { useToast } from '@chakra-ui/react'
  import { ADMIN_CLEAR_SNACKBAR } from "constants/types";
  import { AddIcon, SearchIcon } from "@chakra-ui/icons";
  import { useState } from "react";
  import AdminPagination from "views/Dashboard/AdminPagination.js"
  import { FaChevronDown, FaChevronUp } from "react-icons/fa";
  import moment from "moment/moment";
  
  
  
  function CorporateTable() {
    const textColor = useColorModeValue("gray.700", "white");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    const searchIconColor = useColorModeValue("gray.700", "gray.200");
    const inputBg = useColorModeValue("#edf3f8", "navy.800");
    const responseStatus = useSelector(state => state.admin.status);
    const responseMessage = useSelector(state => state.admin.text);
    const response = useSelector(state => state.admin.response);
    const loading = useSelector(state => state.admin.loading);
    const adminList = useSelector(state => state.admin.adminList);
    const showSnackbar = useSelector(state => state.admin.showSnackbar);
  
    const [search, setSearch] = useState("");
    const [sortColumn, setSortColumn] = useState("");
    const [sortOrder, setSortOrder] = useState("asc");
    const dispatch = useDispatch();
    const toast = useToast();
  
    console.log("ADMINLIST");
    console.log(adminList);
    useEffect(() => {
      dispatch(corporateGetAll());
    }, []);
  
    useEffect(() => {
      if (showSnackbar) {
        if (responseStatus === 'OK' && loading === false) {
          if (responseMessage !== "") {
            toastFunction(responseMessage, 'success');
          }
        } else if (responseStatus === 'NOK' && loading === false) {
          if (responseMessage !== "") {
            toastFunction(responseMessage, 'error');
          }
        }
      }
      dispatch({
        type: ADMIN_CLEAR_SNACKBAR,
        payload: {
          showSnackbar: false,
        }
      });
    }, [loading]);
  
    const toastFunction = (title, status) => {
      toast({
        title: title,
        status: status,
        duration: 3000,
        isClosable: true,
      })
    };
  
  
    const handleSort = (column) => {
      if (column === sortColumn) {
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
      } else {
        setSortColumn(column);
        setSortOrder("desc");
      }
      const sorting = "&sortOrder=" + sortOrder + "&column=" + column;
      dispatch(corporateGetAll(sorting));
    };
  
    useEffect(() => {
      const searching = "&search=" + search;
      console.log(searching);
      dispatch(corporateGetAll(searching));
    }, [search]);
  
  
  
    return (
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
  
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="16px">
          <Flex >
            <CardHeader p="6px 0px 22px 0px">
              <Text fontSize="xl" color={textColor} fontWeight="bold">
                Corporate
              </Text>
            </CardHeader>
            <Spacer />
            <Flex justify="flex-end" style={{ padding: '10px', marginTop: '-5px', marginRight: '12px' }}>
              <Link href={`#/admin/corprate-form`}>
                <IconButton
                  colorScheme="blue"
                  aria-label="Search database"
                  icon={<AddIcon />}
                />
              </Link>
            </Flex>
  
            <CardHeader p="6px 0px 22px 0px">
              <Text fontSize="xl" color={textColor} fontWeight="bold">
                <InputGroup borderRadius="8px" w="200px">
                  <InputLeftElement
                    children={
                      <IconButton
                        bg="inherit"
                        borderRadius="inherit"
                        _hover="none"
                        _active={{
                          bg: "inherit",
                          transform: "none",
                          borderColor: "transparent",
                        }}
                        _focus={{
                          boxShadow: "none",
                        }}
                        icon={
                          <SearchIcon
                            color={searchIconColor}
                            w="15px"
                            h="15px"
                          />
                        }
                      ></IconButton>
                    }
                  />
  
  
                  <Input
                  type="pass"
                  variant="search"
                  fontSize="xs"
                  bg={inputBg}
                  onKeyUp={(e) => {
                      setSearch(e.target.value);
                      if (e.target.value.length < 3) {
                        setSearch("");
                      }
                    }}
                    placeholder="Type here..."
                  />
                </InputGroup>
              </Text>
            </CardHeader>
          </Flex>
  
          <CardBody>
            <div style={{ overflowX: "auto" }}>
              <Table variant="simple" color={textColor}>
                <Thead>
                  <Tr my=".8rem" pl="0px" color="gray.400">
                    <Th>SNo</Th>
                    <Th borderColor={borderColor}
                      color="gray.400"
                      onClick={() => handleSort("cityadminId")}
                      cursor="pointer">
                      City Admin Id
                      {sortColumn === "cityadminId" && (
                        <Icon
                          as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                          boxSize={4}
                          ml={1}
                        />
                      )}
                    </Th>
                    <Th borderColor={borderColor}
                      color="gray.400"
                      onClick={() => handleSort("name")}
                      cursor="pointer">
                      Name
                      {sortColumn === "name" && (
                        <Icon
                          as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                          boxSize={4}
                          ml={1}
                        />
                      )}
                    </Th>
  
                    <Th borderColor={borderColor}
                      color="gray.400"
                      onClick={() => handleSort("email")}
                      cursor="pointer">
                      Email
                      {sortColumn === "email" && (
                        <Icon
                          as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                          boxSize={4}
                          ml={1}
                        />
                      )}
                    </Th>
                    <Th borderColor={borderColor}
                      color="gray.400"
                      onClick={() => handleSort("primary_contactNo")}
                      cursor="pointer">
                      Primary Phone
                      {sortColumn === "primary_contactNo" && (
                        <Icon
                          as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                          boxSize={4}
                          ml={1}
                        />
                      )}</Th>
                    <Th borderColor={borderColor}
                      color="gray.400"
                      onClick={() => handleSort("secoundary_contactNo")}
                      cursor="pointer">
                      Secondary Phone
                      {sortColumn === "secoundary_contactNo" && (
                        <Icon
                          as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                          boxSize={4}
                          ml={1}
                        />
                      )}</Th>
                    <Th borderColor={borderColor}
                      color="gray.400"
  
                    >
                      Start Date
                    </Th>
                    <Th borderColor={borderColor}
                      color="gray.400"
  
                    >
                      End Date
                    </Th>
  
                    <Th borderColor={borderColor}
                      color="gray.400"
                      onClick={() => handleSort("status")}
                      cursor="pointer">
                      Status
                      {sortColumn === "status" && (
                        <Icon
                          as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                          boxSize={4}
                          ml={1}
                        />
                      )}</Th>
                      <Th></Th>
  
                  </Tr>
                </Thead>
                <Tbody>
  
  
                  {
                    adminList.length > 0 ? (adminList.map((row, index, arr) => (
                      <CorporateTableRow
                       serialnumber={((response.currentPage - 1)*response.limit) + (index+1) }
                        cityadminId={row.passcode}
                        name={row.name}
                        email={row.email}
                        primarycontactNo={row.contactNo}
                        secoundarycontactNo={row.
                          secoundarycontactNo}
  
                        isLast={index === arr.length - 1}
                        id={row._id}
                        status={row.status}
                        startDate={moment(row.startDate).format('DD-MM-YY')}
                        endDate={moment(row.endDate).format('DD-MM-YY')}
                      />
                    ))) : (<Tr> <Td colSpan="10" style={{textAlign:"center"}} ><Text>No data found</Text></Td></Tr>)
                  }
                </Tbody>
              </Table>
            </div>
            {adminList.length === 0 ? (
              <></>
            ) : (
              <AdminPagination
                totalPages={response.totalPages}
                currentPage={response.currentPage}
                perPage={response.perPage}
                sortBy={sortColumn}
                sortOrder={sortOrder}
                handlePageClick={(data) => {
                  const selectedPage = data.selected + 1;
                  dispatch(bannerGetAll(`&page=${selectedPage}`));
                }}
              />
            )}
          </CardBody>
        </Card>
      </Flex>
    );
  }
  
  export default CorporateTable;
  