import React, { useEffect, useState,useRef } from 'react';
import { Flex, InputGroup, Spacer, Table, Tbody, Text, Td, Th, Thead, Tr, useColorModeValue, InputLeftElement, IconButton, Input, Icon, Link ,Button, Tabs, TabList, TabPanels, TabPanel, Tab,Badge} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';

import { useToast } from '@chakra-ui/react';

import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';

import { AddIcon } from '@chakra-ui/icons';
import { Skeleton, SkeletonCircle, SkeletonText,Stack } from '@chakra-ui/react'
import { VisitorInOutGetAll } from '../../actions/VisitorInOutAction';
import { useParams ,useLocation,useHistory} from "react-router-dom/cjs/react-router-dom.min";
import axios from 'axios';
import { server_url } from "constants/types";
import dataConstant from "constants/dataConstant";
import VisitorHeadcountTable from './VisitorHeadcount/VisitorHeadcountTable';
import VisitorHeadcountSearch from './VisitorHeadcount/VisitorHeadCountAdvanceSearch';
import DeliveryHeadcountTable from './DeliveryHeadcount/DeliveryHeadcountTable';
import DeliveryHeadcountAdvanceSearch from './DeliveryHeadcount/DeliveryHeadcountAdvanceSearch';
import MaterialHeadcountAdvanceSearch from './MaterialHeadcount/MaterialHeadcountAdvanceSearch';
import MaterialHeadcountTable from './MaterialHeadcount/MaterialHeadcountTable';
import CanteenHeadCountTable from 'views/CanteenInOut/CanteenHeadCountTable';
import CanteenHeadcountAdvanceSearch from './CanteenHeadcount/CanteenHeadcountAdvanceSearch';
import CanteeenHeadcountTable from './CanteenHeadcount/CanteenHeadcountTable';
import WorkforceHeadcountTable from './WorkforceHeadcount/WorkforceHeadcountTable';
import WorkforceHeadcountAdvanceSearch from './WorkforceHeadcount/WorkforceHeadcountAdvanceSearch';

const HeadCountTabs = (props) => {
    const {
        module=1,
      } = props;
  const tableRef = useRef(null);
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const dispatch = useDispatch();
  const toast = useToast();
  const searchIconColor = useColorModeValue("gray.700", "gray.200");
  const inputBg = useColorModeValue("#edf3f8", "navy.800");
  const [search, setSearch] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [tab, setTab] = useState(0);
  const [tabValue, setTabValue] = useState(0);
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [totalVisitor, setTotalVisitor] = useState(0);
  const [totalDelivery, setTotalDelivery] = useState(0);
  const [totalMaterial, setTotalMaterial] = useState(0);
  const [totalCanteeen, setTotalCanteen] = useState(0);
  const [totalWorkforce, setTotalWorkforce] = useState(0);
  const [tabCountDetail, setTabCountDetail] = useState('');
  const searching = useSelector(state => state.visitorInOut.searching);
  const authDetail = useSelector((state) => state.auth.authDetail);
  useEffect(() => {
    
    const query = new URLSearchParams(location.search);
    setTabValue(parseInt(tabValue, 10));
    setTabValue(query.get('tab'));
    setTabValue(query.get('tab'));
    setTabValue(query.get('tab'));
    // tabValue = query.get('tab');
    console.log(tabValue + "taaaaaaa")
    const tabValueInt = parseInt(query.get('tab'), 10)
    if (!isNaN(tabValueInt)) {
        setTab(tabValueInt);
      }
      else
      {
        setTab(0);
      }
      getHeadcountTabCount();
  }, []);

  const getHeadcountTabCount = () => {
    axios
      .get(`${server_url}/admin/head-count/tab-count`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((response) => {
        if(response.data.status == 'OK')
            {
                var data = response.data.data;
                setTotalVisitor(data['totalVisitors']);
                setTotalDelivery(data['totalDelivery']);
                setTotalMaterial(data['totalMaterials']);
                setTotalCanteen(data['totalCanteens']);
                setTotalWorkforce(data['totalWorkforce']);
            }
        
      }).catch(() => console.log("error"))
  }

const onChange = (tab) =>
    {
        if(tab==0)
            {
                history.push(`?tab=${tab}`);
            }
            else if(tab ==1)
                {
                    history.push(`?tab=${tab}`);
                }
            else if(tab ==2)
                {
                    history.push(`?tab=${tab}`);
                }
            else if(tab ==3)
                {
                    history.push(`?tab=${tab}`);
                }
            else if(tab ==4)
                {
                    history.push(`?tab=${tab}`);
                }
        setTab(tab);
    }
    useEffect(() => {
        const timer = setTimeout(() => {
          setLoading(false); // Hide loader after 2 seconds
        }, 200);
    
        return () => clearTimeout(timer); // Clean up timer on unmount
      }, []);

  return (
  <>
  {tab ==0 ? <WorkforceHeadcountAdvanceSearch></WorkforceHeadcountAdvanceSearch>: (tab ==1 ? <VisitorHeadcountSearch/>: tab ==2 ? <DeliveryHeadcountAdvanceSearch></DeliveryHeadcountAdvanceSearch>: tab == 3 ? <MaterialHeadcountAdvanceSearch></MaterialHeadcountAdvanceSearch> :<CanteenHeadcountAdvanceSearch></CanteenHeadcountAdvanceSearch>)}
  
  <Flex direction="column" pt={{ base: "120px", md: "20px" }}>
       
        {!loading ?<Tabs defaultIndex={tab}>
        <Card><TabList>
            <Tab onClick={()=>{
                onChange(0);
                setTab(0);
            }} onChange={()=>{
              onChange(0);
              setTab(0);
          }} >Work Force <Badge ml='1' fontSize='0.8em' colorScheme='red'>
            {totalWorkforce}
          </Badge>
          </Tab>
            <Tab onClick={()=>{
                onChange(1);
                setTab(1);
            }}  onChange={()=>{
              onChange(1);
              setTab(1);
          }} >Visitor <Badge ml='1' fontSize='0.8em' colorScheme='red'>
            {totalVisitor}
          </Badge></Tab>
            <Tab onClick={()=>{
                onChange(2);
                setTab(2);
            }} onChange={()=>{
              onChange(2);
              setTab(2);
          }}>Delivery <Badge ml='1' fontSize='0.8em' colorScheme='red'>
            {totalDelivery}
          </Badge></Tab>
            <Tab isSelected onClick={()=>{
                onChange(3);
                setTab(3);
            }} onChange={()=>{
              onChange(3);
              setTab(3);
          }}>Material Drivers <Badge ml='1' fontSize='0.8em' colorScheme='red'>
            {totalMaterial}
          </Badge> </Tab>
            <Tab isSelected onClick={()=>{
                onChange(4);
                setTab(4);
            }}>Canteen Drivers <Badge ml='1' fontSize='0.8em' colorScheme='red'>
            {totalCanteeen}
          </Badge></Tab>
        </TabList>
        </Card>
        <TabPanels >
            <TabPanel className="remove-padding-table"><WorkforceHeadcountTable></WorkforceHeadcountTable></TabPanel>
            <TabPanel className="remove-padding-table"><VisitorHeadcountTable></VisitorHeadcountTable></TabPanel>
            <TabPanel className="remove-padding-table"><DeliveryHeadcountTable></DeliveryHeadcountTable></TabPanel>
            <TabPanel className="remove-padding-table"><MaterialHeadcountTable></MaterialHeadcountTable></TabPanel>
            <TabPanel className="remove-padding-table"><CanteeenHeadcountTable></CanteeenHeadcountTable></TabPanel>
            
        </TabPanels>
        </Tabs>:<></>}
    </Flex>
  </>
  );
};

export default HeadCountTabs;
