import React from "react";
import {
  Avatar,
  Badge,
  Button,
  Flex,
  Link,
  Td,
  Text,
  Tr,
  useColorModeValue,
  useDisclosure,
  ButtonGroup,
  IconButton,
  Image

} from "@chakra-ui/react";
import { BsBoxArrowUpRight, BsFillTrashFill } from "react-icons/bs";
import { ViewIcon } from "@chakra-ui/icons";
import { AiFillEdit } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
// import { deviceDelete } from "actions/devicesAction"; // Make sure you have the correct import for deviceDelete
import { materialGatePassDelete } from "actions/materialGatePassAction";

const GatePassTableRow = (props) => {
  const {
    date,
    passType,
    mode,
    status,
    txn,
    currentStatus,
    gatepassNumber,
    gatepassStatus,
    guardStatus,
    totalTime,
    serialnumber,
    logs,
    department,
    subdepartment,
    id,
    key,
    isLast,
    gatepassPhoto,
    isNewEntry,
    invoices,
    supplier,
    material,
    raisedMaterial,
    purpose,
    reasonOfRejection,
    materialMode,
    vehicleNumber,
    employee
  } = props;

  const textColor = useColorModeValue("gray.500", "white");
  const titleColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const materialGatePassDetail = useSelector((state) => state.materialGatePass.materialGatePassDetail); // Make sure you have the correct state slice for device detail
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();

  const dispatch = useDispatch();

  const handleDelete = () => {
    const materialGatePass = {
      _id: id,
    };
    dispatch(materialGatePassDelete(materialGatePass._id)); // Use your deviceDelete action
  };
  //   const authDetail = useSelector((state) => state.auth.authDetail);
  
  if(isNewEntry)
  {
    let rowSpanValue = 0;
    invoices.forEach((element) => {
        rowSpanValue += element.materials.length;
    });
    return (
        <>
        {invoices.map((item, invoiceIndex) => (
        <React.Fragment key={invoiceIndex}>
          {item.materials.map((data, itemIndex) => (
            <Tr key={itemIndex}>
              {/* Only render these cells in the first row */}
              {invoiceIndex === 0 && itemIndex === 0 && (
                   
                <>
                  <Td rowSpan={rowSpanValue}>
                  <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                    <Flex direction="column">
                      <Text
                        fontSize="md"
                        color={titleColor}
                        fontWeight="bold"
                        minWidth="100%"
                      >
                        {serialnumber}
                      </Text>
                    </Flex>
                  </Flex>
                </Td>
              <Td
              rowSpan={rowSpanValue}
                pl="0px"
                borderColor={borderColor}
                borderBottom={isLast ? "none" : "1px solid"}
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                  <Flex direction="column">
                    <Text
                      fontSize="md"
                      color={titleColor}
                      fontWeight="bold"
                      minWidth="100%"
                    >
                      {date}
                    </Text>
                  </Flex>
                </Flex>
              </Td>
              <Td
                rowSpan={rowSpanValue}
                pl="0px"
                borderColor={borderColor}
                borderBottom={isLast ? "none" : "1px solid"}
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                  <Flex direction="column">
                    <Text
                      fontSize="md"
                      color={titleColor}
                      fontWeight="bold"
                      minWidth="100%"
                    >
                      {/* {passType.replace(/_/g, " ")} */}
                      {materialMode.replace(/_/g, " ")} <br/> {vehicleNumber}
                    </Text>
                  </Flex>
                </Flex>
              </Td>
      
              <Td 
              rowSpan={rowSpanValue}
              pl="0px"
                borderColor={borderColor}
                borderBottom={isLast ? "none" : "1px solid"}
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis">
                <Flex direction="column">
                  <Text fontSize="md" color={textColor} fontWeight="bold">
                    {gatepassNumber != ""?gatepassNumber:txn}
                  </Text>
                </Flex>
              </Td>
              
              <Td
              rowSpan={rowSpanValue} 
              pl="0px"
                borderColor={borderColor}
                borderBottom={isLast ? "none" : "1px solid"}
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis">
                <Flex direction="column">
                  <Text fontSize="md" color={textColor} fontWeight="bold">
                    {supplier}
                  </Text>
                </Flex>
              </Td>
              {status=='NRGP' ?
              <Td
              rowSpan={rowSpanValue} 
              pl="0px"
                borderColor={borderColor}
                borderBottom={isLast ? "none" : "1px solid"}
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis">
                <Flex direction="column">
                  <Text fontSize="md" color={textColor} fontWeight="bold">
                  {purpose == "" ? "--" : purpose}
                  </Text>
                </Flex>
              </Td>
              :<></>
              }
                </>
              )}
  
              {/* Invoice related data, span across items */}
              {itemIndex === 0 && (
                <Td rowSpan={item.materials.length}>
                  <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                    <Flex direction="column">
                      <Text fontSize="md" color={titleColor} fontWeight="bold" minWidth="100%">
                        {item.invoiceNumber}
                      </Text>
                    </Flex>
                  </Flex>
                </Td>
              )}
  
              {/* Per-item fields */}
              <Td>
                <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                  <Flex direction="column">
                    <Text fontSize="md" color={titleColor} fontWeight="bold" minWidth="100%">
                    {data.materialDescription} - {data.quantity} {data.uom}
                    </Text>
                  </Flex>
                </Flex>
              </Td>

              {
                status == 'REJECTED_GATE_PASS' ? 
                <Td>
                <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                  <Flex direction="column">
                    <Text fontSize="md" color={titleColor} fontWeight="bold" minWidth="100%">
                    {data.reasonOfRejection}
                    </Text>
                  </Flex>
                </Flex>
              </Td>
              : <></>
              }
  
              {
                status == 'RGP' ? 
                <Td>
                <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                  <Flex direction="column">
                    <Text fontSize="md" color={titleColor} fontWeight="bold" minWidth="100%">
                    {data.returnDate}
                    </Text>
                  </Flex>
                </Flex>
              </Td>
              : <></>
              }
              
              {itemIndex === 0 && (
                status == 'RGP' ? 
                <Td rowSpan={item.materials.length}>
                  <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                    <Flex direction="column">
                      <Text fontSize="md" color={titleColor} fontWeight="bold" minWidth="100%">
                        {item.approxValue}
                      </Text>
                    </Flex>
                  </Flex>
                </Td> : <></>
              )}
              
  
              {/* Action button */}
              {invoiceIndex === 0 && itemIndex === 0 && (
                 <>
                 <Td pl="0px"
                 rowSpan={rowSpanValue} 
                  borderColor={borderColor}
                  borderBottom={isLast ? "none" : "1px solid"}
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis">
                  <Badge colorScheme={gatepassStatus == "APPROVED" ? "green" : "red"}>{gatepassStatus}</Badge>
                  </Td>
                  
                  <Td pl="0px"
                  rowSpan={rowSpanValue} 
                  borderColor={borderColor}
                  borderBottom={isLast ? "none" : "1px solid"}
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis">
                  <Flex direction="column">
                      <Text fontSize="md" color={textColor} fontWeight="bold">
                      {department}
                      </Text>
                  </Flex>
                  </Td>
                  <Td pl="0px"
                  rowSpan={rowSpanValue} 
                  borderColor={borderColor}
                  borderBottom={isLast ? "none" : "1px solid"}
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis">
                  <Flex direction="column">
                      <Text fontSize="md" color={textColor} fontWeight="bold">
                      {subdepartment}
                      </Text>
                  </Flex>
                  </Td>
                  <Td pl="0px"
                  rowSpan={rowSpanValue} 
                  borderColor={borderColor}
                  borderBottom={isLast ? "none" : "1px solid"}
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis">
                  <Badge colorScheme={guardStatus == "IN" ? "green" : "red"}>{guardStatus}</Badge>
                  </Td>
                  <Td pl="0px"
                  rowSpan={rowSpanValue} 
                  borderColor={borderColor}
                  borderBottom={isLast ? "none" : "1px solid"}
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis">
                  <Flex direction="column">
                      <Text fontSize="md" color={textColor} fontWeight="bold">
                      {employee?.employeeName}
                      </Text>
                  </Flex>
                  </Td>
                  <Td
                  rowSpan={rowSpanValue} 
                  borderColor={borderColor}
                  borderBottom={isLast ? "none" : null}
                  overflowX="auto" // Add overflowX property for horizontal scrollbar
                  >
                  <Flex direction="column">
                    {/* {logs.map((logItem, index) => ( */}
                    <Text 
                    //   key={index}
                    fontSize="md" color={textColor} fontWeight="bold" whiteSpace="nowrap">
                        {logs[logs.length-1].description} 
                    </Text>
                    {/* ))} */}
                </Flex>
                  </Td>
              
          
                  <Td pl="0px"
                  rowSpan={rowSpanValue} 
                  borderColor={borderColor}
                  borderBottom={isLast ? "none" : "1px solid"}
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis">
                  <ButtonGroup>
                      <Link href={`#/admin/materialGatePass-view-form/${id}`}>
                      <IconButton
                          colorScheme="blue"
                          icon={<ViewIcon />}
                          aria-label="Up"
                      />
                      </Link>
          
                      {/* <Link href={`#/admin/materialGatePass-update-form/${id}`}>
                      <IconButton
                          colorScheme="green"
                          icon={<AiFillEdit />}
                          aria-label="Edit"
                      />
                      </Link> */}
          
          
                      {/* 
                      <IconButton
                      colorScheme="red"
                      variant="outline"
                      icon={<BsFillTrashFill />}
                      aria-label="Delete"
                      onClick={onOpen}
                      /> */}
                  </ButtonGroup>
                  </Td>
                 </>
                
      
              )}
            </Tr>
          ))}
        </React.Fragment>
      ))}
        </>
      );
  }
  else if (passType == "MATERIAL_GATE_PASS")
  {
    let rowSpanValue = 0;
    material.forEach((element) => {
        rowSpanValue ++;
    });
    let materialItems = [{
        "id" : 1
    }];
    return (
        <>
        {material.map((item, invoiceIndex) => (
        <React.Fragment key={invoiceIndex}>
          {materialItems.map((data, itemIndex) => (
            <Tr key={itemIndex}>
              {/* Only render these cells in the first row */}
              {invoiceIndex === 0 && itemIndex === 0 && (
                   
                <>
                  <Td rowSpan={rowSpanValue}>
                  <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                    <Flex direction="column">
                      <Text
                        fontSize="md"
                        color={titleColor}
                        fontWeight="bold"
                        minWidth="100%"
                      >
                        {serialnumber}
                      </Text>
                    </Flex>
                  </Flex>
                </Td>
              <Td
              rowSpan={rowSpanValue}
                pl="0px"
                borderColor={borderColor}
                borderBottom={isLast ? "none" : "1px solid"}
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                  <Flex direction="column">
                    <Text
                      fontSize="md"
                      color={titleColor}
                      fontWeight="bold"
                      minWidth="100%"
                    >
                      {date}
                    </Text>
                  </Flex>
                </Flex>
              </Td>
              <Td
                rowSpan={rowSpanValue}
                pl="0px"
                borderColor={borderColor}
                borderBottom={isLast ? "none" : "1px solid"}
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                  <Flex direction="column">
                    <Text
                      fontSize="md"
                      color={titleColor}
                      fontWeight="bold"
                      minWidth="100%"
                    >
                      
                    --
                    </Text>
                  </Flex>
                </Flex>
              </Td>
      
              <Td 
              rowSpan={rowSpanValue}
              pl="0px"
                borderColor={borderColor}
                borderBottom={isLast ? "none" : "1px solid"}
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis">
                <Flex direction="column">
                  <Text fontSize="md" color={textColor} fontWeight="bold">
                    {gatepassNumber != ""?gatepassNumber:txn}
                  </Text>
                </Flex>
              </Td>
              
                </>
              )}
  
              {/* Invoice related data, span across items */}
              {itemIndex === 0 && (
                <>
                <Td
                rowSpan={materialItems.length}
                pl="0px"
                    borderColor={borderColor}
                    borderBottom={isLast ? "none" : "1px solid"}
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis">
                    <Flex direction="column">
                    <Text fontSize="md" color={textColor} fontWeight="bold">
                        {item.vendor == "" ? "--" : item.vendor}
                    </Text>
                    </Flex>
                </Td>
                {status == 'NRGP' ? 
                <Td
                rowSpan={rowSpanValue} 
                pl="0px"
                    borderColor={borderColor}
                    borderBottom={isLast ? "none" : "1px solid"}
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis">
                    <Flex direction="column">
                    <Text fontSize="md" color={textColor} fontWeight="bold">
                        {purpose == "" ? "--" : purpose}
                    </Text>
                    </Flex>
                </Td> :
                <></>    
            }
                <Td rowSpan={materialItems.length}>
                  <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                    <Flex direction="column">
                      <Text fontSize="md" color={titleColor} fontWeight="bold" minWidth="100%">
                        {item.invoiceNumber == "" ? "--" : item.invoiceNumber}
                      </Text>
                    </Flex>
                  </Flex>
                </Td>
                </>
              )}
  
              {/* Per-item fields */}
              
              
              {itemIndex === 0 && (
                <>
                <Td>
                <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                  <Flex direction="column">
                    <Text fontSize="md" color={titleColor} fontWeight="bold" minWidth="100%">
                    {item.materialDescription} - {item.quantity} {item.oum}
                    </Text>
                  </Flex>
                </Flex>
              </Td>

              {status == 'REJECTED_GATE_PASS' ?
                <>
                 <Td>
                    <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                    <Flex direction="column">
                        <Text fontSize="md" color={titleColor} fontWeight="bold" minWidth="100%">
                        {reasonOfRejection == ""?'--':reasonOfRejection}
                        </Text>
                    </Flex>
                    </Flex>
                </Td>
                </> :<></>
            }
  
             {status == 'RGP' ?
                <>
                 <Td>
                    <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                    <Flex direction="column">
                        <Text fontSize="md" color={titleColor} fontWeight="bold" minWidth="100%">
                        {item.returnDate??'--'}
                        </Text>
                    </Flex>
                    </Flex>
                </Td>
                <Td rowSpan={materialItems.length}>
                  <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                    <Flex direction="column">
                      <Text fontSize="md" color={titleColor} fontWeight="bold" minWidth="100%">
                        {item.approxValue == "" ? "--" : item.approxValue}
                      </Text>
                    </Flex>
                  </Flex>
                </Td>
                </> :<></>
            }
                </>
              )}
              
  
              {/* Action button */}
              {invoiceIndex === 0 && itemIndex === 0 && (
                 <>
                 <Td pl="0px"
                 rowSpan={rowSpanValue} 
                  borderColor={borderColor}
                  borderBottom={isLast ? "none" : "1px solid"}
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis">
                  <Badge colorScheme={gatepassStatus == "APPROVED" ? "green" : "red"}>{gatepassStatus}</Badge>
                  </Td>
                  
                  <Td pl="0px"
                  rowSpan={rowSpanValue} 
                  borderColor={borderColor}
                  borderBottom={isLast ? "none" : "1px solid"}
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis">
                  <Flex direction="column">
                      <Text fontSize="md" color={textColor} fontWeight="bold">
                      {department}
                      </Text>
                  </Flex>
                  </Td>
                  <Td pl="0px"
                  rowSpan={rowSpanValue} 
                  borderColor={borderColor}
                  borderBottom={isLast ? "none" : "1px solid"}
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis">
                  <Flex direction="column">
                      <Text fontSize="md" color={textColor} fontWeight="bold">
                      {subdepartment}
                      </Text>
                  </Flex>
                  </Td>
                  <Td pl="0px"
                  rowSpan={rowSpanValue} 
                  borderColor={borderColor}
                  borderBottom={isLast ? "none" : "1px solid"}
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis">
                  <Badge colorScheme={guardStatus == "IN" ? "green" : "red"}>{guardStatus}</Badge>
                  </Td>
                  <Td pl="0px"
                  rowSpan={rowSpanValue} 
                  borderColor={borderColor}
                  borderBottom={isLast ? "none" : "1px solid"}
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis">
                  <Flex direction="column">
                      <Text fontSize="md" color={textColor} fontWeight="bold">
                      {employee?.employeeName}
                      </Text>
                  </Flex>
                  </Td>
                  <Td
                  rowSpan={rowSpanValue} 
                  borderColor={borderColor}
                  borderBottom={isLast ? "none" : null}
                  overflowX="auto" // Add overflowX property for horizontal scrollbar
                  >
                  <Flex direction="column">
                    {/* {logs.map((logItem, index) => ( */}
                    <Text 
                    //   key={index}
                    fontSize="md" color={textColor} fontWeight="bold" whiteSpace="nowrap">
                        {logs[logs.length-1].description} 
                    </Text>
                    {/* ))} */}
                </Flex>
                  </Td>
              
          
                  <Td pl="0px"
                  rowSpan={rowSpanValue} 
                  borderColor={borderColor}
                  borderBottom={isLast ? "none" : "1px solid"}
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis">
                  <ButtonGroup>
                      <Link href={`#/admin/materialGatePass-view-form/${id}`}>
                      <IconButton
                          colorScheme="blue"
                          icon={<ViewIcon />}
                          aria-label="Up"
                      />
                      </Link>
          
                      {/* <Link href={`#/admin/materialGatePass-update-form/${id}`}>
                      <IconButton
                          colorScheme="green"
                          icon={<AiFillEdit />}
                          aria-label="Edit"
                      />
                      </Link> */}
          
          
                      {/* 
                      <IconButton
                      colorScheme="red"
                      variant="outline"
                      icon={<BsFillTrashFill />}
                      aria-label="Delete"
                      onClick={onOpen}
                      /> */}
                  </ButtonGroup>
                  </Td>
                 </>
                
      
              )}
            </Tr>
          ))}
        </React.Fragment>
      ))}
        </>
      );
  }
  else
  {
    var formattedDate ;
    if(raisedMaterial?.returnDate)
        {
            var returnDate = new Date(raisedMaterial?.returnDate);
            var day = String(returnDate.getDate()).padStart(2, '0'); // Ensures 2 digits
            var month = String(returnDate.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
            var year = returnDate.getFullYear();

            // Format in DD-MM-YYYY
            formattedDate = `${day}-${month}-${year}`;
    
        } 

    // Extract day, month, and year
    
    return (
        <>
          <Tr>
          <Td
              >
                <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                  <Flex direction="column">
                    <Text
                      fontSize="md"
                      color={titleColor}
                      fontWeight="bold"
                      minWidth="100%"
                    >
                      {serialnumber}
                    </Text>
                  </Flex>
                </Flex>
              </Td>
            <Td
              pl="0px"
              borderColor={borderColor}
              borderBottom={isLast ? "none" : "1px solid"}
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                <Flex direction="column">
                  <Text
                    fontSize="md"
                    color={titleColor}
                    fontWeight="bold"
                    minWidth="100%"
                  >
                    {date}
                  </Text>
                </Flex>
              </Flex>
            </Td>
            <Td
              pl="0px"
              borderColor={borderColor}
              borderBottom={isLast ? "none" : "1px solid"}
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                <Flex direction="column">
                  <Text
                    fontSize="md"
                    color={titleColor}
                    fontWeight="bold"
                    minWidth="100%"
                  >
                    BY VEHICLE
                    <br/>
                    {raisedMaterial?.vehicleNumber}
                    {/* {passType} */}
                  </Text>
                </Flex>
              </Flex>
            </Td>
    
            <Td pl="0px"
              borderColor={borderColor}
              borderBottom={isLast ? "none" : "1px solid"}
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis">
              <Flex direction="column">
                <Text fontSize="md" color={textColor} fontWeight="bold">
                  {gatepassNumber != "" ? gatepassNumber :txn}
                </Text>
              </Flex>
            </Td>
            {/* <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
              <Badge colorScheme={status == "RGP" || status == 'NRGP' ? "green" : "red"}>{status}</Badge>
            </Td> */}
            {/* <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
              <Flex direction="column">
                <Text fontSize="md" color={textColor} fontWeight="bold">
                  {currentStatus}
                </Text>
              </Flex>
            </Td> */}
            <Td pl="0px"
              borderColor={borderColor}
              borderBottom={isLast ? "none" : "1px solid"}
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis">
              <Flex direction="column">
                <Text fontSize="md" color={textColor} fontWeight="bold">
                  {raisedMaterial?.vendor}
                </Text>
              </Flex>
            </Td>
            {status == 'NRGP' ? 
            <Td pl="0px"
            borderColor={borderColor}
            borderBottom={isLast ? "none" : "1px solid"}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis">
            <Flex direction="column">
              <Text fontSize="md" color={textColor} fontWeight="bold">
                {purpose == "" ? "--" : purpose}
              </Text>
            </Flex>
          </Td>
          :
          <></>    
        }
            <Td pl="0px"
              borderColor={borderColor}
              borderBottom={isLast ? "none" : "1px solid"}
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis">
              <Flex direction="column">
                <Text fontSize="md" color={textColor} fontWeight="bold">
                  --
                </Text>
              </Flex>
            </Td>
            <Td pl="0px"
              borderColor={borderColor}
              borderBottom={isLast ? "none" : "1px solid"}
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis">
              <Flex direction="column">
                <Text fontSize="md" color={textColor} fontWeight="bold">
                {raisedMaterial?.materialDescription} - {raisedMaterial?.quantity} {raisedMaterial?.oum}
                </Text>
              </Flex>
            </Td>
            {status == 'REJECTED_GATE_PASS' ?
                <>
                <Td>
                    <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                    <Flex direction="column">
                        <Text fontSize="md" color={titleColor} fontWeight="bold" minWidth="100%">
                        {reasonOfRejection == ""?'--':reasonOfRejection}
                        </Text>
                    </Flex>
                    </Flex>
                </Td>
                </> :<></>
            }
            {
                status == 'RGP' ?
                <>
                <Td pl="0px"
                borderColor={borderColor}
                borderBottom={isLast ? "none" : "1px solid"}
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis">
                <Flex direction="column">
                    <Text fontSize="md" color={textColor} fontWeight="bold">
                        
                    {formattedDate}
                    </Text>
                </Flex>
                </Td>
                <Td pl="0px"
                borderColor={borderColor}
                borderBottom={isLast ? "none" : "1px solid"}
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis">
                <Flex direction="column">
                    <Text fontSize="md" color={textColor} fontWeight="bold">
                        
                    --
                    </Text>
                </Flex>
                </Td>
                </> : <></>
            }
            
            <Td pl="0px"
              borderColor={borderColor}
              borderBottom={isLast ? "none" : "1px solid"}
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis">
              <Badge colorScheme={gatepassStatus == "APPROVED" ? "green" : "red"}>{gatepassStatus}</Badge>
            </Td>
            
            <Td pl="0px"
              borderColor={borderColor}
              borderBottom={isLast ? "none" : "1px solid"}
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis">
              <Flex direction="column">
                <Text fontSize="md" color={textColor} fontWeight="bold">
                  {department}
                </Text>
              </Flex>
            </Td>
            <Td pl="0px"
              borderColor={borderColor}
              borderBottom={isLast ? "none" : "1px solid"}
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis">
              <Flex direction="column">
                <Text fontSize="md" color={textColor} fontWeight="bold">
                  {subdepartment}
                </Text>
              </Flex>
            </Td>
            <Td pl="0px"
              borderColor={borderColor}
              borderBottom={isLast ? "none" : "1px solid"}
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis">
              <Badge colorScheme={guardStatus == "IN" ? "green" : "red"}>{guardStatus}</Badge>
            </Td>
            <Td pl="0px"
                  borderColor={borderColor}
                  borderBottom={isLast ? "none" : "1px solid"}
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis">
                  <Flex direction="column">
                      <Text fontSize="md" color={textColor} fontWeight="bold">
                      {employee?.employeeName}
                      </Text>
                  </Flex>
                  </Td>
            <Td
              borderColor={borderColor}
              borderBottom={isLast ? "none" : null}
              overflowX="auto" // Add overflowX property for horizontal scrollbar
            >
              <Flex direction="column">
                {/* {logs.map((logItem, index) => ( */}
                  <Text 
                //   key={index}
                   fontSize="md" color={textColor} fontWeight="bold" whiteSpace="nowrap">
                    {logs[logs.length-1].description}
                  </Text>
                {/* ))} */}
              </Flex>
            </Td>
           
    
            <Td pl="0px"
              borderColor={borderColor}
              borderBottom={isLast ? "none" : "1px solid"}
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis">
              <ButtonGroup>
                <Link href={`#/admin/materialGatePass-view-form/${id}`}>
                  <IconButton
                    colorScheme="blue"
                    icon={<ViewIcon />}
                    aria-label="Up"
                  />
                </Link>
    
                {/* <Link href={`#/admin/materialGatePass-update-form/${id}`}>
                  <IconButton
                    colorScheme="green"
                    icon={<AiFillEdit />}
                    aria-label="Edit"
                  />
                </Link> */}
    
    
                {/* 
                <IconButton
                  colorScheme="red"
                  variant="outline"
                  icon={<BsFillTrashFill />}
                  aria-label="Delete"
                  onClick={onOpen}
                /> */}
              </ButtonGroup>
            </Td>
    
    
    
    
            <AlertDialog
              isOpen={isOpen}
              leastDestructiveRef={cancelRef}
              onClose={onClose}
            >
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader fontSize="lg" fontWeight="bold">
                    Delete Device
                  </AlertDialogHeader>
    
                  <AlertDialogBody>
                    Are you sure? You can't undo this action afterwards.
                  </AlertDialogBody>
    
                  <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={onClose}>
                      Cancel
                    </Button>
                    <Button colorScheme="red" onClick={() => { handleDelete(); onClose(); }} ml={3}>
                      Delete
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>
          </Tr>
        </>
      );
  }
};

export default GatePassTableRow;
