import React from "react";
import {
  chakra,
  Badge,
  Td,
  Tr,
  Text,
  useColorModeValue,
  Flex,
  ButtonGroup,
  IconButton,
  Link,
  Image,
} from "@chakra-ui/react";
import { ViewIcon } from "@chakra-ui/icons";
import { id } from "date-fns/locale";

const VisitorHeadcountTableRow = (props) => {
  const {
    serialnumber,
    date,
    txnNumber,
    guardNameEntryRaisedBy,
    checkpostInName,
    image,
    name,
    company,
    contactno,
    visitorType,
    purpose,
    department,
    subdepartment,
    carryItem,
    vehicleType,
    vehicleNumber,
    totalTime,
    ExitByGuardName,
    checkpostOutName,
    inTime,
    outTime,
    isLast,
    id,
    approvedBy,
    driverMobileNumber,
    driverName,
    actualVisitors
  } = props;
  const textColor = useColorModeValue("gray.500", "white");
  const titleColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  return (
    <>
      <Tr>

        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {name} <br/>
              {contactno}
            </Text>
          </Flex>
        </Td>

        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {department} <br/>
              {subdepartment}
            </Text>
          </Flex>
        </Td>
        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {date} <br/> {inTime}
              </Text>
            </Flex>
          </Flex>
        </Td>

        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {driverName != 'null'?driverName:""} <br/>
              {(driverMobileNumber == "" && driverName && driverName != 'null')? 'NA':driverMobileNumber}
            </Text>
          </Flex>
        </Td>

        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {(driverName != null && driverName != "" && driverName != "null") ? (2 + actualVisitors):(1+actualVisitors)}
            </Text>
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <ButtonGroup>
            <Link href={`#/admin/visitor-view/${id}`}>
              <IconButton
              colorScheme="blue"
                icon={<ViewIcon />}
                aria-label="Up"
              />
            </Link>

            
          </ButtonGroup>
        </Td>
      </Tr>
    </>
  );
};

export default VisitorHeadcountTableRow;
