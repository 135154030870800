const {
    MEETING_ROOM_BOOKINGS_LOADING,
    MEETING_ROOM_BOOKINGS_GET_ALL_SUCCESS,
    MEETING_ROOM_BOOKINGS_ERROR,
    MEETING_ROOM_BOOKINGS_CLEAR_SNACKBAR,
    MEETING_ROOM_BOOKINGS_SEARCH,
    MEETING_ROOM_BOOKINGS_DATE_RANGE
  } = require('../constants/types');
  
  const initialState = {
    response: {},
    meetingroombookingsList: [],
    meetingroombookingsListDetail: {},
    loading: false,
    status: 'success',
    text: '',
    showSnackbar: false,
    limit : 0,
    page : 0,
    totalPages : 0,
    totalItems : 0,
    searching : "",
    startDate : "",
    endDate : "",
  };
  
  const meetingroombookingsReducer = (state = initialState, action) => {
    switch (action.type) {
      case MEETING_ROOM_BOOKINGS_SEARCH:
        return {
          ...state,
          searching: action.payload.searching
        };
      case MEETING_ROOM_BOOKINGS_CLEAR_SNACKBAR:
        return {
          ...state,
          showSnackbar: false
        };
      case MEETING_ROOM_BOOKINGS_LOADING:
        return {
          ...state,
          loading: true
        };
      case MEETING_ROOM_BOOKINGS_ERROR:
        return {
          ...state,
          meetingroombookingsList: [],
          response: {},
          meetingroombookingsListDetail: {},
          limit : 0,
          page : 0,
          totalPages : 0,
          totalItems : 0,
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true
        };
      case MEETING_ROOM_BOOKINGS_GET_ALL_SUCCESS:
        return {
          ...state,
          meetingroombookingsList: action.payload.meetingroombookingsList,
          response: action.payload.response,
          meetingroombookingsListDetail: action.payload.meetingroombookingsListDetail,
          limit: action.payload.limit,
          page: action.payload.page,
          totalPages: action.payload.totalPages,
          totalItems: action.payload.totalItems,
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true
        };
      case MEETING_ROOM_BOOKINGS_DATE_RANGE: // Add the new case
      return {
        ...state,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };
      default:
        return state || {};
    }
  };
  
  export default meetingroombookingsReducer;
