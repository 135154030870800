import React from "react";
import {
  chakra,
  Badge,
  Td,
  Tr,
  Text,
  useColorModeValue,
  Flex,
  ButtonGroup,
  IconButton,
  Link,
  Image,
} from "@chakra-ui/react";
import { ViewIcon,CheckIcon,CloseIcon } from "@chakra-ui/icons";
import { id } from "date-fns/locale";

const MeetingRoomBookingsTableRow = (props) => {
  const {
    serialnumber,
    date,
    startTime,
    endTime ,
    name,
    approvalRequired,
    requestedBy,
    requestedDept,
    status,
    actionBy,
    actionOn,
    isLast,
    id,
    duration,
    actionByDept,
    reason
  } = props;
  const textColor = useColorModeValue("gray.500", "white");
  const titleColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  return (
    <>
      <Tr key={id}>

        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {date}
              </Text>
            </Flex>
          </Flex>
        </Td>
        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {name}
            </Text>
          </Flex>
        </Td>
        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {duration} <br/>
                ({startTime} to {endTime})
              </Text>
            </Flex>
          </Flex>
        </Td>

        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {requestedBy} <br/>
              Dept : {requestedDept}
            </Text>
          </Flex>
        </Td>
        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {status}
              {status == 'DENIED' ? reason:""}
            </Text>
          </Flex>
        </Td>
        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {actionBy}<br/>
              {actionBy != ""?`Dept : ${actionByDept}` : ""} <br/>
              {actionBy != ""?`${actionOn}` : ""}
            </Text>
          </Flex>
        </Td>
        
        <Td></Td>

        
      </Tr>
    </>
  );
};

export default MeetingRoomBookingsTableRow;
