// Chakra imports
import {
  Flex,
  Box,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  WrapItem,
  TableContainer,
  Image,
  useColorModeValue,
  Wrap,
  HStack,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  iamSafeClearShowSnackbar,
  iamSafeAddOrUpdate,
} from "../../actions/iamSafeAction";
import { useToast } from "@chakra-ui/react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { IAMSAFE_CLEAR_SNACKBAR, IAMSAFE_LOADING } from "constants/types";
import { Skeleton, SkeletonCircle, SkeletonText } from "@chakra-ui/react";
// import { iamSafeClearShowSnackbar } from "actions/iamSafeAction";
import axios from "axios";
import { server_url } from "constants/types";
import { useHistory } from "react-router-dom";
function IamSafeView() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [status, setStatus] = useState("");
  const [subName, setSubName] = useState("");
  const [subPlant, setSubPlant] = useState("");
  const [inAt, setInat] = useState("");
  const [mode, setMode] = useState("");
  const [mobNum, setMobnum] = useState("");
  const [sipmnttype, setSipmnttype] = useState("");
  const [materialBy, setMaterialBy] = useState("");
  const [weighmentStatus, setweighmentStatus] = useState("");
  const [weighment, setWeighment] = useState("");
  const [vendor, setVendor] = useState("");
  const [vehicleNum, setVehicleNum] = useState("");
  const [updated, setUpdated] = useState("");
  const [type, setType] = useState("");
  const [txn, setTxn] = useState("");
  const [subUpdated, setSubUpdated] = useState("");
  const [depName, setDepName] = useState("");
  const [description, setDescription] = useState("");
  const [depCreatedAt, setDepCreatedAt] = useState("");
  const [depUpdatedAt, setDepUpdatedAt] = useState("");
  const [depImg, setDepImg] = useState("");
  const [grdName, setGrdName] = useState("");
  const [grdNum, setGrdNum] = useState("");
  const [grdPass, setGrdPass] = useState("");
  const [grdPic, setGrdPic] = useState("");
  const [grdCreate, setGrdCreate] = useState("");
  const [grdMail, setGrdMail] = useState("");
  const [grdUpdate, setGrdUpdate] = useState("");
  const [grdId, setGrdId] = useState("");
  const [iamSafeData, setIamSafeData] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();
  const toast = useToast();

  const statuses = ["success", "error", "warning", "info"];
  const responseStatus = useSelector((state) => state.iamSafe.status);
  const responseMessage = useSelector((state) => state.iamSafe.text);
  const loading = useSelector((state) => state.iamSafe.loading);
  // const designationDetail = useSelector(state => state.iamSafe.designationDetail);
  const iamSafeDetail = useSelector((state) => state.iamSafe.iamSafeDetail);
  const showSnackbar = useSelector((state) => state.iamSafe.showSnackbar);
  const logs = useSelector((state) => state.iamSafe.logs);
  // const materialData = useSelector(state => state.iamSafe.iamSafeDetail.material);
  // const address = useSelector(state => state.iamSafe.guard.Address);
  // const checkpost = useSelector(state => state.iamSafe.guard.checkpost);

  const params = useParams();

  console.log("iamSafeDetail------------");
  console.log(iamSafeDetail);
  // console.log(materialData);
  // console.log(logs);

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === "OK" && loading === false) {
        console.log("logs");
        console.log(logs);
        console.log("logs");

        setStatus(iamSafeDetail.status ? "Active" : "inActive");
        setIamSafeData(iamSafeDetail);
        if (responseMessage !== "") {
          toastFunction(responseMessage, "success");
        }
      } else if (responseStatus === "NOK" && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, "error");
        }
      }
      dispatch({
        type: IAMSAFE_CLEAR_SNACKBAR,
        payload: {
          showSnackbar: false,
        },
      });
    }
  }, [loading]);

  useEffect(() => {
    dispatch({
      type: IAMSAFE_LOADING,
      payload: {
        loading: true,
      },
    });
    const iamSafeId = params.id;
    const iamSafe = {
      _id: iamSafeId,
    };
    dispatch(iamSafeAddOrUpdate(iamSafe, "view"));
  }, [params.id]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  };
  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("authToken"),
        },
      })
      .then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push("/auth/signin");
      });
  }, []);
  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="20px" mb={4}>
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            I Am Safe View
          </Text>
        </CardHeader>
        <CardBody>
          {!iamSafeData ? (
            <Skeleton height="20px" />
          ) : iamSafeDetail.guard ? (
            <TableContainer>
              <Table variant="simple">
                <Tbody mb={4}>
                  <Tr>
                    <Td> Name</Td>
                    <Td>{iamSafeDetail.guard?.name}</Td>
                  </Tr>

                  <Tr>
                    <Td> Photo</Td>
                    <Td>
                      <img src={iamSafeDetail.guard?.photo} style={{ maxWidth: "120px" }} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td> Phone No.</Td>
                    <Td>{iamSafeDetail.guard?.number}</Td>
                  </Tr>

                  <Tr>
                    <Td> Department</Td>
                    <Td>{iamSafeDetail.guard.department?.name}</Td>
                  </Tr>

                  <Tr>
                    <Td> Sub department</Td>
                    <Td>{iamSafeDetail.guard.subdepartment?.name} </Td>
                  </Tr>
                  <Tr>
                    <Td> Designation</Td>
                    <Td>{iamSafeDetail.guard.designation?.name}</Td>
                  </Tr>

                  <Tr>
                    <Td> Blood Group</Td>
                      <Td>{iamSafeDetail.guard?.bloodGroup}</Td>
                  </Tr>
                  <Tr>
                    <Td> Date</Td>
                      <Td>{iamSafeDetail.date}</Td>
                  </Tr>
                  <Tr>
                    <Td> Time</Td>
                    <Td>{iamSafeDetail.time}</Td>
                  </Tr>

                  <Tr>
                    <Td> Acknowledge BY</Td>
                   
                      <Td>{iamSafeDetail.acknowledgeBy?.name}</Td>
                    
                  </Tr>
                  <Tr>
                    <Td> Acknowledge Date</Td>
                    
                      <Td>{iamSafeDetail.acknowledgeDate}</Td>
                    
                  </Tr>
                  <Tr>
                    <Td> Acknowledge Time</Td>
                    
                      <Td>{iamSafeDetail.acknowledgeTime}</Td>
                    
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          ) : iamSafeDetail.employee ? (
            <TableContainer>
              <Table variant="simple">
                <Tbody mb={4}>
                  <Tr>
                    <Td> Name</Td>
                    <Td>{iamSafeDetail.employee?.employeeName}</Td>
                  </Tr>
                  <Tr>
                    <Td> Photo</Td>
                    <Td>
                      <img src={iamSafeDetail.employee?.photo} style={{ maxWidth: "120px" }} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td> Phone No.</Td>
                    <Td>{iamSafeDetail.employee?.contactNo}</Td>
                  </Tr>

                  <Tr>
                    <Td> Department</Td>
                    <Td>{iamSafeDetail.employee.department?.name}</Td>
                  </Tr>

                  <Tr>
                    <Td> Sub department</Td>
                    <Td>{iamSafeDetail.employee.subdepartment?.name}</Td>
                  </Tr>

                  <Tr>
                    <Td> Designation</Td>
                    <Td>{iamSafeDetail.employee.designation?.name}</Td>
                  </Tr>

                  <Tr>
                    <Td> Blood Group</Td>
                    <Td>
                      {iamSafeDetail.employee.bloodGroup}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td> Date</Td>
                      <Td>{iamSafeDetail.date}</Td>
                  </Tr>
                  <Tr>
                    <Td> Time</Td>
                    <Td>{iamSafeDetail.time}</Td>
                  </Tr>
                  

                  
                  <Tr>
                    <Td> Acknowledge BY</Td>
                    <Td>
                      {iamSafeDetail.acknowledgeBy?.name}
                      
                    </Td>
                  </Tr>
                  <Tr>
                    <Td> Acknowledge Date</Td>
                    <Td>{iamSafeDetail.acknowledgeDate}</Td>
                  </Tr>
                  <Tr>
                    <Td> Acknowledge Time</Td>
                    <Td>{iamSafeDetail.acknowledgeTime}</Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          ) : (
            <></>
          )}
        </CardBody>
      </Card>
    </Flex>
  );
}
export default IamSafeView;
