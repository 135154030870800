// Chakra imports
import {
    Box,
    Button,
    Center,
    Flex,
    Input,
    SimpleGrid,
    Spacer,
    Text,
    Wrap,
    WrapItem,
    useColorModeValue,
    Select,
  } from "@chakra-ui/react";
  // Custom components
  import Card from "components/Card/Card.js";
  import CardBody from "components/Card/CardBody.js";
  import CardHeader from "components/Card/CardHeader.js";
  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector } from 'react-redux';
  import { multiauthmatrixListAddOrUpdate, multiauthmatrixListClearShowSnackbar,multiauthmatrixListGetAll } from '../../actions/multiauthmatrixAction';
  import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
  } from '@chakra-ui/react'
  import { useToast } from '@chakra-ui/react'
  import { MEETING_ROOMS_CLEAR_SNACKBAR } from "constants/types";
  import { Spinner } from '@chakra-ui/react'
  import dataConstant from "constants/dataConstant";
  import { useHistory,useParams } from 'react-router-dom';
  import axios from 'axios';
  import { server_url } from '../../constants/types';
  import RSelect from 'react-select';


  function MultiAuthMatrixUpdateFrom() {
    const params = useParams();
    const textColor = useColorModeValue("gray.700", "white");
    const borderColor = useColorModeValue("gray.200", "gray.600");
   
    const [plantAdmin, setPlantAdmin] = useState("");
    const [allDepartments, setAllDepartments] = useState("");
    const [department, setDepartment] = useState([]);
    const [allType, setAllType] = useState("");
    const [type, setType] = useState([]);
    const [allPurpose, setAllPurpose] = useState("");
    const [purpose, setPurpose] = useState([]);
    const [purposeList, setPurposeList] = useState([]);
    const [firstAuth, setFirstAuth] = useState(0);
    const [secondAuth, setSecondAuth] = useState(0);
    const [thirdAuth, setThirdAuth] = useState(0);
    const [fourthAuth, setFourthAuth] = useState(0);
    const [fifthAuth, setFifthAuth] = useState(0);
    const [status, setStatus] = useState('false');
    const [isErrorApprovalRequired, setIsErrorApprovalRequired] = useState(false);
    

  
    const dispatch = useDispatch();
    const toast = useToast();
    const history = useHistory();
    const responseStatus = useSelector(state => state.multiauthmatrix.status);
    const responseMessage = useSelector(state => state.multiauthmatrix.text);
    const loading = useSelector(state => state.multiauthmatrix.loading);
    const showSnackbar = useSelector(state => state.multiauthmatrix.showSnackbar);
    const multiauthmatrixListDetail = useSelector(state => state.multiauthmatrix.multiauthmatrixListDetail);
    const [departmentList, setDepartmentList] = useState([]);
    const [isErrorDepartment, setIsErrorDepartment] = useState(false);
    const [isErrorType, setIsErrorType] = useState(false);
  
    useEffect(() => {
      axios
        .get(`${server_url}/admin/detail`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((responce) => {
          console.log("uadlfdafljadhfoadilf");
        })
        .catch((e) => {
          history.push('/auth/signin');
  
        });

        
    }, [])


    // Function to handle form submission
    const handleSubmit = (event) => {
      event.preventDefault();
      setIsErrorDepartment(department.length == 0);
      setIsErrorType(type.length == 0)

      if (department.length != 0 && type.length != 0) {
        console.log("Submit");
        // Get form data
        const id = params.id;
        const multiauthmatrixData = {
            _id : id,
            department,
            allDepartments,
            type,
            allType,
            purpose,
            allPurpose,
        };
        multiauthmatrixData.firstAuth = {};
        multiauthmatrixData.firstAuth.assignedMember = firstAuth;
        multiauthmatrixData.secondAuth = {};
        multiauthmatrixData.secondAuth.assignedMember = secondAuth;
        multiauthmatrixData.thirdAuth = {}
        multiauthmatrixData.thirdAuth.assignedMember = thirdAuth;
        multiauthmatrixData.fourthAuth = {}
        multiauthmatrixData.fourthAuth.assignedMember = fourthAuth;
        multiauthmatrixData.fifthAuth = {};
        multiauthmatrixData.fifthAuth.assignedMember = fifthAuth;
  
        const state = 'edit';
        console.log(multiauthmatrixData);

        dispatch(multiauthmatrixListAddOrUpdate(multiauthmatrixData, state,"","",plantAdmin))
      }
    };
  
    useEffect(() => {
      if (showSnackbar) {

        if (responseStatus === 'OK' && loading === false) {
          if (responseMessage !== "") {
            
            toastFunction(responseMessage, 'success');

          }
        //   toastFunction(multiauthmatrixListDetail.name, 'success');
          
        } else if (responseStatus === 'NOK' && loading === false) {
          if (responseMessage !== "") {
            toastFunction(responseMessage, 'error');
          }
        }
      }
      dispatch({
        type: MEETING_ROOMS_CLEAR_SNACKBAR,
        payload: {
          showSnackbar: false,
        }
      })
    }, [loading]);
  
    const toastFunction = (title, status) => {
      toast({
        title: title,
        status: status,
        duration: 3000,
        isClosable: true,
      })
    };

    useEffect(() => {
      axios
        .get(`${server_url}/admin/detail`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((responce) => {
          console.log("uadlfdafljadhfoadilf");
        })
        .catch((e) => {
          history.push('/auth/signin');
  
        });
        const id = params.id;
        var meetingRoom = {
            _id : id,
        }
        axios
        .get(`${server_url}/admin/multi-auth-matrix/${id}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((response) => {
          // if(responce.data.data)
          // {
            setAllDepartments(response.data.data.allDepartments);
            setDepartment(response.data.data.department);
            setAllType(response.data.data.allType);
            setType(response.data.data.type);
            setAllPurpose(response.data.data.allPurpose);
            setPurpose(response.data.data.purpose);
            setFirstAuth(response.data.data.firstAuth?.assignedMember);
            setSecondAuth(response.data.data.secondAuth?.assignedMember);
            setThirdAuth(response.data.data.thirdAuth?.assignedMember);
            setFourthAuth(response.data.data.fourthAuth?.assignedMember);
            setFifthAuth(response.data.data.fifthAuth?.assignedMember);
            
            
            
            setPlantAdmin(response.data.data.plantAdmin?._id);
            departmentListforDropdown(response.data.data?.plantAdmin?._id);
            console.log("I am here");
            console.log("###############");
            console.log(response.data.data);
            console.log(" ###################");
            getPurposeList();
          // }
        })
        .catch((e) => {
  
        });
    }, [])

    useEffect(() => {
    //   dispatch(multiauthmatrixListGetAll ("&limit=100"));
    }, [dispatch]);

    const departmentListforDropdown = (plant) => {
        axios
          .get(`${server_url}/department?plant=${plant}&limit=1000`, {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
          }).then((response) => {
            setDepartmentList(response.data.data)
          }).catch(() => console.log("error"))
      }

      const getPurposeList = () => {
        axios
          .get(`${server_url}/admin/purpose-list`, {
            headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
          })
          .then((response) => setPurposeList(response.data.data))
          .catch(console.error);
      };
  
    return (
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <Card pb="0px">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Multi Auth Matrix Update
            </Text>
            <SimpleGrid minChildWidth='120px' spacing='40px'>
            <FormControl isInvalid={isErrorDepartment} isRequired>
            <FormLabel>Department</FormLabel>
                  <RSelect
                    isMulti
                    options={[
                    { value: "all", label: "Select All" },
                    ...departmentList.map((item) => ({
                        value: item._id,
                        label: item.name,
                    })),
                    ]}
                    value={department}
                    onChange={(selectedOptions) => {
                    if (selectedOptions.some(option => option.value === "all")) {
                        setDepartment(departmentList.map((item) => ({ value: item._id, label: item.name })));
                        setAllDepartments("true");
                    } else {
                        setDepartment(selectedOptions);
                        setAllDepartments("false");
                    }
                        setIsErrorDepartment(selectedOptions.length == 0);
                    }}
                />
                {!isErrorDepartment ? (
                  <></>
                ) : (
                  <FormErrorMessage>Department is required.</FormErrorMessage>
                )}
              </FormControl>
              
              <FormControl isInvalid={isErrorType} isRequired>
              <FormLabel >Type</FormLabel>
                  <RSelect
                    isMulti
                    options={[
                        { value: "all", label: "Select All" },
                        ...dataConstant.multiauthmatrixTypeConstant.map((item) => ({
                        value: item.value,
                        label: item.name,
                        })),
                    ]}
                    value={type}
                    onChange={(selectedOptions) => {
                        if (selectedOptions.some(option => option.value === "all")) {
                            setType(dataConstant.multiauthmatrixTypeConstant.map((item) => ({
                            value: item.value, 
                            label: item.name,
                            })))
                            setAllType("true");
                        } else {
                            setType(selectedOptions)
                            setAllType("false");
                        }
                        setIsErrorType(selectedOptions.length == 0);
                    }}
                    />
                    {!isErrorType ? (
                  <></>
                ) : (
                  <FormErrorMessage>Type is required.</FormErrorMessage>
                )}
              </FormControl>
            {type.some(option => option.value === dataConstant.gatepassTypeNRGP)?<FormControl isRequired>
              <FormLabel>Purpose (NRGP)</FormLabel>
                  <RSelect
                    isMulti
                    options={[
                    { value: "all", label: "Select All" },
                    ...purposeList.map((item) => ({
                        value: item.value,
                        label: item.name,
                    })),
                    ]}
                    value={purpose}
                    onChange={(selectedOptions) => {
                    if (selectedOptions.some(option => option.value === "all")) {
                        setPurpose(purposeList.map((item) => ({ value: item.value, label: item.name })));
                        setAllPurpose("true");
                    } else {
                        setPurpose(selectedOptions);
                        setAllPurpose("false");
                    }
                        // setIsErrorDepartment(selectedOptions.length == 0);
                    }}
                />
                {/* {!isErrorDepartment ? (
                  <></>
                ) : (
                  <FormErrorMessage>Purpose is required.</FormErrorMessage>
                )} */}
              </FormControl> : null}
              
              <FormControl >
                <FormLabel>1st</FormLabel>
                <Input
                    type="number"
                    value={firstAuth}
                    onChange={(e) => setFirstAuth(e.target.value)}
                />
              </FormControl>
              
              <FormControl >
                <FormLabel>2nd</FormLabel>
                <Input
                    type="number"
                    value={secondAuth}
                    onChange={(e) => setSecondAuth(e.target.value)}
                />
              </FormControl>
              <FormControl >
                <FormLabel>3rd</FormLabel>
                <Input
                    type="number"
                    value={thirdAuth}
                    onChange={(e) => setThirdAuth(e.target.value)}
                />
              </FormControl>
              <FormControl >
                <FormLabel>4th</FormLabel>
                <Input
                    type="number"
                    value={fourthAuth}
                    onChange={(e) => setFourthAuth(e.target.value)}
                />
              </FormControl>
              <FormControl >
                <FormLabel>5th</FormLabel>
                <Input
                    type="number"
                    value={fifthAuth}
                    onChange={(e) => setFifthAuth(e.target.value)}
                />
              </FormControl>
  
              
            </SimpleGrid>



            <Center>
              {loading ? <Spinner mt='24px' size='lg' /> :
                <Button colorScheme='blue' mt='24px' onClick={handleSubmit}>
                  Submit
                </Button>
              }
            </Center>
          </CardHeader>
          <CardBody>
          </CardBody>
        </Card>
      </Flex>
    );
  }
  
  export default MultiAuthMatrixUpdateFrom;