import React, { useEffect, useState,useRef } from 'react';
import { Flex, InputGroup, Spacer, Table, Tbody, Text, Td, Th, Thead, Tr, useColorModeValue, InputLeftElement, IconButton, Input, Icon, Link ,Button, Tabs, TabList, TabPanels, TabPanel, Tab,Spinner,Center,useDisclosure,Tooltip} from "@chakra-ui/react";
import { SearchIcon,InfoOutlineIcon } from "@chakra-ui/icons";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';

import { useToast } from '@chakra-ui/react';

import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';

import { AddIcon } from '@chakra-ui/icons';
import { MEETING_ROOM_BOOKINGS_CLEAR_SNACKBAR ,MEETING_ROOM_BOOKINGS_SEARCH,MEETING_ROOM_BOOKINGS_CLEAR} from 'constants/types';

import { meetingroombookingsListGetAll } from '../../actions/meetingroombookingsActions';
// import PastPagination from './PastPagination';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { Skeleton, SkeletonCircle, SkeletonText,Stack } from '@chakra-ui/react'
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import axios from 'axios';
import { server_url } from "constants/types";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import MeetingRoomBookingsExport from './MeetingRoomBookingExport';
import MeetingRoomBookingsTableRow from './MeetingRoomBookingTableRow';
const MeetingRoomBookingsTable = (props) => {
    const {
        schedule=""
      } = props;
  const tableRef = useRef(null);
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const responseStatus = useSelector(state => state.meetingroombookings.status);
  const responseMessage = useSelector(state => state.meetingroombookings.text);
  const loading = useSelector(state => state.meetingroombookings.loading);
  const meetingroombookingsList = useSelector(state => state.meetingroombookings.meetingroombookingsList);
  const response = useSelector(state => state.meetingroombookings.response);
  const showSnackbar = useSelector(state => state.meetingroombookings.showSnackbar);
  const currentPage = useSelector(state => state.meetingroombookings.page);
  const totalItems = useSelector(state => state.meetingroombookings.totalItems);
  const limit = useSelector(state => state.meetingroombookings.limit);
  const searching = useSelector(state => state.meetingroombookings.searching);
  const dispatch = useDispatch();
  const toast = useToast();
  const searchIconColor = useColorModeValue("gray.700", "gray.200");
  const inputBg = useColorModeValue("#edf3f8", "navy.800");
  const [search, setSearch] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [submittingLoader, setSubmittingLoader] = useState(false);

  // lazy loading
  const [page, setPage] = useState(1);
  const [isFetching, setIsFetching] = useState(false);
  const [newBookingsData, setNewBookingsData] = useState([]);
  const [startAppending, setStartAppending] = useState(false);
  const authDetail = useSelector(state => state.auth.authDetail);
  const searchStartDate = useSelector(state => state.meetingroombookings.startDate);
  const searchEndDate = useSelector(state => state.meetingroombookings.endDate);



  useEffect(() => {
    dispatch(meetingroombookingsListGetAll(`&type=${schedule}&page=${page}&limit=50`));
    dispatch({
      type : MEETING_ROOM_BOOKINGS_SEARCH,
      payload : {
        searching : '',
      }
    })
  }, []);
  console.log("meetingroombookingsList------")
  console.log(meetingroombookingsList)

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: MEETING_ROOM_BOOKINGS_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("desc");
    }
    const sorting = "&type="+schedule+"&sortOrder=" + sortOrder + "&column=" + column+"&limit=50";
    dispatch({
      type : MEETING_ROOM_BOOKINGS_SEARCH,
      payload : {
        searching : '',
      }
    })
    dispatch(meetingroombookingsListGetAll(sorting));
  };

  const handelSearch = (search) => {
    const searching = "&type="+schedule+"&search=" + search+"&limit=50";
    dispatch({
      type : MEETING_ROOM_BOOKINGS_SEARCH,
      payload : {
        searching : '',
      }
    })
    dispatch(meetingroombookingsListGetAll(searching));
    setNewBookingsData([]);
    setPage(1);
  }
  const fetchImageAsBase64 = async (imageUrl) => {
    const response = await fetch(imageUrl,{ mode: 'cors' });
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      // console.log(reader);
      // console.log("reader");
      reader.onloadend = () => resolve(reader.result.split(',')[1]); // Get base64 data
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const exportToExcel = async () => {
    setSubmittingLoader(true);
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet('Sheet 1');
    const filename = "Meetingroombookings_"+Date.now();
    // Define the headers
    const headers = [
      "Date","Name","Duration", "Requested By", "Status", "Action BY", 
    ];

    // Set up the columns with specific widths
    sheet.columns = [
      
      { header: 'Date', key: 'date', width: 15 },
      { header: 'Name', key: 'name', width: 20 },
      { header: 'Duration', key: 'startime', width: 20 },
      { header: 'Request By', key: 'requestedBy', width: 20 },
      { header: 'Status', key: 'status', width: 20 },
      { header: 'Action By', key: 'actionBy', width: 20 },
    ];

    // Add the headers to the sheet
    headers.forEach((header, index) => {
      sheet.getCell(1, index + 1).value = header;
    });

    // Add data and images
    for (const [rowIndex, row] of newBookingsData.entries()) {
      const rowData = [
        row.date,
        row.meetingRoom?.name,
        row.duration,
        row.employee?.name,
        row.status,
        row.actionKey1,
      ];

      for (const [colIndex, cell] of rowData.entries()) {
        if(colIndex == 2)
        {
          sheet.getCell(rowIndex + 2, colIndex + 1).value = `${row.duration} \n(${row.startTime} to ${row.endTime})`;
        }
        else if(colIndex == 3)
        {
          sheet.getCell(rowIndex + 2, colIndex + 1).value = `${row.employee?.employeeName} \n(Dept: ${row.employee.department.name})`;
        }
        else if(colIndex == 4)
        {
          sheet.getCell(rowIndex + 2, colIndex + 1).value = `${row.status} \n  ${row.status == 'DENIED' ? `\n${row.reason}`:""}`;
        }
        else if(colIndex == 5)
        {
          sheet.getCell(rowIndex + 2, colIndex + 1).value = `${row.authorizedBy ?row.authorizedBy?.employeeName:""} \n ${row.authorizedBy ?"Dept. : "+row.authorizedBy?.department?.name:""} \n ${row.authorizedBy ? row.actionValue2:""}`;
        }
        else {
          sheet.getCell(rowIndex + 2, colIndex + 1).value = cell;
        }
      }
    }

    // Set row height (if needed)
    for (let rowIndex = 1; rowIndex <= sheet.rowCount; rowIndex++) {
      sheet.getRow(rowIndex).height = 60; // Set height in points
    }

    // Generate Excel file and save
    const excelBuffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([excelBuffer]), `${filename}.xlsx`);
    setSubmittingLoader(false);
  };

  // lazy loading 
  const handleScroll = (e) => {
    
    const bottomReached = (e.target.scrollHeight - e.target.scrollTop <= (parseInt(e.target.clientHeight) + 1)) &&  (e.target.scrollHeight - e.target.scrollTop >= (e.target.clientHeight));
    if(!loading)
      {
        console.log("->>>>> this ->>>>>>" + bottomReached);
        console.log("->>>>> this ->>>>>>" + (e.target.clientHeight + 1) );
        console.log("->>>>> this ->>>>>>" + e.target.scrollHeight );
        console.log("->>>>> this ->>>>>>" + e.target.scrollTop );
        console.log("->>>>> match ->>>>>>" + (e.target.scrollHeight-e.target.scrollTop) );
        if (bottomReached && !isFetching && newBookingsData.length < totalItems) {
          
          setIsFetching(true); // Prevent multiple fetches at the same time
          setPage(prevPage => prevPage + 1); // Load next page
        }
      }
  };

  useEffect(() => {
    if (isFetching) {
      dispatch(meetingroombookingsListGetAll(`&type=${schedule}&page=${page}&limit=50`));
      setIsFetching(false);
    }
  }, [isFetching,page])
  useEffect(() => {
    if(startAppending)
        {
            addInspectionDataArray(meetingroombookingsList);
            console.log("Mark here");
        }
    setStartAppending(true);
  }, [meetingroombookingsList])
  useEffect(() => {
    setNewBookingsData([]);
  }, [searching])
  // Function to add new data
const addInspectionDataArray = (newDataArray) => {
  setNewBookingsData((prevData) => [...prevData,...newDataArray]);
};

const { isOpen, onOpen, onClose } = useDisclosure();


  return (
  <>
  {/* <PastAdvanceSearch></PastAdvanceSearch> */}
  <Flex direction="column" pt={{ base: "120px", md: "5px" }}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
          <Flex>
            <CardHeader p="6px 0px 22px 0px">
              <Text fontSize="xl" color={textColor} fontWeight="bold">
                {schedule == ""?"ALL":schedule} <span style={{
                color:"red"
              }}>({loading ? '':totalItems})</span>
              </Text>
              <Text fontSize="xl" color={textColor} fontWeight="bold">
              {`${searchStartDate} to ${searchEndDate}`} <span><Tooltip hasArrow label="By default, today's data will be shown." aria-label='A tooltip' ><InfoOutlineIcon/></Tooltip></span>
              </Text>
            </CardHeader>
            <Spacer />

            <Flex justify="flex-end" style={{ padding: '10px', marginTop: '-5px', marginRight: '12px' }}>
              {/* <DownloadTableExcel
                filename="Past Visitor Data"
                sheet="users"
                currentTableRef={tableRef.current}
              > */}
                <Button 
                isLoading={submittingLoader}
                loadingText='Downloading'
                onClick={onOpen}
                // onClick={exportToExcel}
                colorScheme="blue" _hover={{ backgroundColor: 'black', color: 'white' }} >
                  Download
                </Button>
              {/* </DownloadTableExcel> */}
            </Flex>
            {/* Modal Component */}
            <MeetingRoomBookingsExport
                    isOpen={isOpen}
                    onClose={onClose}
                    onExport={exportToExcel}
                />
            <CardHeader p="6px 0px 22px 0px">
              <Text fontSize="xl" color={textColor} fontWeight="bold">
                <InputGroup borderRadius="8px" w="200px">
                  <InputLeftElement
                    children={
                      <IconButton
                        bg="inherit"
                        borderRadius="inherit"
                        _hover="none"
                        _active={{
                          bg: "inherit",
                          transform: "none",
                          borderColor: "transparent",
                        }}
                        _focus={{
                          boxShadow: "none",
                        }}
                        icon={
                          <SearchIcon
                            color={searchIconColor}
                            w="15px"
                            h="15px"
                          />
                        }
                      ></IconButton>
                    }
                  />
                  <Input
                  type="pass"
                  variant="search"
                  fontSize="xs"
                  bg={inputBg}
                  onKeyUp={(e) => {
                      setSearch(e.target.value);
                      handelSearch(e.target.value);
                      if (e.target.value.length < 3) {
                        setSearch("");
                        handelSearch("");
                      }
                    }}
                    placeholder="Type here..."
                  />
                </InputGroup>
              </Text>
            </CardHeader>
          </Flex>
          <CardBody pb={4}>
            <div className='vertical-scroll' // Set a fixed height for the scroll container
        onScroll={handleScroll}>
              {/* {loading ? <Stack>
                      <Skeleton height='20px' />
                      <Skeleton height='20px' />
                      <Skeleton height='20px' />
                      <Skeleton height='20px' />
                      <Skeleton height='20px' />
                      <Skeleton height='20px' />
                      <Skeleton height='20px' />
                    </Stack>: */}
              <Table variant="simple" color={textColor} 
            //   ref={tableRef} 
              >
              <Thead className='stick-header'>
                <Tr my=".8rem" pl="0px" color="gray.400">
                <Th
                    pl="0px"
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("vechilenumber")}
                    cursor="pointer"
                  >
                    Date
                    {sortColumn === "vechilenumber" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("drivername")}
                    cursor="pointer"
                  >
                    Meeting Name
                    {sortColumn === "drivername" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                <Th
                    pl="0px"
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("createdAt")}
                    cursor="pointer"
                  >
                    Duration
                    {sortColumn === "createdAt" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                
                 
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("intime")}
                    cursor="pointer"
                  >
                    Requested By
                    {sortColumn === "intime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("outtime")}
                    cursor="pointer"
                  >
                  Status
                    {sortColumn === "outtime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("outtime")}
                    cursor="pointer"
                  >
                  Action By
                    {sortColumn === "outtime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                  >
                  </Th>

                </Tr>
              </Thead>
                <Tbody>
                  {newBookingsData ? (newBookingsData.map((row, index, arr) => {

                    return (
                      <MeetingRoomBookingsTableRow
                      // serialnumber={totalItems - (((currentPage - 1) * limit) + index)}
                    //   serialnumber={totalItems - index}
                      date = {row.date}
                      startTime = {row.startTime}
                      endTime = {row.endTime}
                      name = {row.meetingRoom?.name}
                      type = {row.type}
                      approvalRequired = {row.approvalRequired}
                      requestedBy = {`${row.employee?.employeeName}`}
                      requestedDept = {`${row.employee?.department?.name}`}
                      status = {row.status }
                      actionBy = {`${row.authorizedBy ? row.authorizedBy?.employeeName:""}`}
                      actionByDept = {`${row.authorizedBy ? row.authorizedBy?.department?.name: ""}`}
                      actionOn = {`${row.actionValue2}`}
                      reason = {`${row.reason}`}
                      isLast={index === arr.length - 1 ? true : false}
                      id={row._id}
                      approvedBy={row.approvedBy}
                      duration={row.duration}
                      />
                    );
                  })) : (<h1></h1>)}
                </Tbody>
              </Table>
              {loading ? <Center><Spinner mt="24px" size="lg" /></Center>:<></>}
              {/* } */}
            </div>
            {meetingroombookingsList.length === 0 ? (
              <></>
            ) : (
              <></>
              // <PastPagination
              //   totalPages={response.totalPages}
              //   currentPage={response.currentPage}
              //   perPage={response.perPage}
              //   sortBy={sortColumn}
              //   sortOrder={sortOrder}
              //   handlePageClick={(data) => {
              //     const selectedPage = data.selected + 1;
              //     dispatch(meetingroombookingsListGetAll(`&page=${selectedPage}&limit=50`));
              //   }}
              // />
            )}
          </CardBody>
        </Card>
    </Flex>
  </>
  );
};

export default MeetingRoomBookingsTable;
