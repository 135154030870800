// Chakra imports
import {
    Box,
    Button,
    Center,
    Flex,
    Input,
    SimpleGrid,
    Spacer,
    Text,
    Wrap,
    WrapItem,
    useColorModeValue,
    Select,
  } from "@chakra-ui/react";
  // Custom components
  import Card from "components/Card/Card.js";
  import CardBody from "components/Card/CardBody.js";
  import CardHeader from "components/Card/CardHeader.js";
  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector } from 'react-redux';
  import { meetingroomsListAddOrUpdate, meetingroomsListClearShowSnackbar,meetingroomsListGetAll } from '../../actions/meetingroomsAction';
  import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
  } from '@chakra-ui/react'
  import { useToast } from '@chakra-ui/react'
  import { MEETING_ROOMS_CLEAR_SNACKBAR } from "constants/types";
  import { Spinner } from '@chakra-ui/react'
  import dataConstant from "constants/dataConstant";
  import { useHistory,useParams } from 'react-router-dom';
  import axios from 'axios';
  import { server_url } from '../../constants/types';
  import RSelect from 'react-select';


  function MeetingRoomsUpdateFrom() {
    const params = useParams();
    const textColor = useColorModeValue("gray.700", "white");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    const [name, setName] = useState("");
    const [position, setPosition] = useState("");
    const [capacity, setCapacity] = useState("");
    const [baseurl, setBaseurl] = useState("");
    const [authtype, setAuthtype] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [token, setToken] = useState("");
    const [plantAdmin, setPlantAdmin] = useState("");


    const [amenities, setAmenities] = useState([]);
    const [roomType, setRoomType] = useState("");
    const [departmentId, setDepartmentId] = useState('');
    const [departmentList, setDepartmentList] = useState([]);
    const [approvalRequired, setApprovalRequired] = useState("NO");

  
    const [isErrorName, setIsErrorName] = useState(false);

    const [isErrorCapacity, setIsErrorCapacity] = useState(false);
    
    const [isErrorAmenities, setIsErrorAmenities] = useState(false);
    const [isErrorRoomType, setIsErrorRoomType] = useState(false);
    const [isErrorDepartment, setIsErrorDepartment] = useState(false);
    const [isErrorApprovalRequired, setIsErrorApprovalRequired] = useState(false);
    

  
    const dispatch = useDispatch();
    const toast = useToast();
    const history = useHistory();
    const responseStatus = useSelector(state => state.meetingrooms.status);
    const responseMessage = useSelector(state => state.meetingrooms.text);
    const loading = useSelector(state => state.meetingrooms.loading);
    const showSnackbar = useSelector(state => state.meetingrooms.showSnackbar);
    const meetingroomsListDetail = useSelector(state => state.meetingrooms.meetingroomsListDetail);

    useEffect(()=>{
        const id = params.id;
        var meetingRoom = {
            _id : id,
        }
      axios
        .get(`${server_url}/admin/meeting-room/${id}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((response) => {
          // if(responce.data.data)
          // {
            setName(response.data.data.name);
          setRoomType(response.data.data.type);
          setCapacity(response.data.data.capacity);
          setAmenities(response.data.data.amenities);
          setDepartmentId(response.data.data?.department?._id);
          departmentListforDropdown(response.data.data?.plantAdmin?._id);
          setApprovalRequired(response.data.data?.approvalRequired);
            
            
            setPlantAdmin(response.data.data.plant?._id);
            console.log("I am here");
          // }
        })
        .catch((e) => {
  
        });

    }, [])
  
    useEffect(() => {
      axios
        .get(`${server_url}/admin/detail`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((responce) => {
          console.log("uadlfdafljadhfoadilf");
        })
        .catch((e) => {
          history.push('/auth/signin');
  
        });

        
    }, [])


    // Function to handle form submission
    const handleSubmit = (event) => {
      event.preventDefault();
      setIsErrorName(name === '');
      setIsErrorRoomType(roomType ==='')
      setIsErrorCapacity(roomType ==='')
      setIsErrorAmenities(amenities.length === 0)
      setIsErrorApprovalRequired(approvalRequired ==='')

      if (name !== ""
      //  && password !== "" 
       ) {
        console.log("Submit");
        // Get form data
        const id = params.id;
        const meetingRoomData = {
            _id : id,
            type : roomType,
          name: name,
          capacity: capacity,
          amenities: amenities,
          department : departmentId,
          approvalRequired : approvalRequired
        };
  
        const state = 'edit';
        console.log(meetingRoomData);

        dispatch(meetingroomsListAddOrUpdate(meetingRoomData, state,"","",plantAdmin))
      }
    };
  
    useEffect(() => {
      if (showSnackbar) {

        if (responseStatus === 'OK' && loading === false) {
          if (responseMessage !== "") {
            
            toastFunction(responseMessage, 'success');

          }
        //   toastFunction(meetingroomsListDetail.name, 'success');
          
        } else if (responseStatus === 'NOK' && loading === false) {
          if (responseMessage !== "") {
            toastFunction(responseMessage, 'error');
          }
        }
      }
      dispatch({
        type: MEETING_ROOMS_CLEAR_SNACKBAR,
        payload: {
          showSnackbar: false,
        }
      })
    }, [loading]);
  
    const toastFunction = (title, status) => {
      toast({
        title: title,
        status: status,
        duration: 3000,
        isClosable: true,
      })
    };

    useEffect(() => {
      axios
        .get(`${server_url}/admin/detail`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((responce) => {
          console.log("uadlfdafljadhfoadilf");
        })
        .catch((e) => {
          history.push('/auth/signin');
  
        });
        departmentListforDropdown();
    }, [])

    useEffect(() => {
      dispatch(meetingroomsListGetAll ("&limit=100"));
    }, [dispatch]);

    const departmentListforDropdown = (plant) => {
        axios
          .get(`${server_url}/department?plant=${plant}&limit=1000`, {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
          }).then((response) => {
            setDepartmentList(response.data.data)
          }).catch(() => console.log("error"))
      }
  
    return (
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Meeting Room Form
            </Text>
            <SimpleGrid minChildWidth='120px' spacing='40px'>
            <FormControl isInvalid={isErrorRoomType}>
                <FormLabel>Room Type</FormLabel>
                <Select
                  placeholder="Select Room Type"
                  value={roomType}
                  onChange={(e) => {
                    setIsErrorRoomType(e.target.value === '')
                    setRoomType(e.target.value)
                  }}
                >
                  { dataConstant.meetingRoomType.map((element,index) => {
                      return (<option key={element['id']} value={element['value']} >{element['name']}</option>);   
                        
                    })}
                </Select>
                {!isErrorRoomType ? (
                  <></>
                ) : (
                  <FormErrorMessage>Room Type is required.</FormErrorMessage>
                )}
              </FormControl>
              
              <FormControl isInvalid={isErrorName}>
                <FormLabel>Name</FormLabel>
                <Input
                  type='text'
                  value={name}
                  onChange={(e) => {
                    setIsErrorName(e.target.value === '')
                    setName(e.target.value)
                  }}
                />
                {!isErrorName ? (
                  <></>
                ) : (
                  <FormErrorMessage>Name is required.</FormErrorMessage>
                )}
              </FormControl>
              
              <FormControl isInvalid={isErrorCapacity}>
                <FormLabel>Capacity</FormLabel>
                <Input
                  type='text'
                  value={capacity}
                  onChange={(e) => {
                    setIsErrorCapacity(e.target.value === '')
                    setCapacity(e.target.value)
                  }}
                />
                {!isErrorCapacity ? (
                  <></>
                ) : (
                  <FormErrorMessage>Capacity is required.</FormErrorMessage>
                )}
              </FormControl>
              
              <FormControl isInvalid={isErrorAmenities}>
                <FormLabel>Amenities</FormLabel>
                <RSelect
                  placeholder="Select amenities"
                  isMulti
                  options={dataConstant.meetingRoomAmenities?.map((item) => ({
                    value: item.value,
                    label: item.name
                    }))}
                    onChange={(value) => {
                    console.log(value);
                    console.log(value)
                    setAmenities(value)
                    setIsErrorAmenities(value.length==0)}}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    value={amenities}
                />
                
                {!isErrorAmenities ? (
                  <></>
                ) : (
                  <FormErrorMessage>Amenities is required.</FormErrorMessage>
                )}
              </FormControl>
  
              
            </SimpleGrid>

            <SimpleGrid minChildWidth='120px' spacing='40px' mt="10px">
            <FormControl >
              <FormLabel>Department</FormLabel>
              <Select
              value={departmentId}
                placeholder="Department"
                onChange={(e) => {
                  setIsErrorDepartment(e.target.value === '');
                  setDepartmentId(e.target.value);
                }}
                
              >
                {departmentList ? (departmentList.map((item, index) => (
                  <option key={item._id} value={item._id}>{item.name}</option>
                ))) : (<></>)}
              </Select>
              
            </FormControl>
            <FormControl isInvalid={isErrorApprovalRequired}>
                <FormLabel>Approval Required</FormLabel>
                <Select
                  placeholder="Select Room Type"
                  value={approvalRequired}
                  onChange={(e) => {
                    setIsErrorApprovalRequired(e.target.value === '')
                    setApprovalRequired(e.target.value)
                  }}
                >
                  { dataConstant.meetingRoomApproval.map((element,index) => {
                      return (<option key={element['id']} value={element['value']} >{element['name']}</option>);   
                        
                    })}
                </Select>
                {!isErrorApprovalRequired ? (
                  <></>
                ) : (
                  <FormErrorMessage>Approval Required is required.</FormErrorMessage>
                )}
              </FormControl>
              
              
              <FormControl/>
              
              <FormControl/>
                
                
              
  
              
            </SimpleGrid>


            <Center>
              {loading ? <Spinner mt='24px' size='lg' /> :
                <Button colorScheme='blue' mt='24px' onClick={handleSubmit}>
                  Submit
                </Button>
              }
            </Center>
          </CardHeader>
          <CardBody>
          </CardBody>
        </Card>
      </Flex>
    );
  }
  
  export default MeetingRoomsUpdateFrom;