import React, { useEffect, useState,useRef } from 'react';
import { Flex, InputGroup, Spacer, Table, Tbody, Text, Td, Th, Thead, Tr, useColorModeValue, InputLeftElement, IconButton, Input, Icon, Link ,Button, Tabs, TabList, TabPanels, TabPanel, Tab,Badge} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';

import { useToast } from '@chakra-ui/react';

import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';

import { AddIcon } from '@chakra-ui/icons';
import { Skeleton, SkeletonCircle, SkeletonText,Stack } from '@chakra-ui/react'
import { useParams ,useLocation,useHistory} from "react-router-dom/cjs/react-router-dom.min";
import axios from 'axios';
import { server_url } from "constants/types";
import { getPatrollingSession,getPatrollingByFloor ,getMissedLogs} from '../../actions/patrollingLogsAction';
import PartollingSessionTable from './PatrollingSession/PatrollingSessionTable';
import PatrollingSesssionAdvanceSearch from './PatrollingSession/PatrollingSessionAdvanceSearch';
import PatrollingByFloorAdvanceSearch from './PatrollingByFloor/PatrollingByFloorAdvanceSearch';
import PatrollingByFloorTable from './PatrollingByFloor/PatrollingByFloorTable';
import MissedLogsAdvanceSearch from './MissedLogs/MissedLogsAdvanceSearch';
import MissedLogsTable from './MissedLogs/MissedLogsTable';
import Dashboard from './Dashboard';
const PartollingTabs = (props) => {
    const {
        module=1,
      } = props;
  const tableRef = useRef(null);
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const dispatch = useDispatch();
  const toast = useToast();
  const searchIconColor = useColorModeValue("gray.700", "gray.200");
  const inputBg = useColorModeValue("#edf3f8", "navy.800");
  const [search, setSearch] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [tab, setTab] = useState(0);
  const [tabValue, setTabValue] = useState(0);
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const totalItems = useSelector(state => state.patrollingLogs.totalItems);
  const [checkedCount, setCheckedCount] = useState(0);
  const [missedCount, setMissedCount] = useState(0);
  const [tabCountDetail, setTabCountDetail] = useState('');
  useEffect(() => {
    
    const query = new URLSearchParams(location.search);
    setTabValue(parseInt(tabValue, 10));
    setTabValue(query.get('tab'));
    setTabValue(query.get('tab'));
    setTabValue(query.get('tab'));
    // tabValue = query.get('tab');
    console.log(tabValue + "taaaaaaa")
    const tabValueInt = parseInt(query.get('tab'), 10)
    if (!isNaN(tabValueInt)) {
        setTab(tabValueInt);
      }
      else
      {
        setTab(0);
      }
      getPatrollingTabCount();
  }, []);

  const getPatrollingTabCount = () => {
    axios
      .get(`${server_url}/patrolling/tab-count`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((response) => {
        if(response.data.status == 'OK')
            {
                var data = response.data.data;
                setCheckedCount(data['checkedCount']);
                setMissedCount(data['missedCount']);
            }
        
      }).catch(() => console.log("error"))
  }

const onChange = (tab) =>
    {
        if(tab==0)
            {
                history.push(`?tab=${tab}`);
                dispatch(getPatrollingSession(""));
            }
            else if(tab ==1)
                {
                    history.push(`?tab=${tab}`);
                    dispatch(getPatrollingByFloor(""));
                }
            else if(tab ==2)
                {
                    history.push(`?tab=${tab}`);
                    // dispatch(getMissedLogs(""));
                }
            // else if(tab ==3)
            //     {
            //         history.push(`?tab=${tab}`);
            //         dispatch(DelivaryInOutGetAll("status=RECIEVED"));
            //     }
        setTab(tab);
    }
    useEffect(() => {
        const timer = setTimeout(() => {
          setLoading(false); // Hide loader after 2 seconds
        }, 200);
    
        return () => clearTimeout(timer); // Clean up timer on unmount
      }, []);

  return (
  <>
  <Dashboard></Dashboard>
  {tab ==0 ?<PatrollingSesssionAdvanceSearch></PatrollingSesssionAdvanceSearch> : tab ==1 ?<PatrollingByFloorAdvanceSearch></PatrollingByFloorAdvanceSearch>:<MissedLogsAdvanceSearch></MissedLogsAdvanceSearch>}
  <Flex direction="column" pt={{ base: "120px", md: "20px" }}>
       
        {!loading ?<Tabs defaultIndex={tab}>
        <Card><TabList>
            <Tab onClick={()=>{
                onChange(0);
                setTab(0);
            }} onChange={()=>{
                onChange(0);
                setTab(0);
            }}>Patrol Summary <Badge ml='1' fontSize='0.8em' colorScheme='red'>
            
          </Badge>
          </Tab>
            <Tab onClick={()=>{
                onChange(1);
                setTab(1);
            }} onChange={()=>{
                onChange(1);
                setTab(1);
            }}>Checked Locations <Badge ml='1' fontSize='0.8em' colorScheme='red'>
            {checkedCount} 
          </Badge>
          </Tab>
            <Tab onClick={()=>{
                onChange(2);
                setTab(2);
            }} onChange={()=>{
                onChange(2);
                setTab(2);
            }}>Missed Locations <Badge ml='1' fontSize='0.8em' colorScheme='red'>
            {missedCount}
          </Badge>
          </Tab>
            
        </TabList>
        </Card>
        <TabPanels >
            <TabPanel className="remove-padding-table">{tab ==0 ? <PartollingSessionTable></PartollingSessionTable>:<></>}</TabPanel>
            <TabPanel className="remove-padding-table">{tab ==1 ? <PatrollingByFloorTable></PatrollingByFloorTable>:<></>}</TabPanel>
             <TabPanel className="remove-padding-table">{tab ==2 ? <MissedLogsTable></MissedLogsTable>:<></>}</TabPanel>
            
        </TabPanels>
        </Tabs>:<></>}
    </Flex>
  </>
  );
};

export default PartollingTabs;
