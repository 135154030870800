import React, { useEffect, useState,useRef } from 'react';
import { Flex, InputGroup, Spacer, Table, Tbody, Text, Td, Th, Thead, Tr, useColorModeValue, InputLeftElement, IconButton, Input, Icon, Link ,Button, Tabs, TabList, TabPanels, TabPanel, Tab,Badge,
    Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
  Spinner,
  SimpleGrid,
  FormControl,
  FormLabel
} from "@chakra-ui/react";
import { 
WarningTwoIcon
} from "@chakra-ui/icons";
import { SearchIcon } from "@chakra-ui/icons";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';

import { AddIcon } from '@chakra-ui/icons';
import { Skeleton, SkeletonCircle, SkeletonText,Stack } from '@chakra-ui/react'
import { useParams ,useLocation,useHistory} from "react-router-dom/cjs/react-router-dom.min";
import axios from 'axios';
import { server_url } from "constants/types";
import { inspectionSummaryListGetAll } from '../../actions/inspectionsummaryAction';
import { VISITORINOUT_CLEAR_SNACKBAR ,INSPECTION_SUMMARY_SEARCH,INSPECTION_SUMMARY_ERROR} from 'constants/types';
import OtpInput from 'react-otp-input';

const MeetingRoomBookingsExport = ({ isOpen, onClose, onExport }) => {
  const tableRef = useRef(null);
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const dispatch = useDispatch();
  const toast = useToast();
  const searchIconColor = useColorModeValue("gray.700", "gray.200");
  const inputBg = useColorModeValue("#edf3f8", "navy.800");
  
  
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState("");
  const [otp, setOtp] = useState('');
  const [password, setPassword] = useState('');

//   Get Otp function
const verifyPasswordFunction = () => {
    setLoading(true);
    const body = {
        "name" : "Meeting Room Bookings",
        "password" : password
    }
    axios
      .post(`${server_url}/admin/verify/password`,body, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((response) => {
        if(response.data.status == 'OK')
        {
            setData(response.data.data);
            toastFunction(response.data.message, 'success');
            onClose();
            onExport();
        }
        else
        {
            toastFunction(response.data.message, 'error');
        }
      }).catch(() => console.log("error"))

      setLoading(false);
  }


  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  };

  return (
  <>

    <Modal onClose={onClose} isOpen={isOpen} size="xl" isCentered>
    <ModalOverlay />
    <ModalContent>
        <ModalHeader fontSize="16px"><span style={{
          color : "red"
        }}><WarningTwoIcon scale="10"></WarningTwoIcon> THIS ACTION SHALL BE AUDITED  </span>- DOWNLOAD REPORT</ModalHeader>
        <ModalHeader fontSize="14px">Authenticate to Continue</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
        <SimpleGrid minChildWidth="120px" spacing="40px">
            <FormControl>
              <FormLabel>Enter your password</FormLabel>
              <Input
                type="password"
                onChange={(e) => {
                  // setIsErrorName(e.target.value === '');
                  setPassword(e.target.value);
                }}
              />
              {/* {!isErrorName ? (
                <></>
              ) : (
                <FormErrorMessage>Name is required.</FormErrorMessage>
              )} */}
            </FormControl>
            </SimpleGrid>
        </ModalBody>
        <ModalFooter>
        
        {loading ? <Spinner mt='24px' size='lg' />:<Button m="4px" size='sm' onClick={()=>
            {
                verifyPasswordFunction();
            }
        } colorScheme="blue" _hover={{ backgroundColor: 'black', color: 'white' }}>Confirm</Button>}
        </ModalFooter>
    </ModalContent>
    </Modal>
  </>
  );
};

export default MeetingRoomBookingsExport;
