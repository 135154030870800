import axios from 'axios';
import dataConstant from 'constants/dataConstant'

export const uploadToImageKit = (file) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('fileName', `department_${Date.now()}`); // Unique file name
  
      axios
        .post('https://upload.imagekit.io/api/v1/files/upload', formData, {
          headers: {
            'Authorization': 'Basic '+dataConstant.IMAGEKIT_KEY, // Replace with your ImageKit key
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          resolve(response.data.url); // Resolve with the image URL
        })
        .catch((error) => {
          reject(error); // Reject with the error
        });
    });
  };
  