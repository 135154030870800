import React, { useEffect, useState, useRef } from 'react';
import {
  Flex,
  SimpleGrid,
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";
import { WarningTwoIcon } from "@chakra-ui/icons";
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { server_url } from "constants/types";
import dataConstant from 'constants/dataConstant';

const MaterialExport = ({ reportName, isOpen, onClose, onExport }) => {
  const tableRef = useRef(null);
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const dispatch = useDispatch();
  const toast = useToast();
  
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState("");
  const [password, setPassword] = useState('');
  const [reason, setReason] = useState('');
  const [others, setOthers] = useState('');

  // Function to verify password and reason
  const verifyPasswordFunction = () => {
    setLoading(true);
    const body = {
      "name": reportName,
      "reason": reason,
      "others": others,
      "password": password
    };
    axios
      .post(`${server_url}/admin/verify/password`, body, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((response) => {
        if (response.data.status === 'OK') {
          setData(response.data.data);
          toastFunction(response.data.message, 'success');
          onClose();
          onExport();
        } else {
          toastFunction(response.data.message, 'error');
        }
      }).catch((error) => {
        console.error("Error verifying password:", error);
        toastFunction("An error occurred", 'error');
      }).finally(() => setLoading(false));
  };

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <>
      <Modal onClose={onClose} isOpen={isOpen} size="xl" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="16px">
            <span style={{ color: "red" }}>
              <WarningTwoIcon scale="10" /> THIS ACTION SHALL BE AUDITED
            </span>
            - DOWNLOAD REPORT
          </ModalHeader>
          <ModalHeader fontSize="14px">Authenticate to Continue</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SimpleGrid minChildWidth="120px" spacing="25px">
              <FormControl isRequired>
                <FormLabel>Select Reason</FormLabel>
                <Select onChange={(e) => setReason(e.target.value)}>
                  {dataConstant.reportAuthenticationReason.map((element) => (
                    <option key={element.id} value={element.value}>
                      {element.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
              {reason === dataConstant.reportAuthenticationOthers && (
                <FormControl isRequired>
                  <FormLabel>Others</FormLabel>
                  <Input
                    onChange={(e) => setOthers(e.target.value)}
                  />
                </FormControl>
              )}
            </SimpleGrid>
            <SimpleGrid minChildWidth="120px" spacing="40px" mt="8px">
              <FormControl isRequired>
                <FormLabel>Enter your password</FormLabel>
                <Input
                  type="text"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormControl>
            </SimpleGrid>
          </ModalBody>
          <ModalFooter>
            {loading ? (
              <Spinner mt='24px' size='lg' />
            ) : (
              <Button
                m="4px"
                size='sm'
                onClick={verifyPasswordFunction}
                colorScheme="blue"
                _hover={{ backgroundColor: 'black', color: 'white' }}
              >
                Confirm
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default MaterialExport;
