// Chakra imports
import {
    Box,
    Button,
    Center,
    Checkbox,
    Flex,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    Input,
    Select,
    SimpleGrid,
    Spinner,
    Stack,
    Text,
    useColorModeValue,
    useToast,
  } from "@chakra-ui/react";
  // Custom components
  import Card from "components/Card/Card.js";
  import CardBody from "components/Card/CardBody.js";
  import CardHeader from "components/Card/CardHeader.js";
  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import { useParams } from "react-router-dom/cjs/react-router-dom.min";
  import axios from 'axios';
  import { server_url } from "constants/types";
  import { useHistory } from 'react-router-dom';
  import { DeleteIcon } from '@chakra-ui/icons';

 
  import {DEPARTMENT_CLEAR_SNACKBAR } from "constants/types";
  import {
    List,
    ListItem,
    ListIcon,
    OrderedList,
    UnorderedList,
  } from "@chakra-ui/react";
  import dataConstant from "constants/dataConstant";
import { permissionGetAll } from "actions/permissionActions";
import { moduleGetAll } from "actions/moduleActions";
import { departmentGetAll } from "actions/departmentActions";
import { adminGetAll } from "actions/adminActions";
import { departmentAddOrUpdate,multiDepartmentAdd } from "actions/departmentActions";
import { departmentClearShowSnackbar } from "actions/departmentActions";

import { uploadToImageKit} from "services/helper";
  
  function DepartmentFormV2() {
    const textColor = useColorModeValue("gray.700", "white");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [status, setStatus] = useState("");
    const [image , setImage] = useState("");

    const [adminId, setAdminId] = useState("");
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const [isErrorName, setIsErrorName] = useState(false);
    const [isErrorDescription, setIsErrorDescription] = useState(false);
    const [isErrorStatus, setIsErrorStatus] = useState(false);
    const [isErrorImage, setIsErrorImage] = useState(false);
    const [isErrorAdminId, setIsErrorAdminId] = useState(false);
    const [permissionStyle, setPermissionStyle] = useState({
      display: "none",
    });
    const history = useHistory();
    // const [loading, setLoading] = useState(false);
    const [isError, setIsError] = useState(false);
  
    const dispatch = useDispatch();
    const toast = useToast();
  
    const statuses = ["success", "error", "warning", "info"];
    const responseStatus = useSelector((state) => state.department.status);
    const responseMessage = useSelector((state) => state.department.text);
    const loading = useSelector((state) => state.department.loading);
    // const loading = useSelector((state) => state.department.loading);
    const showSnackbar = useSelector(
      (state) => state.department.showSnackbar
    );
    const moduleList = useSelector((state) => state.module.moduleList);
    const permissionList = useSelector(
      (state) => state.permission.permissionList
    );

    const departmentList = useSelector((state) => state.department.departmentList)
   console.log("departmentList");
   console.log(departmentList);
   const params = useParams();
    useEffect(() => {
      if (showSnackbar) {
        if (responseStatus === "OK" && loading === false) {
          if (responseMessage !== "") {
            toastFunction(responseMessage, "success");
            history.goBack();
          }
        } else if (responseStatus === "NOK" && loading === false) {
          if (responseMessage !== "") {
            toastFunction(responseMessage, "error");
          }
        }
      }
      dispatch(departmentClearShowSnackbar());
    }, [loading]);
  
    useEffect(() => {
      dispatch(moduleGetAll());
      dispatch(permissionGetAll());
   
      dispatch(adminGetAll());
      

    }, []);
    useEffect(() => {
      axios
        .get(`${server_url}/admin/detail`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((responce) => {
          console.log("uadlfdafljadhfoadilf");
        })
        .catch((e) => {
          history.push('/auth/signin');
  
        });
    }, [])
    const toastFunction = (title, status) => {
      toast({
        title: title,
        status: status,
        duration: 3000,
        isClosable: true,
      });
    };
  
  //   const handleSubmit = (event) => {
  //     event.preventDefault();
  //     setIsErrorName(name === "");
  //     setIsErrorImage(image === '');
  //     // setIsErrorDescription(description === "");
  //     setIsErrorStatus(status === "");
      
  
  //     if (
  //       name !== "" &&
  //       // description !== "" &&
  //       status !== ""&&
  //       image !==""

  //     ) {
  //       const department = {
  //         name: name,
  //         description: description,
  //         status: status,
  //         file : image,
  //         plant: params.id,
  //         permissions: selectedPermissions,
  //       };
  // console.log("department");
  // console.log(department);
  //       const state = "add";
  //       dispatch(departmentAddOrUpdate(department, state));
        
  //     }
  //   };
    
  
    const handlePermissionChange = (permissionId, moduleId) => {
      setSelectedPermissions((prevSelectedPermissions) => {
        const existingPermissionIndex = prevSelectedPermissions.findIndex(
          (permission) =>
            permission._id === permissionId &&
            permission.modules._id === moduleId
        );
  
        if (existingPermissionIndex !== -1) {
          const updatedPermissions = [...prevSelectedPermissions];
          updatedPermissions.splice(existingPermissionIndex, 1);
          return updatedPermissions;
        } else {
          const permission = permissionList.find(
            (permission) =>
              permission._id === permissionId &&
              permission.modules._id === moduleId
          );
  
          return [...prevSelectedPermissions, permission];
        }
      });
    };

    const [departments, setDepartments] = useState([
      { name: "", description: "", image : "", plant: params.id, subDepartments: [{ name: "" }] },
    ]);
    
  
    // Handle adding a new department
    const addDepartment = () => {
      setDepartments([
        ...departments,
        { name: "", description: "", image : "",plant: params.id, subDepartments: [{ name: "" }] },
      ]);
    };
  
    // Handle removing a department
    const removeDepartment = (index) => {
      setDepartments(departments.filter((_, i) => i !== index));
    };
  
    // Handle updating department details
    const handleDepartmentChange = (index, field, value) => {
      const updatedDepartments = [...departments];
      updatedDepartments[index][field] = value;
      setDepartments(updatedDepartments);
    };
  
    // Handle adding a sub-department
    const addSubDepartment = (deptIndex) => {
      const updatedDepartments = [...departments];
      updatedDepartments[deptIndex].subDepartments.push({ name: "" });
      setDepartments(updatedDepartments);
    };
  
    // Handle removing a sub-department
    const removeSubDepartment = (deptIndex, subDeptIndex) => {
      const updatedDepartments = [...departments];
      updatedDepartments[deptIndex].subDepartments.splice(subDeptIndex, 1);
      setDepartments(updatedDepartments);
    };
  
    // Handle updating sub-department details
    const handleSubDepartmentChange = (deptIndex, subDeptIndex, value) => {
      const updatedDepartments = [...departments];
      updatedDepartments[deptIndex].subDepartments[subDeptIndex].name = value;
      setDepartments(updatedDepartments);
    };
  
    const handleSubmit = () => {
      // Perform validation
      const isValid = departments.every((dept) => dept.name.trim() !== "");
      setIsError(!isValid);
      if (isValid) {
        console.log("Submitting departments:", departments);
        var multiDepartments = {
          departments
        }
        // Add API call here
        dispatch(multiDepartmentAdd(multiDepartments));
      }
    };
 

    return (
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" fontWeight="bold">
              Department Creation Form
            </Text>
            <br />
            {departments.map((dept, deptIndex) => (
              <Flex
                key={deptIndex}
                direction="column"
                border="1px solid gray"
                borderRadius="md"
                p={4}
                mb={4}
              >
                <SimpleGrid minChildWidth="120px" spacing="40px">
                  <FormControl isInvalid={isError && !dept.name} isRequired>
                    <FormLabel>Department Name</FormLabel>
                    <Input
                      type="text"
                      value={dept.name}
                      onChange={(e) =>
                        handleDepartmentChange(deptIndex, "name", e.target.value)
                      }
                    />
                    {isError && !dept.name && (
                      <FormErrorMessage>Name is required.</FormErrorMessage>
                    )}
                  </FormControl>
  
                  <FormControl>
                    <FormLabel>Description</FormLabel>
                    <Input
                      type="text"
                      value={dept.description}
                      onChange={(e) =>
                        handleDepartmentChange(
                          deptIndex,
                          "description",
                          e.target.value
                        )
                      }
                    />
                  </FormControl>
                  <FormControl isInvalid={isErrorImage}>
                    <FormLabel>Image</FormLabel>
                    <Input
                      type='file'
                      onChange={(e) => {
                        uploadToImageKit(e.target.files[0])
                        .then((imageUrl) => {
                          handleDepartmentChange(
                            deptIndex,
                            "image",
                            imageUrl
                          )
                        })
                        .catch((error) => {
                          console.error('Error uploading image:', error);
                        });
                        
                      }}
                    />
                    {!isErrorImage ? (
                      <></>
                    ) : (
                      <FormErrorMessage>Image is required.</FormErrorMessage>
                    )}
                  </FormControl>
                </SimpleGrid>
  
                <Text mt={4} fontWeight="bold">
                  Sub-Departments
                </Text>
                {dept.subDepartments.map((subDept, subDeptIndex) => (
                  <Flex key={subDeptIndex} align="center" mb={2}>
                    <Input
                    width={"20%"}
                      type="text"
                      placeholder="Sub-department name"
                      value={subDept.name}
                      onChange={(e) =>
                        handleSubDepartmentChange(
                          deptIndex,
                          subDeptIndex,
                          e.target.value
                        )
                      }
                    />
                    <DeleteIcon
                    cursor={"pointer"}
                      ml={2}
                      color="red"
                      onClick={() =>
                        removeSubDepartment(deptIndex, subDeptIndex)
                      }
                    >
                      Remove
                    </DeleteIcon>
                  </Flex>
                ))}
                <Button
                alignSelf={"center"}
                width={"20%"}
                  mt={2}
                  colorScheme="blue"
                  onClick={() => addSubDepartment(deptIndex)}
                >
                  Add Sub-Department
                </Button>
                <Button
                alignSelf={"center"}
                width={"20%"}
                  mt={2}
                  colorScheme="red"
                  onClick={() => removeDepartment(deptIndex)}
                >
                  Remove Department
                </Button>
              </Flex>
            ))}
            <Button 
            colorScheme="blue" mt={4} onClick={addDepartment}>
              Add Department
            </Button>
            <Center>
              {loading ? (
                <Spinner mt="24px" size="lg" />
              ) : (
                <Button colorScheme="green" mt="24px" onClick={handleSubmit}>
                  Submit
                </Button>
              )}
            </Center>
          </CardHeader>
          <CardBody></CardBody>
        </Card>
      </Flex>
    );
  }
  
  export default DepartmentFormV2;
  