const {
    VISITORINOUT_LOADING,
    VISITORINOUT_GET_ALL_SUCCESS,
    VISITORINOUT_ERROR,
    VISITORINOUT_CLEAR_SNACKBAR,
    VISITOR_SEARCH
  } = require('../constants/types');
  
  const initialState = {
    response: {},
    visitorInOutList: [],
    visitorInOutListIN: [],
    visitorInOutDetail: {},
    loading: false,
    visitorInOutListIN: [],
    VisitorListToday: [],
    status: 'success',
    text: '',
    showSnackbar: false,
    limit: 0,  // Add missing properties
    page: 0,
    totalPages: 0,
    searching: '',  // Add missing properties
    totalItems : 0
  };
  
  const VisitorInOutReducer = (state = initialState, action) => {
    switch (action.type) {
      case VISITORINOUT_CLEAR_SNACKBAR:
        return {
          ...state,
          showSnackbar: false
        };
      case VISITORINOUT_LOADING:
        return {
          ...state,
          loading: true
        };
      case VISITORINOUT_ERROR:
        return {
          ...state,
          visitorInOutList: [],
          visitorInOutListIN: [],
          VisitorListToday : [],
          response: {},
          visitorInOutDetail: {},
          limit: 0,  // Add missing properties
          page: 0,
          totalPages: 0,
          totalItems: 0,
          loading: false,
          visitorInOutListIN : [],
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true
        };
      case VISITORINOUT_GET_ALL_SUCCESS:
        return {
          ...state,
          visitorInOutList: action.payload.visitorInOutList,
          visitorInOutListIN: action.payload.visitorInOutList,
          response: action.payload.response,
          visitorInOutDetail: action.payload.visitorInOutDetail,
          limit: action.payload.limit,
          page: action.payload.page,
          totalPages: action.payload.totalPages,
          totalItems: action.payload.totalItems,
          visitorInOutListIN: action.payload.visitorInOutListIN,
          VisitorListToday: action.payload.VisitorListToday,
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true
        };
        case VISITOR_SEARCH: // Add the new case
      return {
        ...state,
        searching: action.payload.searching
      };
      default:
        return state || {};
    }
  };
  
  export default VisitorInOutReducer;
  // module.exports = VisitorInOutReducer;