import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  SimpleGrid,
  Text,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Select,
  Spinner,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import RSelect from "react-select";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import axios from "axios";
import { MEETING_ROOMS_CLEAR_SNACKBAR, server_url } from "../../constants/types";
import dataConstant from "constants/dataConstant";
import {meetingroomsListAddOrUpdate} from "../../actions/meetingroomsAction";
function MeetingRoomsForm() {
  const params = useParams();
  const textColor = useColorModeValue("gray.700", "white");
  const toast = useToast();
  const dispatch = useDispatch();
  const history = useHistory();

  // State to store multiple room entries
  const [rooms, setRooms] = useState([
    {
      name: "",
      type: "",
      capacity: "",
      amenities: [],
      department: "",
      approvalRequired: "NO",
      plantAdmin : params.id
    },
  ]);
  const [departmentList, setDepartmentList] = useState([]);
  const loading = useSelector((state) => state.meetingrooms.loading);
  const showSnackbar = useSelector(state => state.meetingrooms.showSnackbar);
  const responseStatus = useSelector(state => state.meetingrooms.status);
  const responseMessage = useSelector(state => state.meetingrooms.text);

  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
      })
      .catch(() => history.push("/auth/signin"));

    getDepartmentList();
  }, []);

  const getDepartmentList = () => {
    axios
      .get(`${server_url}/department?plant=${params.id}&limit=1000`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
      })
      .then((response) => setDepartmentList(response.data.data))
      .catch(console.error);
  };

  const handleRoomChange = (index, field, value) => {
    const updatedRooms = [...rooms];
    updatedRooms[index][field] = value;
    setRooms(updatedRooms);
  };

  const addRoom = () => {
    const lastRoom = rooms[rooms.length - 1]; // Get the last added room
    var isRoomValid;
    // Validate if all required fields are filled
    if(lastRoom.type.trim() == "INTERNAL")
    {
        isRoomValid = 
        lastRoom.name.trim() !== "" &&
        lastRoom.type.trim() !== "" &&
        lastRoom.capacity.trim() !== "" &&
        lastRoom.amenities.length > 0 &&
        lastRoom.department.trim() !== "" &&
        lastRoom.approvalRequired.trim() !== "";
    }
    else
    {
        isRoomValid = 
        lastRoom.name.trim() !== "" &&
        lastRoom.type.trim() !== "" &&
        lastRoom.capacity.trim() !== "" &&
        lastRoom.amenities.length > 0 &&
        lastRoom.approvalRequired.trim() !== "";
    }
  
    if (!isRoomValid) {
      // Show a toast message if validation fails
      toast({
        title: "Please fill all required fields for the last room.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return; // Stop the execution if validation fails
    }
  
    // If the last room is valid, add a new room
    setRooms([
      ...rooms,
      {
        name: "",
        type: "",
        capacity: "",
        amenities: [],
        department: "",
        approvalRequired: "NO",
        plantAdmin : params.id
      },
    ]);
  };

  const removeRoom = (index) => {
    const updatedRooms = rooms.filter((_, i) => i !== index);
    setRooms(updatedRooms);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const firstRoom = rooms[rooms.length - 1]; // Get the last added room
    var isRoomValid;
    // Validate if all required fields are filled
    if(firstRoom.type.trim() == "INTERNAL")
    {
        isRoomValid = 
        firstRoom.name.trim() !== "" &&
        firstRoom.type.trim() !== "" &&
        firstRoom.capacity.trim() !== "" &&
        firstRoom.amenities.length > 0 &&
        firstRoom.department.trim() !== "" &&
        firstRoom.approvalRequired.trim() !== "";
    }
    else
    {
        isRoomValid = 
        firstRoom.name.trim() !== "" &&
        firstRoom.type.trim() !== "" &&
        firstRoom.capacity.trim() !== "" &&
        firstRoom.amenities.length > 0 &&
        firstRoom.approvalRequired.trim() !== "";
    }
     
  
    if (!isRoomValid) {
      // Show a toast message if validation fails
      toast({
        title: "Please fill all required fields for the last room.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return; // Stop the execution if validation fails
    }
    console.log("Rooms Data:", rooms);
    var meetingRooms = {
        meetingRooms : rooms
    };
    console.log("Meetings Rooms Data:", meetingRooms);
    // Dispatch or submit logic here
    var state = "add"
     dispatch(meetingroomsListAddOrUpdate(meetingRooms, state,params.id))
  };

  useEffect(() => {
    if (showSnackbar) {
        if (responseStatus === 'OK' && loading === false) {
          if (responseMessage !== '') {
            toastFunction(responseMessage, 'success');
            history.goBack();
          }
        } else if (responseStatus === 'NOK' && loading === false) {
          if (responseMessage !== '') {
            toastFunction(responseMessage, 'error');
          }
        }
      }
      dispatch({
        type: MEETING_ROOMS_CLEAR_SNACKBAR,
        payload: {
          showSnackbar: false,
        },
      });
    
  }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Meeting Room Form
          </Text>

          {rooms.map((room, index) => (
            <Box key={index} border="1px solid" borderColor="gray.300" p="4" mt="4" borderRadius="md">
              <SimpleGrid minChildWidth="120px" spacing="40px">
                <FormControl isRequired>
                  <FormLabel>Room Type</FormLabel>
                  <Select
                    placeholder="Select Room Type"
                    value={room.type}
                    onChange={(e) => handleRoomChange(index, "type", e.target.value)}
                  >
                    {dataConstant.meetingRoomType.map((element) => (
                      <option key={element.id} value={element.value}>
                        {element.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>

                <FormControl isRequired>
                  <FormLabel>Name</FormLabel>
                  <Input
                    value={room.name}
                    onChange={(e) => handleRoomChange(index, "name", e.target.value)}
                  />
                </FormControl>

                <FormControl isRequired>
                  <FormLabel>Capacity</FormLabel>
                  <Input
                  type="number"
                    value={room.capacity}
                    onChange={(e) => handleRoomChange(index, "capacity", e.target.value)}
                  />
                </FormControl>

                <FormControl isRequired>
                  <FormLabel>Amenities</FormLabel>
                  <RSelect
                    isMulti
                    options={dataConstant.meetingRoomAmenities.map((item) => ({
                      value: item.value,
                      label: item.name,
                    }))}
                    value={room.amenities}
                    onChange={(value) => handleRoomChange(index, "amenities", value)}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Department</FormLabel>
                  <Select
                    placeholder="Select Department"
                    value={room.departmentId}
                    onChange={(e) => handleRoomChange(index, "department", e.target.value)}
                  >
                    {departmentList.map((item) => (
                      <option key={item._id} value={item._id}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>

                <FormControl isRequired>
                  <FormLabel>Approval Required</FormLabel>
                  <Select
                    value={room.approvalRequired}
                    onChange={(e) => handleRoomChange(index, "approvalRequired", e.target.value)}
                  >
                    {dataConstant.meetingRoomApproval.map((element) => (
                      <option key={element.id} value={element.value}>
                        {element.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </SimpleGrid>

              {index != 0 ? <Button size='xs' mt="4" colorScheme="red" onClick={() => removeRoom(index)}>
                Remove
              </Button>:<></>}
            </Box>
          ))}

          <Button size='xs' mt="6" colorScheme="blue" onClick={addRoom}>
            Add More
          </Button>

          <Center>
            {loading ? (
              <Spinner mt="24px" size="lg" />
            ) : (
              <Button colorScheme="green" mt="24px" onClick={handleSubmit}>
                Submit
              </Button>
            )}
          </Center>
        </CardHeader>
        <CardBody />
      </Card>
    </Flex>
  );
}

export default MeetingRoomsForm;
