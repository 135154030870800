import React, { useEffect, useState, useRef } from 'react';
import { Flex, Button, InputGroup, Spacer, Table, Tbody, Text, Td, Th, Thead, Tr, useColorModeValue, InputLeftElement, IconButton, Input, Icon, Link, Box,useDisclosure } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';

import { useToast } from '@chakra-ui/react';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
// import DevicePagination from './DevicePagination';
import { AddIcon } from '@chakra-ui/icons';
import { MATERIAL_CLEAR_SNACKBAR ,MATERIAL_SEARCH} from 'constants/types';
// import {  } from '../../actions/devicesAction'; // Assuming you have a DeviceAction file
// import DeviceTableRow from './DevicesTableRow';

// import DevicesPagination from './DevicesPagination';
// import materialPagination from './materialPagination'
import CurrentTableRow from './CurrentTableRow';
import { materialGetAll } from '../../../actions/materialAction';
import CurrentPagination from './CurrentPagination';
import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';
import CurrentAdvanceSearch from './CurrentAdvanceSearch';
import { Skeleton, SkeletonCircle, SkeletonText,Stack } from '@chakra-ui/react'
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import MaterialExport from '../MaterialExport';

const CurrentTable = (props) => {
  const { url = "", plant } = props;
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const responseStatus = useSelector(state => state.material.status);
  const responseMessage = useSelector(state => state.material.text);
  const loading = useSelector(state => state.material.loading);
  const materialList = useSelector(state => state.material.materialList || []);
  const currentPage = useSelector(state => state.material.page);
  const totalItems = useSelector(state => state.material.totalItems);
  const limit = useSelector(state => state.material.limit);
  const response = useSelector(state => state.material.response);
  const showSnackbar = useSelector(state => state.material.showSnackbar);
  const dispatch = useDispatch();
  const toast = useToast();
  const searchIconColor = useColorModeValue("gray.700", "gray.200");
  const inputBg = useColorModeValue("#edf3f8", "navy.800");
  const [search, setSearch] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [submittingLoader, setSubmittingLoader] = useState(false);
  const history = useHistory();
  const tableRef = useRef(null);
  useEffect(() => {
    dispatch(materialGetAll("&type=IN&plant=" + plant));
    dispatch({
      type : MATERIAL_SEARCH,
      payload : {
        searching : '',
      }
    })
  }, [dispatch]);
  console.log("reportIncedentList123456")
  console.log(materialList)
  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: MATERIAL_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("desc");
    }
    const sorting = "&sortOrder=" + sortOrder + "&column=" + column;
    dispatch({
      type : MATERIAL_SEARCH,
      payload : {
        searching : '',
      }
    })
    dispatch(deviceGetAll(sorting));
  };

  useEffect(() => {
    const searching = "&type=IN&search=" + search;
    dispatch({
      type : MATERIAL_SEARCH,
      payload : {
        searching : '',
      }
    })
    dispatch(materialGetAll(searching));
  }, [search]);
  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])
  const exportToExcel = async () => {
    setSubmittingLoader(true);
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet('Sheet 1');
    var filename = "Current_material_data"+Date.now();

    // Define the headers
    const headers = [
      'SNO', 'Date', 'Txn. No.', 'PO / Invoice Number', 'Material Description', 
      'Department', 'Sub department', 'Material By', 'Vehicle Number', 
      'Shipment Type', 'Vendor', 'Purpose', 'In Time', 'Out Time', 
      'Total Time', 'Guard', 'Employee', 'Weighment', 'Last Status'
    ];

    // Set up the columns with specific widths
    sheet.columns = [
      { header: 'SNO', key: 'sno', width: 10 },
      { header: 'Date', key: 'date', width: 15 },
      { header: 'Txn. No.', key: 'txnNumber', width: 20 },
      { header: 'PO / Invoice Number', key: 'invoiceNumber', width: 20 },
      { header: 'Material Description', key: 'materialDescription', width: 25 },
      { header: 'Department', key: 'department', width: 20 },
      { header: 'Sub Department', key: 'subDepartment', width: 20 },
      { header: 'Material By', key: 'materialBy', width: 20 },
      { header: 'Vehicle Number', key: 'vehicleNumber', width: 20 },
      { header: 'Shipment Type', key: 'shipmentType', width: 20 },
      { header: 'Vendor', key: 'vendor', width: 20 },
      { header: 'Purpose', key: 'purpose', width: 20 },
      { header: 'In Time', key: 'inTime', width: 20 },
      { header: 'Out Time', key: 'outTime', width: 20 },
      { header: 'Total Time', key: 'totalTime', width: 20 },
      { header: 'Guard', key: 'guard', width: 20 },
      { header: 'Employee', key: 'employee', width: 20 },
      { header: 'Weighment', key: 'weighment', width: 20 },
      { header: 'Logs', key: 'logs', width: 30 }
    ];

    // Add the headers to the sheet
    headers.forEach((header, index) => {
      sheet.getCell(1, index + 1).value = header;
    });

    // Add data and images
    for (const [rowIndex, row] of materialList.entries()) {
      const rowData = [
        totalItems - (((currentPage - 1) * limit) + rowIndex),
        row.date, 
        row.txnNumber, 
        row.poInvoiceNumber,
        row.material, 
        row.department?.name, 
        row.subdepartment?.name, 
        row.materialBy,
        row.vehicleNumber, 
        row.materialShipmentType, 
        row.vendor, 
        row.purpose,
        row.gateInTime, 
        row.gateOutTime, 
        row.totalTime, 
        row.type, 
        row.status,
        row.weighment, 
        row.lastStatus[0]['description'] +" "+ row.lastStatus[0]['formattedDateTime']
      ];

      for (const [colIndex, cell] of rowData.entries()) {
        if (colIndex === 4) {
          var materialdetails='';
          for(const list of row.material)
            {
              materialdetails += list.materialDescription+ "-"+ list.quantity+" "+ list.uom+"\n";
            }
            sheet.getCell(rowIndex + 2, colIndex + 1).value =  materialdetails;
        }
        else if (colIndex === 3) { 
          var invoice = '';
          for(const list of row.material)
            {
              invoice +=list.invoiceNumber+"\n";
            }
            sheet.getCell(rowIndex + 2, colIndex + 1).value = invoice;
        }
        else {
          sheet.getCell(rowIndex + 2, colIndex + 1).value = cell;
        }
      }
    }

    // Set row height (if needed)
    for (let rowIndex = 1; rowIndex <= sheet.rowCount; rowIndex++) {
      sheet.getRow(rowIndex).height = 60; // Set height in points
    }

    // Generate Excel file and save
    const excelBuffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([excelBuffer]), `${filename}.xlsx`);
    setSubmittingLoader(false);
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
    {/* <CurrentAdvanceSearch></CurrentAdvanceSearch> */}
    <Flex direction="column" pt={{ base: "120px", md: "10px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <Flex>
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Current
            </Text>
          </CardHeader>
          <Spacer />
          {/* <Flex justify="flex-end" style={{ padding: '10px' ,marginTop : '-5px' ,marginRight :'12px' }}> 
            <Link href={`#/admin/patrolling-route-form`}>
            <IconButton
              colorScheme="blue"
              aria-label="Search database"
              icon={<AddIcon />}
            />
          </Link>
          </Flex> */}
          <Flex justify="flex-end" style={{ padding: '10px', marginTop: '-5px', marginRight: '12px' }}>
            {/* <DownloadTableExcel
              filename="Current Material Data"
              sheet="users"
              currentTableRef={tableRef.current}
            > */}
              <Button 
              isLoading={submittingLoader}
              loadingText='Downloading'
               onClick={onOpen}
              colorScheme="blue" _hover={{ backgroundColor: 'black', color: 'white' }}>
                Export Excel
              </Button>
            {/* </DownloadTableExcel> */}
          </Flex>
          {/* Export Modal */}
          <MaterialExport
            reportName = "Current Material Data"
            isOpen={isOpen}
            onClose={onClose}
            onExport={exportToExcel}
            />
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              <InputGroup borderRadius="8px" w="200px">
                <InputLeftElement
                  children={
                    <IconButton
                      bg="inherit"
                      borderRadius="inherit"
                      _hover="none"
                      _active={{
                        bg: "inherit",
                        transform: "none",
                        borderColor: "transparent",
                      }}
                      _focus={{
                        boxShadow: "none",
                      }}
                      icon={
                        <SearchIcon
                          color={searchIconColor}
                          w="15px"
                          h="15px"
                        />
                      }
                    ></IconButton>
                  }
                />
                <Input
                  type="pass"
                  variant="search"
                  fontSize="xs"
                  bg={inputBg}
                  onKeyUp={(e) => {
                    setSearch(e.target.value);
                    if (e.target.value.length < 3) {
                      setSearch("");
                    }
                  }}
                  placeholder="Type here..."
                />
              </InputGroup>
            </Text>
          </CardHeader>
        </Flex>
        <CardBody pb={4}>
          <div style={{ overflowX: "auto" }}>

            {loading ? <Stack>
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                  </Stack>:<Table className='vertical-scroll' variant="simple" color={textColor} ref={tableRef}>
              <Thead className='stick-header'>
                <Tr my=".8rem" pl="0px" color="gray.400">
                <Th
                    pl="15px"
                    borderColor={borderColor}
                    color="gray.400"
                    cursor="pointer"
                    rowSpan={2}
                  >
                   SNO
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("outtime")}
                    cursor="pointer"
                    rowSpan={2}
                  >
                    Date
                    {sortColumn === "outtime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("outtime")}
                    cursor="pointer"
                    rowSpan={2}
                  >
                    Txn. No.
                    {sortColumn === "outtime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    pl="0px"
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("name")}
                    cursor="pointer"
                    rowSpan={2}
                  >
                    PO / Invoice Number
                    {sortColumn === "name" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    pl="0px"
                    borderColor={borderColor}
                    color="gray.400"
                    cursor="pointer"
                    rowSpan={2}
                  >
                    Material Description
                    {sortColumn === "name" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    pl="0px"
                    borderColor={borderColor}
                    color="gray.400"
                    cursor="pointer"
                    rowSpan={2}
                  >
                    Department
                    {sortColumn === "name" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    pl="0px"
                    borderColor={borderColor}
                    color="gray.400"
                    cursor="pointer"
                    rowSpan={2}
                  >
                    Sub department
                    {sortColumn === "name" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("outtime")}
                    cursor="pointer"
                    rowSpan={2}
                  >
                    Mode
                    {sortColumn === "outtime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>

                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("outtime")}
                    cursor="pointer"
                    rowSpan={2}
                  >
                    Vehicle Number
                    {sortColumn === "outtime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  

                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("outtime")}
                    cursor="pointer"
                    rowSpan={2}
                  >
                    Type
                    {sortColumn === "outtime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>

                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("outtime")}
                    cursor="pointer"
                    rowSpan={2}
                  >
                    Party Name
                    {sortColumn === "outtime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>

                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("outtime")}
                    cursor="pointer"
                    rowSpan={2}
                  >
                    Purpose
                    {sortColumn === "outtime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("outtime")}
                    cursor="pointer"
                    rowSpan={2}
                  >
                    In Time
                    {sortColumn === "outtime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("outtime")}
                    cursor="pointer"
                    rowSpan={2}
                  >
                    Out Time
                    {sortColumn === "outtime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    cursor="pointer"
                    rowSpan={2}
                  >
                    Total Time
                    {sortColumn === "outtime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="white"
                    colSpan={2}
                    textAlign="center"
                  >
                    Live Status
                    {/* <Flex>
                      <Box >
                        Guard
                      </Box>
                      <Spacer />
                      <Box >
                        Employee
                      </Box>
                    </Flex> */}
                  </Th>

                  
                  {/* <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("outtime")}
                    cursor="pointer"
                    rowSpan={2}
                  >
                    Weighment
                    {sortColumn === "outtime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th> */}
                  
                  
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("outtime")}
                    cursor="pointer"
                    rowSpan={2}
                  >
                   Last Status
                    {sortColumn === "outtime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                <Th rowSpan={2}></Th>

                </Tr>
                <Tr my=".8rem" pl="0px" color="gray.400">
                <Th
                    borderColor={borderColor}
                    color="gray.400"
                    cursor="pointer"
                  >
                    Guard
                  </Th>
                <Th
                    borderColor={borderColor}
                    color="gray.400"
                    cursor="pointer"
                  >
                    Employee
                  </Th>
                  
                </Tr>
              </Thead>
              <Tbody>
              
                {materialList ? (materialList.map((row, index, arr) => {
                  
                  console.log(row.logs);
                  return (
                    <CurrentTableRow
                    //  serialnumber={response.currentPage - 1 + index + 1}
                     serialnumber={totalItems - (((currentPage - 1) * limit) + index)}
                      date={row.date}
                      material={row.material}
                      materialBy={row.materialBy}
                      //  mobileNumber = {row.mobileNumber}
                      vehicleNumber={row.vehicleNumber}
                      materialShipmentType={row.materialShipmentType}
                      vendor={row.vendor}
                       department = {row.department?.name}
                       subdepartment = {row.subdepartment?.name}
                      purpose={row.purpose}
                      gateInTime={row.gateInTime}
                      gateOutTime={row.gateOutTime}
                      status={row.status}
                      //  currentStatus = {row.currentStatus}
                      type={row.type}
                      weighment={row.weighment}
                      inDateTime={row.inDateTime}
                      exitDateTime={row.exitDateTime}
                      totalTime={row.totalTime}
                      txn={row.txnNumber}
                      logs={row.logs}
                      //  gatepassNumber = {row.gatepassNumber}
                      //  reasonOfRejection = {row.reasonOfRejection}
                      //  txnNumber = {row.txnNumber}
                      isLast={index === arr.length - 1 ? true : false}
                      id={row._id}
                      key={row._id}
                      lastStatus={row.lastStatus}
                      logText={row.logText}
                      invoices={row.invoices}
                    />
                  );
                })) : (<h1></h1>)
                }
              </Tbody>
            </Table>}
          </div>
          {materialList.length === 0 ? (
            <></>
          ) : (
            <CurrentPagination
              totalPages={response.totalPages}
              currentPage={response.currentPage}
              perPage={response.perPage}
              sortBy={sortColumn}
              sortOrder={sortOrder}
              handlePageClick={(data) => {
                const selectedPage = data.selected + 1;
                dispatch(materialGetAll(`&type=IN&page=${selectedPage}`));
              }}
            />
          )}
        </CardBody>
      </Card>
    </Flex>
    </>
  );
};

export default CurrentTable;
