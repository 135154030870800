import React from "react";
import {
  Image,
  Flex,
  IconButton,
  Link,
  Td,
  Text,
  Tr,
  useColorModeValue,
  useDisclosure,
  ButtonGroup,
  Switch
} from "@chakra-ui/react";
import { ViewIcon } from "@chakra-ui/icons";
import { AiFillEdit } from "react-icons/ai";
import { useDispatch,useSelector } from "react-redux";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import { meetingroomsListDelete,meetingroomsListAddOrUpdate } from "../../actions/meetingroomsAction";
import { BsFillTrashFill } from "react-icons/bs";
import { Button } from "@chakra-ui/react/dist/chakra-ui-react.cjs";

const MeetingRoomsTableRow = (props) => {
  const {
    name,
    type,
    status,
    isLast,
    id,
    serialnumber,
    plant,
    approvalRequired,
    capacity,
    amenities
  } = props;

  const textColor = useColorModeValue("gray.500", "white");
  const titleColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const authDetail = useSelector((state) => state.auth.authDetail);

  const dispatch = useDispatch();

  const handleDelete = () => {
    const meetingRoom = {
      _id: id,
    };
    
    dispatch(meetingroomsListDelete(meetingRoom._id,plant._id));
  };
  const handleStatus = () => {
    const meetingRoom = {
      _id: id,
      name:name,
      status : !status
    };
    console.log(meetingRoom);
    var state = "edit";
    dispatch(meetingroomsListAddOrUpdate(meetingRoom,state,"","",plant._id));
  };

  return (
    <>
      <Tr>
        {/* Name */}
        <Td
          >
            <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
              <Flex direction="column">
                <Text
                  fontSize="md"
                  color={titleColor}
                  fontWeight="bold"
                  minWidth="100%"
                >
                  {serialnumber}
                </Text>
              </Flex>
            </Flex>
          </Td>
        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {/* Render Name */}
          <Text
            fontSize="md"
            color={titleColor}
            fontWeight="bold"
            minWidth="100%"
          >
            {name}
          </Text>
        </Td>

        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {/* Render Name */}
          <Text
            fontSize="md"
            color={titleColor}
            fontWeight="bold"
            minWidth="100%"
          >
            {type}
          </Text>
        </Td>
        <Td
         pl="0px"
         borderColor={borderColor}
         borderBottom={isLast ? "none" : "1px solid"}
         whiteSpace="nowrap"
         overflow="hidden"
         textOverflow="ellipsis"
        >
          {/* Render Name */}
          <Text
            fontSize="md"
            color={titleColor}
            fontWeight="bold"
            minWidth="100%"
          >
            {capacity}
          </Text>
        </Td>
        <Td
         pl="0px"
         borderColor={borderColor}
         borderBottom={isLast ? "none" : "1px solid"}
         whiteSpace="nowrap"
         overflow="hidden"
         textOverflow="ellipsis"
        >
          {/* Render Name */}
          <Text
            fontSize="md"
            color={titleColor}
            fontWeight="bold"
            minWidth="100%"
          >
            {amenities.map((row, index, arr) => (
                    <Text>{row.label}{index == amenities.length-1 ?'':','}</Text>
                  ))}
          </Text>
        </Td>
        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {/* Render Name */}
          <Text
            fontSize="md"
            color={titleColor}
            fontWeight="bold"
            minWidth="100%"
          >
            {approvalRequired}
          </Text>
        </Td>
        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
            <Switch
                sx={{
                'span.chakra-switch__track:not([data-checked])': {
                    backgroundColor: 'red'
                }
                }}
                colorScheme='green'
                id='isRequired'
                onChange={handleStatus}
                isChecked={status}
                marginLeft="1.5rem" 
                marginBottom="1rem" // Adjust the margin to create space between Switch and inactiveDate
            > {status?'Active':'Inactive'}</Switch>
        </Td>

        {/* Action buttons */}
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        {authDetail.role.allPermissions === false ? (
            <ButtonGroup variant="solid" size="sm" spacing={3}>
              {authDetail.role.permissions.map((permission) => {
                const { permissionName, moduleName } = permission;

                if (
                  permissionName === "VIEW" &&
                  moduleName === "MEETINGROOMS"
                ) {
                  return (
                    <Link href={`#/admin/meeting-room/view/${id}`}>
                    <IconButton
                        colorScheme="blue"
                        icon={<ViewIcon />}
                        aria-label="View"
                    />
                    </Link>
                  );
                }

                if (
                  permissionName === "EDIT" &&
                  moduleName === "MEETINGROOMS"
                ) {
                  return (
                    <Link href={`#/admin/meeting-room/update/${id}`}>
                    <IconButton
                        colorScheme="green"
                        icon={<AiFillEdit />}
                        aria-label="Edit"
                    />
                    </Link>
                  );
                }

                if (
                  permissionName === "DELETE" &&
                  moduleName === "MEETINGROOMS"
                ) {
                  return (
                    <IconButton
                    colorScheme="red"
                    variant="outline"
                    icon={<BsFillTrashFill />}
                    aria-label="Delete"
                    onClick={onOpen}
                    />
                  );
                }

                return null;
              })}
            </ButtonGroup>
          ) : (
            <ButtonGroup>
            {/* View Button */}
            <Link href={`#/admin/meeting-room/view/${id}`}>
              <IconButton
                colorScheme="blue"
                icon={<ViewIcon />}
                aria-label="View"
              />
            </Link>

            {/* Edit Button */}
            <Link href={`#/admin/meeting-room/update/${id}`}>
              <IconButton
                colorScheme="green"
                icon={<AiFillEdit />}
                aria-label="Edit"
              />
            </Link>

            {/* Delete Button */}
            <IconButton
              colorScheme="red"
              variant="outline"
              icon={<BsFillTrashFill />}
              aria-label="Delete"
              onClick={onOpen}
            />
          </ButtonGroup>
          )}

          
        </Td>

        {/* Delete Confirmation Dialog */}
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Delete Banner Position
              </AlertDialogHeader>

              <AlertDialogBody>
                Are you sure? You can't undo this action afterwards.
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  colorScheme="red"
                  onClick={() => {
                    handleDelete();
                    onClose();
                  }}
                  ml={3}
                >
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Tr>
    </>
  );
};

export default MeetingRoomsTableRow;
