
// import
import React, { Component } from 'react';
import Dashboard from "views/Dashboard/Dashboard.js";
import Tables from "views/Dashboard/Tables.js";
import AdminTable from "views/Dashboard/AdminTable.js";
import Billing from "views/Dashboard/Billing.js";
import Banner from "views/Dashboard/BannerTable.js";
import RTLPage from "views/RTL/RTLPage.js";
import Profile from "views/Dashboard/Profile.js";
import SignIn from "views/Pages/SignIn.js";
import SignUp from "views/Pages/SignUp.js";
import Form from "views/Dashboard/Form.js";
import UpdateForm from "views/Dashboard/UpdateForm.js";
import ViewForm from "views/Dashboard/ViewForm.js";
import AdminForm from "views/Dashboard/AdminForm.js";
import AdminViewForm from "views/Dashboard/AdminViewForm.js";
import AdminUpdateForm from "views/Dashboard/AdminUpdateForm.js";
import BannerUpdateForm from "views/Dashboard/BannerUpdateForm";
import Role from "views/Role/RoleTable";
import RoleForm from "views/Role/RoleForm";
import RoleView from "views/Role/RoleView";
import RoleUpdate from "views/Role/RoleUpdateForm";
import CheckpostView from "views/Checkpost/CheckpostViewForm";
import EmployeeTypeTable from 'views/EmployeeType/EmployeeTypeTable';
import EmployeeTypeForm from 'views/EmployeeType/EmployeeTypeForm';
import EmployeeTypeView from 'views/EmployeeType/EmployeeTypeView'
import EmployeeTypeUpdateForm from 'views/EmployeeType/EmployeeTypeUpdateForm';
import HighAlertTable from 'views/highAlert/HighAlertTable';
import HighAlertForm from 'views/highAlert/HighAlertForm';
import HighAlertView from 'views/highAlert/HighAlertView'
import EmergencyContactTable from 'views/EmergencyContact/EmergencyContactTable';
import EmergencyContactForm from 'views/EmergencyContact/EmergencyContactForm';
import emergencyContactView from 'views/EmergencyContact/EmergencyContactView';
import PatrollingRequestTable from 'views/PatrollingRequest/PatrollingRequestTable';
import PatrollingRequestForm from 'views/PatrollingRequest/PatrollingRequestForm';
// import PatrollingRouteUpdateForm from 'views/PatrollingRoute/PatrollingRouteUpdateForm';
import PatrollingRequestView from 'views/PatrollingRequest/PatrollingRequestView';
import PatrollingRequestUpdate from 'views/PatrollingRequest/PatrollingRequestUpdateTable';
import ScheduleHighAlertTable from 'views/ScheduleHighAlert/ScheduleHighAlertTable';
import PurposeTable from 'views/Purpose/PurposeTable';
import PurposeView from 'views/Purpose/PurposeView';
import PurposeForm from 'views/Purpose/PurposeForm';
import PurposeUpdateForm from 'views/Purpose/PurposeUpdateForm'
import {
  HomeIcon,
  StatsIcon,
  CreditIcon,
  PersonIcon,
  DocumentIcon,
  RocketIcon, HomeIcon2, HomeIcon3, HomeIcon4, HomeIcon5, HomeIcon6, HomeIcon7, CreditIcon22, CreditIcon23, CreditIcon24,
  SupportIcon, CreditIcon11, CreditIcon16, CreditIcon12, CreditIcon13, CreditIcon14, CreditIcon15,CreditIcon25,CreditIcon26,
  CreditIcon2,CreditIcon27,CreditIcon100,CreditIcon28,CreditIcon29, CreditIcon3, HomeIcon8, HomeIcon9, CreditIcon21, CreditIcon20,
   CreditIcon19, CreditIcon18, CreditIcon17, CreditIcon4, CreditIcon5, CreditIcon6, CreditIcon7, CreditIcon8, CreditIcon9, CreditIcon10, PhoneIcon,VisitorIcon,
   CheckPostIcon,DepartmentIcon , SubDepartmentIcon ,DesignationIcon ,EmployeeIcon ,GuardIcon , DriverIcon ,SosIcon , SosCategoryIcon , ReportIcon , CanteenInOutIcon,
   DeliveryInOutIcon, IntercartingInOutIcon, MaterialIcon, GatePassIcon ,LongStayAlertIcon , WrongEntryIcon ,OutPassIcon , WeighmentIcon ,BannerPositionIcon,
   HowItWorkIcon, HowItWorkCatIcon, GuardModuleIcon,
   BannerIcon
} from "components/Icons/Icons";

import BannerForm from 'views/Dashboard/BannerForm';
import BannerView from 'views/Dashboard/BannerView';
import BannerTable from 'views/Dashboard/BannerTable';

import CityTable from 'views/City/CityTable';
import CityForm from 'views/City/CityForm';
import CityView from './views/City/CityView'
import CityUpdateForm from 'views/City/CityUpdateForm';
import FactoryForm from 'views/Factory/FactoryForm'
import FactoryTable from 'views/Factory/FactoryTable';
import FactoryView from 'views/Factory/FactoryView';
import FactoryUpdateForm from 'views/Factory/FactoryUpdateForm';
import Checkpost from 'views/Checkpost/CheckpostTable';
import DepartmentTable from "views/Department/DepartmentTable"
import CheckpostUpdateForm from 'views/Checkpost/CheckpostUpdateForm';
import CheckpostForm from 'views/Checkpost/CheckpostForm';
import GroupTable from 'views/Group/GroupTable';
import GroupViewForm from 'views/Group/GroupViewForm';
import GroupForm from 'views/Group/GroupForm';
import GroupUpdateForm from 'views/Group/GroupUpdateForm';
import SubDepartmentTable from 'views/subDepartment/SubDepartmentTable';
import SubDepartmentForm from 'views/subDepartment/SubDepartmentForm';
import SubDepartmentView from 'views/subDepartment/SubDepartmentView';
import SubDepartmentUpdateForm from 'views/subDepartment/SubDepartmentUpdateForm';
import DepartmentForm from 'views/Department/DepartmentForm';
import DepartmentView from 'views/Department/DepartmentView';
import DepartmentUpdateForm from 'views/Department/departmentUpdateForm';
import ResetPassword from 'views/Pages/ResetPassword';

import PlantAdminForm from 'views/Plant_admin/PlantAdminForm';
import PlantAdminView from 'views/Plant_admin/PlantAdminView';
import PlantAdminTable from 'views/Plant_admin/PlantAdminTable';
import PlantAdminUpdate from 'views/Plant_admin/PlantAdminUpdate';

import EmployeeForm from 'views/Employee/EmployeeForm';
import EmployeeTable from 'views/Employee/EmployeeTable';
import EmployeeView from 'views/Employee/EmployeeView';
import EmployeeUpdateForm from 'views/Employee/EmployeeUpdateForm';
import EmployeeUploadFileForm from 'views/Employee/EmployeeUploadFileForm';
import EmployeeCreatePasscode from 'views/Employee/EmployeeUploadFileForm'

import GaurdForm from 'views/Gaurd/GaurdForm';
import GaurdTable from 'views/Gaurd/GaurdTable';
import GuardUpdateForm from 'views/Gaurd/GuardUpdateForm';
import GuardView from 'views/Gaurd/GaurdView';

import HowItWorksForm from 'views/How It Works/HowItWorksForm';
import HowItWorksTable from 'views/How It Works/HowItWorksTable';
import HowItWorksTableRow from 'views/How It Works/HowItWorksTableRow';
import HowItWorksUpdate from 'views/How It Works/HowItWorksUpdate';
import HowItWorksView from 'views/How It Works/HowItWorksView';

import SosTable from 'views/Sos/SosTable';
import SosUpdateForm from 'views/Sos/SosUpdateForm';
import SosView from 'views/Sos/SosView';
import SosForm from 'views/Sos/SosForm';
import SosLatestTable from 'views/Sos/SosTodayTable';

import SosCategoryTable from 'views/SosCategory/SosCategoryTable';
import SosCategoryUpdateForm from 'views/SosCategory/SosCategoryUpdateForm';
import SosCategoryView from 'views/SosCategory/SosCategoryView';
import SosCategoryForm from 'views/SosCategory/SosCategoryForm';

import IamSafeTable from 'views/I Am Safe/IamSafeTable';
import IamSafeView from 'views/I Am Safe/IamSafeView';
import IamSafeTodayTable from 'views/I Am Safe/IamSafeTodayTable';

import BusTable from 'views/Bus/BusTable';
import BusUpdateForm from 'views/Bus/BusUpdateForm';
import BusViewForm from 'views/Bus/BusViewForm';
import BusForm from 'views/Bus/BusForm';

import GaurdAttendenceTable from 'views/GuardAttendence/GuardAttendenceTable';
import EmployeeAttendenceTable from 'views/EmployeeAttendence/EmployeeAttendenceTable';
import EmployeeAttendenceOutTable from 'views/EmployeeAttendence/EmployeeAttendenceOutTable';
import EmployeeAttendenceInTable from 'views/EmployeeAttendence/EmployeeAttendenceInTable';

import DriverForm from 'views/Driver/DriverForm';
import DriverTable from 'views/Driver/DriverTable';
import DriverView from 'views/Driver/DriverView';
import DriverUpdateForm from 'views/Driver/DriverUpdateForm';
import DriverUploadFile from 'views/Driver/DriverUploadFile';

import TransportInOutTable from 'views/Transport_in_out_list/TransportInOutTable';
import TransportInOutView from 'views/Transport_in_out_list/TransportInOutView';

import IntercartingInOutTable from 'views/IntercartingInOut/IntercartingInOutTable';



import GaurdAttendenceView from 'views/GuardAttendence/GuardAttendenceView';
import EmployeeAttendenceView from 'views/EmployeeAttendence/EmployeeAttendenceView';

import ParkingDepartmentForm from 'views/ParkingDepartment/ParkingDepartmentForm';
import ParkingDepartmentTable from 'views/ParkingDepartment/ParkingDepartmentTable';
import ParkingDepartmentUpdateForm from 'views/ParkingDepartment/ParkingDepartmentUpdateTable';
import ParkingDepartmentView from 'views/ParkingDepartment/ParkingDepartmentView';

import ParkingForm from 'views/Parking/ParkingForm';
import ParkingTable from 'views/Parking/ParkingTable';
import ParkingView from 'views/Parking/ParkingView';
import { parkingAddOrUpdate } from 'actions/parkingAction';
import ParkingUpdateForm from 'views/Parking/ParkingUpdateForm';

import DelivaryTable from 'views/Delivary/DelivaryTable';
import DelivaryForm from 'views/Delivary/DelivaryForm';
import DelivaryUpdateForm from 'views/Delivary/DelivaryUpdateForm';
import DelivaryView from 'views/Delivary/DelivaryView';

import CanteenForm from 'views/Canteen/CanteenForm';
import CanteenTable from 'views/Canteen/CanteenTable';

import CanteenUpdateForm from 'views/Canteen/CanteenUpdateForm';
import CanteenInOutTable from 'views/CanteenInOut/CanteenInOutTable';
import CanteenInOutView from 'views/CanteenInOut/CanteenInOutView';

import DelivaryInOutView from 'views/DelivaryInOut/DelivaryInOutView';
import DelivaryInOutTable from 'views/DelivaryInOut/DelivaryInOutTable';
import DeliveryTabs from 'views/DelivaryInOut/DeliveryTabs';

import GuardmoduleTable from 'views/Guard Modules/GuardModuleTable';
import GuardmoduleView from 'views/Guard Modules/GuardModuleView';
import GuardmoduleUpdateForm from 'views/Guard Modules/GuardModuleUpdateForm';
import GuardmoduleForm from 'views/Guard Modules/GuardModuleForm';

import ReportIncedentModuleForm from 'views/Report Incedent Module/ReportIncedentModuleForm';
import ReportIncedentModuleView from 'views/Report Incedent Module/ReportIncedentModuleView';
import ReportIncedentModuleTable from 'views/Report Incedent Module/ReportIncedentModuleTable';
import ReportIncedentModuleUpdateForm from 'views/Report Incedent Module/ReportIncedentModuleUpdateForm';

import VisitorInOutTable from 'views/VisitorInOut/VisitorInOutTable';
import VisitorInOutView from 'views/VisitorInOut/VisitorInOutView';

import ReportIncedentUpdateForm from 'views/Report Incedent/RedportIncedentUpdateForm';
import ReportIncedentTable from 'views/Report Incedent/ReportIncedentTable';
import ReportIncedentView from 'views/Report Incedent/ReportIncedentView';
import ReportIncedentForm from 'views/Report Incedent/ReportIncedentForm';

import BannerPositionTable from 'views/Bannerposition/BannerPositionTable';
import BannerPositionForm from 'views/Bannerposition/BannerPositionForm';

import DevicesTable from 'views/Devices/DevicesTable';
import DevicesForm from 'views/Devices/DevicesForm';
import DevicesUpdateForm from 'views/Devices/DevicesUpdateForm';
import DeviceView from 'views/Devices/DevicesView';


import DesignationTable from 'views/Designation/DesignationTable';
import DesignationsForm from 'views/Designation/DesignationForm';
import DesignationUpdateForm from 'views/Designation/DesignationUpdateForm';
import DesignationView from 'views/Designation/DesignationView';

import EmployeemoduleTable from 'views/Employee Module/EmployeeModuleTable';
import EmployeemoduleForm from 'views/Employee Module/EmployeeModuleForm';
import EmployeemoduleUpdateForm from 'views/Employee Module/EmployeeModuleUpdateForm'
import EmployeemoduleView from 'views/Employee Module/EmployeeModuleView';

import PatrollingRouteTable from 'views/PatrollingRoute/PatrollingRouteTable';
import PatrollingRouteForm from 'views/PatrollingRoute/PatrollingRouteForm';
import PatrollingRouteUpdateForm from 'views/PatrollingRoute/PatrollingRouteUpdateForm';
import PatrollingRouteView from 'views/PatrollingRoute/PatrollingRouteView';

import FloorTable from 'views/Floor/FloorTable';
import FloorForm from 'views/Floor/FloorForm';
import FloorView from 'views/Floor/FloorView';
import FloorUpdateForm from 'views/Floor/FloorUpdateForm';

import IntercartingView from 'views/IntercartingInOut/IntercartingView';

import BusRouteTable from 'views/BusRoute/BusRouteTable';
import BusRouteForm from 'views/BusRoute/BusRouteForm';
import BusRouteView from 'views/BusRoute/BusRouteView';
import BusRouteUpdateForm from 'views/BusRoute/BusRouteUpdateForm';

import PatrollingLogsForm from 'views/PatrollingLogs/PatrollingLogsForm';
import PatrollingLogsTable from 'views/PatrollingLogs/PatrollingLogsTable';
import PatrollingLogsView from 'views/PatrollingLogs/PatrollingLogsView';

import MaterialTable from 'views/Material/MaterialTable';
import IncomingMaterialTable from 'views/Material/IncomingMaterialTable';
import MaterialView from 'views/Material/MaterialView';

import OutPassTable from 'views/OutPass/OutPassTable';
import OutPassView from 'views/OutPass/OutPassView';
import OutPassTodayTable from 'views/OutPass/OutPassTodayTable';

import materialGatePassTable from 'views/Material Gate Pass/MaterialGatePassTable';
import NrgpmaterialGatePassTable from 'views/Material Gate Pass/NrgpMaterialGatePassTable';
import RejectedmaterialGatePassTable from 'views/Material Gate Pass/RejectedMaterialGatePassTable';
import materialGatePassView from 'views/Material Gate Pass/MaterialGatePassView';
// import raisedGatePassTable from "views/"
import raisedGatePassTable from 'views/RaisedGatePass/RaisedGatePassTable';
import raisedNrgpTable from 'views/RaisedGatePass/RaisedNrgpTable';
import raisedGatePassView from 'views/RaisedGatePass/RaisedGatePassView';

import WeighmentBridgeTable from 'views/WeighmentBridge/WeighmentBridgeTable';
import WeighmentBridgeView from 'views/WeighmentBridge/WeighmentBridgeView';

import BannerPositionUpdateForm from 'views/Bannerposition/BannerPositionUpdate';
import BannerPositionView from 'views/Bannerposition/BannerPositionView';

import MaterialTypeInTable from 'views/Material/materialTypeInTable';
import VisitorHeadCountTable from 'views/VisitorInOut/VisitorHeadCountTable';
import VisitorTodayVisitor from 'views/VisitorInOut/VisitorVisitTodayTable';
import EmployeeAttendenceHeadCountTable from 'views/EmployeeAttendence/EmployeeAttendenceHeadCountTable';
import EmployeeAttendanceHistoryTable from 'views/Employee/EmployeeAttendanceHistoryTable';
import GuardAttendanceHistoryTable from 'views/Gaurd/GaurdAttendanceHistoryTable'
import DriverAttendanceHistoryTable from 'views/Driver/DriverAttendanceHistoryTable'
import CanteenHeadCountTable from 'views/CanteenInOut/CanteenHeadCountTable';
import CanteenTodayTable from 'views/CanteenInOut/CanteenTodayTable';
import DelivaryHeadCountTable from 'views/DelivaryInOut/DelivaryHeadCountTable';
import DelivaryTodayTable from 'views/DelivaryInOut/DelivaryTodayTable';

import LongAlertTable from 'views/Long Stay Alert/LongAlertTable';
import LongAlertView from 'views/Long Stay Alert/LongAlertView';

import WrongEntryView from 'views/Wrong Entry/WrongEntryView';
import WrongEntryTable from 'views/Wrong Entry/WrongEntryTable';

// import HowItWorkCategoryForm from 'views/How It Works Category/HowItWorkCategoryForm';
import HowItWorkCategoryTable from 'views/How It Works Category/HowItWorkCategoryTable';
import HowItWorkCategoryTableRow from 'views/How It Works Category/HowItWorkCategoryTableRow';
import HowItWorkCategoryUpdate from 'views/How It Works Category/HowItWorkCategoryUpdate';
import HowItWorkCategoryView from 'views/How It Works Category/HowItWorkCategoryView';
import guardBulkUpload from 'views/Gaurd/GaurdBulkUpload';

import ScheduleHighAlertUpdateForm from 'views/ScheduleHighAlert/ScheduleHighAlertUpdate';
import scheduleHighAlertView from 'views/ScheduleHighAlert/ScheduleHighAlertView';
import scheduleHighAlertForm from 'views/ScheduleHighAlert/ScheduleHighAlertForm';

import CallSetting from "views/CallSetting/CallSettingTable"
import CallSettingUpdateForm from "views/CallSetting/CallSettingUpdateForm"
import ShiftTable from "views/Shift/ShiftTable"
import ShiftForm from "views/Shift/ShiftForm"
import ShiftView from "views/Shift/ShiftView"
import ShiftUpdateForm from "views/Shift/ShiftUpdateForm"

// Biometric Settings
import BiometricSettingsTable from "views/BiometricSettings/BiometricSettingsTable";
import BiometricSettingsForm from "views/BiometricSettings/BiometricSettingsForm";
import BiometricSettingsUpdate from "views/BiometricSettings/BiometricSettingsUpdate";
import BiometricSettingsView from "views/BiometricSettings/BiometricSettingsView";
import ManagerCreate from "views/Manager/ManagerForm";
import DevicesConnectionTable from "views/Devices/DevicesConnection/DevicesConnectionTable";
import DevicesConnectionLogsTable from "views/Devices/DevicesConnectionLogs/DevicesConnectionLogsTable";
import VisitorPreapprovalTable from "views/VisitorInOut/Preapproval/PreapprovalTable";
import VisitorTabs from "views/VisitorInOut/VisitorTabs";
import visitorPreapprovalView from "views/VisitorInOut/Preapproval/PreapprovalView";
import MaterialTabs from 'views/Material/MaterialTabs';
import PatrollingTabs from 'views/PatrollingLogs/PatrollingTabs';
import PatrollingByFloorView from 'views/PatrollingLogs/PatrollingByFloor/PatrollingByFloorView';
import GroupCheckpostForm from 'views/Checkpost/GroupCheckpost/GroupCheckpostForm';
import GroupCheckpostUpdateForm from 'views/Checkpost/GroupCheckpost/GroupCheckpostUpdateForm';
import GroupDevicesForm from 'views/Devices/GroupDevice/GroupDevicesForm';
import GroupDepartmentForm from 'views/Department/GroupDepartment/GroupDepartmentForm';
import GroupSubDepartmentForm from 'views/subDepartment/GroupSubDepartment/GroupSubDepartmentForm';
import GroupDesignationsForm from 'views/Designation/GroupDesignation/GroupDesignationForm';
import GroupPatrollingRouteForm from 'views/PatrollingRoute/GroupPatrollingRoute/GroupPatrollingRouteForm';
import GroupFloorForm from 'views/Floor/GroupFloor/GroupFloorForm';
import GroupSignIn from 'views/Pages/GroupSignIn';
import CorporateTable from 'views/Corporate/CorporateTable';
import CorporateForm from 'views/Corporate/CorporateForm';
import CorporateView from 'views/Corporate/CorporateView';
import CorporateUpdateForm from 'views/Corporate/CorporateUpdateForm';
import Weblink from 'views/Pages/Weblink';
import InspectionListTable from 'views/InspectionList/InspectionListTable';
import InspectionListForm from 'views/InspectionList/InspectionListForm';
import InspectionListUpdate from 'views/InspectionList/InspectionListUpdate';
import InspectionListView from 'views/InspectionList/InspectionListView';
import InspectionQrTable from 'views/InspectionQr/InspectionQrTable';
import InspectionQrForm from 'views/InspectionQr/InspectionQrForm';
import InspectionQrView from 'views/InspectionQr/InspectionQrView';
import InspectionQrUpdate from 'views/InspectionQr/InspectionQrUpdate';
import InspectionTab from 'views/InspectionSummery/InspectionTab';
import HeadCountTabs from 'views/Headcount/HeadcountTabs';
import MeetingRoomsFrom from 'views/MeetingRooms/MeetingRoomForm';
import MeetingRoomsView from 'views/MeetingRooms/MeetingRoomsViewForm';
import MeetingRoomsUpdateFrom from 'views/MeetingRooms/MeetingRoomsUpdateForm';
import MeetingRoomsTable from 'views/MeetingRooms/MeetingRoomsTable';
import MeetingRoomBookingsTabs from 'views/MeetingRoomBookings/MeetingRoomBookingsTabs';
import MultiAuthMatrixForm from 'views/MultiAuthMatrix/MultiAuthMatrixForm';
import MultiAuthMatrixView from 'views/MultiAuthMatrix/MultiAuthMatrixView';
import MultiAuthMatrixUpdateFrom from 'views/MultiAuthMatrix/MultiAuthMatrixUpdate';
import PlantAdminMultiAuthMatrixTable from 'views/MultiAuthMatrix/PlantAdmin/PlantAdminMultiAuthMatrixTable';
import DepartmentFormV2 from 'views/Department/DepartmentFormV2';
import GatepassTabs from 'views/Gatepass/GatepassTabs';
import RgpManagementTabs from 'views/RgpManagement/RgpManagementTabs';

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    base: "Dashboard",
    baseUrl:"/admin/dashboard ",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon9 color='inherit' />,
    component: Dashboard,
    layout: "/admin",
    sidebar: true,
    plantAdmin: true,
    module: 'DASHBOARD'
  },
  {
    path: "/role",
    name: "Role ",
    base: "Role",
    baseUrl:"/admin/role ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon12 color='inherit' />,
    component: Role,
    layout: "/admin",
    sidebar: true,
    plantAdmin : false,
    module: 'ROLE'
  },
  {
    path: "/city",
    name: "City",
    base: "Citytable",
    baseUrl:"/admin/city",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon13 color='inherit' />,
    component: CityTable,
    layout: "/admin",
    sidebar: true,
    plantAdmin : false,
    module: 'CITY'
  },
  {
    path: "/admin-tables",
    name: "City Admin",
    base: "CityAdmin",
    baseUrl:"/admin/admin-tables",
    rtlName: "لوحة القيادة",
    icon: <StatsIcon color='inherit' />,
    component: AdminTable,
    layout: "/admin",
    sidebar: true,
    plantAdmin : false,
    module: 'CITY ADMIN'
  },
  {
    path: "/banners",
    name: "Banner ",
    base: "Banner",
    baseUrl:"/admin/banners",
    rtlName: "لوحة القيادة",
    icon: <BannerIcon color='inherit' />,
    component: BannerTable,
    layout: "/admin",
    sidebar: true,
    plantAdmin : false,
    module: 'BANNER'
  },
  {
    path: "/banner-position",
    name: "Banner Position",
    base: "BannerPosition",
    baseUrl:"/admin/banner-position ",
    rtlName: "لوحة القيادة",
    icon: <BannerPositionIcon color='inherit' />,
    component: BannerPositionTable,
    layout: "/admin",
    sidebar: true,
    plantAdmin : false,
    module: 'BANNER POSITION'
  },
  {
    path: "/employeemodule-table",
    name: "Employee Module",
    base: "Employee",
    baseUrl:"/admin/employeemodule-table ",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon2 color='inherit' />,
    component: EmployeemoduleTable,
    layout: "/admin",
    sidebar: true,
    plantAdmin : false,
    module: 'EMPLOYEEMODULE'
  },
  {
    path: "/gaurdmodule-table",
    name: "Guard Module",
    base: "Guardmod",
    baseUrl:"/admin/gaurdmodule-table ",
    rtlName: "لوحة القيادة",
    icon: <GuardModuleIcon color='inherit' />,
    component: GuardmoduleTable,
    layout: "/admin",
    sidebar: true,
    plantAdmin : false,
    module: 'GUARDMODULE'
  },

  {
    path: "/howItWork-table",
    name: "How-It-Work",
    base: "How It Work",
    baseUrl:"/admin/howItWork-table ",
    rtlName: "لوحة القيادة",
    icon: <HowItWorkIcon color='inherit' />,
    component: HowItWorksTable,
    layout: "/admin",
    sidebar: true,
    plantAdmin : false,
    module: 'HOWITSWORK'
  },
  {
    path: "/howItWorkCategoryform-table",
    name: "How-It-Work Category",
    base: "HowItWork",
    baseUrl:"/admin/howItWorkCategoryform-table ",
    rtlName: "لوحة القيادة",
    icon: <HowItWorkCatIcon color='inherit' />,
    component: HowItWorkCategoryTable,
    layout: "/admin",
    sidebar: true,
    plantAdmin : false,
    module: 'HOWITSWORKCATEGORY'
  },

//plantadmin
{
  path: "/checkpost",
  name: "Checkpost ",
  base: "Checkpost",
  baseUrl:"/admin/checkpost ",
  rtlName: "لوحة القيادة",
  icon: <CheckPostIcon color='inherit' />,
  component: Checkpost,
  layout: "/admin",
  sidebar: true,
  plantAdmin : false,
  module: 'CHECKPOST'
},
{
  path: "/DevicesTable",
  name: "Devices ",
  base: "Devices",
  baseUrl:"/admin/DevicesTable ",
  rtlName: "لوحة القيادة",
  icon: <CreditIcon15 color='inherit' />,
  component: DevicesTable,
  layout: "/admin",
  sidebar: true,
  plantAdmin : false,
  module: 'DEVICES',
},
{
  path: "/callsetting",
  name: "Call-Setting ",
  base: "callSetting",
  baseUrl:"/admin/call-setting ",
  rtlName: "لوحة القيادة",
  icon: <PhoneIcon color='inherit' />,
  component: CallSetting,
  layout: "/admin",
  sidebar: true,
  plantAdmin : true,
  module: 'CALLSETTING',
},
{
  path: "/device-connection",
  name: "Device Connection",
  base: "callSetting",
  baseUrl:"/admin/Devices ",
  rtlName: "لوحة القيادة",
  icon: <PhoneIcon color='inherit' />,
  component: DevicesConnectionTable,
  layout: "/admin",
  sidebar: true,
  plantAdmin : true,
  module: 'ALLREPORTS',
},
{
  path: "/device-connection-logs/:id",
  name: "Device Connection Logs",
  base: "callSetting",
  baseUrl:"/admin/Devices ",
  rtlName: "لوحة القيادة",
  icon: <PhoneIcon color='inherit' />,
  component: DevicesConnectionLogsTable,
  layout: "/admin",
  sidebar: false,
  plantAdmin : false,
  module: 'ALLREPORTS',
},
{
  path: "/department",
  name: "Department ",
  base: "Department",
  baseUrl:"/admin/department ",
  rtlName: "لوحة القيادة",
  icon: <DepartmentIcon color='inherit' />,
  component: DepartmentTable,
  layout: "/admin",
  sidebar: true,
  plantAdmin : false,
  module: 'DEPARTMENT'
},
{
  path: "/subdepartment",
  name: "Sub Department ",
  base: "Sub Department",
  baseUrl:"/admin/subdepartment ",
  rtlName: "لوحة القيادة",
  icon: <SubDepartmentIcon color='inherit' />,
  component: SubDepartmentTable,
  layout: "/admin",
  sidebar: true,
  plantAdmin : false,
  module: 'SUBDEPARTMENT'
},
{
  path: "/DesignationTable",
  name: "Designation ",
  base: "Designation",
  baseUrl:"/admin/DesignationTable ",
  rtlName: "لوحة القيادة",
  icon: <DesignationIcon color='inherit' />,
  component: DesignationTable,
  layout: "/admin",
  sidebar: true,
  plantAdmin : false,
  module: 'DESIGNATION',
},
{
  path: "/employee-table",
  name: "Employee ",
  base: "Employee",
  baseUrl:"/admin/employee-table ",
  rtlName: "لوحة القيادة",
  icon: <EmployeeIcon color='inherit' />,
  component: EmployeeTable,
  layout: "/admin",
  sidebar: true,
  plantAdmin : false,
  module: 'EMPLOYEE'
},
{
  path: "/gaurd-table",
  name: "Security Guard",
  base: "Gaurd",
  baseUrl:"/admin/gaurd-table ",
  rtlName: "لوحة القيادة",
  icon: <GuardIcon color='inherit' />,
  component: GaurdTable,
  layout: "/admin",
  sidebar: true,
  plantAdmin : false,
  module: 'GUARD'
},
{
  path: "/driver-table",
  name: "Driver ",
  base: "Driver",
  baseUrl:"/admin/driver-table ",
  rtlName: "لوحة القيادة",
  icon: <DriverIcon color='inherit' />,
  component: DriverTable,
  layout: "/admin",
  sidebar: true,
  plantAdmin : false,
  module: 'DRIVER'
},
{
  path: "/bus-table",
  name: "Transport Vehicle",
  base: "Busbase",
  baseUrl:"/admin/bus-table ",
  rtlName: "لوحة القيادة",
  icon: <HomeIcon3 color='inherit' />,
  component: BusTable,
  layout: "/admin",
  sidebar: true,
  plantAdmin : false,
  module: 'BUS'
},
{
  path: "/parking",
  name: "Parking ",
  base: "Parking",
  baseUrl:"/admin/parking ",
  rtlName: "لوحة القيادة",
  icon: <CreditIcon8 color='inherit' />,
  component: ParkingTable,
  layout: "/admin",
  sidebar: true,
  plantAdmin : false,
  module: 'PARKING'
},
{
  path: "/floor",
  name: "Patrolling Checkpoints",
  base: "Floor",
  baseUrl:"/admin/floor ",
  rtlName: "لوحة القيادة",
  icon: <CreditIcon24 color='inherit' />,
  component: FloorTable,
  layout: "/admin",
  sidebar: true,
  plantAdmin : false,
  module: 'FLOOR'
},
{
  path: "/PatrollingRequest-table",
  name: "Patrolling Scheduling",
  base: "Patrolling Request",
  baseUrl:"/admin/patrolling-request-table ",
  rtlName: "لوحة القيادة",
  icon: <CreditIcon28 color='inherit' />,
  component: PatrollingRequestTable,
  layout: "/admin",
  sidebar: true,
  plantAdmin : false,
  module: 'PATROLLINGREQUEST'
},
{
  path: "/sosCategory-table",
  name: "SOS Category",
  base: "Sos Category",
  baseUrl:"/admin/sosCategory-table ",
  rtlName: "لوحة القيادة",
  icon: <SosCategoryIcon color='inherit' />,
  component: SosCategoryTable,
  layout: "/admin",
  sidebar: true,
  plantAdmin : false,
  module: 'SOSCATEGORY'
},


  {
    path: "/tables",
    name: "User",
    base: "User",
    baseUrl:"/admin/tables ",
    rtlName: "لوحة القيادة",
    icon: <StatsIcon color='inherit' />,
    component: Tables,
    layout: "/admin",
    sidebar: true,
    plantAdmin : false,
    module: 'USER'
  },

  {
    path: "/billing",
    name: "Billing",
    base: "Billing",
    baseUrl:"/admin/billing ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: Billing,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'BILLING'
  },

  
  {
    path: "/bannerposition-form",
    name: "Banner Position Form",
    base: "BannerPosition",
    baseUrl:"/admin/banner-position ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: BannerPositionForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'BANNERPOSITION'
  },
  {
    path: "/bannerposition-update-form/:id",
    name: "Banner Update Form",
    base: "BannerPosition",
    baseUrl:"/admin/banner-position ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: BannerPositionUpdateForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'BANNERPOSITION'
  },
  {
    path: "/bannerposition-view/:id",
    name: "Banner View Form",
    base: "BannerPosition",
    baseUrl:"/admin/banner-position ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: BannerPositionView,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'BANNERPOSITION'
  },

  {
    path: "/role-form",
    name: "Role Form",
    base: "Role",
    baseUrl:"/admin/role ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: RoleForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'ROLE'
  },
  {
    path: "/role-view/:id",
    name: "Role Form",
    base: "Role",
    baseUrl:"/admin/role ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: RoleView,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'ROLE'
  },
  {
    path: "/role-update/:id",
    name: "Role Form",
    base: "Role",
    baseUrl:"/admin/role ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: RoleUpdate,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'ROLE'
  },

  {
    path: "/banners-form",
    name: "Banner Form",
    base: "Banner",
    baseUrl:"/admin/banners ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: BannerForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'BANNER',
  },
  {
    path: "/banner-view/:id",
    name: "Banner View",
    base: "Banner",
    baseUrl:"/admin/banners ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: BannerView,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'BANNER'
  },
  {
    path: "/banner-update/form/:id",
    name: "Banner Update Form",
    base: "Banner",
    baseUrl: "/admin/banners ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: BannerUpdateForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'BANNER'
  },
  // {
  //   path: "/rtl-support-page",
  //   name: "RTL",
  //   rtlName: "آرتيإل",
  //   icon: <SupportIcon color='inherit' />,
  //   component: RTLPage,
  //   layout: "/rtl",
  //   sidebar: true,
  //   module: 'RTL'
  // },
  {
    path: "/form",
    name: "Form",
    base: "Form",
    baseUrl:"/admin/form",
    rtlName: "لوحة القيادة",
    icon: <RocketIcon color='inherit' />,
    component: Form,
    layout: "/admin",
    sidebar: true,
    plantAdmin : false,
    module: 'USER'
  },
  {
    path: "/update/form/:id",
    name: "Form Update",
    base: "Form",
    baseUrl:"/admin/form ",
    rtlName: "لوحة القيادة",
    icon: <RocketIcon color='inherit' />,
    component: UpdateForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'USER'
  },
  {
    path: "/view/form/:id",
    name: "View Form",
    base: "Form",
    baseUrl:"/admin/form ",
    rtlName: "لوحة القيادة",
    icon: <RocketIcon color='inherit' />,
    component: ViewForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'USER'
  },
  {
    path: "/admin-form",
    name: "Admin Form",
    base: "Form",
    baseUrl:"/admin/admin-tables",
    rtlName: "لوحة القيادة",
    icon: <RocketIcon color='inherit' />,
    component: AdminForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'ADMIN'
  },
  {
    path: "/admin-view/form/:id",
    name: "Admin View Form",
    base: "Form",
    baseUrl:"/admin/admin-tables",
    rtlName: "لوحة القيادة",
    icon: <RocketIcon color='inherit' />,
    component: AdminViewForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'ADMIN'
  },
  {
    path: "/admin-update/form/:id",
    name: "Admin Update Form",
    base: "Form",
    baseUrl:"/admin/admin-tables",
    rtlName: "لوحة القيادة",
    icon: <RocketIcon color='inherit' />,
    component: AdminUpdateForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'ADMIN'
  },


  {
    path: "/delivary-partner-Table",
    name: "Delivary Partners",
    base: "Delivery",
    baseUrl:"/admin/delivary-Table ",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon6 color='inherit' />,
    component: DelivaryTable,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'DELIVERY PARTNER',
  },
  {
    path: "/city-form",
    name: "CityForm",
    base: "Citytable",
    baseUrl:"/admin/city",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: CityForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'CITY'
  },
  {
    path: "/city-view/:id",
    name: "City-View",
    base: "Citytable",
    baseUrl:"/admin/city",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: CityView,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'CITY'
  },
  {
    path: "/city-update/form/:id",
    name: "Update",
    base: "Citytable",
    baseUrl:"/admin/city",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: CityUpdateForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'CITY'
  },

  {
    path: "/factory-table",
    name: "Factory",
    base: "Factory",
    baseUrl:"/admin/factory-table ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: FactoryTable,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'FACTORY'
  },
  {
    path: "/factory-form",
    name: "Factoryform",
    base: "Factory",
    baseUrl:"/admin/factory-table ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: FactoryForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'FACTORY'
  },
  {
    path: "/factory-view/:id",
    name: "FactoryView",
    base: "Factory",
    baseUrl:"/admin/factory-table ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: FactoryView,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'FACTORY'
  },
  {
    path: "/factory-update/form/:id",
    name: "FactoryUpdateForm",
    base: "Factory",
    baseUrl:"/admin/factory-table ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: FactoryUpdateForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'FACTORY'
  },


  {
    path: "/plant-admin-table",
    name: "Plant Admin ",
    base: "Plant Admin",
    baseUrl:"/admin/plant-admin-table ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon2 color='inherit' />,
    component: PlantAdminTable,
    layout: "/admin",
    sidebar: true,
    plantAdmin : false,
    module: 'ADMIN PLANT'
  },
  {
    path: "/plant-admin-form",
    name: "Plant Admin form",
    base: "Plant Admin",
    baseUrl:"/admin/plant-admin-table ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon3 color='inherit' />,
    component: PlantAdminForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'ADMIN PLANT'
  },
  {
    path: "/plant-admin-update-form/:id",
    name: "Plant Admin Update form",
    base: " Plant Admin",
    baseUrl:"/admin/plant-admin-table ",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: PlantAdminUpdate,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'ADMIN PLANT'
  },
  {
    path: "/corporate-table",
    name: "Corporate",
    base: "Corporate",
    baseUrl:"/admin/corporate-table ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon2 color='inherit' />,
    component: CorporateTable,
    layout: "/admin",
    sidebar: true,
    plantAdmin : false,
    module: 'CORPORATE'
  },
  {
    path: "/corprate-form",
    name: "Corporate Form",
    base: "Corporate",
    baseUrl:"/admin/corporate-table ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon3 color='inherit' />,
    component: CorporateForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'CORPORATE'
  },
  {
    path: "/corporate-view/:id",
    name: "Corporate View",
    base: "Corporate",
    baseUrl:"/admin/corporate-table ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: CorporateView,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'CORPORATE'
  },
  {
    path: "/corporate-update-form/:id",
    name: "Corporate Update Form",
    base: " Corporate",
    baseUrl:"/admin/corporate-table ",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: CorporateUpdateForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'CORPORATE'
  },

  {
    path: "/checkpost-view/form/:id",
    name: "checkPost View Form",
    base: "Checkpost",
    baseUrl:"/admin/checkpost ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: CheckpostView,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'CHECKPOST'
  },
  {
    path: "/checkpost-update/form/:id",
    name: "CheckPost Update Form",
    base: "Checkpost",
    baseUrl:"/admin/checkpost ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: CheckpostUpdateForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'CHECKPOST'
  },
  {
    path: "/group-checkpost-update/form/:id",
    name: "CheckPost Update Form",
    base: "Checkpost",
    baseUrl:"/admin/checkpost ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: GroupCheckpostUpdateForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'CHECKPOST'
  },
  {
    path: "/checkpost-form/:id",
    name: "CheckPost Form",
    base: "Checkpost",
    baseUrl:"/admin/checkpost ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: CheckpostForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'CHECKPOST'
  },
  {
    path: "/group-checkpost-form/:id",
    name: "CheckPost Form",
    base: "Checkpost",
    baseUrl:"/admin/checkpost ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: GroupCheckpostForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'CHECKPOST'
  },
  {
    path: "/group",
    name: "Group ",
    base: "Group",
    baseUrl:"/admin/group ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon5 color='inherit' />,
    component: GroupTable,
    layout: "/admin",
    sidebar: true,
    plantAdmin : false,
    module: 'GROUP'
  },
  {
    path: "/group-view/form/:id",
    name: "Group View",
    base: "Group",
    baseUrl:"/admin/group",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: GroupViewForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'GROUP'
  },
  {
    path: "/group-form",
    name: "Group Form",
    base: "Group",
    baseUrl:"/admin/group ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: GroupForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'GROUP'
  },
  {
    path: "/group-update/form/:id",
    name: "Group Update",
    base: "Group",
    baseUrl:"/admin/group ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: GroupUpdateForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'GROUP'
  },

  {
    path: "/department-form/:id",
    name: "form",
    base: "Department",
    baseUrl:"/admin/department ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: DepartmentForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'DEPARTMENT'
  },
  {
    path: "/department-form-v2/:id",
    name: "form",
    base: "Department",
    baseUrl:"/admin/department ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: DepartmentFormV2,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'DEPARTMENT'
  },
  {
    path: "/group-department-form/:id",
    name: "form",
    base: "Department",
    baseUrl:"/admin/department ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: GroupDepartmentForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'DEPARTMENT'
  },
  {
    path: "/department-update/:id",
    name: "Dupdate",
    base: "Department",
    baseUrl:"/admin/department ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: DepartmentUpdateForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'DEPARTMENT'
  },
  {
    path: "/department-view/:id",
    name: "Department",
    base: "Department",
    baseUrl:"/admin/department ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: DepartmentView,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'DEPARTMENT'
  },

  {
    path: "/subdepartment-form/:id",
    name: "Sub Dep form",
    base: "Sub Department",
    baseUrl:"/admin/subdepartment ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: SubDepartmentForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'SUBDEPARTMENT'
  },
  {
    path: "/group-subdepartment-form/:id",
    name: "Sub Dep form",
    base: "Sub Department",
    baseUrl:"/admin/subdepartment ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: GroupSubDepartmentForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'SUBDEPARTMENT'
  },
  {
    path: "/subdepartment-view/:id",
    name: "Sub dep view",
    base: "Sub Department",
    baseUrl:"/admin/subdepartment ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: SubDepartmentView,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'SUBDEPARTMENT'
  },
  {
    path: "/subdepartment-update/:id",
    name: "Sub Department update",
    base: "Sub Department",
    baseUrl:"/admin/subdepartment ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: SubDepartmentUpdateForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'SUBDEPARTMENT'
  },
  // {
  //   path: "/parkingdepartment",
  //   name: "Parking Department ",
  //   base: "Parking Department",
  //   baseUrl:"/admin/parkingdepartment ",
  //   rtlName: "لوحة القيادة",
  //   icon: <CreditIcon8 color='inherit' />,
  //   component: ParkingDepartmentTable,
  //   layout: "/admin",
  //   sidebar: true,
  //   module: 'PARKINGDEPARTMENT'
  // },

  {
    path: "/parkingdepartment-form/:id",
    name: "parkingdepartment form",
    base: "Parking Department",
    baseUrl:"/admin/parkingdepartment ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: ParkingDepartmentForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'PARKINGDEPARTMENT'
  },
  {
    path: "/parkingdepartment-update/:id",
    name: "parkingdepartment update form",
    base: "Parking Department",
    baseUrl:"/admin/parkingdepartment ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: ParkingDepartmentUpdateForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'PARKINGDEPARTMENT'
  },
  {
    path: "/parkingdepartment-view/:id",
    name: "parkingdepartment view",
    base: "Parking Department",
    baseUrl:"/admin/parkingdepartment ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: ParkingDepartmentView,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'PARKINGDEPARTMENT'
  },
  {
    path: "/reset-password",
    name: "Reset Password",
    base: "Reset Password",
    baseUrl:"/admin/reset-password ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: ResetPassword,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'Reset Password',
  },
  {
    path: "/plant-view/:id",
    name: "Plant View Form",
    base: "Plant View",
    baseUrl:"/admin/plant-admin-table ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: PlantAdminView,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'ADMIN PLANT'
  },

  {
    path: "/employee-form",
    name: "Employee Form",
    base: "Employee",
    baseUrl:"/admin/ ",
    baseUrl:"/admin/employee-table",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: EmployeeForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'EMPLOYEE'
  },
  {
    path: "/employee-upload-excelsheet",
    name: "Employee sheet",
    base: "Employee",
    baseUrl:"/admin/employee-table ",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: EmployeeUploadFileForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'EMPLOYEE'
  },


  {
    path: "/employee-view/:id",
    name: "Employee view",
    base: "Employee",
    baseUrl:"/admin/employee-table ",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: EmployeeView,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'EMPLOYEE'
  },
  {
    path: "/employee-create-passcode/:id",
    name: "Employee view",
    base: "Employee",
    baseUrl:"/admin/employee-table ",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: EmployeeCreatePasscode,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'EMPLOYEE'
  },
  {
    path: "/employee-update-form/:id",
    name: "Employee update form",
    base: "Employee",
    baseUrl:"/admin/employee-table ",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: EmployeeUpdateForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'EMPLOYEE'
  },
  {
    path: "/gaurd-form",
    name: "Gaurd Form",
    base: "Guard",
    baseUrl:"/admin/gaurd-table ",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: GaurdForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'GUARD'
  },
  {
    path: "/guard-upload-excelsheet",
    name: "Guard Upload ExcelSheet",
    base: "Guard",
    baseUrl:"/admin/gaurd-table",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: guardBulkUpload,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'GUARD'
  },
  
  // {
  //   path: "/howItWorkCategoryform",
  //   name: "How It Work Form",
  //   base: "HowItWork",
  //   baseUrl:"/admin/howItWorkCategoryform-table",
  //   rtlName: "لوحة القيادة",
  //   icon: <HomeIcon color='inherit' />,
  //   component: HowItWorkCategoryForm,
  //   layout: "/admin",
  //   sidebar: false,
  //   module: 'HOWITSWORKCATEGORY'
  // },
  {
    path: "/howItWorkCategoryform-update-form/:id",
    name: "How It Works update form",
    base: "HowItWork",
    baseUrl:"/admin/howItWorkCategoryform-table ",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: HowItWorkCategoryUpdate,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'HOWITSWORKCATEGORY'
  },
  {
    path: "/howItWork-form",
    name: "How It work Form",
    base: "HowItWork",
    baseUrl:"/admin/howItWork-table ",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: HowItWorksForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'HOWITSWORK'
  },

  {
    path: "/howItWorkCategoryform-view/:id",
    name: "HowItWork view",
    base: " HowItWork",
    baseUrl:"/admin/howItWorkCategoryform-table",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: HowItWorkCategoryView,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'HOWITSWORKCATEGORY'
  },
  
  // Biometric Settings
  {
    path: "/biometric/iot/setting",
    name: "Biometric IOT",
    base: "bio",
    baseUrl:"/biometric/iot/setting",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: BiometricSettingsTable,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'BIOMETRIC IOT SETTINGS'
  },
  {
    path: "/biometric/iot/setting-form/:id",
    name: "Biometric IOT",
    base: "bio",
    baseUrl:"/biometric/iot/setting",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: BiometricSettingsForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'BIOMETRIC IOT SETTINGS'
  },
  {
    path: "/biometric/iot/setting-update/:id",
    name: "Biometric IOT",
    base: "bio",
    baseUrl:"/biometric/iot/setting",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: BiometricSettingsUpdate,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'BIOMETRIC IOT SETTINGS'
  },
  {
    path: "/biometric/iot/setting-view/:id",
    name: "Biometric IOT",
    base: "bio",
    baseUrl:"/biometric/iot/setting",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: BiometricSettingsView,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'BIOMETRIC IOT SETTINGS'
  },

  // Inspection
  {
    path: "/inspection/list",
    name: "Inspection List",
    base: "bio",
    baseUrl:"/inspection/list",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: InspectionListTable,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'INSPECTION LIST'
  },
  {
    path: "/inspection/list-form/:id",
    name: "Inspection List",
    base: "bio",
    baseUrl:"/inspection/list",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: InspectionListForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'INSPECTION LIST'
  },
  {
    path: "/inspection/list-update/:id",
    name: "Inspection Update",
    base: "bio",
    baseUrl:"/inspection/list",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: InspectionListUpdate,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'INSPECTION LIST'
  },
  {
    path: "/inspection/list-view/:id",
    name: "Inspection View",
    base: "bio",
    baseUrl:"/inspection/list",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: InspectionListView,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'INSPECTION LIST'
  },
  // Inspection
  {
    path: "/inspection-qr/list",
    name: "Inspection QR List",
    base: "bio",
    baseUrl:"/inspection-qr/list",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: InspectionQrTable,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'INSPECTION QR LIST'
  },
  {
    path: "/inspection-qr/form/:id",
    name: "Inspection QR",
    base: "bio",
    baseUrl:"/inspection-qr/list",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: InspectionQrForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'INSPECTION QR LIST'
  },
  {
    path: "/inspection-qr/view/:id",
    name: "Inspection QR View",
    base: "bio",
    baseUrl:"/inspection-qr/list",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: InspectionQrView,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'INSPECTION QR LIST'
  },
  {
    path: "/inspection-qr/update/:id",
    name: "Inspection QR Update",
    base: "bio",
    baseUrl:"/inspection-qr/list",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: InspectionQrUpdate,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'INSPECTION QR LIST'
  },
// Inspection Summary
{
  path: "/inspection-summary/list",
  name: "Inspection List",
  base: "bio",
  baseUrl:"/inspection-summary/list",
  rtlName: "لوحة القيادة",
  icon: <HomeIcon color='inherit' />,
  component: InspectionTab,
  layout: "/admin",
  sidebar: false,
  plantAdmin : false,
  module: 'INSPECTION SUMMARY LIST'
},

// Meeting Rooms
{
  path: "/meeting-room/form/:id",
  name: "Meeting Room List",
  base: "Meeting Room",
  baseUrl:"/meeting-room",
  rtlName: "لوحة القيادة",
  icon: <HomeIcon color='inherit' />,
  component: MeetingRoomsFrom,
  layout: "/admin",
  sidebar: false,
  plantAdmin : false,
  module: 'MEETING ROOMS'
},
{
  path: "/meeting-room/view/:id",
  name: "Meeting Room View",
  base: "Meeting Room",
  baseUrl:"/meeting-room",
  rtlName: "لوحة القيادة",
  icon: <HomeIcon color='inherit' />,
  component: MeetingRoomsView,
  layout: "/admin",
  sidebar: false,
  plantAdmin : false,
  module: 'MEETING ROOMS'
},
{
  path: "/meeting-room/update/:id",
  name: "Meeting Room Update",
  base: "Meeting Room",
  baseUrl:"/meeting-room",
  rtlName: "لوحة القيادة",
  icon: <HomeIcon color='inherit' />,
  component: MeetingRoomsUpdateFrom,
  layout: "/admin",
  sidebar: false,
  plantAdmin : false,
  module: 'MEETING ROOMS'
},
  
// Multi Auth Matrix
{
  path: "/multi-auth-matrix/form/:id",
  name: "Multi Auth Matrix List",
  base: "Multi Auth Matrix",
  baseUrl:"/multi-auth-matrix",
  rtlName: "لوحة القيادة",
  icon: <HomeIcon color='inherit' />,
  component: MultiAuthMatrixForm,
  layout: "/admin",
  sidebar: false,
  plantAdmin : false,
  module: 'MULTIAUTHMATRIX'
},
{
  path: "/multi-auth-matrix/view/:id",
  name: "Multi Auth Matrix View",
  base: "Multi Auth Matrix",
  baseUrl:"/multi-auth-matrix",
  rtlName: "لوحة القيادة",
  icon: <HomeIcon color='inherit' />,
  component: MultiAuthMatrixView,
  layout: "/admin",
  sidebar: false,
  plantAdmin : false,
  module: 'MULTIAUTHMATRIX'
},
{
  path: "/multi-auth-matrix/update/:id",
  name: "Multi Auth Matrix Update",
  base: "Multi Auth Matrix",
  baseUrl:"/multi-auth-matrix",
  rtlName: "لوحة القيادة",
  icon: <HomeIcon color='inherit' />,
  component: MultiAuthMatrixUpdateFrom,
  layout: "/admin",
  sidebar: false,
  plantAdmin : false,
  module: 'MULTIAUTHMATRIX'
},



  {
    path: "/guard-view/:id",
    name: "Gaurd view",
    base: " Gaurd",
    baseUrl:"/admin/gaurd-table ",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: GuardView,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'GUARD'
  },
  {
    path: "/howItWork-view/:id",
    name: "How It Work view",
    base: "How It Work",
    baseUrl:"/admin/howItWork-table ",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: HowItWorksView,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'HOWITSWORK'
  },
  {
    path: "/guard-update-form/:id",
    name: "Gaurd update form",
    base: " Gaurd",
    baseUrl:"/admin/gaurd-table ",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: GuardUpdateForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'GUARD'
  },
  {
    path: "/howItWork-update-form/:id",
    name: "How It work update",
    base: " How It Work",
    baseUrl:"/admin/howItWork-table ",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: HowItWorksUpdate,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'HOWITSWORK'
  },

  {
    path: "/bus-view/:id",
    name: "bus view",
    base: "Busbase",
    baseUrl:"/admin/bus-table",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: BusViewForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'BUS'
  },
  {
    path: "/bus-form",
    name: "Bus",
    base: "Busbase",
    baseUrl:"/admin/bus-table ",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: BusForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'BUS'
  },
  {
    path: "/bus-update-form/:id",
    name: "Bus update form",
    base: "Busbase",
    baseUrl:"/admin/bus-table ",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: BusUpdateForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'BUS'
  },
  {
    path: "/driver-upload-excelsheet",
    name: "Driver Upload Excel Sheet",
    base: "Driver",
    baseUrl:"/admin/driver-table",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: DriverUploadFile,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'DRIVER'
  },

  {
    path: "/driver-view/:id",
    name: "driver view",
    base: "Driver",
    baseUrl:"/admin/driver-table ",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: DriverView,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'DRIVER'
  },
  {
    path: "/driver-form",
    name: "Driver From",
    base: "Driver",
    baseUrl:"/admin/driver-table ",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: DriverForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'DRIVER'
  },
  {
    path: "/driver-update-form/:id",
    name: "Driver update form",
    base: "Driver",
    baseUrl:"/admin/driver-table ",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: DriverUpdateForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'DRIVER'
  },

  {
    path: "/floor-form/:id",
    name: "Floor form",
    base: "Floor",
    baseUrl:"/admin/floor ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: FloorForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'FLOOR',
  },
  {
    path: "/group-floor-form/:id",
    name: "Floor form",
    base: "Floor",
    baseUrl:"/admin/floor ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: GroupFloorForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'FLOOR',
  },
  {
    path: "/floor-view/:id",
    name: "Floor view",
    base: "Floor",
    baseUrl:"/admin/floor ",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: FloorView,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'FLOOR'
  },
  {
    path: "/floor-update-form/:id",
    name: "Floor Update form",
    base: "Floor",
    baseUrl:"/admin/floor ",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: FloorUpdateForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'FLOOR'
  },

  {
    path: "/device-form/:id",
    name: "device form",
    base: "Devices",
    baseUrl:"/admin/DevicesTable ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: DevicesForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'DEVICES',
  },
  {
    path: "/group-device-form/:id",
    name: "device form",
    base: "Devices",
    baseUrl:"/admin/DevicesTable ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: GroupDevicesForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'DEVICES',
  },
  {
    path: "/device-update-form/:id",
    name: "device update form",
    base: "Devices",
    baseUrl:"/admin/DevicesTable ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: DevicesUpdateForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'DEVICES'
  },
  {
    path: "/device-view/:id",
    name: "device View form",
    base: "Devices",
    baseUrl:"/admin/DevicesTable ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: DeviceView,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'DEVICES'
  },

  {
    path: "/parking-form/:id",
    name: "Parking Form",
    base: "Parking",
    baseUrl:"/admin/parking ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon29 color='inherit' />,
    component: ParkingForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'PARKING'
  },
  {
    path: "/parking-view/:id",
    name: "Parking View",
    base: "Parking",
    baseUrl:"/admin/parking ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: ParkingView,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'PARKING'
  },
  {
    path: "/parking-update/:id",
    name: "Parking Form",
    base: "Parking",
    baseUrl:"/admin/parking ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: ParkingUpdateForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'PARKING'
  },
  {
    path: "/sos-form",
    name: "Sos",
    base: " SOS",
    baseUrl:"/admin/sos-table ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon25 color='inherit' />,
    component: SosForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'SOS'
  },
  
  {
    path: "/sos-view/:id",
    name: "Sos view",
    base: " SOS",
    baseUrl:"/admin/sos-table ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon25 color='inherit' />,
    component: SosView,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'SOS'
  },
  {
    path: "/latest-sos",
    name: "Sos latest",
    base: " SOS",
    baseUrl:"/latest-sos ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon25 color='inherit' />,
    component: SosLatestTable,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'SOS'
  },
  {
    path: "/sos-update-form/:id",
    name: "Sos update form",
    base: " SOS",
    baseUrl:"/admin/sos-table",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon25 color='inherit' />,
    component: SosUpdateForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'SOS'
  },

  {
    path: "/sosCategory-form",
    name: "Sos Category",
    base: " SOS Category",
    baseUrl:"/admin/sosCategory-table ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon26 color='inherit' />,
    component: SosCategoryForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'SOSCATEGORY'
  },

  {
    path: "/sosCategory-view/:id",
    name: "Sos Category view",
    base: "SOS Category",
    baseUrl:"/admin/sosCategory-table ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon26 color='inherit' />,
    component: SosCategoryView,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'SOSCATEGORY'
  },
  {
    path: "/sosCategory-update-form/:id",
    name: "Sos Category update form",
    base: "SOS Category",
    baseUrl:"/admin/sosCategory-table ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon26 color='inherit' />,
    component: SosCategoryUpdateForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'SOSCATEGORY'
  },

  {
    path: "/guardmodule-form",
    name: "GaurdModule Form",
    base: "Guardmod",
    baseUrl:"/admin/gaurdmodule-table ",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: GuardmoduleForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'GUARDMODULE'
  },

  {
    path: "/guardmodule-view/:id",
    name: "Gaurd Module view",
    base: "Guardmod",
    baseUrl:"/admin/gaurdmodule-table ",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: GuardmoduleView,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'GUARDMODULE'
  },
  {
    path: "/guardmodule-update/:id",
    name: "Gaurd Module update form",
    base: "Guardmod",
    baseUrl:"/admin/gaurdmodule-table ",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    // component: ReportIncedentModuleUpdateForm,
    component: GuardmoduleUpdateForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'GUARDMODULE'
  },

  {
    path: "/reportIncedentModule-form",
    name: "ReportIncedentModule Form",
    base: "Report Incedent Module",
    baseUrl:"/admin/reportIncedentModule-table ",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: ReportIncedentModuleForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'REPORTINCEDENTMOD'
  },
  {
    path: "/reportIncedentModule-table",
    name: "Report-Incedent Module",
    base: "Report Incedent Module",
    baseUrl: "/admin/reportIncedentModule-table ",
    rtlName: "لوحة القيادة",
    icon: <ReportIcon color='inherit' />,
    component: ReportIncedentModuleTable,
    layout: "/admin",
    sidebar: true,
    plantAdmin : false,
    module: 'REPORTINCEDENTMOD'
  },
  {
    path: "/reportIncedentModule-view/:id",
    name: "Report Incedent Module view",
    base: "Report Incedent Module",
    baseUrl:"/admin/reportIncedentModule-table ",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: ReportIncedentModuleView,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'REPORTINCEDENTMOD'
  },
  {
    path: "/reportIncedentModule-update/:id",
    name: "report Incedent update form",
    base: "Report Incedent Module",
    baseUrl:"/admin/reportIncedentModule-table ",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: ReportIncedentModuleUpdateForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'REPORTINCEDENTMOD'
  },


  
  
  {
    path: "/guard-attendence-view/:id",
    name: "Gaurd Attendence view",
    base: "Guard Attendance",
    baseUrl:"/admin/guard-attendence-table ",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: GaurdAttendenceView,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'GUARDATTENDENCE'
  },
  
  
  {
    path: "/employee-attendence-view/:id",
    name: "Employee Attendence view",
    base: "Employee ",
    baseUrl:"/admin/employee-attendence-table",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: EmployeeAttendenceView,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'EMPLOYEEATTENDENCE'
  },
  {
    path: "/employee-attendence-view/:id",
    name: "Employee Attendence view",
    base: "Employee",
    baseUrl:"/admin/employee-attendence-table ",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: EmployeeAttendenceView,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'EMPLOYEEATTENDENCE'
  },

  {
    path: "/workforce/head-count",
    name: "workforce Head count",
    base: "workforce",
    baseUrl:"/workforce/head-count ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: EmployeeAttendenceHeadCountTable,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'EMPLOYEEATTENDENCE'
  }, 

  {
    path: "/employee/attendance/history/list/:id",
    name: "employee attendance history",
    base: "employee attendance ",
    baseUrl:"/employee",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: EmployeeAttendanceHistoryTable,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'EMPLOYEEATTENDENCE'
  }, 

  {
    
    path: "/guard/attendance/history/list/:id",
    name: "guard attendance history",
    base: "guard attendance ",
    baseUrl:"/guard",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: GuardAttendanceHistoryTable,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'GUARDATTENDENCE'
  }, 
  {
    
    path: "/driver/attendance/history/list/:id",
    name: "driver attendance history",
    base: "driver attendance ",
    baseUrl:"/driver",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: DriverAttendanceHistoryTable,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'DRIVERATTENDENCE'
  }, 
  
  {
    path: "/employee-attendence-out",
    name: "employee attendence out",
    base: "employee",
    baseUrl:"/employee-attendence-out ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: EmployeeAttendenceOutTable,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'EMPLOYEEATTENDENCE'
  }, 
  {
    path: "/employee-attendence-in",
    name: "employee attendence in",
    base: "employee",
    baseUrl:"/employee-attendence-in ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: EmployeeAttendenceInTable,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'EMPLOYEEATTENDENCE'
  }, 
  
  {
    path: "/canteen-view/:id",
    name: "Canteen InOut View",
    base: "Canteen",
    baseUrl:"/admin/Canteen-InOut-table ",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: CanteenInOutView,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'CANTEENINOUT'
  },
  {
    path: "/canteen/head-count",
    name: "centeen Head count",
    base: "Centeen",
    baseUrl:"/canteen/head-count ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: CanteenHeadCountTable,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'CANTEENINOUT'
  }, 
  {
    path: "/canteen/latest-canteen",
    name: "centeen latest canteen",
    base: "Centeen",
    baseUrl:"/canteen/latest-canteen",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: CanteenTodayTable,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'CANTEENINOUT'
  }, 
  {
    path: "/canteen-Table",
    name: "Canteen Items",
    base: "Canteen",
    baseUrl:"/admin/canteen-Table ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: CanteenTable,
    layout: "/admin",
    sidebar: true,
    plantAdmin : false,
    module: 'CANTEEN',
  },
  {
    path: "/visitor-view/:id",
    name: "Visitor InOut View",
    base: "Visitor",
    baseUrl:"/admin/Visitor-InOut-table ",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: VisitorInOutView,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'VISITOR'
  },
  {
    path: "/visitor/head-count",
    name: "visitor Head count",
    base: "Visitor",
    baseUrl:"/visitor/head-count ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: VisitorHeadCountTable,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'VISITOR'
  }, 
  {
    path: "/visitor/latest-visitor",
    name: "latest visitor",
    base: "Visitor",
    baseUrl:"/visitor/latest-visitor ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: VisitorTodayVisitor,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'VISITOR'
  }, 
  
  {
    path: "/delivary-view/:id",
    name: "Delivery InOut View",
    base: "Delivery",
    baseUrl:"/admin/Delivary-InOut-table ",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: DelivaryInOutView,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'DELIVERY'
  },

  {
    path: "/delivery/latest-delivery",
    name: "delivery latest delivery",
    base: "delivery",
    baseUrl:"/delivery/latest-delivery ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: DelivaryTodayTable,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'DELIVERY'
  }, 
  {
    path: "/delivery/head-count",
    name: "delivery Head count",
    base: "delivery",
    baseUrl:"/delivery/head-count ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: DelivaryHeadCountTable,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'DELIVERY'
  }, 
  
  {
    path: "/transport-view/:id",
    name: "Transport view",
    base: "Transport",
    baseUrl:"/admin/Transport-InOut-table ",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: TransportInOutView,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'TRANSPORT'
  },
  
  {
    path: "/intercart-view/:id",
    name: "Intercarting view",
    base: "Intercarting IN OUT",
    baseUrl:"/admin/Intercarting-InOut-table ",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: IntercartingView,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'INTERCARTING'
  },
  
  {
    path: "/delivary-partner-form/:id",
    name: "Delivary form",
    base: "Delivery",
    baseUrl:"/admin/delivary-Table ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: DelivaryForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'DELIVERY PARTNER',
  },
  
  {
    path: "/delivary-partner-view/:id",
    name: "DelivaryView",
    base: "Delivery",
    baseUrl:"/admin/delivary-Table ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: DelivaryView,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'DELIVERY PARTNER'
  },
  {
    path: "/delivary-partner-update/:id",
    name: "DelivaryUpdateForm",
    base: "Delivery",
    baseUrl:"/admin/delivary-Table ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: DelivaryUpdateForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'DELIVERY PARTNER'
  },
  {
    path: "/canteen-form",
    name: "Canteen form",
    base: "Canteen",
    baseUrl:"/admin/canteen-Table ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: CanteenForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'CANTEEN',
  },


  {
    path: "/canteen-update/form/:id",
    name: "CanteenUpdateForm",
    base: "Canteen",
    baseUrl:"/admin/canteen-Table ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: CanteenUpdateForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'CANTEEN'
  },
  // {
  //   path: "/reportIncedent-form",
  //   name: "Report Incedent form",
  //   rtlName: "لوحة القيادة",
  //   icon: <CreditIcon color='inherit' />,
  //   component: ReportIncedentForm,
  //   layout: "/admin",
  //   sidebar: false,
  //   module: 'REPORTINCEDENT',
  // },
  
  {
    path: "/reportIncedent-view/:id",
    name: "Report Incedent View",
    base: "Report Incedent",
    baseUrl:"/admin/reportIncedent-Table ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: ReportIncedentView,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'REPORTINCEDENT'
  },
  // {
  //   path: "/reportIncedent-update/:id",
  //   name: "Report Incedent UpdateForm",
  //   rtlName: "لوحة القيادة",
  //   icon: <CreditIcon color='inherit' />,
  //   component: ReportIncedentUpdateForm,
  //   layout: "/admin",
  //   sidebar: false,
  //   module: 'REPORTINCEDENT'
  // },
  
  



  {
    path: "/highalert/settings/:id",
    name: "Call-Setting ",
    base: "callSetting",
    baseUrl:"/admin/highalert/settings",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon16 color='inherit' />,
    component: CallSettingUpdateForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'CALLSETTING',
  },
  {
    path: "/Designation-form/:id",
    name: "Designation form",
    base: "Designation",
    baseUrl:"/admin/DesignationTable ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: DesignationsForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'DESIGNATION',
  },
  {
    path: "/group-designation-form/:id",
    name: "Designation form",
    base: "Designation",
    baseUrl:"/admin/DesignationTable ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: GroupDesignationsForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'DESIGNATION',
  },
  {
    path: "/designation-update-form/:id",
    name: "designation update form",
    base: "Designation",
    baseUrl:"/admin/DesignationTable ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: DesignationUpdateForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'DESIGNATION'
  },
  {
    path: "/designation-view/:id",
    name: "designation view",
    base: "Designation",
    baseUrl:"/admin/DesignationTable ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: DesignationView,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'DESIGNATION'
  },
  {
    path: "/employeemodule-form",
    name: "Employee Module Form",
    base: "Employee",
    baseUrl:"/admin/employeemodule-table ",
    // baseUrl: "/admin/employeemodule-table",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: EmployeemoduleForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'EMPLOYEEMODULE'
  },

  {
    path: "/employeemodule-view/:id",
    name: "Employee Module view",
    base: "Employee",
    baseUrl:"/admin/employeemodule-table ",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: EmployeemoduleView,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'EMPLOYEEMODULE'
  },
  {
    path: "/employeemodule-update/:id",
    name: "Employee Module update form",
    base: "Employee",
    baseUrl:"/admin/employeemodule-table ",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: EmployeemoduleUpdateForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'EMPLOYEEMODULE'
  },
  {
    path: "/employeetype-table",
    name: "Employee Type",
    base: "Employee",
    baseUrl:"/admin/employeetype-table ",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: EmployeeTypeTable,
    layout: "/admin",
    sidebar: true,
    plantAdmin : false,
    module: 'EMPLOYEETYPE'
  },
  {
    path: "/employeetype-form",
    name: "Employee Type Form",
    base: "Employee",
    baseUrl:"/admin/employeetype-table ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: EmployeeTypeForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'EMPLOYEETYPE',
  },
  {
    path: "/employee-type-view/:id",
    name: "PEmployee Type View Form",
    base: "Employee",
    baseUrl:"/admin/employeetype-table ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: EmployeeTypeView,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'EMPLOYEETYPE'
  },
  {
    path: "/employee-type/:id",
    name: "Employee type update form",
    base: "Employee",
    baseUrl:"/admin/employeetype-table ",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: EmployeeTypeUpdateForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'EMPLOYEEMODULE'
  },
  
  {
    path: "/highalert-form",
    name: "HighAlert form",
    base: "High Alert",
    baseUrl:"/admin/highalert-table ",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: HighAlertForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'HIGHALERT'
  },
  {
    path: "/highalert-view/:id",
    name: "HighAlert view",
    base: "High Alert",
    baseUrl:"/admin/highalert-table ",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: HighAlertView,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'HIGHALERT'
  },
  {
    path: "/emergencycontact-table",
    name: "Emergency Contact",
    base: "Emergency Contact",
    baseUrl:"/admin/emergencycontact-table ", 
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: EmergencyContactTable,
    layout: "/admin",
    sidebar: true,
    plantAdmin : false,
    module: 'EMERGENCYCONTACT'
  },
  {
    path: "/emergencycontact-form",
    base: "Emergency Contact",
    name: "Emergency Contact form",
    baseUrl:"/admin/emergencycontact-table ",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: EmergencyContactForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'EMERGENCYCONTACT'
  },
  {
    path: "/emergencycontact-view/:id",
    name: "Emergency Contact view",
    base: "Emergency Contact",
    baseUrl:"/admin/emergencycontact-table ",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: emergencyContactView,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'EMERGENCYCONTACT'
  },
  
  {
    path: "/patrolling-route-form/:id",
    name: "Patrolling Route form",
    base: "Patrolling Route",
    baseUrl:"/admin/patrolling-route ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: PatrollingRouteForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'PATROLLINGROUTE',
  },
  {
    path: "/group-patrolling-route-form/:id",
    name: "Patrolling Route form",
    base: "Patrolling Route",
    baseUrl:"/admin/patrolling-route ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: GroupPatrollingRouteForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'PATROLLINGROUTE',
  },
  {
    path: "/patrolling-route-update-form/:id",
    name: "Patrolling Route update form",
    base: "Patrolling Route",
    baseUrl:"/admin/patrolling-route ",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: PatrollingRouteUpdateForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'PATROLLINGROUTE'
  },
  {
    path: "/patrolling-route-view-form/:id",
    name: "Patrolling Route update View",
    base: "Patrolling Route",
    baseUrl:"/admin/patrolling-route ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: PatrollingRouteView,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'PATROLLINGROUTE'
  },

  
  {
    path: "/patrolling-logs-form",
    name: "form",
    base: "Patrolling",
    baseUrl:"/admin/patrolling-logs",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: PatrollingLogsForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'PATROLLINGLOGS',
  },
  {
    path: "/patrolling-logs-view-form/:id",
    name: "update View",
    base: "Patrolling ",
    baseUrl:"/admin/patrolling-logs",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: PatrollingLogsView,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'PATROLLINGLOGS'
  },
  {
    path: "/patrolling-checked-logs-view/:id",
    name: "update View",
    base: "Patrolling ",
    baseUrl:"/admin/patrolling-logs",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: PatrollingByFloorView,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'PATROLLINGLOGS'
  },

  
  {
    path: "/material-view-form/:id",
    name: "Material update View",
    base: "Material",
    baseUrl:"/admin/material-table ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: MaterialView,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'MATERIAL'
  },

  
  {
    path: "/materialGatePass-view-form/:id",
    name: "MaterialGatePass update View",
    base: "MaterialGatePass",
    baseUrl:"/admin/materialGatePass-table ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: materialGatePassView,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'MATERIALGATEPASS'
  }, 
  {
    path: "/longStayAlert-view-form/:id",
    name: "Long Stay Alert View",
    base: "longStayAlert",
    baseUrl:"/admin/longStayAlert-table ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: LongAlertView,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'LONGSTAY'
  },
  
  {
    path: "/wrongEntry-view-form/:id",
    name: "Wrong Entry View",
    base: "wrongEntry",
    baseUrl:"/admin/wrongEntry-table ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: WrongEntryView,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'WRONGENTRY'
  },

  {
    path: "/material/head-count",
    name: "Material Head count",
    base: "Material",
    baseUrl:"/material/head-count ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: MaterialTypeInTable,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'MATERIAL'
  }, 
  
  {
    path: "/outPass-view-form/:id",
    name: "OutPass update View",
    base: "Out Pass",
    baseUrl:"/admin/outPass-table ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: OutPassView,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'OUTPASS'
  },
  
  {
    path: "/weighmentBridge-view-form/:id",
    name: "WeighmentBridge update View",
    base: "Weighment Bridge",
    baseUrl:"/admin/weighmentBridge-table ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: WeighmentBridgeView,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'WEIGHMENTBRIDGE'
  },
  
  {
    path: "/iamSafe-view-form/:id",
    name: "Iam Safe update View",
    base: "I am safe",
    baseUrl:"/admin/iamSafe-table ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon27 color='inherit' />,
    component: IamSafeView,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'IAMSAFE'
  },
  {
    path: "/iamSafe-latest",
    name: "Iam Safe latest",
    base: "I am safe",
    baseUrl:"/iamSafe-latest ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon27 color='inherit' />,
    component: IamSafeTodayTable,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'IAMSAFE'
  },
  {
    path: "/today-outpass",
    name: "/today outpass",
    base: "/toda outpass",
    baseUrl:"/today-outpass ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon27 color='inherit' />,
    component: OutPassTodayTable,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'OUTPASS'
  },

  

  {
    path: "/patrolling-request-form",
    name: "Patrolling Request form",
    base: "Patrolling Request",
    baseUrl:"/admin/PatrollingRequest-table ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon28 color='inherit' />,
    component: PatrollingRequestForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'PATROLLINGREQUEST',
  },
  {
    path: "/patrolling-request-view/:id",
    name: "Patrolling Request View form",
    base: "Patrolling Request",
    baseUrl:"/admin/PatrollingRequest-table ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon28 color='inherit' />,
    component: PatrollingRequestView,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'PATROLLINGREQUEST',
  },
  {
    path: "/patrolling-request-update-form/:id",
    name: "Patrolling Request Update form",
    base: "Patrolling Request",
    baseUrl:"/admin/PatrollingRequest-table ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon28 color='inherit' />,
    component: PatrollingRequestUpdate,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'PATROLLINGREQUEST',
  },
  {
    path: "/bus-route",
    name: "Bus Route ",
    base: "Bus Route",
    baseUrl:"/admin/bus-route ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: BusRouteTable,
    layout: "/admin",
    sidebar: true,
    plantAdmin : false,
    module: 'BUSROUTE'
  },
  {
    path: "/bus-route-form",
    name: "Bus Route form",
    base: "Bus Route",
    baseUrl:"/admin/bus-route ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: BusRouteForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'BUSROUTE',
  },
  {
    path: "/bus-route-view-form/:id",
    name: "bus route view form ",
    base: "Bus Route",
    baseUrl:"/admin/bus-route ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: BusRouteView,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'BUSROUTE',
  },
  {
    path: "/bus-route-update-form/:id",
    name: "Bus Route Update form",
    base: "Bus Route",
    baseUrl:"/admin/bus-route ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: BusRouteUpdateForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'BUSROUTE',
  },
  {
    path: "/shift",
    name: "Shift ",
    base: "Shift",
    baseUrl:"/admin/shift",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: ShiftTable,
    layout: "/admin",
    sidebar: true,
    plantAdmin : false,
    module: 'SHIFT'
  },
  {
    path: "/shift-form",
    name: "Shift form",
    base: "Shift",
    baseUrl:"/admin/shift",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: ShiftForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'SHIFT',
  },
  {
    path: "/shift-view/:id",
    name: "bus route view form ",
    base: "Shift",
    baseUrl:"/admin/shift",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: ShiftView,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'SHIFT',
  },
  {
    path: "/shift-update-form/:id",
    name: "Shift Update form",
    base: "Shift",
    baseUrl:"/admin/shift",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: ShiftUpdateForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'SHIFT',
  },
  {
    path: "/schedule/highalert",
    name: "Schedule High Alert",
    base: "Schedule high alert",
    baseUrl:"/schedule/highalert ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon100 color='inherit' />,
    component: ScheduleHighAlertTable,
    layout: "/admin",
    sidebar: true,
    plantAdmin : false,
    module: 'SCHEDULEHIGHALERT',
  },
  {
    path: "/scheduleHighAlert-update-form/:id",
    name: "Schedule-High-Alert Update form",
    base: "Schedule high alert",
    baseUrl:"/schedule/highalert ",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: ScheduleHighAlertUpdateForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'SCHEDULEHIGHALERT'
  },
  {
    path: "/scheduleHighAlert-view/:id",
    name: "Schedule-High-Alert View",
    base: "Schedule high alert",
    baseUrl:"/schedule/highalert ",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: scheduleHighAlertView,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'SCHEDULEHIGHALERT'
  },
  {
    path: "/scheduleHighAlert-form/:id",
    name: "Schedule-High-Alert Form",
    base: "Schedule high alert",
    baseUrl:"/schedule/highalert ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: scheduleHighAlertForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'SCHEDULEHIGHALERT',
  },
  {
    path: "/purpose-table",
    name: "purpose ",
    base: "purpose table",
    baseUrl:"/admin/purpose-table ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon28 color='inherit' />,
    component: PurposeTable,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'PURPOSE'
  },
  {
    path: "/purpose-view/:id",
    name: "purpose ",
    base: "purposenview",
    baseUrl:"/purpose-view ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon28 color='inherit' />,
    component: PurposeView,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'PURPOSE'
  },
  {
    path: "/purpose-form/:id",
    name: "purpose Form",
    base: "purpose",
    baseUrl:"/admin/purpose ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon29 color='inherit' />,
    component:PurposeForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'PURPOSE'
  },
  {
    path: "/purpose-update-form/:id",
    name: "purpose Form",
    base: "purpose",
    baseUrl:"/admin/purpose ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon29 color='inherit' />,
    component:PurposeUpdateForm,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'PURPOSE'
  },
  {
    path: "/manager-create/:id",
    name: "Manager Create",
    base: "manager",
    baseUrl:"/admin/manager ",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon29 color='inherit' />,
    component:ManagerCreate,
    layout: "/admin",
    sidebar: false,
    plantAdmin : false,
    module: 'MANAGER'
  },
  {
    name: "MANAGE",
    base: "Account Page",
    baseUrl:"/admin/ ",
    category: "account",
    rtlName: "صفحات",
    state: "pageCollapse",
    sidebar: false,
    plantAdmin : true,
    module: 'ALLREPORTS',
    views: [
      {
        path: "/DesignationTable",
        name: "Work Force ",
        base: "Designation",
        baseUrl:"/admin/DesignationTable ",
        rtlName: "لوحة القيادة",
        icon: <EmployeeIcon color='inherit' />,
        component: DesignationTable,
        layout: "/admin",
        sidebar: true,
        plantAdmin : true,
        module: 'DESIGNATION',
      },
      {
        path: "/shift",
        name: "Shift ",
        base: "Shift",
        baseUrl:"/admin/shift",
        rtlName: "لوحة القيادة",
        icon: <CreditIcon26 color='inherit' />,
        component: ShiftTable,
        layout: "/admin",
        sidebar: true,
        plantAdmin : true,
        module: 'SHIFT'
      },
      {
        path: "/bus-route",
        name: "Bus Route ",
        base: "Bus Route",
        baseUrl:"/admin/bus-route ",
        rtlName: "لوحة القيادة",
        icon: <CreditIcon26 color='inherit' />,
        component: BusRouteTable,
        layout: "/admin",
        sidebar: true,
        plantAdmin : true,
        module: 'BUSROUTE'
      },
      {
        path: "/bus-table",
        name: "Transport Vehicle",
        base: "Busbase",
        baseUrl:"/admin/bus-table ",
        rtlName: "لوحة القيادة",
        icon: <HomeIcon3 color='inherit' />,
        component: BusTable,
        layout: "/admin",
        sidebar: true,
        plantAdmin : true,
        module: 'BUS'
      },
      {
        path: "/emergencycontact-table",
        name: "Emergency Contact",
        base: "Emergency Contact",
        baseUrl:"/admin/emergencycontact-table ", 
        rtlName: "لوحة القيادة",
        icon: <CreditIcon23 color='inherit' />,
        component: EmergencyContactTable,
        layout: "/admin",
        sidebar: true,
        plantAdmin : true,
        module: 'EMERGENCYCONTACT'
      },

    ],
  },
  {
    name: "LIST",
    base: "Account Page",
    baseUrl:"/admin/ ",
    category: "account",
    rtlName: "صفحات",
    state: "pageCollapse",
    sidebar: false,
    plantAdmin : true,
    module: 'ALLREPORTS',
    views: [
      {
        path: "/employee-table",
        name: "Employee ",
        base: "Employee",
        baseUrl:"/admin/employee-table ",
        rtlName: "لوحة القيادة",
        icon: <EmployeeIcon color='inherit' />,
        component: EmployeeTable,
        layout: "/admin",
        sidebar: true,
        plantAdmin : true,
        module: 'EMPLOYEE'
      },
      {
        path: "/gaurd-table",
        name: "Security Guard",
        base: "Gaurd",
        baseUrl:"/admin/gaurd-table ",
        rtlName: "لوحة القيادة",
        icon: <GuardIcon color='inherit' />,
        component: GaurdTable,
        layout: "/admin",
        sidebar: true,
        plantAdmin : true,
        module: 'GUARD'
      },
      {
        path: "/driver-table",
        name: "Driver ",
        base: "Driver",
        baseUrl:"/admin/driver-table ",
        rtlName: "لوحة القيادة",
        icon: <DriverIcon color='inherit' />,
        component: DriverTable,
        layout: "/admin",
        sidebar: true,
        plantAdmin : true,
        module: 'DRIVER'
      },
      {
        path: "/floor",
        name: "Patrolling Checkpoints ",
        base: "Floor",
        baseUrl:"/admin/floor ",
        rtlName: "لوحة القيادة",
        icon: <CreditIcon24 color='inherit' />,
        component: FloorTable,
        layout: "/admin",
        sidebar: true,
        plantAdmin : true,
        module: 'FLOOR'
      },
      {
        path: "/meeting-rooms",
        name: "Meeting Room",
        base: "meeting/room",
        baseUrl:"/admin/meeting/room",
        rtlName: "لوحة القيادة",
        icon: <CreditIcon26 color='inherit' />,
        component: MeetingRoomsTable,
        layout: "/admin",
        sidebar: true,
        plantAdmin : true,
        module: 'MEETINGROOMS'
      },
      {
        path: "/multi-auth-matrix",
        name: "Multi Auth",
        base: "/multi-auth-matrix",
        baseUrl:"/admin/multi-auth-matrix",
        rtlName: "لوحة القيادة",
        icon: <CreditIcon26 color='inherit' />,
        component: PlantAdminMultiAuthMatrixTable,
        layout: "/admin",
        sidebar: true,
        plantAdmin : true,
        module: 'MULTIAUTHMATRIX'
      },
      

    ],
  },
  {
    name: "REPORTS",
    base: "Account Page",
    baseUrl:"/admin/ ",
    category: "account",
    rtlName: "صفحات",
    state: "pageCollapse",
    sidebar: false,
    plantAdmin : true,
    module: 'ALLREPORTS',
    views: [
   
      {
        path: "/guard-attendence-table",
        name: "Gaurd Attendence",
        base: "Guard Attendance",
        baseUrl:"/admin/guard-attendence-table ",
        rtlName: "لوحة القيادة",
        icon: <HomeIcon color='inherit' />,
        component: GaurdAttendenceTable,
        layout: "/admin",
        sidebar: true,
        plantAdmin : true,
        module: 'GUARDATTENDENCE'
      },
      {
        path: "/employee-attendence-table",
        name: "Employee Attendence",
        base: "Employee Attendence",
        baseUrl:"/admin/employee-attendence-table ",
        rtlName: "لوحة القيادة",
        icon: <HomeIcon color='inherit' />,
        component: EmployeeAttendenceTable,
        layout: "/admin",
        sidebar: true,
        plantAdmin : true,
        module: 'EMPLOYEEATTENDENCE'
      },
      {
        path: "/Canteen-InOut-table",
        name: "Canteen",
        base: "Canteen",
        baseUrl:"/admin/Canteen-InOut-table ",
        rtlName: "لوحة القيادة",
        icon: <CanteenInOutIcon color='inherit' />,
        component: CanteenInOutTable,
        layout: "/admin",
        sidebar: true,
        plantAdmin : true,
        module: 'CANTEENINOUT'
      },
      {
        path: "/Visitor-InOut-table",
        name: "Visitor",
        base: "Visitor IN OUT",
        baseUrl:"/admin/Visitor-InOut-table ",
        rtlName: "لوحة القيادة",
        // icon: <HomeIcon5 color='inherit' />,
        icon: <VisitorIcon color='inherit' />,
        component: VisitorInOutTable,
        layout: "/admin",
        sidebar: false,
        plantAdmin : false,
        module: 'VISITOR'
      },
      {
        path: "/visitor-preapproval/:id",
        name: "Visitor",
        base: "Visitor IN OUT",
        baseUrl:"/admin/Visitor-InOut-table ",
        rtlName: "لوحة القيادة",
        // icon: <HomeIcon5 color='inherit' />,
        icon: <VisitorIcon color='inherit' />,
        component: visitorPreapprovalView,
        layout: "/admin",
        sidebar: false,
        plantAdmin : false,
        module: 'VISITOR'
      },
      {
        path: "/visitor-tab",
        name: "Visitor",
        base: "Visitor IN OUT",
        baseUrl:"/admin/Visitor-InOut-table ",
        rtlName: "لوحة القيادة",
        icon: <VisitorIcon color='inherit' />,
        component: VisitorTabs,
        layout: "/admin",
        sidebar: true,
        plantAdmin : true,
        module: 'VISITOR'
      },
      {
        path: "/Delivary-InOut-table",
        name: "Delivery",
        base: "Delivery IN OUT",
        baseUrl:"/admin/Delivary-InOut-table ",
        rtlName: "لوحة القيادة",
        icon: <DeliveryInOutIcon color='inherit' />,
        component: DelivaryInOutTable,
        layout: "/admin",
        sidebar: false,
        plantAdmin : false,
        module: 'DELIVERY'
      },
      {
        path: "/delivery-tab",
        name: "Delivery",
        base: "Delivery IN OUT",
        baseUrl:"/admin/Delivary-InOut-table ",
        rtlName: "لوحة القيادة",
        icon: <DeliveryInOutIcon color='inherit' />,
        component: DeliveryTabs,
        layout: "/admin",
        sidebar: true,
        plantAdmin : true,
        module: 'DELIVERY'
      },
      {
        path: "/Transport-InOut-table",
        name: "Transport",
        base: "Transport IN OUT",
        baseUrl:"/admin/Transport-InOut-table ",
        rtlName: "لوحة القيادة",
        icon: <HomeIcon7 color='inherit' />,
        component: TransportInOutTable,
        layout: "/admin",
        sidebar: true,
        plantAdmin : true,
        module: 'TRANSPORT'
      },
      {
        path: "/Intercarting-InOut-table",
        name: "Intercarting",
        base: "Intercarting IN OUT",
        baseUrl:"/admin/Intercarting-InOut-table ",
        rtlName: "لوحة القيادة",
        icon: <IntercartingInOutIcon color='inherit' />,
        component: IntercartingInOutTable,
        layout: "/admin",
        sidebar: true,
        plantAdmin : true,
        module: 'INTERCARTING'
      },
      
      {
        path: "/reportIncedent-Table",
        name: "Report Incident",
        base: "Report Incedent List",
        baseUrl:"/admin/reportIncedent-Table ",
        rtlName: "لوحة القيادة",
        icon: <CreditIcon14 color='inherit' />,
        component: ReportIncedentTable,
        layout: "/admin",
        sidebar: true,
        plantAdmin : true,
        module: 'REPORTINCEDENT',
      },

      {
        path: "/sos-table",
        name: "SOS",
        base: "Sos",
        baseUrl:"/admin/sos-table ",
        rtlName: "لوحة القيادة",
        icon: <SosIcon color='inherit' />,
        component: SosTable,
        layout: "/admin",
        sidebar: true,
        plantAdmin : true,
        module: 'SOS'
      },
      {
        path: "/iamSafe-table",
        name: "I am Safe ",
        base: "I am Safe",
        baseUrl:"/admin/iamSafe-table ",
        rtlName: "لوحة القيادة",
        icon: <CreditIcon27 color='inherit' />,
        component: IamSafeTable,
        layout: "/admin",
        sidebar: true,
        plantAdmin : true,
        module: 'IAMSAFE'
      },
      {
        path: "/highalert-table",
        name: "High-Alert",
        base: "HighAlert",
        baseUrl:"/admin/highalert-table ",
        rtlName: "لوحة القيادة",
        icon: <CreditIcon17 color='inherit' />,
        component: HighAlertTable,
        layout: "/admin",
        sidebar: true,
        plantAdmin : true,
        module: 'HIGHALERT'
      },
      {
        path: "/patrolling-route",
        name: "Patrolling Route ",
        base: "Patrolling Route Table",
        baseUrl:"/admin/patrolling-route ",
        rtlName: "لوحة القيادة",
        icon: <CreditIcon18 color='inherit' />,
        component: PatrollingRouteTable,
        layout: "/admin",
        sidebar: true,
        plantAdmin : false,
        module: 'PATROLLINGROUTE'
      },
      {
        path: "/patrolling-logs",
        name: "Patrolling Logs",
        base: "Patrolling Logs ",
        baseUrl:"/admin/patrolling-logs ",
        rtlName: "لوحة القيادة",
        icon: <CreditIcon19 color='inherit' />,
        component: PatrollingLogsTable,
        layout: "/admin",
        sidebar: false,
        plantAdmin : true,
        module: 'PATROLLINGLOGS'
      },
      {
        path: "/patrolling-tab",
        name: "Patrolling",
        base: "Patrolling",
        baseUrl:"/admin/patrolling-logs ",
        rtlName: "لوحة القيادة",
        icon: <CreditIcon19 color='inherit' />,
        component: PatrollingTabs,
        layout: "/admin",
        sidebar: true,
        plantAdmin : true,
        module: 'PATROLLINGLOGS'
      },
      {
        path: "/inspection-summary/list",
        name: "Inspection",
        base: "Inspection",
        baseUrl:"/admin/inspection-summary ",
        rtlName: "لوحة القيادة",
        icon: <CreditIcon19 color='inherit' />,
        component: PatrollingTabs,
        layout: "/admin",
        sidebar: true,
        plantAdmin : true,
        module: 'PATROLLINGLOGS'
      },
      {
        path: "/meeting-room-bookings/list",
        name: "Meeting Room Bookings",
        base: "Meeting Room Booking",
        baseUrl:"/admin/meeting-room-bookings/list",
        rtlName: "لوحة القيادة",
        icon: <CreditIcon19 color='inherit' />,
        component: MeetingRoomBookingsTabs,
        layout: "/admin",
        sidebar: true,
        plantAdmin : true,
        module: 'PATROLLINGLOGS'
      },
      {
        path: "/material-tabs",
        name: "Material Vehicles",
        base: "Material",
        baseUrl:"/admin/material-table ",
        rtlName: "لوحة القيادة",
        icon: <MaterialIcon color='inherit' />,
        component: MaterialTabs,
        layout: "/admin",
        sidebar: true,
        plantAdmin : true,
        module: 'MATERIAL'
      },
      {
        path: "/gatepass-tabs",
        name: "Gatepass",
        base: "Gatepass",
        baseUrl:"/admin/gatepass-table ",
        rtlName: "لوحة القيادة",
        icon: <MaterialIcon color='inherit' />,
        component: GatepassTabs,
        layout: "/admin",
        sidebar: true,
        plantAdmin : true,
        module: 'MATERIAL'
      },
      {
        path: "/head-count-all",
        name: "Head Count",
        base: "Head count",
        baseUrl:"/admin/head-count",
        rtlName: "لوحة القيادة",
        icon: <MaterialIcon color='inherit' />,
        component: HeadCountTabs,
        layout: "/admin",
        sidebar: true,
        plantAdmin : true,
        module: 'HEAD COUNT'
      },
      // {
      //   path: "/incoming-material-table",
      //   name: "Incoming Material ",
      //   base: "Material",
      //   baseUrl:"/admin/material-table ",
      //   rtlName: "لوحة القيادة",
      //   icon: <MaterialIcon color='inherit' />,
      //   component: IncomingMaterialTable,
      //   layout: "/admin",
      //   sidebar: false,
      //   plantAdmin : false,
      //   module: 'MATERIAL'
      // },
      // {
      //   path: "/material-table",
      //   name: "Outgoing Material ",
      //   base: "Material",
      //   baseUrl:"/admin/material-table ",
      //   rtlName: "لوحة القيادة",
      //   icon: <MaterialIcon color='inherit' />,
      //   component: MaterialTable,
      //   layout: "/admin",
      //   sidebar: false,
      //   plantAdmin : false,
      //   module: 'MATERIAL'
      // },
      // {
      //   path: "/material-gate-pass-table",
      //   name: "RGP",
      //   base: "MaterialGatePass",
      //   baseUrl:"/admin/materialGatePass-table ",
      //   rtlName: "لوحة القيادة",
      //   icon: <CreditIcon21 color='inherit' />,
      //   component: materialGatePassTable,
      //   layout: "/admin",
      //   sidebar: true,
      //   plantAdmin : true,
      //   module: 'MATERIALGATEPASS'
      // },
      // {
      //   path: "/nrgp-material-gate-pass-table",
      //   name: "NRGP",
      //   base: "MaterialGatePass",
      //   baseUrl:"/admin/materialGatePass-table ",
      //   rtlName: "لوحة القيادة",
      //   icon: <CreditIcon21 color='inherit' />,
      //   component: NrgpmaterialGatePassTable,
      //   layout: "/admin",
      //   sidebar: true,
      //   plantAdmin : true,
      //   module: 'MATERIALGATEPASS'
      // },
      // {
      //   path: "/rejected-material-gate-pass-table",
      //   name: "Rejected Gate Pass",
      //   base: "MaterialGatePass",
      //   baseUrl:"/admin/materialGatePass-table ",
      //   rtlName: "لوحة القيادة",
      //   icon: <CreditIcon21 color='inherit' />,
      //   component: RejectedmaterialGatePassTable,
      //   layout: "/admin",
      //   sidebar: true,
      //   plantAdmin : true,
      //   module: 'MATERIALGATEPASS'
      // },
      // {
      //   path: "/weighmentBridge-table",
      //   name: "Weighment Bridge",
      //   base: "WeighmentBridge",
      //   baseUrl:"/admin/weighmentBridge-table ",
      //   rtlName: "لوحة القيادة",
      //   icon: <WeighmentIcon color='inherit' />,
      //   component: WeighmentBridgeTable,
      //   layout: "/admin",
      //   sidebar: true,
      //   plantAdmin : true,
      //   module: 'WEIGHMENTBRIDGE'
      // },
      {
        path: "/rgp-management-tabs",
        name: "RGP Management",
        base: "RaisedGatePass",
        baseUrl:"/admin/raisedlGatePass-table ",
        rtlName: "لوحة القيادة",
        icon: <GatePassIcon color='inherit' />,
        component: RgpManagementTabs,
        layout: "/admin",
        sidebar: true,
        plantAdmin : true,
        module: 'RAISEDGATEPASS'
      },
      // {
      //   path: "/raisedGatePass-table",
      //   name: "Raised (RGP)",
      //   base: "RaisedGatePass",
      //   baseUrl:"/admin/raisedlGatePass-table ",
      //   rtlName: "لوحة القيادة",
      //   icon: <GatePassIcon color='inherit' />,
      //   component: raisedGatePassTable,
      //   layout: "/admin",
      //   sidebar: true,
      //   plantAdmin : true,
      //   module: 'RAISEDGATEPASS'
      // },
      // {
      //   path: "/raised-nrgp-table",
      //   name: "Raised (NRGP)",
      //   base: "RaisedGatePass",
      //   baseUrl:"/admin/raisedlGatePass-table ",
      //   rtlName: "لوحة القيادة",
      //   icon: <GatePassIcon color='inherit' />,
      //   component: raisedNrgpTable,
      //   layout: "/admin",
      //   sidebar: true,
      //   plantAdmin : true,
      //   module: 'RAISEDGATEPASS'
      // },
      {
        path: "/outPass-table",
        name: "Employee Out Pass",
        base: "OutPass",
        baseUrl:"/admin/outPass-table ",
        rtlName: "لوحة القيادة",
        icon: <OutPassIcon color='inherit' />,
        component: OutPassTable,
        layout: "/admin",
        sidebar: true,
        plantAdmin : true,
        module: 'OUTPASS'
      },
      {
        path: "/longStayAlert-table",
        name: "Long Stay Alert",
        base: "longStayAlert",
        baseUrl:"/admin/longStayAlert-table ",
        rtlName: "لوحة القيادة",
        icon: <LongStayAlertIcon color='inherit' />,
        component: LongAlertTable,
        layout: "/admin",
        sidebar: true,
        plantAdmin : true,
        module: 'LONGSTAY'
      },
      {
        path: "/wrongEntry-table",
        name: "Wrong Entry",
        base: "wrongEntry",
        baseUrl:"/admin/wrongEntry-table ",
        rtlName: "لوحة القيادة",
        icon: <WrongEntryIcon color='inherit' />,
        component: WrongEntryTable,
        layout: "/admin",
        sidebar: true,
        plantAdmin : true,
        module: 'WRONGENTRY'
      }, 
     
      {
        path: "/signin",
        name: "",
        rtlName: "لوحة القيادة",
        // icon: <DocumentIcon color='inherit' />,
        component: SignIn,
        layout: "/auth",
        sidebar: false,
        plantAdmin : false,
        module: 'LOGIN'
      },
      {
        path: "/group-signin",
        name: "",
        rtlName: "لوحة القيادة",
        // icon: <DocumentIcon color='inherit' />,
        component: GroupSignIn,
        layout: "/auth",
        sidebar: false,
        plantAdmin : false,
        module: 'LOGIN'
      },
      {
        path: "/raisedGatePass-view-form/:id",
        name: "raisedGatePass update View",
        base: "MaterialGatePass",
        baseUrl:"/admin/raisedGatePass-table ",
        rtlName: "لوحة القيادة",
        icon: <CreditIcon color='inherit' />,
        component: raisedGatePassView,
        layout: "/admin",
        sidebar: false,
        plantAdmin : false,
        module: 'RAISEDGATEPASS'
      },
   
      // {
      //   path: "/signup",
      //   name: "Sign Up",
      //   rtlName: "لوحة القيادة",
      //   icon: <RocketIcon color='inherit' />,
      //   component: SignUp,
      //   layout: "/auth",
      //   sidebar: false,
      //   module : 'LOGIN'
      // },
      {
        path: "/web-link/:id",
        name: "Web Link",
        rtlName: "لوحة القيادة",
        icon: <RocketIcon color='inherit' />,
        component: Weblink,
        layout: "/auth",
        sidebar: false,
        plantAdmin : false,
        module : 'WEBLINK'
      },

    ],
  },
];
export default dashRoutes;
