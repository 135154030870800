// Chakra imports
import {
    Box,
    Button,
    Center,
    Flex,
    Image,
    Input,
    SimpleGrid,
    Spacer,
    Stack,
    Text,
    Wrap,
    WrapItem,
    useColorModeValue
  } from "@chakra-ui/react";
  // Custom components
  import Card from "components/Card/Card.js";
  import CardBody from "components/Card/CardBody.js";
  import CardHeader from "components/Card/CardHeader.js";
  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector } from 'react-redux';
  import { bannerPositionAddOrUpdate, bannerPositionClearShowSnackbar } from "actions/inspectionAction";
  import { useToast } from '@chakra-ui/react'
  import { useParams } from "react-router-dom/cjs/react-router-dom.min";
  import { BANNER_POSITION_CLEAR_SNACKBAR } from "constants/types";
  import axios from 'axios';
  import { server_url } from '../../constants/types';
  import { useHistory } from 'react-router-dom';
  
  import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
  } from '@chakra-ui/react'
  import { Skeleton, SkeletonCircle, SkeletonText } from '@chakra-ui/react'
  import dataConstant from "constants/dataConstant";
  import InspectionQrTable from "views/InspectionQr/InspectionQrTable";
  
  function MeetingRoomsView() {
    const textColor = useColorModeValue("gray.700", "white");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    const [id, setId] = useState("");
    const [name, setName] = useState("");
    const [position, setPosition] = useState("");
    const [description, setDescription] = useState("");
    const [baseurl, setBaseurl] = useState("");
    const [authtype, setAuthtype] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [token, setToken] = useState("");
    const [timeInterval, setTimeIntervel] = useState("");
    const [plantAdmin, setPlantAdmin] = useState("");
    const [capacity, setCapacity] = useState("");
    const [amenities, setAmenities] = useState([]);
    const [approvalRequired, setApprovalRequired] = useState([]);
    const [department, setDepartment] = useState("");
    const [loading, setLoading] = useState(false);
  
  
    const [status, setStatus] = useState('false');
    const [type, setType] = useState("");
  
    const dispatch = useDispatch();
    const toast = useToast();
  
    const statuses = ['success', 'error', 'warning', 'info'];
    
    

    const history = useHistory();
  
    const params = useParams();
  
    useEffect(() => {
      setLoading(true);
      console.log("params");
      const id = params.id;
      axios
        .get(`${server_url}/admin/meeting-room/${id}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((response) => {
          // if(responce.data.data)
          // {
            setId(response.data.data._id);
            setName(response.data.data.name);
            setDescription(response.data.data.description);
            setStatus(response.data.data.status);
            setType(response.data.data.type);
            setCapacity(response.data.data.capacity);
            setAmenities(response.data.data.amenities);
            setDepartment(response.data.data.department);
            
            
            setPlantAdmin(response.data.data.plant?._id);
            console.log("I am here");
          // }
        })
        .catch((e) => {
  
        });
        setLoading(false);
    }, []);
  
    const toastFunction = (title, status) => {
      toast({
        title: title,
        status: status,
        duration: 3000,
        isClosable: true,
      })
    };
  
    useEffect(() => {
      axios
        .get(`${server_url}/admin/detail`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((responce) => {
          console.log("uadlfdafljadhfoadilf");
        })
        .catch((e) => {
          history.push('/auth/signin');
  
        });
    }, [])
    return (
      <>
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="18px">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Meeting Room Detail
            </Text>
          </CardHeader>
          <CardBody>
            {loading ? <Stack>
              <Skeleton height='20px' />
              <Skeleton height='20px' />
              <Skeleton height='20px' />
            </Stack>
              :
              <TableContainer>
                <Table variant='simple'>
                  <Tbody>
                    <Tr>
                      <Td>Room Type</Td>
                      <Td>{type}</Td>
                    </Tr>
                    <Tr>
                      <Td>Name</Td>
                      <Td>{name}</Td>
                    </Tr>
                    <Tr>
                      <Td>Capacity</Td>
                      <Td>{capacity}</Td>
                    </Tr>
                    <Tr>
                      <Td>Amenities</Td>
                      <Td>{amenities.map((row, index, arr) => (
                    <Text>{row.label}</Text>
                  ))}</Td>
                    </Tr>
                    <Tr>
                      <Td>Department</Td>
                      <Td>{department?.name}</Td>
                    </Tr>
                    <Tr>
                      <Td>Status</Td>
                      <Td>{status?'Active':'Inactive'}</Td>
                    </Tr>
                    
                    {/* <Tr>
                        <Td>City</Td>
                        <Td>{city}</Td>
                      </Tr> */}
                  </Tbody>
                </Table>
              </TableContainer>}
          </CardBody>
        </Card>
      </Flex>
    </>
    );
  }
  
  export default MeetingRoomsView;