import React, { useEffect, useState, useRef } from 'react';
import { Flex, Button, InputGroup, Spacer, Table, Tbody, Text, Td, Th, Thead, Tr, useColorModeValue, InputLeftElement, IconButton, Input, Icon, Link, SimpleGrid, FormControl, Center, Spinner, FormLabel, Select ,useDisclosure} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';

import { useToast } from '@chakra-ui/react';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
// import DevicePagination from './DevicePagination';
import { AddIcon } from '@chakra-ui/icons';
import { MATERIAL_GATE_PASS_CLEAR_SNACKBAR , MATERIAL_GATE_PASS_SEARCH} from 'constants/types';
// import {  } from '../../actions/devicesAction'; // Assuming you have a DeviceAction file
// import DeviceTableRow from './DevicesTableRow';

// import DevicesPagination from './DevicesPagination';
// import iamSafePagination from './iamSafePagination'
import { materialGatePassGetAll } from '../../actions/materialGatePassAction';
import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';
import dataConstant from 'constants/dataConstant';
import GatePassExport from './GatePassExport';
const GatePassAdvanceSearch = (props) => {
  const { url = "", plant ,gatepassType} = props;
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const responseStatus = useSelector(state => state.iamSafe.status);
  const responseMessage = useSelector(state => state.iamSafe.text);
  const loading = useSelector(state => state.materialGatePass.loading);
  const iamSafeList = useSelector(state => state.iamSafe.iamSafeList);
  const response = useSelector(state => state.iamSafe.response);
  const showSnackbar = useSelector(state => state.iamSafe.showSnackbar);
  const dispatch = useDispatch();
  const toast = useToast();
  const searchIconColor = useColorModeValue("gray.700", "gray.200");
  const inputBg = useColorModeValue("#edf3f8", "navy.800");
  const history = useHistory();
  const tableRef = useRef(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [status , setStatus] = useState("");
  const [mode , setMode] = useState("");
  const [department , setDepartment] = useState("");
  const [subdepartment , setSubdepartment] = useState("");
  const [departmentList , setDepartmentList] = useState([]);
  const [subDepartmentList , setSubdepartmentList] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [downloadLoader, setDownloadLoader] = useState(false);



  useEffect(() => {
    departmentListforDropdown();
  }, [dispatch]);

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: MATERIAL_GATE_PASS_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  const departmentListforDropdown = () => {
    axios
      .get(`${server_url}/department`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        setDepartmentList(responce.data.data)
      }).catch(() => console.log("error"))
  }

  const subdepartmentListforDropdown = (depId) => {
    if (depId) {
      axios
        .get(`${server_url}/plant/subdepartment-list/${depId}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((responce) => {
          setSubdepartmentList(responce.data.data)
        })
    }
    else {
      setSubdepartmentList([])
    }

  }


  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    const searching = `&status=${gatepassType}&startDate=` + startDate+ "&endDate=" + endDate +"&department=" + department+"&subdepartment=" + subdepartment + "&status=" + status + "&mode=" + mode;
    dispatch({
      type : MATERIAL_GATE_PASS_SEARCH,
      payload:{
        searching : searching,
      }
    })
    dispatch(materialGatePassGetAll(searching));
  };
  
  const handleClear = (event) => {
    event.preventDefault();
    setSubmitted(true);
    setStartDate('');
    setEndDate('');
    setStatus('');
    setMode('');
    setDepartment('');
    setSubdepartment('')
    const searching = `&status=${gatepassType}`;
    dispatch({
      type : MATERIAL_GATE_PASS_SEARCH,
      payload:{
        searching : searching,
      }
    })
    dispatch(materialGatePassGetAll(searching));
  };

  const handleDownload = () => {
    var downloadRule = "";
    if(gatepassType == dataConstant.gatepassTypeRGP)
    {
        downloadRule = '/admin/materialGatepass/export-v2';
    }
    else if(gatepassType == dataConstant.gatepassTypeNRGP)
    {
        downloadRule = '/admin/materialGatepass/nrgp-export-v2';
    }
    else if(gatepassType == dataConstant.gatepassTypeREJECTEDGATEPASS)
    {
        downloadRule = '/admin/materialGatepass/rejected-export-v2';
    }
    
    setDownloadLoader(true);
    const searching = "&startDate=" + startDate+ "&endDate=" + endDate +"&department=" + department+"&subdepartment=" + subdepartment + "&status=" + status + "&mode=" + mode;
    axios
      .get(`${server_url}${downloadRule}?status=${gatepassType}${searching}`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        },
        responseType: 'blob'
      }).then((response) => {
        const blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
  
        // Create a link element to initiate the download
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `gatepass_${Date.now()}.xlsx`); // File name
  
        // Append the link to the body, trigger click and remove link
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setDownloadLoader(false);
      }).catch(() => console.log("error"))
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Advance Search ({gatepassType.replace(/_/g, " ")})
          </Text>
          <SimpleGrid minChildWidth="120px" spacing="40px">
            <FormControl >
            <FormLabel>Start Date</FormLabel>
              <Input
                type="date"
                label="Bus No"
                value={startDate}
                onChange={(e) => {
                    setStartDate(e.target.value);
                }}
                
                 
              />
            </FormControl>
            <FormControl >
            <FormLabel>End Date</FormLabel>
              <Input
                type="date"
                min={startDate}
                value={endDate}
                onChange={(e) => {
                setEndDate(e.target.value);
    
                }}
                
                 
              />
            </FormControl>

           
            <FormControl >
              <FormLabel>Department</FormLabel>
              <Select
                placeholder="Department"
                onChange={(e) => {
                  setDepartment(e.target.value);
                  subdepartmentListforDropdown(e.target.value);
                }}
                value={department}
              >
                {departmentList ? (departmentList.map((item, index) => (
                  <option key={item._id} value={item._id}>{item.name}</option>
                ))) : (<></>)}
              </Select>
              
            </FormControl>
            </SimpleGrid>



            <SimpleGrid minChildWidth='120px' spacing='40px' pt='20px'>
            <FormControl >
              <FormLabel>Sub Department</FormLabel>
              <Select
                placeholder="Subdepartment"
                onChange={(e) => {
                  setSubdepartment(e.target.value);
                }}
                value={subdepartment}
              >
                {subDepartmentList ? (subDepartmentList.map((item, index) => (
                  <option key={item._id} value={item._id}>{item.name}</option>
                ))) : (<></>)}
              </Select>
              
            </FormControl>
           
            {/* <FormControl >
              <FormLabel>Status</FormLabel>
              <Select
                placeholder="Status"
                onChange={(e) => {
                  setStatus(e.target.value);
                }}
                value={status}
              >
                {dataConstant.statusType ? (dataConstant.statusType.map((item, index) => (
                  <option key={item._id} value={item.value}>{item.name}</option>
                ))) : (<></>)}
              </Select>
              
            </FormControl> */}
           
            <FormControl >
              <FormLabel>Mode</FormLabel>
              <Select
                placeholder="Mode"
                onChange={(e) => {
                  setMode(e.target.value);
                }}
                value={mode}
              >
                {dataConstant.mode? (dataConstant.mode.map((item, index) => (
                  <option key={item._id} value={item.value}>{item.name}</option>
                ))) : (<></>)}
              </Select>
              
            </FormControl>
    
          </SimpleGrid>
          
          <Center>
            <>
            <Button
            isLoading={downloadLoader}
            colorScheme="blue" mt="24px" mr={"10px"} onClick={onOpen} >
              Download
            </Button>
            <Button
            isLoading={loading}
              colorScheme="blue"  mt="24px" onClick={handleSubmit} >
            Submit
          </Button>
          <Button colorScheme="green" mt="24px" ml="10px" onClick={ handleClear } >
            Reset
          </Button>
          </>
          </Center>
        </CardHeader>
        <CardBody></CardBody>
      </Card>
    </Flex>
    {/* Export Modal */}
    <GatePassExport
      reportName = "RGP Data"
      isOpen={isOpen}
      onClose={onClose}
      onExport={
        () => {
          handleDownload();
        }
      }
      />
    </>
  );
};

export default GatePassAdvanceSearch;
