import React from "react";
import {
  chakra,
  Td,
  Tr,
  Text,
  useColorModeValue,
  Flex,
  ButtonGroup,
  IconButton,
  Link,
  Badge,
} from "@chakra-ui/react";
import { ViewIcon } from "@chakra-ui/icons";

const TransportTableRow = (props) => {
  const { 
    routeNo ,
    vechilenumber,
    drivername,
    driverPasscode,
    shiftSlots,
    numberOfPersons,
    type,
    inTime,
    outTime,
    totalTime,
    isLast,
    serialnumber,
    id,
    date,
    time,
    lastStatus,
    kilometers
   } = props;
  const textColor = useColorModeValue("gray.500", "white");
  const titleColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  return (
    <>
      <Tr>
      <Td pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          >
            <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
              <Flex direction="column">
                <Text
                  fontSize="md"
                  color={titleColor}
                  fontWeight="bold"
                  minWidth="100%"
                >
                  {date}
                </Text>
              </Flex>
            </Flex>
          </Td>
          
      <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {routeNo}
              </Text>
            </Flex>
          </Flex>
        </Td>
      

        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {vechilenumber}
            </Text>
          </Flex>
        </Td>

       

        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {drivername}
            </Text>
          </Flex>
        </Td>

        

        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {shiftSlots}
            </Text>
          </Flex>
        </Td>

        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {numberOfPersons}
            </Text>
          </Flex>
        </Td>

        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {kilometers}
            </Text>
          </Flex>
        </Td>

        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Badge colorScheme={type === "IN" ? "green" : "red"}>{type}</Badge>
        </Td>
        
        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {time}
            </Text>
          </Flex>
        </Td>
        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {lastStatus}
            </Text>
          </Flex>
        </Td>
        
        {/* <Td
          minWidth={{ sm: "250px" }}
          pl="20px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {totalTime}
            </Text>
          </Flex>
        </Td> */}
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <ButtonGroup>
            <Link href={`#/admin/transport-view/${id}`}>
              <IconButton
                colorScheme="blue"
                icon={<ViewIcon />}
                aria-label="Up"
              />
            </Link>
            </ButtonGroup>
            </Td>
      </Tr>
    </>
  );
};

export default TransportTableRow;
