import React, { useEffect, useState, useRef } from 'react';
import { Flex, Button, InputGroup, Spacer, Table, Tbody, Text, Td, Th, Thead, Tr, useColorModeValue, InputLeftElement, IconButton, Input, Icon, Link,useDisclosure,Tooltip  } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { FaChevronDown, FaChevronUp, FaUpload } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';

import { useToast } from '@chakra-ui/react';


import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import EmployeePagination from './EmployeePagination';
import { AddIcon } from '@chakra-ui/icons';
import { EMPLOYEE_CLEAR_SNACKBAR } from 'constants/types';
import { employeeGetAll } from 'actions/EmployeeAction';
import EmployeeTableRow from './EmployeeTableRow';
import { BsUpload } from 'react-icons/bs';
import EmployeeView from './EmployeeView';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import EmployeeAdvanceSearch from './EmployeeAdvanceSearch';
import Export from 'views/Export/Export';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
const EmployeeTable = () => {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const responseStatus = useSelector(state => state.employee.status);
  const responseMessage = useSelector(state => state.employee.text);
  const loading = useSelector(state => state.employee.loading);
  const employeeList = useSelector(state => state.employee.employeeList);
  const totalItems = useSelector(state => state.employee.totalItems);
  const response = useSelector(state => state.employee.response);
  const showSnackbar = useSelector(state => state.employee.showSnackbar);
  const dispatch = useDispatch();
  const toast = useToast();
  const searchIconColor = useColorModeValue("gray.700", "gray.200");
  const inputBg = useColorModeValue("#edf3f8", "navy.800");
  const [search, setSearch] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const tableRef = useRef(null);
  useEffect(() => {
    dispatch(employeeGetAll());
  }, []);

  console.log("employeeList")
  console.log(employeeList)
  console.log("employeeList")

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: EMPLOYEE_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("desc");
    }
    const sorting = "&sortOrder=" + sortOrder + "&column=" + column;
    dispatch(employeeGetAll(sorting));
  };

  useEffect(() => {
    const searching = "&search=" + search;
    dispatch(employeeGetAll(searching));
  }, [search]);

const [submittingLoader, setSubmittingLoader] = useState(false);
const exportToExcel = async () => {
  setSubmittingLoader(true);
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet('Sheet 1');
  const filename = "Employee_"+Date.now();
  // Define the headers
  const headers = [
    "SNo","Employee Name", "Id", "Kavach Passcode","Phone", "Status",
    "Dept.", "Sub-Dept"
  ];

  // Set up the columns with specific widths
  sheet.columns = [
    
    { header: 'SNo', key: 'sno', width: 15 },
    { header: 'Employee Name', key: 'employeeName', width: 15 },
    { header: 'Id', key: 'id', width: 15 },
    { header: 'Kavach Passcode', key: 'kavachPasscode', width: 20 },
    { header: 'Phone', key: 'phone', width: 20 },
    { header: 'Status', key: 'status', width: 20 },
    { header: 'Dept', key: 'department', width: 20 },
    { header: 'Sub Dept', key: 'subDepartment', width: 20,}, // Adjust width as needed
  ];

  // Add the headers to the sheet
  headers.forEach((header, index) => {
    sheet.getCell(1, index + 1).value = header;
  });

  // Add data and images
  for (const [rowIndex, row] of employeeList.entries()) {
    const rowData = [
      totalItems - (((response.currentPage - 1) * response.limit) + rowIndex),
      row.employeeName,
      row.employeeId,
      row.passcode,
      row.phone,
      row.status,
      row.department?.name,
      row.subdepartment?.name
    ];

    for (const [colIndex, cell] of rowData.entries()) {
      sheet.getCell(rowIndex + 2, colIndex + 1).value = cell;
    }
  }

  // Set row height (if needed)
  for (let rowIndex = 1; rowIndex <= sheet.rowCount; rowIndex++) {
    sheet.getRow(rowIndex).height = 60; // Set height in points
  }

  // Generate Excel file and save
  const excelBuffer = await workbook.xlsx.writeBuffer();
  saveAs(new Blob([excelBuffer]), `${filename}.xlsx`);
  setSubmittingLoader(false);
};
const { isOpen, onOpen, onClose } = useDisclosure();


  return (
  <>
    <EmployeeAdvanceSearch></EmployeeAdvanceSearch>
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <Flex>
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Employees
            </Text>
          </CardHeader>
          <Spacer />

          {/* <Flex justify="flex-end" fontSize="xl" style={{ padding: '10px', marginTop: '-5px', marginRight: '12px' }}>
            <Link href={`#/admin/employee-form`}>
              <IconButton
                colorScheme="blue"
                aria-label="Search database"
                icon={<AddIcon />}
              />
            </Link>
          </Flex>
          <Flex justify="flex-end" style={{ padding: '2px', marginTop: '3px', marginRight: '12px' }}>
            <Link href={`#/admin/employee-upload-excelsheet`}>
              <IconButton
                colorScheme="blue"
                aria-label="Search database"
                icon={<FaUpload />}
              />
            </Link>
          </Flex> */}

          <Flex justify="flex-end" style={{ padding: '10px', marginTop: '-5px', marginRight: '12px' }}>
            {/* <DownloadTableExcel
              filename="Employee Table"
              sheet="users"
              currentTableRef={tableRef.current}
            > */}
              <Button
              loading={submittingLoader}
              onClick={onOpen}
              colorScheme="blue" _hover={{ backgroundColor: 'black', color: 'white' }}>
                Download
              </Button>
            {/* </DownloadTableExcel> */}
          </Flex>
          {/* Export */}
          <Export
            reportName = "Employee List"
            isOpen={isOpen}
            onClose={onClose}
            onExport={exportToExcel}
            />

          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              <InputGroup borderRadius="8px" w="200px">
                <InputLeftElement
                  children={
                    <IconButton
                      bg="inherit"
                      borderRadius="inherit"
                      _hover="none"
                      _active={{
                        bg: "inherit",
                        transform: "none",
                        borderColor: "transparent",
                      }}
                      _focus={{
                        boxShadow: "none",
                      }}
                      icon={
                        <SearchIcon
                          color={searchIconColor}
                          w="15px"
                          h="15px"
                        />
                      }
                    ></IconButton>
                  }
                />
                <Input
                  type="pass"
                  variant="search"
                  fontSize="xs"
                  bg={inputBg}
                  onKeyUp={(e) => {
                    setSearch(e.target.value);
                    if (e.target.value.length < 3) {
                      setSearch("");
                    }
                  }}
                  placeholder="Type here..."
                />
              </InputGroup>
            </Text>
          </CardHeader>
        </Flex>
        <CardBody pb={4}>
          <div style={{ overflowX: "auto" }}>

            <Table variant="simple" color={textColor} ref={tableRef}>
              <Thead>
                <Tr my=".8rem" pl="0px" color="gray.400">
                <Th
                    pl="15px"
                    borderColor={borderColor}
                    color="gray.400"
                    cursor="pointer"
                  >
                   SNO
                  </Th>
                  <Th
                    pl="0px"
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("employeeName")}
                    cursor="pointer"
                  >
                    Employee Name
                    {sortColumn === "employeeName" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    pl="0px"
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("employeeName")}
                    cursor="pointer"
                  >
                   ID
                    {sortColumn === "employeeName" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    pl="0px"
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("employeeName")}
                    cursor="pointer"
                  >
                   Kavach Passcode
                    {sortColumn === "employeeName" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>

                  {/* <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("photo")}
                    cursor="pointer"
                  >
                    Image
                    {sortColumn === "photo" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th> */}
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("phone")}
                    cursor="pointer"
                  >
                    phone
                    {sortColumn === "phone" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  {/* <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("officialEmail")}
                    cursor="pointer"
                  >
                    Official Email
                    {sortColumn === "officialEmail" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th> */}

                  <Th borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("status")}
                    cursor="pointer">
                    Status
                    {sortColumn === "status" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>

                  <Th
                    pl="0px"
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("employeeName")}
                    cursor="pointer"
                  >
                    Dept
                    {sortColumn === "employeeName" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    pl="0px"
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("employeeName")}
                    cursor="pointer"
                  >
                    Sub-dept
                    {sortColumn === "employeeName" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  {/* <Th
                    pl="0px"
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("employeeName")}
                    cursor="pointer"
                  >
                    designation
                    {sortColumn === "employeeName" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    pl="0px"
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("employeeName")}
                    cursor="pointer"
                  >
                    Start Date
                    {sortColumn === "employeeName" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    pl="0px"
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("employeeName")}
                    cursor="pointer"
                  >
                    End Date
                    {sortColumn === "employeeName" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    pl="0px"
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("employeeName")}
                    cursor="pointer"
                  >
                    Service Provider
                    {sortColumn === "employeeName" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th> */}
                  <Th
                    pl="0px"
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("employeeName")}
                    cursor="pointer"
                  >
                    Logs
                    {sortColumn === "employeeName" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>

                {employeeList.length != 0 ? (employeeList.map((row, index, arr) => {
                  return (
                    <>
                      <EmployeeTableRow
                        // serialnumber={((response.currentPage - 1)*response.limit) +( index + 1)}
                        serialnumber={totalItems - (((response.currentPage - 1) * response.limit) + index)}
                        employeeName={row.employeeName}
                        passcode= {row.passcode}
                        employeeId= {row?.employeeId}
                        photo={row.photo}
                        phone={row.phone}
                        officialEmail={row.officialEmail}
                        department={row.department? row.department.name :'NA'}
                        subdepartment={row.subdepartment ? row.subdepartment.name : 'NA'}
                        designation={row.designation ? row.designation.name :'NA'}
                        startDate={row.startDate}
                        endDate={row.endDate}
                        companyName={row.companyName}
                        activeDate = {row.activeDate}
                        inactiveDate = {row.inactiveDate}
                        isLast={index === arr.length - 1 ? true : false}
                        status={row.status}
                        id={row._id}
                        isExit={row.isExit}

                      />

                    </>
                  );
                })) : (<h1></h1>)
                }

              </Tbody>
            </Table>
          </div>
          {employeeList.length == 0 ? (
            <></>
          ) : (
            <EmployeePagination
            limit = {response.limit}
              totalPages={response.totalPages}
              currentPage={response.currentPage}
              perPage={response.perPage}
              sortBy={sortColumn}
              sortOrder={sortOrder}
              handlePageClick={(data) => {
                const selectedPage = data.selected + 1;
                dispatch(employeeGetAll(`&page=${selectedPage}`));
              }}
            />
          )}
        </CardBody>
      </Card>
    </Flex>
  </>
  );
};

export default EmployeeTable;
