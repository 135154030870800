import axios from 'axios';
import {
    CANTEENINOUT_LOADING,
    CANTEENINOUT_GET_ALL_SUCCESS,
    CANTEENINOUT_ERROR,
    CANTEENINOUT_CLEAR_SNACKBAR
} from '../constants/types';

import { server_url } from '../constants/types';

export const CanteenInOutGetAll = (pagination = '') => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: CANTEENINOUT_LOADING,
        payload: {
          loading: true
        }
      });
      axios
        .get(`${server_url}/canteen?${pagination}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
        .then((response) => {
          
          console.log(response.data.data)
          dispatch({
            type: CANTEENINOUT_GET_ALL_SUCCESS,
            payload: {
              canteenInOutList: response.data.data,
              limit: response.data.limit,
              page: response.data.currentPage,
              totalPages: response.data.totalPages,
              totalItems: response.data.totalItems,
              response: response.data,
              loading: false,
              status: 'success',
              text: 'Get all canteen successfully.'
            }
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: CANTEENINOUT_ERROR,
            payload: {
              status: 'error',
              text: 'Error occurred during getting canteen  data.',
              loading: false
            }
          });
          resolve();
        });
    });
  };
};

export const CanteenTodayGetAll = (pagination) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: CANTEENINOUT_LOADING,
        payload: {
          loading: true
        }
      });
      axios
        .get(`${server_url}/admin/todays-canteen?{pagination}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
        .then((response) => {
          
          console.log(response.data.data)
          dispatch({
            type: CANTEENINOUT_GET_ALL_SUCCESS,
            payload: {
              canteenTodayList: response.data.data,
              limit: response.data.limit,
              page: response.data.currentPage,
              totalPages: response.data.totalPages,
              response: response.data,
              loading: false,
              status: 'success',
              text: 'Get all canteen successfully.'
            }
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: CANTEENINOUT_ERROR,
            payload: {
              status: 'error',
              text: 'Error occurred during getting canteen  data.',
              loading: false
            }
          });
          resolve();
        });
    });
  };
};

export const CanteenHeadCount = () => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: CANTEENINOUT_LOADING,
        payload: {
          loading: true
        }
      });
      axios
        .get(`${server_url}/admin/canteen-headcount`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
        .then((response) => {
          
          console.log(response.data.data)
          dispatch({
            type: CANTEENINOUT_GET_ALL_SUCCESS,
            payload: {
              canteenIN: response.data.data,
              limit: response.data.limit,
              page: response.data.currentPage,
              totalPages: response.data.totalPages,
              response: response.data,
              loading: false,
              status: 'success',
              text: 'Get all canteen successfully.'
            }
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: CANTEENINOUT_ERROR,
            payload: {
              status: 'error',
              text: 'Error occurred during getting canteen  data.',
              loading: false
            }
          });
          resolve();
        });
    });
  };
};


export const canteenGetById = (canteen) => {
  console.log("yes it is working")
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: CANTEENINOUT_LOADING,
        payload: {
          loading: true
        }
      });
      axios
        .get(`${server_url}/canteen-view/${canteen}`)
        .then((responseF) => {
          axios
            .get(`${server_url}/canteen`, {
              headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
              }
            })
            .then((response) => {
              console.log("responseF.data['data']")
              console.log(responseF.data['data'])
              dispatch({
                type: CANTEENINOUT_GET_ALL_SUCCESS,
                payload: {
                  canteenInOutList: response.data.data,
                  response: response.data,
                  canteenInOutDetail: responseF.data['data'],
                  loading: false,
                  status: responseF.data['status'],
                  text: responseF.data['message']
                }
              });
              resolve();
            })
            .catch((e) => {
              dispatch({
                type: CANTEENINOUT_ERROR,
                payload: {
                  text: 'Error occurred during getting canteen data.',
                  status: 'error',
                  loading: false
                }
              });
              resolve();
            });
        })
        .catch((e) => {
          dispatch({
            type: CANTEENINOUT_ERROR,
            payload: {
              text: 'Error occurred during deleting canteen data.',
              status: 'error',
              loading: false
            }
          });
          resolve();
        });
    });
  };
};
