import React,{ useEffect, useState } from "react";
import {
  Image,
  Flex,
  IconButton,
  Link,
  Td,
  Text,
  Tr,
  useColorModeValue,
  useDisclosure,
  ButtonGroup,
  Switch,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Input,
  FormControl,
  FormLabel,
  SimpleGrid,
  useToast,
  VStack,
  Avatar,
  Box,
  Divider,
  RadioGroup,
  Stack,
  Radio
} from "@chakra-ui/react";
import { ViewIcon,EditIcon,DeleteIcon } from "@chakra-ui/icons";
import { AiFillEdit } from "react-icons/ai";
import { useDispatch,useSelector } from "react-redux";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import { multiauthmatrixListDelete,multiauthmatrixListAddOrUpdate,multiauthmatrixListGetAll } from "../../../actions/multiauthmatrixAction";
import { BsFillTrashFill } from "react-icons/bs";
import { Button } from "@chakra-ui/react/dist/chakra-ui-react.cjs";
import axios from 'axios';
import { server_url } from '../../../constants/types';
import { useHistory } from 'react-router-dom';
import dataConstant from "../../../constants/dataConstant";
import PasswordViewVerification from "views/PasswordView/PasswordViewVerification";

const PlantAdminMultiAuthMatrixTableRow = (props) => {
  const {
    isLast,
    id,
    serialnumber,
    allDepartment,
    department,
    allType,
    allPurpose,
    purpose,
    type,
    firstAuth,
    secondAuth,
    thirdAuth,
    fourthAuth,
    fifthAuth,
    status,
    plant
  } = props;

  const textColor = useColorModeValue("gray.500", "white");
  const titleColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpen1, onOpen: onOpen1, onClose: onClose1 } = useDisclosure();
  const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure();
  const { isOpen: isOpen3, onOpen: onOpen3, onClose: onClose3 } = useDisclosure();
  const cancelRef = React.useRef();
  const authDetail = useSelector((state) => state.auth.authDetail);
  const [authStage,setAuthStage] = useState("");
  const [authStageMember,setAuthStageMember] = useState(0);
  const [employeeDetail,setEmployeeDetail] = useState("");
  const [phone,setPhone] = useState("");
  const [loader,setLoader] = useState(false);
  const [typeModule,setType] = useState("Update");
  const [moduleCallType,setModuleCallType] = useState("NONE");

  const dispatch = useDispatch();

  const handelView = () => {
    if(typeModule == "Remove")
        {
            onOpen2();
        }
        else if(typeModule == "Update")
        {
            onOpen();
        }
  };
  const handleStatus = () => {
    const multiAuthMatrix = {
      _id: id,
      status : !status
    };
    console.log(multiAuthMatrix);
    var state = "edit";
    dispatch(multiauthmatrixListAddOrUpdate(multiAuthMatrix,state,"","",plant._id));
  };
  const handleEmployeeSearch = (phone) => {
    setLoader(true);
    axios
        .post(`${server_url}/admin/search-employee-to-assign`,{phone}, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((response) => {
          if(response.data.status == 'OK')
          {
            setEmployeeDetail(response.data.data);
            onOpen1();
          }
          else
          {
            toastFunction(response.data.message, 'error');
          }
        })
        .catch((e) => {
  
        });
    setLoader(false);
  };

  const handleAssignEmployee = (id) => {
    setLoader(true);
    var data = {

    };
    switch (authStage) {
        case dataConstant.multiAuthFirstAuth:
            var members = createMembers(firstAuth.assignedMember,firstAuth.members);
            
            data.firstAuth = {
                members
            }
            break;
        case dataConstant.multiAuthSecondAuth:
            var members = createMembers(secondAuth.assignedMember,secondAuth.members);
            
            data.secondAuth = {
                members
            }
            break;
        case dataConstant.multiAuthThirdAuth:
            var members = createMembers(thirdAuth.assignedMember,thirdAuth.members);
            
            data.thirdAuth = {
                members
            }
            break;
        case dataConstant.multiAuthFourthAuth:
            var members = createMembers(fourthAuth.assignedMember,fourthAuth.members);
            
            data.fourthAuth = {
                members
            }
            break;
        case dataConstant.multiAuthFifthAuth:
            var members = createMembers(fifthAuth.assignedMember,fifthAuth.members);
            
            data.fifthAuth = {
                members
            }
            break;
    
        default:
            break;
    }
    
    axios
        .put(`${server_url}/admin/multi-auth-matrix-assign-member/${id}`,data, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((response) => {
          if(response.data.status == 'OK')
          {
            toastFunction(response.data.message, 'success');
            dispatch(multiauthmatrixListGetAll("&plant="));
            onClose();
          }
          else
          {
            toastFunction(response.data.message, 'error');
          }
        })
        .catch((e) => {
  
        });
    setLoader(false);
  };
  const handleRemove = (id) => {
    setLoader(true);
    var data = {

    };
    switch (authStage) {
        case dataConstant.multiAuthFirstAuth:
            var members = removeMembers(firstAuth.assignedMember,firstAuth.members);
            
            data.firstAuth = {
                members
            }
            break;
        case dataConstant.multiAuthSecondAuth:
            var members = removeMembers(secondAuth.assignedMember,secondAuth.members);
            
            data.secondAuth = {
                members
            }
            break;
        case dataConstant.multiAuthThirdAuth:
            var members = removeMembers(thirdAuth.assignedMember,thirdAuth.members);
            
            data.thirdAuth = {
                members
            }
            break;
        case dataConstant.multiAuthFourthAuth:
            var members = removeMembers(fourthAuth.assignedMember,fourthAuth.members);
            
            data.fourthAuth = {
                members
            }
            break;
        case dataConstant.multiAuthFifthAuth:
            var members = removeMembers(fifthAuth.assignedMember,fifthAuth.members);
            
            data.fifthAuth = {
                members
            }
            break;
    
        default:
            break;
    }
    
    axios
        .put(`${server_url}/admin/multi-auth-matrix-assign-member/${id}`,data, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((response) => {
          if(response.data.status == 'OK')
          {
            toastFunction(response.data.message, 'success');
            dispatch(multiauthmatrixListGetAll("&plant="));
            onClose();
          }
          else
          {
            toastFunction(response.data.message, 'error');
          }
        })
        .catch((e) => {
  
        });
    setLoader(false);
  };
  const toast = useToast();
  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  };
  const createMembers = (assignedMember,olderMember) => {
    const members = [];
    for (let i = 0; i < assignedMember; i++) {
        if((authStageMember-1) == i)
        {
            members.push({
                employee: employeeDetail._id, // Or any placeholder employee ID
                callType: moduleCallType, // Default call type
                status: '' // Default status
            });
        }
        else
        {
            members.push({
                employee: olderMember[i]['employee'], // Or any placeholder employee ID
                callType: olderMember[i]['callType'], // Default call type
                status: olderMember[i]['status'] // Default status
            });
        }
        
    }
    return members;
};

  const removeMembers = (assignedMember,olderMember) => {
    const members = [];
    for (let i = 0; i < assignedMember; i++) {
        if((authStageMember-1) == i)
        {
            members.push({
                employee: null, // Or any placeholder employee ID
                callType: 'NONE', // Default call type
                status: '' // Default status
            });
        }
        else
        {
            members.push({
                employee: olderMember[i]['employee'], // Or any placeholder employee ID
                callType: olderMember[i]['callType'], // Default call type
                status: olderMember[i]['status'] // Default status
            });
        }
        
    }
    return members;
};
  return (
    <>
      <Tr>
        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {/* Render Name */}
          <Text
            fontSize="md"
            color={titleColor}
            fontWeight="bold"
            minWidth="100%"
          >
            {allDepartment ?'All Department':
            department.map((row, index, arr) => (
                    <Text>{row.label}{index == department.length-1 ?'':','}</Text>
                  ))
            }
          </Text>
        </Td>

        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {/* Render Name */}
          <Text
            fontSize="md"
            color={titleColor}
            fontWeight="bold"
            minWidth="100%"
          >
            {allType ? 'All Type':type.map((row, index, arr) => (
                    <Text>{row.label}{index == type.length-1 ?'':','}</Text>
                  ))}
          </Text>
        </Td>
        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {/* Render Name */}
          <Text
            fontSize="md"
            color={titleColor}
            fontWeight="bold"
            minWidth="100%"
          >
            {allPurpose ? 'All Purpose':purpose.map((row, index, arr) => (
                    <Text>{row.label}{index == type.length-1 ?'':','}</Text>
                  ))}
          </Text>
        </Td>
        <Td
         pl="0px"
         borderColor={borderColor}
         borderBottom={isLast ? "none" : "1px solid"}
         whiteSpace="nowrap"
         overflow="hidden"
         textOverflow="ellipsis"
        >
          {/* Render Name */}
          {firstAuth.members.length > 0 ?<>
          <Text fontSize="md" color={titleColor} fontWeight="bold" minWidth="100%">
            1st Auth ({firstAuth.assignedMember} Member) 
            </Text> </> : <></>}
          {
            firstAuth.members.length == 0 ? (
                <>
                
                </>
            
            ) : (
                firstAuth.members.map((row, index) => (
                <Text key={index} color={titleColor} fontWeight="bold">
                {row.employee != null ?`${row.employee?.employeeName} | ${row.employee?.phone} | ${row.employee?.department?.name} | Gate Pass Auth :${row.employee?.gatePassAuth?'Yes' : 'No'} | Call Type : ${row.callType}`:"Not Assigned" }
                <EditIcon cursor={"pointer"} ml="4px" onClick={() =>{
                    onOpen3();
                    setAuthStage(dataConstant.multiAuthFirstAuth);
                    setAuthStageMember(index+1);
                    setType("Update");
                }} color='#3182ce' size='xs'>
                </EditIcon>
                {row.employee != null ? 
                <DeleteIcon
                onClick={() =>{
                    onOpen3();
                    setAuthStage(dataConstant.multiAuthFirstAuth);
                    setAuthStageMember(index+1);
                    setType("Remove")
                }}
                 cursor={"pointer"} ml="4px" color='red' size='xs'></DeleteIcon> :<></>
                }
                </Text>
            ))
            )
        }
        
        {secondAuth.members.length > 0 ?<>
          <Text fontSize="md" color={titleColor} fontWeight="bold" minWidth="100%">
            2nd Auth ({secondAuth.assignedMember} Member) 
            </Text> </> : <></>}
          {
            secondAuth.members.length == 0 ? (
                <>
                
                </>
            
            ) : (
                secondAuth.members.map((row, index) => (
                <Text key={index} color={titleColor} fontWeight="bold">
                {row.employee != null ?`${row.employee?.employeeName} | ${row.employee?.phone} | ${row.employee?.department?.name} | Gate Pass Auth :${row.employee?.gatePassAuth?'Yes' : 'No'}  | Call Type : ${row.callType}`:"Not Assigned"}
                <EditIcon cursor={"pointer"} ml="4px" onClick={() =>{
                    onOpen3();
                    setAuthStage(dataConstant.multiAuthSecondAuth);
                    setAuthStageMember(index+1);
                    setType("Update")
                }} color='#3182ce' size='xs'>
                </EditIcon>
                {row.employee != null ? 
                <DeleteIcon
                onClick={() =>{
                    onOpen3();
                    setAuthStage(dataConstant.multiAuthSecondAuth);
                    setAuthStageMember(index+1);
                    setType("Remove")
                }}
                 cursor={"pointer"} ml="4px" color='red' size='xs'></DeleteIcon> :<></>
                }
                </Text>
            ))
            )
        }
        
        {thirdAuth.members.length > 0 ?<>
          <Text fontSize="md" color={titleColor} fontWeight="bold" minWidth="100%">
            3rd Auth ({thirdAuth.assignedMember} Member) 
            </Text> </> : <></>}
          {
            thirdAuth.members.length == 0 ? (
                <>
                
                </>
            
            ) : (
                thirdAuth.members.map((row, index) => (
                <Text key={index} color={titleColor} fontWeight="bold">
                {row.employee != null ?`${row.employee?.employeeName} | ${row.employee?.phone} | ${row.employee?.department?.name} | Gate Pass Auth :${row.employee?.gatePassAuth?'Yes' : 'No'}  | Call Type : ${row.callType}`:"Not Assigned"}
                <EditIcon cursor={"pointer"} ml="4px" onClick={() =>{
                    onOpen3();
                    setAuthStage(dataConstant.multiAuthThirdAuth);
                    setAuthStageMember(index+1);
                    setType("Update")
                }} color='#3182ce' size='xs'>
                </EditIcon>
                {row.employee != null ? 
                <DeleteIcon
                onClick={() =>{
                    onOpen3();
                    setAuthStage(dataConstant.multiAuthThirdAuth);
                    setAuthStageMember(index+1);
                    setType("Remove")
                }}
                 cursor={"pointer"} ml="4px" color='red' size='xs'></DeleteIcon> :<></>
                }
                </Text>
            ))
            )
        }

        {fourthAuth.members.length > 0 ?<>
          <Text fontSize="md" color={titleColor} fontWeight="bold" minWidth="100%">
            4th Auth ({fourthAuth.assignedMember} Member) 
            </Text> </> : <></>}
          {
            fourthAuth.members.length == 0 ? (
                <>
                
                </>
            
            ) : (
                fourthAuth.members.map((row, index) => (
                <Text key={index} color={titleColor} fontWeight="bold">
                {row.employee != null ?`${row.employee?.employeeName} | ${row.employee?.phone} | ${row.employee?.department?.name} | Gate Pass Auth :${row.employee?.gatePassAuth?'Yes' : 'No'}  | Call Type : ${row.callType}`:"Not Assigned"}
                <EditIcon cursor={"pointer"} ml="4px" onClick={() =>{
                     onOpen3();
                     setAuthStage(dataConstant.multiAuthFourthAuth);
                     setAuthStageMember(index+1);
                     setType("Update")
                }} color='#3182ce' size='xs'>
                </EditIcon>
                {row.employee != null ? 
                <DeleteIcon
                onClick={() =>{
                    onOpen3();
                    setAuthStage(dataConstant.multiAuthFourthAuth);
                    setAuthStageMember(index+1);
                    setType("Remove");
                }}
                 cursor={"pointer"} ml="4px" color='red' size='xs'></DeleteIcon> :<></>
                }
                </Text>
            ))
            )
        }

        {fifthAuth.members.length > 0 ?<>
          <Text fontSize="md" color={titleColor} fontWeight="bold" minWidth="100%">
            5th Auth ({fifthAuth.assignedMember} Member) 
            </Text> </> : <></>}
          {
            fifthAuth.members.length == 0 ? (
                <>
                
                </>
            
            ) : (
                fifthAuth.members.map((row, index) => (
                <Text key={index} color={titleColor} fontWeight="bold">
                {row.employee != null ?`${row.employee?.employeeName} | ${row.employee?.phone} | ${row.employee?.department?.name} | Gate Pass Auth :${row.employee?.gatePassAuth?'Yes' : 'No'}  | Call Type : ${row.callType}`:"Not Assigned"}
                <EditIcon cursor={"pointer"} ml="4px" onClick={() =>{
                    onOpen3();
                    setAuthStage(dataConstant.multiAuthFifthAuth);
                    setAuthStageMember(index+1);
                    setType("Update")
                }} color='#3182ce' size='xs'>
                </EditIcon>
                {row.employee != null ? 
                <DeleteIcon
                onClick={() =>{
                    onOpen3();
                    setAuthStage(dataConstant.multiAuthFifthAuth);
                    setAuthStageMember(index+1);
                    setType("Remove")
                }}
                 cursor={"pointer"} ml="4px" color='red' size='xs'></DeleteIcon> :<></>
                }
                </Text>
            ))
            )
        }
          
        </Td>
        

        

      </Tr>

      <Modal isOpen={isOpen} onClose={onClose} size="sm">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text>{authStage}</Text>
            <Text fontSize={"14px"}>{authStageMember == 1? '1st' :authStageMember == 2? '2nd':authStageMember == 3?'3rd': `${authStageMember}th`} Member</Text>

          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <SimpleGrid>
          <FormControl isRequired>
            <FormLabel>Phone Number</FormLabel>
            <Input
                type="text"
                value={phone}
                onChange={(e) => {
                    setPhone(e.target.value);
                }}
            />
         </FormControl>
         </SimpleGrid>
          </ModalBody>
          
          <ModalFooter>
            <Button
            isLoading={loader}
            size={"sm"} colorScheme='green' mr={3} onClick={()=>{
                handleEmployeeSearch(phone);
            }}>
              Search
            </Button>
            {/* <Button size={"sm"} colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>
      {/* Employee Details Model */}
      <Modal isOpen={isOpen1} onClose={onClose1} size="sm">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center">Confirm Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4} align="center">
              {/* User Photo */}
              <Avatar size="xl" src={employeeDetail.photo} name={employeeDetail.name} />
              </VStack>
              {/* User Details */}
              <Box textAlign="center">
                <Text fontSize="lg" fontWeight="bold">Name: {employeeDetail.name}</Text>
                <Text fontSize="md">Type: {employeeDetail.type}</Text>
                <Text fontSize="md">Dept/Sub-Dept: {employeeDetail.department} / {employeeDetail.subdepartment}</Text>
                <Text fontSize="md">Gate Pass Module: {employeeDetail.gatePassModule ? "Yes" : "No"}</Text>
                <Divider orientation='horizontal' mt={"12px"} />
                <RadioGroup defaultValue='NONE' onChange={(value) => {
                    setModuleCallType(value);
                }}>
                <Stack spacing={5} direction='row'>
                <Radio colorScheme='blue' value='NONE'>
                    None
                    </Radio>
                    <Radio colorScheme='blue' value='PRIMARY'>
                    Primary
                    </Radio>
                    <Radio colorScheme='blue' value='SECONDARY'>
                    secondary
                    </Radio>
                </Stack>
                </RadioGroup>
              </Box>
            
          </ModalBody>
          <Divider />
          <ModalFooter display="flex" justifyContent="center">
            <Button size="sm" colorScheme="blue" onClick={() => {
                onClose1();
                handleAssignEmployee(id);
            }} mr={3}>
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Remove Confirmation Dialog */}
      <AlertDialog
          isOpen={isOpen2}
          leastDestructiveRef={cancelRef}
          onClose={onClose2}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Remove Employee
              </AlertDialogHeader>

              <AlertDialogBody>
                Are you sure? You can't undo this action afterwards.
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button size={"sm"} ref={cancelRef} onClick={onClose2}>
                  Cancel
                </Button>
                <Button
                size={"sm"}
                  colorScheme="red"
                  onClick={() => {
                    handleRemove(id);
                    onClose2();
                  }}
                  ml={3}
                >
                  Remove
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>

        <PasswordViewVerification
          reportName = {`Multi Auth Matrix` }
          type={typeModule}
        //   id={id}
          isOpen={isOpen3}
          onClose={onClose3}
          onExport={() =>
          {
            setPhone("");
            handelView();
          }
          }
      />
    </>
  );
};

export default PlantAdminMultiAuthMatrixTableRow;
