const {
    MEETING_ROOMS_LOADING,
    MEETING_ROOMS_GET_ALL_SUCCESS,
    MEETING_ROOMS_ERROR,
    MEETING_ROOMS_CLEAR_SNACKBAR,
    MEETING_ROOMS_SEARCH
  } = require('../constants/types');
  
  const initialState = {
    response: {},
    meetingroomsList: [],
    meetingroomsListDetail: {},
    loading: false,
    status: 'success',
    text: '',
    showSnackbar: false,
    limit : 0,
    page : 0,
    totalPages : 0,
    totalItems : 0,
    searching : ""
  };
  
  const meetingroomsReducer = (state = initialState, action) => {
    switch (action.type) {
      case MEETING_ROOMS_SEARCH:
        return {
          ...state,
          searching: action.payload.searching
        };
      case MEETING_ROOMS_CLEAR_SNACKBAR:
        return {
          ...state,
          showSnackbar: false
        };
      case MEETING_ROOMS_LOADING:
        return {
          ...state,
          loading: true
        };
      case MEETING_ROOMS_ERROR:
        return {
          ...state,
          meetingroomsList: [],
          response: {},
          meetingroomsListDetail: {},
          limit : 0,
          page : 0,
          totalPages : 0,
          totalItems : 0,
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true
        };
      case MEETING_ROOMS_GET_ALL_SUCCESS:
        return {
          ...state,
          meetingroomsList: action.payload.meetingroomsList,
          response: action.payload.response,
          meetingroomsListDetail: action.payload.meetingroomsListDetail,
          limit: action.payload.limit,
          page: action.payload.page,
          totalPages: action.payload.totalPages,
          totalItems: action.payload.totalItems,
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true
        };
      default:
        return state || {};
    }
  };
  
  export default meetingroomsReducer;
