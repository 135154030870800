// Chakra imports
import {
  Flex,
  InputGroup,
  Spacer,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  InputLeftElement,
  IconButton,
  Input,
  Icon,
  Link,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Pagination from "components/Tables/Pagination";
// import BannerPagination from 'src/views/Dashboard/BannerPagination.js';
import BannerPagination from '../../views/Dashboard/BannerPagination';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToast } from "@chakra-ui/react";
import {
  bannerGetAll,
  bannerDelete,
  bannerAddOrUpdate,
  bannerClearShowSnackbar,
} from "../../actions/bannerActions";
import { BANNER_CLEAR_SNACKBAR } from "constants/types";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import BannersTableRow from "components/Tables/BannersTableRow";
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { server_url } from '../../constants/types';


import { AddIcon } from '@chakra-ui/icons';


function BannerTable() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const responseStatus = useSelector((state) => state.banner.status);
  const responseMessage = useSelector((state) => state.banner.text);
  const loading = useSelector((state) => state.banner.loading);
  const bannerList = useSelector((state) => state.banner.bannerList);
  const response = useSelector((state) => state.banner.response);
  const showSnackbar = useSelector((state) => state.banner.showSnackbar);
  const dispatch = useDispatch();
  const toast = useToast();
  const searchIconColor = useColorModeValue("gray.700", "gray.200");
  const inputBg = useColorModeValue("#edf3f8", "navy.800");
  const [search, setSearch] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  const history = useHistory()
  useEffect(() => {
    dispatch(bannerGetAll());
  }, [dispatch]);
  console.log("bannerList")
  console.log(bannerList)
  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === "OK" && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, "success");
        }
      } else if (responseStatus === "NOK" && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, "error");
        }
      }
    }
    dispatch({
      type: BANNER_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      },
    });
  }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  };

  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("desc");
    }
    const sorting = "&sortOrder=" + sortOrder + "&column=" + column;
    dispatch(bannerGetAll(sorting));
  };

  useEffect(() => {
    const searching = "&search=" + search;
    console.log(searching);
    dispatch(bannerGetAll(searching));
  }, [search]);

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <Flex>
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Banners
            </Text>
          </CardHeader>
          <Spacer />


          <Flex justify="flex-end" style={{ padding: '10px', marginTop: '-5px', marginRight: '12px' }}>
            <Link href={`#/admin/banners-form`}>
              <IconButton
                colorScheme="blue"
                aria-label="Search database"
                icon={<AddIcon />}
              />
            </Link>
          </Flex>


          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              <InputGroup borderRadius="8px" w="200px">
                <InputLeftElement
                  children={
                    <IconButton
                      bg="inherit"
                      borderRadius="inherit"
                      _hover="none"
                      _active={{
                        bg: "inherit",
                        transform: "none",
                        borderColor: "transparent",
                      }}
                      _focus={{
                        boxShadow: "none",
                      }}
                      icon={
                        <SearchIcon
                          color={searchIconColor}
                          w="15px"
                          h="15px"
                        />
                      }
                    ></IconButton>
                  }
                />


                <Input
                  type="pass"
                  variant="search"
                  fontSize="xs"
                  bg={inputBg}
                  onKeyUp={(e) => {
                    setSearch(e.target.value);
                    if (e.target.value.length < 3) {
                      setSearch("");
                    }
                  }}
                  placeholder="Type here..."
                />
              </InputGroup>
            </Text>
          </CardHeader>
        </Flex>
        <CardBody pb={4}>
          <div style={{ overflowX: "auto" }}>
            <Table variant="simple" color={textColor}>
              <Thead>
                <Tr my=".8rem" pl="0px" color="gray.400">
                <Th
                    pl="15px"
                    borderColor={borderColor}
                    color="gray.400"
                    cursor="pointer"
                  >
                   SNO
                  </Th>
                  <Th
                    pl="0px"
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("name")}
                    cursor="pointer"
                  >
                    Name
                    {sortColumn === "name" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("image")}
                    cursor="pointer"
                  >
                    image
                    {sortColumn === "image" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("status")}
                    cursor="pointer"
                  >
                    Status
                    {sortColumn === "status" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("position")}
                    cursor="pointer"
                  >
                    Position
                    {sortColumn === "position" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("bannerPosition")}
                    cursor="pointer"
                  >
                    Banner Position
                    {sortColumn === "bannerPosition" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th borderColor={borderColor}></Th>
                  {/* <Th borderColor={borderColor}></Th> */}
                </Tr>
              </Thead>
              <Tbody>

                {
                  bannerList.map((row, index, arr) => {
                    return (
                      <BannersTableRow
                       serialnumber={((response.currentPage - 1)*response.limit) + (index+1) }
                        name={row.name}
                        image={row.image}
                        status={row.status}
                        position={row.position}
                        bannerPosition={row.bannerPosition.name}
                        isLast={index === arr.length - 1 ? true : false}
                        id={row._id}

                      />
                    );
                  })}

              </Tbody>
            </Table>
          </div>
          {bannerList.length === 0 ? (
            <></>
          ) : (
            <BannerPagination
              totalPages={response.totalPages}
              currentPage={response.currentPage}
              perPage={response.perPage}
              sortBy={sortColumn}
              sortOrder={sortOrder}
              handlePageClick={(data) => {
                const selectedPage = data.selected + 1;
                dispatch(bannerGetAll(`&page=${selectedPage}`));
              }}
            />
          )}
        </CardBody>
      </Card>
    </Flex>
  );
}

export default BannerTable;