import React from "react";
import {
  Avatar,
  Badge,
  Button,
  Flex,
  Link,
  Td,
  Text,
  Tr,
  useColorModeValue,
  useDisclosure,
  ButtonGroup,
  IconButton,
  Tooltip,

} from "@chakra-ui/react";
import { BsBoxArrowUpRight, BsFillTrashFill } from "react-icons/bs";
import { InfoOutlineIcon, ViewIcon } from "@chakra-ui/icons";
import { AiFillEdit } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
// import { deviceDelete } from "actions/devicesAction"; // Make sure you have the correct import for deviceDelete
import { materialDelete } from "../../../actions/materialAction";

const PastTableRow = (props) => {
  const {
    material,
    date,
    materialBy,
    vehicleNumber,
    materialShipmentType,
    vendor,
    purpose,
    status,
    type,
    weighment,
    inDateTime,
    exitDateTime,
    totalTime,
    txn,
    log,
    serialnumber,
    id,
    logs,
    key,
    isLast,
    gateInTime,
    gateOutTime,
    lastStatus,
    logText,
    department,
    subdepartment,
    invoices
  } = props;

  const textColor = useColorModeValue("gray.500", "white");
  const textColorRed = useColorModeValue("red.500", "red");
  const titleColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const materialDetail = useSelector((state) => state.material.materialDetail); // Make sure you have the correct state slice for device detail
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();

  const dispatch = useDispatch();

  console.log("log2222222222222222222");
  console.log(logs);

  // console.log(dates);
  const handleDelete = () => {
    const material = {
      _id: id,
    };
    dispatch(materialDelete(material._id)); // Use your deviceDelete action
  };
  //   const authDetail = useSelector((state) => state.auth.authDetail);
  let rowSpanValue = 0;
  invoices.forEach((element) => {
    rowSpanValue += element.materials.length;
  });
  if(invoices.length > 0)
  {
    return(
      <>
      {invoices.map((item, invoiceIndex) => (
      <React.Fragment key={invoiceIndex}>
        {item.materials.map((data, itemIndex) => (
          <Tr key={itemIndex}>
            {/* Only render these cells in the first row */}
            {invoiceIndex === 0 && itemIndex === 0 && (
              <>
                <Td rowSpan={rowSpanValue}
                    >
                      <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                        <Flex direction="column">
                          <Text
                            fontSize="md"
                            color={titleColor}
                            fontWeight="bold"
                            minWidth="100%"
                          >
                            {serialnumber}
                          </Text>
                        </Flex>
                      </Flex>
                    </Td>
                    <Td
                    pl="0px"
                    rowSpan={rowSpanValue}
                    borderColor={borderColor}
                    borderBottom={isLast ? "none" : "1px solid"}
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                  >
                    <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                      <Flex direction="column">
                        <Text
                          fontSize="md"
                          color={titleColor}
                          fontWeight="bold"
                          minWidth="100%"
                        >
                          {date}
                        </Text>
                      </Flex>
                    </Flex>
                  </Td>
                  <Td
                  rowSpan={rowSpanValue}
                    pl="0px"
                    borderColor={borderColor}
                    borderBottom={isLast ? "none" : "1px solid"}
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                  >
                    <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                      <Flex direction="column">
                        <Text
                          fontSize="md"
                          color={titleColor}
                          fontWeight="bold"
                          minWidth="100%"
                        >
                          {txn}
                        </Text>
                      </Flex>
                    </Flex>
                  </Td>
              </>
            )}

            {/* Invoice related data, span across items */}
            {itemIndex === 0 && (
              <Td rowSpan={item.materials.length}>
                <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                  <Flex direction="column">
                    <Text fontSize="md" color={titleColor} fontWeight="bold" minWidth="100%">
                      {item.invoiceNumber}
                    </Text>
                  </Flex>
                </Flex>
              </Td>
            )}

            {/* Per-item fields */}
            <Td>
              <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                <Flex direction="column">
                  <Text fontSize="md" color={titleColor} fontWeight="bold" minWidth="100%">
                  {data.materialDescription} - {data.quantity} {data.uom}
                  </Text>
                </Flex>
              </Flex>
            </Td>
            {/* Delivery Agent details */}
            

            {/* Action button */}
            {invoiceIndex === 0 && itemIndex === 0 && (
               <>
               <Td
                 pl="0px"
                 rowSpan={rowSpanValue}
                 borderColor={borderColor}
                 borderBottom={isLast ? "none" : "1px solid"}
                 whiteSpace="nowrap"
                 overflow="hidden"
                 textOverflow="ellipsis"
                 >
                   <Flex direction="column">
                     <Text fontSize="md" color={textColor} fontWeight="bold">
                       {department}
                     </Text>
                   </Flex>
                 </Td>
                 <Td pl="0px"
                 rowSpan={rowSpanValue}
                   borderColor={borderColor}
                   borderBottom={isLast ? "none" : "1px solid"}
                   whiteSpace="nowrap"
                   overflow="hidden"
                   textOverflow="ellipsis">
                   <Flex direction="column">
                     <Text fontSize="md" color={textColor} fontWeight="bold">
                       {subdepartment}
                     </Text>
                   </Flex>
                 </Td>
                 <Td pl="0px"
                 rowSpan={rowSpanValue}
                   borderColor={borderColor}
                   borderBottom={isLast ? "none" : "1px solid"}
                   whiteSpace="nowrap"
                   overflow="hidden"
                   textOverflow="ellipsis">
                   <Flex direction="column">
                     <Text fontSize="md" color={textColor} fontWeight="bold">
                       {materialBy}
                     </Text>
                   </Flex>
                 </Td>
                 <Td pl="0px"
                 rowSpan={rowSpanValue}
                   borderColor={borderColor}
                   borderBottom={isLast ? "none" : "1px solid"}
                   whiteSpace="nowrap"
                   overflow="hidden"
                   textOverflow="ellipsis">
                   <Flex direction="column">
                     <Text fontSize="md" color={textColor} fontWeight="bold">
                       {vehicleNumber}
                     </Text>
                   </Flex>
                 </Td>
                 <Td pl="0px"
                 rowSpan={rowSpanValue}
                   borderColor={borderColor}
                   borderBottom={isLast ? "none" : "1px solid"}
                   whiteSpace="nowrap"
                   overflow="hidden"
                   textOverflow="ellipsis">
                   <Flex direction="column">
                     <Text fontSize="md" color={textColor} fontWeight="bold">
                       {materialShipmentType}
                     </Text>
                   </Flex>
                 </Td>
                 <Td pl="0px"
                 rowSpan={rowSpanValue}
                   borderColor={borderColor}
                   borderBottom={isLast ? "none" : "1px solid"}
                   whiteSpace="nowrap"
                   overflow="hidden"
                   textOverflow="ellipsis">
                   <Flex direction="column">
                     <Text fontSize="md" color={textColor} fontWeight="bold">
                       {vendor}
                     </Text>
                   </Flex>
                 </Td>
                 <Td pl="0px"
                 rowSpan={rowSpanValue}
                   borderColor={borderColor}
                   borderBottom={isLast ? "none" : "1px solid"}
                   whiteSpace="nowrap"
                   overflow="hidden"
                   textOverflow="ellipsis">
                   <Flex direction="column">
                     <Text fontSize="md" color={textColor} fontWeight="bold">
                       {purpose}
                     </Text>
                   </Flex>
                 </Td>
                 <Td pl="0px"
                 rowSpan={rowSpanValue}
                   borderColor={borderColor}
                   borderBottom={isLast ? "none" : "1px solid"}
                   whiteSpace="nowrap"
                   overflow="hidden"
                   textOverflow="ellipsis">
                   <Flex direction="column">
                     <Text fontSize="md" color={textColor} fontWeight="bold">
                       {gateInTime}
                     </Text>
                   </Flex>
                 </Td>
                 <Td pl="0px"
                 rowSpan={rowSpanValue}
                   borderColor={borderColor}
                   borderBottom={isLast ? "none" : "1px solid"}
                   whiteSpace="nowrap"
                   overflow="hidden"
                   textOverflow="ellipsis">
                   <Flex direction="column">
                     <Text fontSize="md" color={textColor} fontWeight="bold">
                       {gateOutTime}
                     </Text>
                   </Flex>
                 </Td>
                 <Td pl="0px"
                 rowSpan={rowSpanValue}
                   borderColor={borderColor}
                   borderBottom={isLast ? "none" : "1px solid"}
                   whiteSpace="nowrap"
                   overflow="hidden"
                   textOverflow="ellipsis">
                   <Flex direction="column">
                     <Text fontSize="md" color={textColor} fontWeight="bold">
                       {totalTime}
                     </Text>
                   </Flex>
                 </Td>
         
         
                 
         
                 <Td pl="0px"
                 rowSpan={rowSpanValue}
                   borderColor={borderColor}
                   borderBottom={isLast ? "none" : "1px solid"}
                   whiteSpace="nowrap"
                   overflow="hidden"
                   textOverflow="ellipsis">
                   <Flex direction="column">
                     <Text fontSize="sm" color={textColorRed} fontWeight="bold">
                       {type}
                     </Text>
                   </Flex>
                 </Td>
                 <Td pl="0px"
                 rowSpan={rowSpanValue}
                   borderColor={borderColor}
                   borderBottom={isLast ? "none" : "1px solid"}
                   whiteSpace="nowrap"
                   overflow="hidden"
                   textOverflow="ellipsis">
                   <Badge colorScheme={status == "ALLOW" || status == "WAIT" || status == "NRGP" || status == "RGP" ? "green" : "red"}>{status}</Badge>
                 </Td>
                 {/* <Td pl="0px"
                   borderColor={borderColor}
                   borderBottom={isLast ? "none" : "1px solid"}
                   whiteSpace="nowrap"
                   overflow="hidden"
                   rowSpan={rowSpanValue}
                   textOverflow="ellipsis">
                   <Flex direction="column">
                     <Text fontSize="md" color={textColor} fontWeight="bold">
                       {weighment}
                     </Text>
                   </Flex>
                 </Td> */}
                 
                 
                 <Td
                   borderColor={borderColor}
                   borderBottom={isLast ? "none" : null}
                   overflowX="auto" // Add overflowX property for horizontal scrollbar
                   rowSpan={rowSpanValue}
                 >
                   <Flex direction="column">
                     {lastStatus.map((logItem, index) => (
                       <Text key={index} fontSize="md" color={textColor} whiteSpace="nowrap">
                         {logItem.description} {logItem.formattedDateTime} { }
                         <Tooltip hasArrow label={logText} aria-label='A tooltip' >
                       <InfoOutlineIcon  />
                     </Tooltip>
                       </Text>
                     ))}
                     
                   </Flex>
                 </Td>
                 <Td pl="0px"
              borderColor={borderColor}
              borderBottom={isLast ? "none" : "1px solid"}
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              rowSpan={rowSpanValue}
              >
              <ButtonGroup>
                <Link href={`#/admin/material-view-form/${id}`}>
                  <IconButton
                    colorScheme="blue"
                    icon={<ViewIcon />}
                    aria-label="Up"
                  />
                </Link>
    
                {/* <Link href={`#/admin/material-update-form/${id}`}>
                  <IconButton
                    colorScheme="green"
                    icon={<AiFillEdit />}
                    aria-label="Edit"
                  />
                </Link> */}
    
    
                {/* 
                <IconButton
                  colorScheme="red"
                  variant="outline"
                  icon={<BsFillTrashFill />}
                  aria-label="Delete"
                  onClick={onOpen}
                /> */}
              </ButtonGroup>
            </Td>
               </>
              
    
            )}
          </Tr>
        ))}
      </React.Fragment>
    ))}
      </>
    );
  }
  else
  {
    return (
      <>
        <Tr>
        <Td
            >
              <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                <Flex direction="column">
                  <Text
                    fontSize="md"
                    color={titleColor}
                    fontWeight="bold"
                    minWidth="100%"
                  >
                    {serialnumber}
                  </Text>
                </Flex>
              </Flex>
            </Td>
            <Td
            pl="0px"
            borderColor={borderColor}
            borderBottom={isLast ? "none" : "1px solid"}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
              <Flex direction="column">
                <Text
                  fontSize="md"
                  color={titleColor}
                  fontWeight="bold"
                  minWidth="100%"
                >
                  {date}
                </Text>
              </Flex>
            </Flex>
          </Td>
          <Td
            pl="0px"
            borderColor={borderColor}
            borderBottom={isLast ? "none" : "1px solid"}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
              <Flex direction="column">
                <Text
                  fontSize="md"
                  color={titleColor}
                  fontWeight="bold"
                  minWidth="100%"
                >
                  {txn}
                </Text>
              </Flex>
            </Flex>
          </Td>
          <Td
            pl="0px"
            borderColor={borderColor}
            borderBottom={isLast ? "none" : "1px solid"}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
              <Flex direction="column">
                <Text
                  fontSize="md"
                  color={titleColor}
                  fontWeight="bold"
                  minWidth="100%"
                >
                  {material.map((materialItem, index) => (
                <Text key={index} fontSize="md" color={textColor} whiteSpace="nowrap">
                  {materialItem.invoiceNumber}
                </Text>
              ))}
                </Text>
              </Flex>
            </Flex>
          </Td>
          <Td
            pl="0px"
            borderColor={borderColor}
            borderBottom={isLast ? "none" : "1px solid"}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
              <Flex direction="column">
                <Text
                  fontSize="md"
                  color={titleColor}
                  fontWeight="bold"
                  minWidth="100%"
                >
                  {material.map((materialItem, index) => (
                <Text key={index} fontSize="md" color={textColor} whiteSpace="nowrap">
                  {materialItem.materialDescription} - {materialItem.quantity} {materialItem.uom} 
                </Text>
              ))}
                </Text>
              </Flex>
            </Flex>
          </Td>
          <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : "1px solid"}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          >
            <Flex direction="column">
              <Text fontSize="md" color={textColor} fontWeight="bold">
                {department}
              </Text>
            </Flex>
          </Td>
          <Td pl="0px"
            borderColor={borderColor}
            borderBottom={isLast ? "none" : "1px solid"}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis">
            <Flex direction="column">
              <Text fontSize="md" color={textColor} fontWeight="bold">
                {subdepartment}
              </Text>
            </Flex>
          </Td>
          <Td pl="0px"
            borderColor={borderColor}
            borderBottom={isLast ? "none" : "1px solid"}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis">
            <Flex direction="column">
              <Text fontSize="md" color={textColor} fontWeight="bold">
                {materialBy}
              </Text>
            </Flex>
          </Td>
          <Td pl="0px"
            borderColor={borderColor}
            borderBottom={isLast ? "none" : "1px solid"}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis">
            <Flex direction="column">
              <Text fontSize="md" color={textColor} fontWeight="bold">
                {vehicleNumber}
              </Text>
            </Flex>
          </Td>
          <Td pl="0px"
            borderColor={borderColor}
            borderBottom={isLast ? "none" : "1px solid"}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis">
            <Flex direction="column">
              <Text fontSize="md" color={textColor} fontWeight="bold">
                {materialShipmentType}
              </Text>
            </Flex>
          </Td>
          <Td pl="0px"
            borderColor={borderColor}
            borderBottom={isLast ? "none" : "1px solid"}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis">
            <Flex direction="column">
              <Text fontSize="md" color={textColor} fontWeight="bold">
                {vendor}
              </Text>
            </Flex>
          </Td>
          <Td pl="0px"
            borderColor={borderColor}
            borderBottom={isLast ? "none" : "1px solid"}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis">
            <Flex direction="column">
              <Text fontSize="md" color={textColor} fontWeight="bold">
                {purpose}
              </Text>
            </Flex>
          </Td>
          <Td pl="0px"
            borderColor={borderColor}
            borderBottom={isLast ? "none" : "1px solid"}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis">
            <Flex direction="column">
              <Text fontSize="md" color={textColor} fontWeight="bold">
                {gateInTime}
              </Text>
            </Flex>
          </Td>
          <Td pl="0px"
            borderColor={borderColor}
            borderBottom={isLast ? "none" : "1px solid"}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis">
            <Flex direction="column">
              <Text fontSize="md" color={textColor} fontWeight="bold">
                {gateOutTime}
              </Text>
            </Flex>
          </Td>
          <Td pl="0px"
            borderColor={borderColor}
            borderBottom={isLast ? "none" : "1px solid"}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis">
            <Flex direction="column">
              <Text fontSize="md" color={textColor} fontWeight="bold">
                {totalTime}
              </Text>
            </Flex>
          </Td>
  
  
          
  
          <Td pl="0px"
            borderColor={borderColor}
            borderBottom={isLast ? "none" : "1px solid"}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis">
            <Flex direction="column">
              <Text fontSize="sm" color={textColorRed} fontWeight="bold">
                {type}
              </Text>
            </Flex>
          </Td>
          <Td pl="0px"
            borderColor={borderColor}
            borderBottom={isLast ? "none" : "1px solid"}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis">
            <Badge colorScheme={status == "ALLOW" || status == "WAIT" || status == "NRGP" || status == "RGP" ? "green" : "red"}>{status}</Badge>
          </Td>
          {/* <Td pl="0px"
            borderColor={borderColor}
            borderBottom={isLast ? "none" : "1px solid"}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis">
            <Flex direction="column">
              <Text fontSize="md" color={textColor} fontWeight="bold">
                {weighment}
              </Text>
            </Flex>
          </Td> */}
          
          
          <Td
            borderColor={borderColor}
            borderBottom={isLast ? "none" : null}
            overflowX="auto" // Add overflowX property for horizontal scrollbar
          >
            <Flex direction="column">
              {lastStatus.map((logItem, index) => (
                <Text key={index} fontSize="md" color={textColor} whiteSpace="nowrap">
                  {logItem.description} {logItem.formattedDateTime} { }
                  <Tooltip hasArrow label={logText} aria-label='A tooltip' >
                <InfoOutlineIcon  />
              </Tooltip>
                </Text>
              ))}
              
            </Flex>
          </Td>
          <Td pl="0px"
            borderColor={borderColor}
            borderBottom={isLast ? "none" : "1px solid"}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis">
            <ButtonGroup>
              <Link href={`#/admin/material-view-form/${id}`}>
                <IconButton
                  colorScheme="blue"
                  icon={<ViewIcon />}
                  aria-label="Up"
                />
              </Link>
  
              {/* <Link href={`#/admin/material-update-form/${id}`}>
                <IconButton
                  colorScheme="green"
                  icon={<AiFillEdit />}
                  aria-label="Edit"
                />
              </Link> */}
  
  
              {/* 
              <IconButton
                colorScheme="red"
                variant="outline"
                icon={<BsFillTrashFill />}
                aria-label="Delete"
                onClick={onOpen}
              /> */}
            </ButtonGroup>
          </Td>
  
  
  
  
          <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Delete Device
                </AlertDialogHeader>
  
                <AlertDialogBody>
                  Are you sure? You can't undo this action afterwards.
                </AlertDialogBody>
  
                <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={onClose}>
                    Cancel
                  </Button>
                  <Button colorScheme="red" onClick={() => { handleDelete(); onClose(); }} ml={3}>
                    Delete
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </Tr>
      </>
    );
  }
};

export default PastTableRow;
