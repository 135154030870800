import React, { useEffect, useState,useRef } from 'react';
import { Flex, InputGroup, Spacer, Table, Tbody, Text, Td, Th, Thead, Tr, useColorModeValue, InputLeftElement, IconButton, Input, Icon, Link ,Button, Tabs, TabList, TabPanels, TabPanel, Tab,Badge,
    Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
  Spinner,
  SimpleGrid,
  FormControl,
  FormLabel,
  Select
} from "@chakra-ui/react";
import { 
WarningTwoIcon
} from "@chakra-ui/icons";

import { useDispatch, useSelector } from 'react-redux';

import { useParams ,useLocation,useHistory} from "react-router-dom/cjs/react-router-dom.min";
import axios from 'axios';
import { server_url } from "constants/types";
import dataConstant from 'constants/dataConstant';

const PasswordViewVerification = ({ reportName,type="",id="",isOpen, onClose, onExport }) => {
  const tableRef = useRef(null);
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const dispatch = useDispatch();
  const toast = useToast();
  const searchIconColor = useColorModeValue("gray.700", "gray.200");
  const inputBg = useColorModeValue("#edf3f8", "navy.800");
  
  
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState("");
  const [otp, setOtp] = useState('');
  const [password, setPassword] = useState('');
  const [reason, setReason] = useState('');
  const [others, setOthers] = useState('');

//   Get Otp function
const ViewDataFunction = () => {
    setLoading(true);
    const body = {
        "name" : reportName,
        "reason" : reason,
        "others" : others,
        "password" : password
    }
    if(type)
    {
        body.type = type;
    }
    if(id)
    {
        body.employee = id;
    }
    axios
      .post(`${server_url}/admin/verify/password`,body, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((response) => {
        if(response.data.status == 'OK')
        {
            setData(response.data.data);
            toastFunction(response.data.message, 'success');
            onClose();
            onExport();
        }
        else
        {
            toastFunction(response.data.message, 'error');
        }
      }).catch(() => console.log("error"))

      setLoading(false);
  }


  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  };

  return (
  <>

    <Modal onClose={onClose} isOpen={isOpen} size="xl" isCentered>
    <ModalOverlay />
    <ModalContent>
        <ModalHeader fontSize="16px"><span style={{
          color : "red"
        }}><WarningTwoIcon scale="10"></WarningTwoIcon> THIS ACTION SHALL BE AUDITED  </span>- VIEW DETAILS</ModalHeader>
        <ModalHeader fontSize="14px">Authenticate to Continue</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
        <SimpleGrid minChildWidth="120px" spacing="25px">
        <FormControl isRequired>
              <FormLabel>Select Reason</FormLabel>
              <Select
                placeholder="Select reason"
                onChange={(e) => {
                //   setIsErrorStatus(e.target.value === '');
                  setReason(e.target.value);
                  console.log(e.target.value);
                }}
              >
                {dataConstant.reportAuthenticationReason.map((element, index) => (
                  <option key={element.id} value={element.value}>
                    {element.name}
                  </option>
                ))}
              </Select>
              {/* {!isErrorStatus ? (
                <></>
              ) : (
                <FormErrorMessage>Status is required.</FormErrorMessage>
              )} */}
            </FormControl >
            {reason == dataConstant.reportAuthenticationOthers?
                <FormControl isRequired>
                <FormLabel>Others</FormLabel>
                <Input
                  type="pass"
                  onChange={(e) => {
                    // setIsErrorName(e.target.value === '');
                    setOthers(e.target.value);
                  }}
                />
                {/* {!isErrorName ? (
                  <></>
                ) : (
                  <FormErrorMessage>Name is required.</FormErrorMessage>
                )} */}
              </FormControl>:<></>
            }
            </SimpleGrid>
        <SimpleGrid minChildWidth="120px" spacing="40px" mt="8px">
            <FormControl isRequired>
              <FormLabel>Enter your password</FormLabel>
              <Input
                type="pass"
                onChange={(e) => {
                  // setIsErrorName(e.target.value === '');
                  setPassword(e.target.value);
                }}
              />
              {/* {!isErrorName ? (
                <></>
              ) : (
                <FormErrorMessage>Name is required.</FormErrorMessage>
              )} */}
            </FormControl>
            </SimpleGrid>
        </ModalBody>
        <ModalFooter>
        
        {loading ? <Spinner mt='24px' size='lg' />:<Button m="4px" size='sm' onClick={()=>
            {
                ViewDataFunction();
            }
        } colorScheme="blue" _hover={{ backgroundColor: 'black', color: 'white' }}>Confirm</Button>}
        </ModalFooter>
    </ModalContent>
    </Modal>
  </>
  );
};

export default PasswordViewVerification;
